import React, { useEffect, useState } from 'react';

import type {
  AuditReport as AuditReportModel,
  UserFilterSegment,
} from 'models';

import { useAppDispatch } from 'helpers/hooks';
import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';
import { Match } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { DataLoaderProvidedProps } from 'lib/dataLoader/types';
import { get, put } from 'redux/actions/api';

import { Box, Icon, Text } from 'components';

import UserFilter from 'scenes/components/UserFilter';

import AuditReportPageHeader from './AuditReportPageHeader';
import AuditStats from './AuditStats';
import AuditedUsersTable from './AuditedUsersTable';

type RouterProps = {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps & {
  auditReport: AuditReportModel;
};

type AfterLoadProps = DataLoaderProvidedProps & Props;

const AuditReport = ({ auditReport, match }: AfterLoadProps) => {
  const { urlQueryParams, replaceHistory } = useUrlQueryParams();
  const [userFilter, setUserFilter] = useState<UserFilterSegment | undefined>(
    urlQueryParams().userFilter || undefined
  );
  const [isDataUpdating, setIsDataUpdating] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = {
      ...urlQueryParams(),
      userFilter: userFilter,
      page: 1,
    };
    replaceHistory(params);
  }, [urlQueryParams, replaceHistory, userFilter]);

  const refreshData = async () => {
    setIsDataUpdating(true);
    await dispatch(put(`audit_reports/${auditReport.id}`));
    setIsDataUpdating(false);
  };

  if (!auditReport) return null;
  return (
    <div>
      {!!auditReport && (
        <AuditReportPageHeader
          auditReport={auditReport}
          refreshData={refreshData}
          isDataUpdating={isDataUpdating}
        />
      )}
      <div className="m-4  md:m-6">
        {isDataUpdating && (
          <Text>
            <Icon name="sync" /> {__('Report data are being updated')}
          </Text>
        )}
      </div>
      <div className="m-4  md:m-6">
        <UserFilter segment={userFilter} onChange={setUserFilter} />
      </div>
      <div className="m-4 md:m-6">
        <AuditStats
          auditReportId={match.params.id}
          userFilter={userFilter}
          lastUpdate={auditReport.dataUpdatedAt}
        />
        <Box>
          <AuditedUsersTable
            auditReportId={match.params.id}
            paginationType="url"
            defaultPaginationParams={{ filter: { late: true } }}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            userFilter={userFilter}
            lastUpdate={auditReport.dataUpdatedAt}
          />
        </Box>
      </div>
    </div>
  );
};

export default newDataLoader({
  fetch: ({ match }) => get(`audit_reports/${match.params.id}`),
  hydrate: {
    auditReport: {
      tags: {},
    },
  },
})(AuditReport);
