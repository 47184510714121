import React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import type { LegendItemData } from './Legend';

import Legend from './Legend';
import { RoleStats } from './QuestionPieChart';
import ChartTooltip from './Tooltip';

const colors = [
  '#81c1fd',
  '#fea58e',
  '#ea90b1',
  '#f5c78e',
  '#bda9ea',
  '#51d3d9',
  '#a2d28f',
  '#9784c2',
];

export const noAnswerColor = '#D3DADC';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = props => {
  const { cx, cy, midAngle, innerRadius, outerRadius, payload } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const text = !!payload.percentage ? `${payload.percentage}%` : 'n/a';

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize="12px"
      fontWeight="600"
      fontFamily="ProximaNova"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {text}
    </text>
  );
};

type Props = {
  data: RoleStats;
  title: string;
  onLegendItemClick: (label: string | null) => void;
};

const SimplePieChart = ({ data, title, onLegendItemClick }: Props) => {
  const legendItemData: Array<LegendItemData> = [];
  // TODO: TSFIXME: ideally this should be Array<PieChartValue> but making the values nullable break a lot of type elsewhere
  const displayedValues: Array<{
    label: string | null;
    value: number;
    percentage: number | null;
    realPercentage?: number | null;
    color?: string;
  }> = [];
  let totalPercentage = 0;

  data.values.forEach((itemStat, index) => {
    let newItem = {
      ...itemStat,
      color: colors[index % colors.length],
    };
    legendItemData.push(newItem);
    totalPercentage += newItem.percentage || 0;

    if (!!itemStat.percentage && itemStat.percentage > 0) {
      displayedValues.push(newItem);
    }
  });

  if (totalPercentage === 0) {
    displayedValues.push({
      label: null,
      value: 1,
      realPercentage: null,
      percentage: null,
    });
  }

  return (
    <div>
      <PieChart width={164} height={164} style={{ margin: 'auto' }}>
        <Pie
          data={displayedValues}
          cx={80}
          cy={80}
          labelLine={false}
          label={renderCustomizedLabel}
          isAnimationActive={false}
          startAngle={90}
          endAngle={450}
          innerRadius={55}
          outerRadius={80}
          fill="#D3DADC"
          paddingAngle={1}
          dataKey="value"
          nameKey="label"
        >
          {displayedValues.map(
            entry =>
              !!entry.percentage &&
              entry.percentage > 0 && (
                <Cell
                  fill={entry.label === null ? noAnswerColor : entry.color}
                  key={entry.label}
                />
              )
          )}
        </Pie>
        <Tooltip
          isAnimationActive={false}
          content={props => <ChartTooltip {...props} />}
          cursor={false}
        />
        <text
          x={84}
          y={86}
          fill="black"
          fontSize="14px"
          fontWeight="600"
          fontFamily="ProximaNova"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {title}
        </text>
      </PieChart>

      <Legend
        data={legendItemData}
        style={{ marginTop: 16 }}
        onLegendItemClick={onLegendItemClick}
      />
    </div>
  );
};

export default SimplePieChart;
