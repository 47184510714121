// @flow
import * as React from 'react';

import type { ReviewCycle } from 'models';

import icon360 from './icon360.png';
import iconManager from './iconManager.png';

type Props = {|
  reviewCycle: ReviewCycle,
|};

export default function ReviewCycleIcon({ reviewCycle }: Props) {
  const style = {
    height: '24px',
    width: '24px',
    lineHeight: '24px',
    backgroundImage: `url("${
      reviewCycle.interactionType === '360' ? icon360 : iconManager
    }")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'middle',
    display: 'inline-block',
  };

  return <span style={style} />;
}
