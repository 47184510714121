// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ReviewCycle, ThreeSixtyUserReview } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n/index';
import invariant from 'helpers/invariant';

import { put } from 'redux/actions/api';

import { MenuItem, Toggleable } from 'components';

import DelegateModal from './DelegateModal';

type Props = {|
  userReview: ThreeSixtyUserReview,
  reviewCycle: ReviewCycle,

  onAfterAction: () => Promise<any>,
|};

type AfterConnectProps = {|
  ...Props,
  updateResponsible: (
    responsibleId: string | null,
    responsibleRole: string
  ) => Promise<any>,
|};

function UpdateResponsibleAction(props: AfterConnectProps) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          <MenuItem onClick={toggle}>{__('Update responsible')}</MenuItem>
          {isToggled && (
            <DelegateModal
              isActive={isToggled}
              userReviewId={props.userReview.id}
              reviewCycle={props.reviewCycle}
              onSubmit={async ({ responsible, responsibleRole }) => {
                invariant(responsibleRole);
                await props.updateResponsible(
                  responsible ? responsible.id : null,
                  responsibleRole
                );
                toggle();
              }}
              onCancel={toggle}
            />
          )}
        </React.Fragment>
      )}
    />
  );
}

function mapDispatchToProps(dispatch: AppDispatch, ownProps: Props) {
  return {
    updateResponsible: async (
      responsibleId: string | null,
      responsibleRole: string
    ) => {
      await dispatch(
        put(`user_reviews/${ownProps.userReview.id}/delegate`, {
          responsibleId,
          responsibleRole,
        })
      );
      ownProps.onAfterAction && (await ownProps.onAfterAction());
    },
  };
}

export default (connect(
  null,
  mapDispatchToProps
)(UpdateResponsibleAction): React.ComponentType<Props>);
