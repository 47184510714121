// @flow
import * as React from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/navigation';

import {
  BoxListItem,
  Column,
  type ColumnSize,
  Columns,
  Control,
  HamburgerMenu,
  MenuItem,
  MenuList,
  PullRight,
  Text,
  Tooltip,
} from 'components';
import Icon from 'components/Icon';

import UserAvatar from 'scenes/components/UserAvatar';

import UserListItemInvitationTooltip from './UserListItemInvitationTooltip';

const UserListItemAvatarColumn = ({ user }) => (
  <Column tabletSize={3} isVerticallyCentered>
    <UserAvatar user={user} link={pathToUser(user.id)} withJobTitle />
  </Column>
);

type UserListItemDataColumnProps = {
  label: string,
  data: string,
  size?: ColumnSize,
  hideOnMobile?: boolean,
};
const UserListItemDataColumn = ({
  label,
  data,
  size,
  hideOnMobile,
}: UserListItemDataColumnProps) => (
  <Column
    size={size}
    isVerticallyCentered
    ellipsisOnLineOverflow
    hideOn={hideOnMobile ? 'mobile' : undefined}
  >
    <Text color="info">{label}</Text> <Text title={data}>{data}</Text>
  </Column>
);

const UserListItemActionsColumn = ({ children, visible, userId }) => (
  <Column
    isNarrow
    isVerticallyCentered
    style={{ visibility: visible ? 'visible' : 'hidden' }}
    testClassName={`test-user-list-actions-${userId}`}
  >
    <PullRight>{children}</PullRight>
  </Column>
);

const UserListItemExternalTooltipColumn = ({ external }) => {
  return (
    <Column isVerticallyCentered>
      {external && (
        <Tooltip content={__('External to the organization')}>
          <Icon additionalClassName={'external-icon'} name="public" />
        </Tooltip>
      )}
    </Column>
  );
};

type UserListItemProps = {
  user: User,
  manager: ?User,
  actions: Array<React.Element<typeof MenuItem>>,
};

export default function UserListItem({
  user,
  manager,
  actions,
}: UserListItemProps) {
  return (
    <BoxListItem key={user.id}>
      <Columns isMobile>
        <UserListItemAvatarColumn user={user} />
        <UserListItemDataColumn
          size={3}
          label={__('Manager:')}
          data={manager ? manager.fullName : '-'}
          hideOnMobile
        />
        <UserListItemDataColumn
          label={__('Email:')}
          data={user.email ? user.email : '-'}
          hideOnMobile
        />
        <Column isNarrow isVerticallyCentered>
          <Columns isMobile>
            {user.isActive && (
              <React.Fragment>
                <UserListItemExternalTooltipColumn external={user.external} />
                {user.invitationStatus && (
                  <Column isVerticallyCentered>
                    <UserListItemInvitationTooltip
                      userFirstName={user.firstName}
                      invitationStatus={user.invitationStatus}
                      invitedAt={user.invitedAt}
                      invitationAcceptedAt={user.invitationAcceptedAt}
                      email={user.email}
                      useSSO={user.useSSO}
                    />
                  </Column>
                )}
              </React.Fragment>
            )}
            <UserListItemActionsColumn
              visible={actions.length > 0}
              userId={user.id}
            >
              <Control>
                <HamburgerMenu align="right">
                  <MenuList>{actions}</MenuList>
                </HamburgerMenu>
              </Control>
            </UserListItemActionsColumn>
          </Columns>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
