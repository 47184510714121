import React, { useState } from 'react';

import { PeopleUserReviewPlannedAction } from 'models';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import useUpdatePlannedAction from '../hooks/useUpdatePlannedAction';
import PeopleReviewActionForm from './PeopleReviewActionForm';

type Props = {
  plannedAction: PeopleUserReviewPlannedAction;
  onClose: () => void;
  afterUpdate: () => any;
};

function ActionPlanUpdateModal({ plannedAction, onClose, afterUpdate }: Props) {
  const defaultEditedPlannedActionObject = {
    title: plannedAction.title,
    tagLabels: plannedAction.tags.map(tag => tag.label),
    description: plannedAction.description,
    dueDate: plannedAction.dueDate,
    status: plannedAction.status,
  } as Partial<PeopleUserReviewPlannedAction>;

  const [editedPlannedAction, setEditedPlannedAction] = useState(
    defaultEditedPlannedActionObject
  );

  const { handleSubmit, errors } = useUpdatePlannedAction(
    plannedAction,
    afterUpdate
  );

  return (
    <ModalCard isActive onClose={onClose} refreshContentOnOpening isLarge>
      <ModalCardHead>
        <ModalCardTitle>
          {__('Update action for %1', plannedAction.user.fullName)}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <PeopleReviewActionForm
          plannedActionObject={editedPlannedAction}
          setPlannedActionObject={setEditedPlannedAction}
          errors={errors}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
        <Button
          color="primary"
          onClick={() => handleSubmit(editedPlannedAction)}
          disabled={!editedPlannedAction.title}
        >
          {__('Update action')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}

export default ActionPlanUpdateModal;
