import React from 'react';

import type { NavigationItem } from 'components/navigation/Tabs/NavigationList';
import type { Match } from 'helpers/paths';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ReviewCycle, Session } from 'models';
import type { RouteComponentProps } from 'react-router-dom';

import can from 'helpers/can';
import { useAppSelector } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { anyTemplateWithSkillsInCycle } from 'helpers/models/reviewCycle';
import {
  pathToReviewCycleAnalytics,
  pathToReviewCycleParticipants,
  pathToReviewCycleResults,
  pathToReviewCycleSkills,
  pathToReviewCycleTemplates,
} from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';
import { getCurrentSession } from 'redux/reducers';

import {
  ContentContainer,
  DesignSystem,
  FetchContainer,
  Icon,
  PageTitle,
  Redirect,
  Route,
  Switch,
} from 'components';

import Skills from 'scenes/components/reviewCycle/analytics/Skills';

import Analytics from './Analytics';
import Header from './Header';
import LaunchingCycle from './LaunchingCycle';
import Participants from './Participants';
import PendingParticipantAddition from './PendingParticipantAddition';
import Results from './Results';
import ResultViewFeaturePromptModal from './Results/ResultViewFeaturePromptModal';
import Templates from './Templates';

type RouterProps = RouteComponentProps & {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps;

type AfterDataloaderProps = Props &
  DataLoaderProvidedProps & {
    reviewCycle: ReviewCycle;
  };

function NewReviewCycleManage({
  match,
  reviewCycle,
  isFetching,
  hasError,
}: AfterDataloaderProps) {
  const [resultModal, setResultModal] = React.useState(false);
  const session = useAppSelector(getCurrentSession) as Session;

  const shouldShowResultsAsLocked =
    !session.organization.plan.resultViewEnabled;

  const skillsEnabled =
    session.organization.featureFlags.includes(
      'mockDataForSkillsAndCareersAnalytics'
    ) ||
    session.organization.featureFlags.includes('skillsAndCareersAnalytics');

  return (
    <DesignSystem version={2}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          const shouldShowResultsTab = can({
            perform: 'show_results_tab',
            on: reviewCycle,
          });
          const shouldShowSkillsTab =
            skillsEnabled &&
            can({ perform: 'show_skills_tab', on: reviewCycle });

          if (reviewCycle.status === 'launching') {
            return (
              <React.Fragment>
                <PageTitle title={[reviewCycle.name, __('Admin')]} />
                <LaunchingCycle reviewCycle={reviewCycle} />
              </React.Fragment>
            );
          }

          const pendingParticipantAddition =
            reviewCycle.pendingParticipantAddition;
          if (!!pendingParticipantAddition) {
            return (
              <React.Fragment>
                <PageTitle title={[reviewCycle.name, __('Admin')]} />
                <PendingParticipantAddition
                  participantAddition={pendingParticipantAddition}
                  reviewCycleId={reviewCycle.id}
                />
              </React.Fragment>
            );
          }

          const tabItems: Array<NavigationItem> = [
            {
              label: __('Participants'),
              to: pathToReviewCycleParticipants(reviewCycle.id),
              testName: 'test-participants-link',
            },
            {
              label: __('Progress'),
              to: pathToReviewCycleAnalytics(reviewCycle.id),
              testName: 'test-analytics-link',
            },
          ];

          if (shouldShowResultsTab) {
            let resultsTabItem: NavigationItem = {
              label: __('Results'),
              testName: 'test-results-link',
              rightContent: null,
              to: pathToReviewCycleResults(reviewCycle.id),
            };

            if (shouldShowResultsAsLocked) {
              resultsTabItem = {
                ...resultsTabItem,
                rightContent: <Icon size="small" name="lock" />,
                onClick: () => {
                  setResultModal(!resultModal);
                },
              };
            }

            tabItems.push(resultsTabItem);
          }

          if (
            shouldShowSkillsTab &&
            anyTemplateWithSkillsInCycle(reviewCycle)
          ) {
            tabItems.push({
              label: __('Skills'),
              to: pathToReviewCycleSkills(reviewCycle.id),
            });
          }

          if (session.isImpersonated) {
            tabItems.push({
              label: __('Templates'),
              to: `${pathToReviewCycleTemplates(reviewCycle.id)}/`,
            });
          }

          return (
            <div className="review-cycle-manage">
              <Header reviewCycle={reviewCycle} tabItems={tabItems} />

              {!!shouldShowResultsAsLocked && (
                <ResultViewFeaturePromptModal
                  isActive={resultModal}
                  onClose={() => setResultModal(!resultModal)}
                />
              )}

              <ContentContainer>
                <Switch>
                  <Route
                    exact
                    path={`${match.path}/analytics`}
                    render={() => <Analytics reviewCycle={reviewCycle} />}
                  />
                  <Route
                    exact
                    path={`${match.path}/participants`}
                    render={() => (
                      <Participants
                        paginationType="url"
                        defaultPaginationParams={{ filter: { all: true } }}
                        reviewCycle={reviewCycle}
                      />
                    )}
                  />
                  {shouldShowResultsTab && (
                    <Route
                      exact
                      path={`${match.path}/results`}
                      render={() => <Results reviewCycle={reviewCycle} />}
                    />
                  )}
                  {shouldShowSkillsTab && (
                    <Route
                      exact
                      path={`${match.path}/skills`}
                      render={() => <Skills reviewCycle={reviewCycle} />}
                    />
                  )}
                  {session.isImpersonated && (
                    <Route
                      path={`${match.path}/templates`}
                      render={() => (
                        <Templates
                          reviewCycle={reviewCycle}
                          userIsImpersonated={session.isImpersonated}
                        />
                      )}
                    />
                  )}
                  <Redirect
                    to={pathToReviewCycleParticipants(match.params.id)}
                  />
                </Switch>
              </ContentContainer>
            </div>
          );
        }}
      />
    </DesignSystem>
  );
}

export default newDataLoader({
  fetch: (ownProps: Props) => get(`review_cycles/${ownProps.match.params.id}`),
  hydrate: {
    reviewCycle: {
      pendingParticipantAddition: {},
      abilities: {},
      defaultTemplate: {},
      templates: {},
      lastPendingExportAction: {},
      responsible: {},
      tags: {},
    },
  },
  cacheKey: ({ match }: Props) => match.params.id,
})(NewReviewCycleManage) as React.ComponentType<Props>;
