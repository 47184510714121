// @flow
import * as React from 'react';

import type { RadarChart as RadarChartType } from 'models';

import colors from 'styles/colors';

import { RadarChart } from 'components';

type Props = {|
  radarChart: RadarChartType,
  onCriterionClick?: (criterion: string) => any,
|};

export default function MultipleScaleQuestionRadarChart({
  radarChart,
  onCriterionClick,
}: Props) {
  const colorsMap = {
    reviewee: colors.radarChart.reviewee,
    reviewer: colors.radarChart.reviewer,
    additionalReviewer: colors.radarChart.additionalReviewer,
  };

  const radarOptions = Object.entries(radarChart.legend).map(([key, name]) => ({
    dataKey: key,
    name,
    color: colorsMap[key],
  }));

  const orderedLabels = Object.keys(radarChart.polarAxisLabels).sort(
    (a, b) => radarChart.polarAxisLabels[a] - radarChart.polarAxisLabels[b]
  );

  return (
    <RadarChart
      axisLabels={orderedLabels}
      data={radarChart.data}
      axisDataKey="label"
      onCriterionClick={onCriterionClick}
      // $FlowIgnore https://github.com/facebook/flow/issues/4771
      radarOptions={radarOptions}
    />
  );
}
