import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, User, UserReview } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { withPagination } from 'lib/dataLoader';
import { fetchUserReviewsForUser } from 'redux/actions';

import {
  Box,
  BoxList,
  BreadcrumbAnchor,
  DatatableWrapper,
  FetchContainer,
  Loading,
} from 'components';

import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState';

import UserReviewListItem from './UserReviewListItem';

type Props = {
  user: User;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  userReviewCollection: PaginatedCollection<UserReview>;
  isFetching: boolean;
  hasError: boolean;
};

function UserReviewList({
  userReviewCollection,
  isFetching,
  hasError,
  ...paginationParams
}: AfterConnectProps) {
  const userReviews = userReviewCollection?.items;

  if (!userReviewCollection && isFetching) {
    return <Loading containerStyle={{ padding: 15 }} />;
  }

  if (userReviews?.length === 0) {
    return <ReviewEmptyState title={__('There is no review for this user')} />;
  }

  return (
    <Box additionalClassName="mb-12">
      <DatatableWrapper
        collectionInfo={omit(userReviewCollection, 'items')}
        isFetching={isFetching}
        hasError={hasError}
        {...paginationParams}
        totalCountRenderer={count => n__('%1 review', '%1 reviews', count || 0)}
        renderHeader={() => null}
      >
        <FetchContainer
          loadingStyle="overlay"
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            return (
              <BoxList>
                {userReviews?.map(userReview => (
                  <UserReviewListItem
                    key={userReview.id}
                    userReview={userReview}
                  />
                ))}
              </BoxList>
            );
          }}
        />
      </DatatableWrapper>
    </Box>
  );
}

const UserReviewListWithBreadcrumb = (props: AfterConnectProps) => (
  <React.Fragment>
    <BreadcrumbAnchor name="user_review_list" />
    <UserReviewList {...props} />
  </React.Fragment>
);

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({ user, page, countPerPage }) =>
      fetchUserReviewsForUser(user, {
        page,
        countPerPage,
        abilities: ['view_results'],
      }),
    hydrate: {
      userReviewCollection: {
        items: {
          user: {},
          manager: {},
          evaluations: {},
          reviewCycle: {},
          abilities: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
      }),
  })
)(UserReviewListWithBreadcrumb);
