import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Flex, Icon, type MaterialIconName, SupportLink } from 'components';

type Props = {
  children: ReactNode | null | undefined;
  kind?: 'dark' | 'primary' | 'info' | 'success' | 'warning' | 'danger';
  style?: CSSProperties;
  onDismiss?: () => void;
  additionalClassName?: string;
  testClassName?: string;
  withContact: boolean;
  icon?: MaterialIconName;
};

function Notification({
  kind,
  children,
  style,
  onDismiss,
  withContact,
  additionalClassName,
  testClassName,
  icon,
}: Props) {
  return (
    <div
      style={style}
      className={classNames(
        'notification',
        testClassName,
        additionalClassName,
        {
          [`is-${kind ? kind : ''}`]: !!kind,
        }
      )}
    >
      <Flex additionalClassName="gap-2">
        {icon && <Icon style={{ marginTop: 2 }} name={icon} />}
        {children && <div style={{ display: 'block' }}>{children}</div>}
      </Flex>
      {onDismiss ? <button onClick={onDismiss} className="delete" /> : ''}

      {withContact && (
        <p>
          {__(
            '%1 for more information.',
            <SupportLink>{__('Contact us')}</SupportLink>
          )}
        </p>
      )}
    </div>
  );
}

Notification.defaultProps = {
  withContact: false,
};

export default Notification;
