// @flow
import * as React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models/index';

import { __ } from 'helpers/i18n/index';
import { getSelectedPeers } from 'helpers/models/evaluation';

import { MenuItem, Toggleable } from 'components/index';

import ValidatePeersModal from './ValidatePeersModal';

type Props = {|
  userReview: ThreeSixtyUserReview,
  reviewCycle: ThreeSixtyReviewCycle,
  renderAction: (onClick: () => void) => React.Node,
  onAfterAction?: () => Promise<*>,
|};

export { ValidatePeersModal };
export { default as NominatePeersModal } from './NominatePeersModal';

export default function ManagePeersAction({
  userReview,
  reviewCycle,
  renderAction,
  onAfterAction,
}: Props) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          {renderAction(toggle)}
          {isToggled && (
            <ValidatePeersModal
              user={userReview.user}
              isActive
              onClose={toggle}
              reviewCycle={reviewCycle}
              userReview={userReview}
              onAfterValidate={onAfterAction}
              peers={getSelectedPeers(userReview.evaluations)}
            />
          )}
        </React.Fragment>
      )}
    />
  );
}

ManagePeersAction.defaultProps = {
  renderAction: onClick => (
    <MenuItem onClick={onClick}>{__('Manage peers')}</MenuItem>
  ),
};
