import React from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';

import { Entity, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n/index';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  SearchBar,
  SimpleTable,
  Text,
} from 'components';

type Props = WithPaginationProps & { roleName: string; adminId: string };

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = Props &
  PaginationProps &
  DataLoaderProvidedProps & { entityCollection: PaginatedCollection<Entity> };

const EntityList = ({
  search,
  page,
  countPerPage,
  entityCollection,
  isFetching,
  hasError,
  onSearchChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterDataLoaderProps) => {
  return (
    <div className="my-2">
      <DatatableWrapper
        collectionInfo={entityCollection}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <EmptyState title={__('No entity matches your search')} />
        )}
        renderHeader={() => (
          <SearchBar
            value={search}
            placeholder={__('Search')}
            onChange={onSearchChange}
            style={{ marginBottom: 8 }}
          />
        )}
        totalCountRenderer={totalCount =>
          n__('%1 entity', '%1 entities', totalCount || 0)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              withBorder
              columns={[
                {
                  header: __('Entity'),
                  cell: entity => <Text preset="16bs5.5">{entity.name}</Text>,
                },
                {
                  header: __('Number of users'),
                  cell: entity => (
                    <Text>{n__('%1 user', '%1 users', entity.usersCount)}</Text>
                  ),
                },
              ]}
              rows={entityCollection?.items || []}
              keyFn={entity => entity.id}
              emptyState={<div />}
              additionalClassName="mb-4"
            />
          )}
        />
      </DatatableWrapper>
    </div>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      roleName,
      adminId,
      page,
      countPerPage,
      search,
      filter,
    }: AfterPaginateProps) =>
      get(`users/${adminId}/administered_entities`, {
        roleName,
        page,
        countPerPage,
        search,
        filter,
      }),
    hydrate: {
      entityCollection: {
        items: {},
      },
    },
    cacheKey: ({
      roleName,
      adminId,
      page,
      countPerPage,
      search,
      filter,
    }: AfterPaginateProps) =>
      compositeKey({ roleName, adminId, page, countPerPage, search, filter }),
  })
)(EntityList);
