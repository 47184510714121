import React, { Fragment } from 'react';

import type { Integration } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Toggleable,
} from 'components';

import IntegrationModal from './IntegrationModal';
import {
  ADPIntegrationDefaultSettings,
  BambooHRIntegrationDefaultSettings,
  EureciaIntegrationDefaultSettings,
  LuccaIntegrationDefaultSettings,
  PayfitIntegrationDefaultSettings,
  SFTPIntegrationDefaultSettings,
} from './IntegrationModal/IntegrationSettings';

type Option = {
  label: string;
  value: string;
};

const getOptions = (): Array<Option> => [
  {
    label: __('Payfit'),
    value: 'Payfit',
  },
  {
    label: __('Lucca'),
    value: 'Lucca',
  },
  {
    label: __('Eurecia'),
    value: 'Eurecia',
  },
  {
    label: __('SFTP'),
    value: 'SFTP',
  },
  {
    label: __('Bamboo HR'),
    value: 'BambooHR',
  },
  {
    label: __('ADP DECIDIUM'),
    value: 'ADP',
  },
];

const getDefaultSettingsFromIntegrationType = (type: string) => {
  switch (type) {
    case 'Payfit':
      return PayfitIntegrationDefaultSettings;
    case 'Eurecia':
      return EureciaIntegrationDefaultSettings;
    case 'Lucca':
      return LuccaIntegrationDefaultSettings;
    case 'BambooHR':
      return BambooHRIntegrationDefaultSettings;
    case 'SFTP':
      return SFTPIntegrationDefaultSettings;
    case 'ADP':
      return ADPIntegrationDefaultSettings;
    default:
      return {};
  }
};

function buildNewIntegration(integrationType: string): Integration {
  return {
    id: 0,
    active: false,
    integrationType: integrationType,
    launchedAt: '',
    createdAt: '',
    lastSync: undefined,
    settings: getDefaultSettingsFromIntegrationType(integrationType),
    syncTime: '',
  };
}

const IntegrationCreator = () => {
  const [newIntegration, setNewIntegration] =
    React.useState<Integration | null>(null);
  const dispatch = useAppDispatch();

  const createIntegration = async (integration: Partial<Integration>) => {
    await dispatch(post(`/integrations`, { integration }));
    window.location.reload();
  };

  return (
    <Fragment>
      <Dropdown align="right">
        <DropdownTrigger
          render={() => (
            <Button color="primary">
              <span>{__('New integration')}</span>
              <Icon name="keyboard_arrow_down" />
            </Button>
          )}
        />
        <DropdownMenu>
          <Toggleable
            render={() =>
              getOptions().map(option => (
                <DropdownMenuItem
                  key={option.value}
                  onClick={() =>
                    setNewIntegration(buildNewIntegration(option.value))
                  }
                >
                  {option.label}
                </DropdownMenuItem>
              ))
            }
          />
        </DropdownMenu>
      </Dropdown>

      {!!newIntegration && (
        <IntegrationModal
          integration={newIntegration}
          createIntegration
          onClose={() => setNewIntegration(null)}
          onConfirm={createIntegration}
        />
      )}
    </Fragment>
  );
};

export default IntegrationCreator;
