import React from 'react';

import type { TrainingRequest } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { del } from 'redux/actions/api';

import {
  BoxListItem,
  Checkbox,
  Column,
  Columns,
  ConfirmationModal,
  HamburgerMenu,
  Icon,
  Link,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  currentTrainingPeriodSlug: string;
  trainingRequest: TrainingRequest;
  onClick: () => void;
  onSelect: () => void;
  selected: boolean;
  onAfterDelete?: () => Promise<void>;
};

const TrainingRequestListItem = ({
  currentTrainingPeriodSlug,
  trainingRequest,
  onClick,
  onSelect,
  selected,
  onAfterDelete,
}: Props) => {
  const dispatch = useAppDispatch();

  const deleteTrainingRequest = async () => {
    await dispatch(
      del(
        `training/periods/${currentTrainingPeriodSlug}/requests/${trainingRequest.id}`
      )
    );

    !!onAfterDelete && (await onAfterDelete());
  };

  const [isDeletionModalActive, setIsDeletionModalActive] =
    React.useState(false);

  const columnsStyle = {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  };

  let disabledExplanation: string | undefined = undefined;
  if (!!trainingRequest.session) {
    disabledExplanation = __(
      'This request is already linked to the session: "%1"',
      trainingRequest.session.name || __('Untitled session')
    );
  }
  if (trainingRequest.approvalStatus === 'refused') {
    disabledExplanation = __('This request is refused');
  }

  return (
    <BoxListItem
      testClassName="test-training-request-list-item"
      additionalClassName={selected ? 'bg-highlight-gray' : undefined}
    >
      <Columns style={columnsStyle} additionalClassName="py-4">
        <Column isNarrow additionalClassName="training-request-checkbox py-0">
          <Tooltip content={disabledExplanation}>
            <Checkbox
              onChange={onSelect}
              isChecked={selected}
              disabled={!!disabledExplanation}
              size="small"
            />
          </Tooltip>
        </Column>

        <Column ellipsisOnLineOverflow additionalClassName="py-0 pl-0">
          <Link hasInheritedColor onClick={onClick}>
            {trainingRequest.title}
          </Link>
        </Column>

        <Column ellipsisOnLineOverflow additionalClassName="py-0">
          {trainingRequest.session ? (
            <Link
              hasInheritedColor
              to={pathToTrainingSessionDetails(trainingRequest.session.id)}
            >
              {trainingRequest.session?.name || __('Untitled session')}
            </Link>
          ) : (
            <Text transformation="italic" color="light">
              {__('Not linked to a session')}
            </Text>
          )}
        </Column>

        <Column ellipsisOnLineOverflow size={3} additionalClassName="py-0">
          <UserAvatar user={trainingRequest.trainee} withJobTitle />
        </Column>

        <Column
          isNarrow
          additionalClassName="training-requests-approval-column py-0"
          size={3}
        >
          {approvalStatusLabelWithIcon(trainingRequest.approvalStatus)}
        </Column>
        <Column
          isNarrow
          contentIsPulledRight
          additionalClassName="training-requests-action-column py-0"
        >
          <HamburgerMenu>
            <MenuList>
              <MenuItem onClick={() => setIsDeletionModalActive(true)}>
                {__('Delete')}
              </MenuItem>
            </MenuList>
          </HamburgerMenu>
        </Column>
      </Columns>
      {isDeletionModalActive && (
        <ConfirmationModal
          isDanger
          isActive
          onCancel={() => setIsDeletionModalActive(false)}
          onConfirm={deleteTrainingRequest}
          cancelLabel={__('Cancel')}
          confirmLabel={__('Remove request')}
          title={__('Confirm deletion')}
          refreshContentOnOpening={false}
        >
          <p>
            {__(
              "This action will remove the training request from %1's profile. The employee will be informed by email that the training request has been removed.",
              trainingRequest.trainee.fullName
            )}
          </p>
          <p>{__('Are you sure you want to remove this training request ?')}</p>
          <div style={{ marginTop: 16 }}>
            <Text color="danger">
              <Icon style={{ marginRight: 8 }} name="warning" />
              {__('This action cannot be undone')}
            </Text>
          </div>
        </ConfirmationModal>
      )}
    </BoxListItem>
  );
};

export default TrainingRequestListItem;
