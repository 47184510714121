import type { AbilitiesBearer, Ability, AbilityId } from 'models';

function abilitiesSet(on: AbilitiesBearer): Set<AbilityId> | null | undefined {
  const { abilities: cast } = on;
  const abilities: Array<Ability> = cast as any;

  if (!abilities) return null;

  return new Set(abilities.map(action => action.id));
}

type Props = {
  perform: AbilityId;
  on: AbilitiesBearer;
};

export default function can({ perform, on }: Props): boolean {
  const abilities = abilitiesSet(on);

  if (abilities === null) {
    // `type` is on all resources, but is not typed in front to avoid using it in business code
    const { type, id } = on as any;

    throw new Error(
      `No ability found checking for ${perform} on ${type}#${id}`
    );
  }

  if (!!abilities && abilities.has(perform)) {
    return true;
  }

  return false;
}
