import React from 'react';

import type { PeopleReviewMappingCellData, UserFilterSegment } from 'models';

import classNames from 'helpers/classNames';
import { n__ } from 'helpers/i18n/__';

import { NoNullableValues } from 'types/predicates/NoNullValues';

import { Text } from 'components';

import { SelectedDimensionIds } from '.';
import HeatmapCellDetailsModal from './HeatmapCellDetailsModal';

type Props = {
  cycleId: string;
  dimensionIds: NoNullableValues<SelectedDimensionIds>;
  style: React.CSSProperties;
  position: { x: number; y: number };
  dimensionLabels: { x: string; y: string };
  cellData: PeopleReviewMappingCellData;
  userFilter: UserFilterSegment | undefined;
};

const PeopleReviewCycleHeatmapCell = ({
  cycleId,
  dimensionIds,
  style,
  position,
  dimensionLabels,
  cellData,
  userFilter,
}: Props) => {
  const [isModalActive, setIsModalActive] = React.useState<boolean>(false);

  const isClickable = cellData.reviewCount > 0;
  const dimensionValues = {
    x: position.x + 1,
    y: position.y + 1,
  };
  const onCellClick = () => isClickable && setIsModalActive(true);

  return (
    <React.Fragment>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames('flex flex-1 flex-col gap-2 rounded-lg p-4', {
          'cursor-pointer transition duration-200 hover:brightness-90':
            isClickable,
        })}
        onClick={onCellClick}
        style={style}
      >
        <Text preset="18bs5">{cellData.value}%</Text>
        <Text preset="14s6">
          {n__('i.e. %1 employee', 'i.e. %1 employees', cellData.reviewCount)}
        </Text>
      </div>
      {isModalActive && (
        <HeatmapCellDetailsModal
          cycleId={cycleId}
          dimensionIds={dimensionIds}
          onClose={() => setIsModalActive(false)}
          dimensionValues={dimensionValues}
          dimensionLabels={dimensionLabels}
          cellData={cellData}
          userFilter={userFilter}
        />
      )}
    </React.Fragment>
  );
};
export default PeopleReviewCycleHeatmapCell;
