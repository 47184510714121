import React, { createContext, useState } from 'react';

import { __ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import {
  BoldHtmlText,
  Button,
  ContentContainer,
  DesignSystem,
  Icon,
  PageHeader,
  PageTitle,
  Text,
} from 'components';

import AdminList from './AdminList';
import RolesManagementModal from './RolesManagementModal';

type DataContextType = {
  shouldRefetchAdmins: boolean;
  setShouldRefetchAdmins: (value: boolean) => void;
};
export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

const Rights = () => {
  const [isRolesManagementModalActive, setIsRolesManagementModalActive] =
    useState(false);
  const [shouldRefetchAdmins, setShouldRefetchAdmins] = useState(false);
  const documentationURL = zendeskURL({
    en: '4404727300625-Managing-administrator-rights-roles-and-permissions',
    fr: '4404727300625-G%C3%A9rer-les-droits-administrateurs-r%C3%B4les-et-permissions',
  });

  return (
    <DesignSystem version={2}>
      <DataContext.Provider
        value={{
          shouldRefetchAdmins,
          setShouldRefetchAdmins,
        }}
      >
        <PageTitle title={__('Administrators')} />
        <PageHeader
          title={__('Administrators')}
          actions={
            <Button
              key="add-admin-button"
              color="primary"
              onClick={() => setIsRolesManagementModalActive(true)}
            >
              <Icon name="add" additionalClassName="mr-1" />
              {__('Assign a role')}
            </Button>
          }
        />

        <ContentContainer>
          <Text>
            <BoldHtmlText
              html={__(
                'Manage <b>admin roles</b> on Elevo products and give them the necessary level of visibility.'
              )}
            />
          </Text>
          <Text additionalClassName="ml-1">
            {__(
              'For more information, %1.',
              <a href={documentationURL}>{__('visit our help center')}</a>
            )}
          </Text>

          <div className="mt-8">
            <AdminList paginationType="url" />
          </div>
        </ContentContainer>
        {isRolesManagementModalActive && (
          <RolesManagementModal
            onClose={() => setIsRolesManagementModalActive(false)}
          />
        )}
      </DataContext.Provider>
    </DesignSystem>
  );
};

export default Rights;
