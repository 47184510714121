import { isEqual } from 'lodash';
import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { guessTimeZone } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToTrainingSessionIndex } from 'helpers/paths';

import { del, put } from 'redux/actions/api';

import {
  Box,
  Button,
  Icon,
  PullRight,
  SavingStatusLabel,
  WithSavingStatusRecorder,
} from 'components';

import { openRemoveTrainingSessionModal } from '../../TrainingSessions/openRemoveTrainingSessionModal';
import EditableFields from './EditableFields';

type Props = {
  trainingSession: TrainingSession;
};

const Details = ({ trainingSession: trainingSessionFromProps }: Props) => {
  const [errors, setErrors] = React.useState<FormErrors>({});
  const [trainingSession, setTrainingSession] = React.useState<TrainingSession>(
    trainingSessionFromProps
  );
  const dispatch = useAppDispatch();

  const updateTrainingSession = ({ period, ...session }: TrainingSession) =>
    dispatch(
      put(`training/sessions/${trainingSession.id}`, {
        trainingSession: {
          ...session,
          periodId: period.id,
          timezone: guessTimeZone(),
        },
      })
    );

  const deleteTrainingSession = () =>
    dispatch(del(`training/sessions/${trainingSession.id}`));

  const onAfterDestroy = () =>
    navigate(pathToTrainingSessionIndex(trainingSession.period.slug));

  React.useEffect(() => {
    setTrainingSession(trainingSessionFromProps);
  }, [trainingSessionFromProps]);

  const persistSessionChanges = async (
    newTrainingSession?: TrainingSession
  ) => {
    setErrors({});
    if (!!newTrainingSession) setTrainingSession(newTrainingSession);
    await handleFormErrors(
      () => updateTrainingSession(newTrainingSession || trainingSession),
      setErrors,
      true
    );
  };

  const hasChangesOrErrors = (newTrainingSession?: TrainingSession): boolean =>
    !isEqual(newTrainingSession || trainingSession, trainingSessionFromProps) ||
    Object.keys(errors).length > 0;

  return (
    <Fragment>
      <Box additionalClassName="training-session-details-form">
        <PullRight style={{ marginBottom: 8 }}>
          <SavingStatusLabel
            failedText={() => __('The training session could not be updated')}
          />
        </PullRight>
        <WithSavingStatusRecorder
          fieldUid={compositeKey({
            trainingSessionId: trainingSession.id,
            type: 'training_session',
          })}
          onChange={persistSessionChanges}
          render={autoSavingOnChange => (
            <Fragment>
              <EditableFields
                trainingSession={trainingSession}
                setTrainingSession={setTrainingSession}
                onChange={(newTrainingSession?: TrainingSession) => {
                  if (hasChangesOrErrors(newTrainingSession))
                    autoSavingOnChange(newTrainingSession);
                }}
                errors={errors}
              />
            </Fragment>
          )}
        />
      </Box>
      {can({ perform: 'destroy', on: trainingSession }) && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            color="danger"
            onClick={() => {
              openRemoveTrainingSessionModal(
                trainingSession,
                deleteTrainingSession,
                onAfterDestroy
              );
            }}
          >
            <Icon style={{ marginRight: 8 }} name="delete" />
            {__('Delete session')}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default Details;
