import type { NestedResource } from 'models';

type Item<T> = {
  data: NestedResource<T>;
  position: number;
};

type Params<T> = {
  items: Array<T>;
  afterChange?: (items: Array<Item<T>>) => any;
};

export default class NestedResourceWrapper<T> {
  items: Array<Item<T>>;
  afterChange: (items: Array<Item<T>>) => any | null | undefined;

  constructor(params: Params<T>) {
    this.items = params.items.map((data, position) => {
      return { data: { _destroy: false, ...data }, position };
    });

    // @ts-ignore TSFIXME: Fix strictNullChecks error
    this.afterChange = params.afterChange || null;
  }

  map(fn: (data: T, position?: number) => any): any {
    return this.items
      .filter(item => !item.data._destroy)
      .map(item => fn(item.data, item.position));
  }

  onDelete(element: T) {
    this.withAfterChange(() => {
      const index = this.findIndex(element);

      this.items[index].data._destroy = true;
    });
  }

  onCreate(newItem: T) {
    this.withAfterChange(() => {
      this.items.push({
        data: { _destroy: false, ...newItem },
        position: this.items.length,
      });
    });
  }

  onUpdate(oldItem: T, newItem: Partial<T>) {
    this.withAfterChange(() => {
      const index = this.findIndex(oldItem);

      this.items[index].data = { _destroy: false, ...oldItem, ...newItem };
    });
  }

  findIndex(element: T) {
    return this.items.findIndex(item => item.data === element);
  }

  notDeleted(): Array<T> {
    return this.items
      .filter(item => !item.data._destroy)
      .map(item => item.data);
  }

  withAfterChange(action: () => any) {
    action();

    if (this.afterChange) {
      this.afterChange(this.items);
    }
  }
}
