import React from 'react';

import type { ReviewCycle, ThreeSixtyReviewCycle } from 'models';
import type { Moment } from 'moment';

import { dateToDefaultDateStringFormat } from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Control, Field, Label, Testable, Text } from 'components';

type Props = {
  reviewCycle: ReviewCycle;
  tomorrow: Moment;
  readOnly: boolean;
  onFieldChange: (attributes: Partial<ThreeSixtyReviewCycle>) => Promise<void>;
};

export default function ThreeSixtyDeadlines({
  reviewCycle,
  tomorrow,
  readOnly,
  onFieldChange,
}: Props) {
  return (
    <Field additionalClassName="header-field inline-editable-field">
      <Label>{__('Deadline')}</Label>

      <Control additionalClassName="date-control">
        <Testable name="test-review-cycle-reviewee-date">
          <Text
            isEditable={!readOnly}
            editableOptions={{
              type: 'date',
              value: !!reviewCycle.dateReviewRevieweeEnd
                ? new Date(reviewCycle.dateReviewRevieweeEnd)
                : null,
              onChange: dateReviewRevieweeEnd => {
                const dateReviewReviewerEnd = dateReviewRevieweeEnd;
                return onFieldChange({
                  dateReviewRevieweeEnd: dateToDefaultDateStringFormat(
                    dateReviewRevieweeEnd
                  ),
                  dateReviewReviewerEnd: dateToDefaultDateStringFormat(
                    dateReviewReviewerEnd
                  ),
                });
              },
              fieldProps: {
                minDate: tomorrow.toDate(),
              },
            }}
          />
        </Testable>
      </Control>
    </Field>
  );
}
