import { sortBy } from 'lodash';
import React, { Fragment } from 'react';

import type { PeopleGroup } from 'models';

import { __ } from 'helpers/i18n';

import { Tag, Text } from 'components';

type Props = {
  peopleGroups: PeopleGroup[];
};

const PeopleGroupTagList = ({ peopleGroups }: Props) => {
  if (peopleGroups.length === 0) {
    return (
      <Text transformation="italic" color="grey">
        {__('Is not assigned to a group')}
      </Text>
    );
  }

  return (
    <Fragment>
      {sortBy(peopleGroups, pg => pg.position).map(peopleGroup => (
        <Tag
          key={peopleGroup.id}
          additionalClassName="people-group-tag"
          testClassName="test-people-group-tag"
          style={{ maxWidth: '100%' }}
          isRounded
        >
          <div className="is-text-overflow-ellipsis">{peopleGroup.title}</div>
        </Tag>
      ))}
    </Fragment>
  );
};

export default PeopleGroupTagList;
