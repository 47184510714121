import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useOrganization } from 'helpers/hooks';

import { DesignSystem, Route, Switch } from 'components';

import AuditReport from './AuditReport';
import Audits from './index';

export default function Routes() {
  const { path } = useRouteMatch();
  const organization = useOrganization();
  const featureFlagEnabled = organization.featureFlags.includes('audits');

  return (
    <DesignSystem version={2}>
      <Switch>
        {featureFlagEnabled && (
          <Route path={`${path}`} exact component={Audits} />
        )}

        {featureFlagEnabled && (
          <Route exact path={`${path}/:id`} component={AuditReport} />
        )}
      </Switch>
    </DesignSystem>
  );
}
