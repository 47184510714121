import React from 'react';
import { compose } from 'redux';

import type { UpdatableReviewCycleFields } from './AutoAddParticipantsConfiguration';
import type {
  AutoAddRule,
  Organization,
  ResponsibleRole,
  ReviewCycle,
  User,
} from 'models';
import type { UpdateReviewCycle } from 'scenes/admin/reviewCycles/Setup/Edit/index';

import can from 'helpers/can';
import { __, n__ } from 'helpers/i18n';
import { withOrganization } from 'helpers/withSessionProps';

import {
  BigSelectableButton,
  Column,
  Columns,
  FeatureFlagged,
} from 'components';

import AutoAddParticipantsModal from 'scenes/admin/reviewCycles/Setup/Edit/steps/Step3Participants/AutoAddNotEnabledModal';
import ParticipantsChooserComponent from 'scenes/admin/reviewCycles/components/ParticipantsChooser';
import StepContainer from 'scenes/components/Stepper/StepContainer';

import automaticAddSvg from '../../../components/svg/automaticAddSvg';
import manualAddSvg from '../../../components/svg/manualAddSvg';
import AutoAddParticipantsConfiguration from './AutoAddParticipantsConfiguration';
import LegacyAutoAddParticipantsConfiguration from './LegacyAutoAddParticipantsConfiguration';
import NotAllowedToCreateAutoAddRulesModal from './NotAllowedToCreateAutoAddRulesModal';
import ParticipantsChooser from './ParticipantsChooser';
import ResponsiblePicker from './ResponsiblePicker';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
  goToNextStep: () => void;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

const isRuleCompleted = (rule: AutoAddRule): boolean =>
  rule.userSegments.length !== 0 && !!rule.daysThreshold && !!rule.operator;

export function isParticipantsStepCompleted(reviewCycle: ReviewCycle): boolean {
  if (reviewCycle.autoAddParticipantsEnabled) {
    if (reviewCycle.autoAddMode === 'static') {
      return !!reviewCycle.addParticipantsBasedOnWorkStartDateAfterDays;
    }
    return (
      reviewCycle.autoAddRules.length > 0 &&
      reviewCycle.autoAddRules.every(isRuleCompleted)
    );
  }
  return reviewCycle.plannedParticipantIds.length > 0;
}

function Step3Participants({
  reviewCycle,
  updateReviewCycle,
  goToNextStep,
  organization,
}: AfterConnectProps) {
  const [isPlanNotEnabledModalActive, setIsPlanNotEnabledModalActive] =
    React.useState(false);
  const [
    isNotAllowedToCreateAutoAddRulesModalActive,
    setIsNotAllowedToCreateAutoAddRulesModalActive,
  ] = React.useState(false);

  const { autoAddParticipantsEnabled, responsible, responsibleRole } =
    reviewCycle;

  const canPassStep = isParticipantsStepCompleted(reviewCycle);

  const continueLabel =
    reviewCycle.plannedParticipantIds.length === 0
      ? __('Continue')
      : n__(
          'Continue with a single participant',
          'Continue with %1 participants',
          reviewCycle.plannedParticipantIds.length
        );

  const onResponsibleChange = ({
    responsible,
    responsibleRole,
  }: {
    responsible: User | null | undefined;
    responsibleRole: ResponsibleRole | null | undefined;
  }) => {
    let params: Partial<UpdatableReviewCycleFields> = { responsibleRole };

    if (!!responsible) {
      params = { responsibleId: responsible.id, ...params };
    }
    return updateReviewCycle(params);
  };

  const onReviewCycleVisibilityChange = (visibilityOptions: {
    visibleForReviewee?: boolean;
    visibleForSuperiors?: boolean;
  }) => updateReviewCycle({ ...visibilityOptions });

  if (reviewCycle.interactionType === '360') {
    return (
      <StepContainer
        title={__('Participant list')}
        titlePreset="24bs2"
        subtitle={__(
          'Select participants that will be reviewed in this campaign'
        )}
        canPassStep={canPassStep}
        continueLabel={continueLabel}
        onContinueClick={goToNextStep}
        stepName="participants"
      >
        <ParticipantsChooserComponent
          reviewCycle={reviewCycle}
          showBulkActions
          renderAfter={refetchParticipants => (
            <ResponsiblePicker
              onReviewCycleVisibilityChange={onReviewCycleVisibilityChange}
              reviewInteractionType={reviewCycle.interactionType}
              visibleForSuperiors={reviewCycle.visibleForSuperiors}
              visibleForReviewee={reviewCycle.visibleForReviewee}
              responsible={responsible}
              responsibleRole={responsibleRole}
              style={{ marginBottom: 16 }}
              onChange={async attributes => {
                await onResponsibleChange(attributes);

                !!refetchParticipants && (await refetchParticipants());
              }}
            />
          )}
        />
      </StepContainer>
    );
  }

  return (
    <React.Fragment>
      <StepContainer
        title={__('How do you want to add participants?')}
        titlePreset="24bs2"
        canPassStep={canPassStep}
        continueLabel={continueLabel}
        onContinueClick={goToNextStep}
        stepName="participants"
      >
        <Columns additionalClassName="mt-1">
          <Column>
            <BigSelectableButton
              svg={manualAddSvg}
              title={__(
                'By picking them now from the list of existing employees'
              )}
              subtitle={__(
                'Recommended for annual, development and career reviews, etc.'
              )}
              onClick={() =>
                updateReviewCycle({ autoAddParticipantsEnabled: false })
              }
              testClassName="test-manual-participants-addition-button"
              isActive={autoAddParticipantsEnabled === false}
            />
          </Column>
          <Column>
            <BigSelectableButton
              svg={automaticAddSvg}
              title={__(
                'By automating the addition of employees according to their arrival date'
              )}
              subtitle={__(
                'Recommended for discovery reports, trial period validation, etc.'
              )}
              tag={
                !organization.plan.autoAddParticipantsEnabled
                  ? __('Upgrade')
                  : undefined
              }
              onClick={async () => {
                if (!organization.plan.autoAddParticipantsEnabled) {
                  setIsPlanNotEnabledModalActive(true);
                  return;
                }

                if (
                  !can({ perform: 'create_auto_add_rule', on: reviewCycle })
                ) {
                  setIsNotAllowedToCreateAutoAddRulesModalActive(true);
                  return;
                }

                await updateReviewCycle({
                  autoAddParticipantsEnabled: true,
                });
              }}
              testClassName="test-automatic-participants-addition-button"
              isActive={autoAddParticipantsEnabled === true}
            />
          </Column>
        </Columns>

        {autoAddParticipantsEnabled === false && (
          <ParticipantsChooser
            reviewCycle={reviewCycle}
            renderAfter={refetchParticipants => (
              <ResponsiblePicker
                onReviewCycleVisibilityChange={onReviewCycleVisibilityChange}
                reviewInteractionType={reviewCycle.interactionType}
                visibleForSuperiors={reviewCycle.visibleForSuperiors}
                visibleForReviewee={reviewCycle.visibleForReviewee}
                responsible={responsible}
                responsibleRole={responsibleRole}
                style={{ marginBottom: 16 }}
                onChange={async attributes => {
                  await onResponsibleChange(attributes);

                  !!refetchParticipants && (await refetchParticipants());
                }}
              />
            )}
          />
        )}

        {!!autoAddParticipantsEnabled && (
          <React.Fragment>
            <FeatureFlagged
              flag="participantAutoAddRules"
              alternativeChildren={
                <LegacyAutoAddParticipantsConfiguration
                  reviewCycle={reviewCycle}
                  updateReviewCycle={updateReviewCycle}
                />
              }
            >
              <AutoAddParticipantsConfiguration
                reviewCycle={reviewCycle}
                updateReviewCycle={updateReviewCycle}
              />
            </FeatureFlagged>
            <ResponsiblePicker
              onReviewCycleVisibilityChange={onReviewCycleVisibilityChange}
              reviewInteractionType={reviewCycle.interactionType}
              visibleForSuperiors={reviewCycle.visibleForSuperiors}
              visibleForReviewee={reviewCycle.visibleForReviewee}
              responsible={responsible}
              responsibleRole={responsibleRole}
              style={{ marginBottom: 16 }}
              onChange={onResponsibleChange}
            />
          </React.Fragment>
        )}
      </StepContainer>

      <AutoAddParticipantsModal
        isActive={isPlanNotEnabledModalActive}
        onClose={() => setIsPlanNotEnabledModalActive(false)}
      />
      <NotAllowedToCreateAutoAddRulesModal
        isActive={isNotAllowedToCreateAutoAddRulesModalActive}
        onOK={() => setIsNotAllowedToCreateAutoAddRulesModalActive(false)}
      />
    </React.Fragment>
  );
}

export default compose<React.ComponentType<Props>>(withOrganization)(
  Step3Participants
);
