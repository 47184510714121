import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Integration, UsersAttributeOverride } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { del, get, post, put } from 'redux/actions/api';

import { Box, BoxList, FetchContainer, MenuItem } from 'components';

import IntegrationEmptyState from 'scenes/components/integrations/ListEmptyState/index';

import IntegrationModal from '../IntegrationModal';
import IntegrationConfirmationModal from './ConfirmationModal';
import IntegrationListHeaders from './IntegrationListHeaders';
import IntegrationListItem from './IntegrationListItem';

type Props = {};

type AfterConnectProps = Props & {
  launchSyncTestForIntegration: (integration: Integration) => void;
  launchManualSyncForIntegration: (integration: Integration) => void;
  destroyIntegration: (integration: Integration) => void;
  updateIntegration: (integration: Partial<Integration>) => Promise<void>;
};

type AfterDataLoaderProps = AfterConnectProps & {
  integrations: Array<Integration>;
  attributeOverrides: Array<UsersAttributeOverride>;
  isFetching: boolean;
  hasError: boolean;
};

type SetIntegrationAwaitingConfirmationHook = (
  integration?: Integration
) => void;

function getActionsForIntegration(
  integration: Integration,
  setIntegrationAwaitingConfirmation: SetIntegrationAwaitingConfirmationHook,
  launchSyncTestForIntegration: (integration: Integration) => void,
  destroyIntegration: (integration: Integration) => void,
  displayModal: () => void
): Array<React.ReactElement<typeof MenuItem>> {
  const menu: Array<React.ReactElement<typeof MenuItem>> = [];

  menu.push(
    <MenuItem onClick={displayModal} key="edit">
      {__('Edit')}
    </MenuItem>
  );

  if (can({ perform: 'prepare_organization_sync', on: integration })) {
    menu.push(
      <MenuItem
        onClick={() => launchSyncTestForIntegration(integration)}
        key="launchSyncTest"
      >
        {__('Launch a synchronization test')}
      </MenuItem>
    );
  }

  if (can({ perform: 'launch_organization_sync', on: integration })) {
    menu.push(
      <MenuItem
        onClick={() => setIntegrationAwaitingConfirmation(integration)}
        key="launchManualSync"
      >
        {__('Launch a manual synchronization')}
      </MenuItem>
    );
  }

  if (can({ perform: 'destroy', on: integration })) {
    menu.push(
      <MenuItem
        onClick={() => destroyIntegration(integration)}
        key="destroyManualSync"
      >
        {__('Delete this integration')}
      </MenuItem>
    );
  }

  return menu;
}

function IntegrationList({
  launchSyncTestForIntegration,
  launchManualSyncForIntegration,
  destroyIntegration,
  updateIntegration,
  integrations,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  const [editedIntegration, setEditedIntegration] = React.useState<
    Integration | undefined
  >(undefined);
  const [integrationAwaitingConfirmation, setIntegrationAwaitingConfirmation] =
    React.useState<Integration | undefined>(undefined);

  return (
    <Box>
      <BoxList>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            if (!isFetching && isEmpty(integrations)) {
              return (
                <IntegrationEmptyState title={__('No integrations found')} />
              );
            }
            return (
              <div className="integration-list">
                <IntegrationListHeaders />
                {integrations.map(integration => {
                  return (
                    <Fragment key={integration.id}>
                      <IntegrationListItem
                        key={integration.id}
                        integration={integration}
                        actions={getActionsForIntegration(
                          integration,
                          setIntegrationAwaitingConfirmation,
                          launchSyncTestForIntegration,
                          destroyIntegration,
                          () => setEditedIntegration(integration)
                        )}
                        onUpdate={updateIntegration}
                      />
                    </Fragment>
                  );
                })}
                {!!editedIntegration && (
                  <IntegrationModal
                    onConfirm={updateIntegration}
                    integration={editedIntegration}
                    onClose={() => setEditedIntegration(undefined)}
                  />
                )}
                <IntegrationConfirmationModal
                  integration={integrationAwaitingConfirmation}
                  onCancel={() => setIntegrationAwaitingConfirmation(undefined)}
                  onConfirm={() => {
                    invariant(
                      integrationAwaitingConfirmation,
                      'An awaiting confirmation should be defined to confirm the sync launch'
                    );
                    launchManualSyncForIntegration(
                      integrationAwaitingConfirmation
                    );
                  }}
                />
              </div>
            );
          }}
        />
      </BoxList>
    </Box>
  );
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    launchSyncTestForIntegration: async (integration: Integration) => {
      await dispatch(
        post(`integrations/${integration.id}/prepare_organization_sync`)
      );
      window.location.reload();
    },
    launchManualSyncForIntegration: async (integration: Integration) => {
      await dispatch(
        post(`integrations/${integration.id}/launch_organization_sync`)
      );
      window.location.reload();
    },
    destroyIntegration: async (integration: Integration) => {
      await dispatch(del(`integrations/${integration.id}`));
      window.location.reload();
    },
    updateIntegration: async (integration: Partial<Integration>) =>
      await dispatch(put(`integrations/${integration.id}`, { integration })),
  };
}

export default compose<React.ComponentType<Props>>(
  connect(null, mapDispatchToProps),
  newDataLoader({
    fetch: () => get('integrations'),
    hydrate: {
      integrations: {
        lastSync: {},
        abilities: {},
      },
    },
  })
)(IntegrationList);
