// @flow
import * as React from 'react';

import type { Objective } from 'models';

import { Flex, Icon, Text } from 'components';

type ObjectiveCompletedKeyResultsIndicatorProps = {
  objective: Objective,
};

export const ObjectiveCompletedKeyResultsIndicator = ({
  objective,
}: ObjectiveCompletedKeyResultsIndicatorProps) => {
  if (objective.totalKeyResultsCount < 1) return null;

  return (
    <Text color="info">
      <Flex>
        <Icon name="playlist_add_check" />
        {objective.completedKeyResultsCount}/{objective.totalKeyResultsCount}
      </Flex>
    </Text>
  );
};
