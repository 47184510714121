// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { AppDispatch } from 'redux/actions/types';

import { createPersonalObjective } from 'redux/actions/resources';

import { Button } from 'components';

type Props = {|
  disabled: boolean,
  disabledExplanation: string,
  objectiveParams: {
    userObjectivePeriodId?: string,
    userReviewsAsPreviousPeriodObjectiveIds?: Array<string>,
    userReviewsAsNextPeriodObjectiveIds?: Array<string>,
  },
  collectionId: string,
  children: React.Node,
|};

type AfterConnectProps = {|
  ...Props,
  createObjective: () => Promise<*>,
|};

function PersonalObjectiveCreator({
  disabled,
  disabledExplanation,
  createObjective,
  children,
}: AfterConnectProps) {
  return (
    <Button
      disabled={disabled}
      disabledExplanation={disabledExplanation}
      color="primary"
      onClick={createObjective}
    >
      {children}
    </Button>
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { collectionId, objectiveParams }: Props
) => ({
  createObjective: () =>
    dispatch(createPersonalObjective(collectionId, objectiveParams)),
});

export default (connect(
  null,
  mapDispatchToProps
)(PersonalObjectiveCreator): React.ComponentType<Props>);
