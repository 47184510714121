import React from 'react';

import { __ } from 'helpers/i18n';

import { BoxListItem, Column, Columns, Text } from 'components';

export default function IntegrationListHeaders() {
  return (
    <BoxListItem>
      <Columns>
        <Column size={2} isVerticallyCentered ellipsisOnLineOverflow>
          <Text weight="bold">{__('Type')}</Text>
        </Column>
        <Column size={4} isVerticallyCentered ellipsisOnLineOverflow>
          <Text weight="bold">{__('Last status')}</Text>
        </Column>
        <Column size={3} isVerticallyCentered ellipsisOnLineOverflow>
          <Text weight="bold">{__('Last update')}</Text>
        </Column>
        <Column size={2} isVerticallyCentered ellipsisOnLineOverflow>
          <Text weight="bold">{__('Activation')}</Text>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
