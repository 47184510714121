import React from 'react';

import type { TrainingSession } from 'models';

import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';
import confirmAsync from 'helpers/react/confirmAsync';

import { Icon } from 'components';

import TrainingSessionSummary from '../../components/TrainingSessionSummary';

const sendInvitationsModal = (
  trainingSession: TrainingSession,
  sendCalendarInvitations: () => Promise<void>
) => {
  const participants = assertDefined(
    trainingSession.participants,
    'Participants should be defined here'
  );

  return confirmAsync(
    __('Send calendar invitation'),
    <React.Fragment>
      {n__(
        'Invite the participant to this training session by sending a calendar invitation.',
        'Invite the %1 participants to this training session by sending them a calendar invitation.',
        participants.length
      )}
      <br />
      <br />
      <TrainingSessionSummary trainingSession={trainingSession} />
      <br />
      <b>
        {n__(
          'Do you want to send a calendar invitation to the participant of the training session ?',
          'Do you want to send a calendar invitation to the participants of the training session ?',
          participants.length
        )}
      </b>
    </React.Fragment>,
    {
      inlineButtonIcon: <Icon style={{ marginRight: 5 }} name="send" />,
      confirmLabel: __('Send invitation'),
      onConfirm: sendCalendarInvitations,
    }
  );
};

export default sendInvitationsModal;
