import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import { BoldHtmlText } from 'components';

type Props = {
  children: ReactNode;
};

const DraftLayer = ({ children }: Props) => {
  return (
    <div className="objective-card-draft-layer">
      <div className="objective-card-draft-info">
        <span className="objective-card-draft-emoji">✏️</span>
        <span>
          <BoldHtmlText
            html={__('<b>Draft</b>: Not visible to the employee')}
          />
        </span>
      </div>
      {children}
    </div>
  );
};

export default DraftLayer;
