import React from 'react';

import classNames from 'helpers/classNames';
import { formatMoney } from 'helpers/money';

import { Text } from 'components';

import PercentChangeIndicator from './PercentChangeIndicator';

type Props = {
  label: string;
  amount: number;
  currency: string;
  blurred: boolean;
  percentChange: number | null | undefined;
};

const SalaryInfo = ({
  label,
  amount,
  currency,
  blurred,
  percentChange,
}: Props) => (
  <div className="mt-1 flex items-center h-6">
    <Text preset="14s6" color="soften" additionalClassName="w-[200px]">
      {label}
    </Text>
    <Text
      preset="14s6"
      additionalClassName={classNames('ml-2', { 'blur-sm': blurred })}
    >
      {blurred ? 1000000 : formatMoney(amount, currency)}
    </Text>
    {!blurred && !!percentChange && (
      <PercentChangeIndicator percentChange={percentChange} />
    )}
  </div>
);

export default SalaryInfo;
