import React from 'react';

import { Icon } from 'components';

const Playground = () => {
  return (
    <div className="design-system-v2">
      <div className="m-4">
        <Icon name="edit_note" />
        <Icon name="check_circle" filled={true} />
      </div>

      <div className="m-4">
        <span className="material-icons">&#xE87C;</span>
        <span className="material-icons">&#xEf91;</span>
      </div>
    </div>
  );
};

export default Playground;
