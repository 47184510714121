import React from 'react';

import { BoldHtmlText } from 'components';

import Notification from '../Notification';
import { AppContentContainer } from '../appLayout';
import DefaultErrorMessage from './DefaultErrorMessage';

type Props = {
  message?: string;
};

export default function GlobalErrorNotice({ message }: Props) {
  return (
    <AppContentContainer>
      <Notification kind="danger" additionalClassName="global-error-notice">
        {message ? <BoldHtmlText html={message} /> : <DefaultErrorMessage />}
      </Notification>
    </AppContentContainer>
  );
}
