import { differenceBy } from 'lodash';
import React from 'react';

import type { FundingSource } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Select } from 'components';

type Props = {
  fundingSource: FundingSource | undefined | null;
  fundingSources: Array<FundingSource>;
  disabledFundingSources: Array<FundingSource>;
  onChange: (fundingSource: FundingSource) => void;
};

const FundingSourceSelect = ({
  fundingSource,
  fundingSources,
  disabledFundingSources,
  onChange,
}: Props) => {
  const [selectedFundingSource, setSelectedFundingSource] = React.useState(
    fundingSource
      ? {
          label: fundingSource.title,
          value: fundingSource.id,
        }
      : null
  );

  const availableFundingSources = differenceBy(
    fundingSources,
    disabledFundingSources,
    'id'
  );

  const handleOnChange = selection => {
    setSelectedFundingSource(selection);

    const fundingSource = fundingSources.find(
      source => source.id === selection?.value
    );

    invariant(
      fundingSource,
      'A valid funding source should be defined at this point'
    );

    onChange(fundingSource);
  };

  return (
    <Select
      placeholder={__('Funding sources')}
      value={selectedFundingSource || null}
      options={availableFundingSources.map(({ id, title }) => ({
        label: title,
        value: id,
      }))}
      onChange={handleOnChange}
      isMulti={false}
      isClearable={false}
      inModal
    />
  );
};

export default FundingSourceSelect;
