import { AdminRole } from 'models';

import { __ } from 'helpers/i18n';

export const roleDescription = (role: AdminRole['name']): string => {
  if (role === 'admin') {
    return __(
      'Manage users, products, and administrator rights. Can access all content.'
    );
  } else if (role === 'performance_admin') {
    return __(
      'Manages campaigns and can see content of reviews, 360 feedback, and people reviews. Manages skills matrices.'
    );
  } else if (role === 'campaigns_supervisor') {
    return __(
      'Manages campaigns without seeing reviews and 360 feedback content.'
    );
  } else if (role === 'training_admin') {
    return __('Manages training requests and sessions.');
  }
  throw new Error('Unknown role');
};
