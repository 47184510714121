// @flow
import * as React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyState as BaseEmptyState } from 'components';

import objective_target from './objective_target.png';

type Props = {|
  title?: string,
|};

export default function EmptyState({ title = __('No objectives yet') }: Props) {
  return <BaseEmptyState title={title} src={objective_target} />;
}
