import { omit, pick } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { ReviewCycleCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n/index';

import { withPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper } from 'components';

import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

import ReviewCycleList from './ReviewCycleList';

type Props = WithPaginationProps & {
  cycleType: string | undefined | null;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  reviewCycleCollection: ReviewCycleCollection;
  isFetching: boolean;
  hasError: boolean;
};

const ReviewCycleListWithPagination = ({
  search,
  page,
  filter,
  countPerPage,
  reviewCycleCollection,
  isFetching,
  hasError,
  onSearchChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterConnectProps) => {
  const reviewCycles = reviewCycleCollection
    ? reviewCycleCollection.reviewCycles
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          reviewCycleCollection
            ? omit(reviewCycleCollection, 'reviewCycles')
            : null
        }
        totalCountRenderer={(totalCount: number | undefined | null) =>
          n__('%1 review campaign', '%1 review campaigns', totalCount || 0)
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'ongoing', label: __('Active or draft') },
          { param: 'archived', label: __('Archived') },
          { param: 'all', label: __('ReviewCycle|All') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search review campaigns')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <ReviewEmptyState
            title={__('No review campaign matches your search')}
            inBoxList
          />
        )}
      >
        <BoxList>
          <ReviewCycleList
            reviewCycles={reviewCycles}
            isFetching={isFetching}
            hasError={hasError}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      cycleType,
    }: AfterPaginateProps) => {
      // Avoid sending request when cycleType not available
      if (!cycleType) {
        return Promise.resolve;
      }

      return get('review_cycles', {
        page,
        countPerPage,
        search,
        filter: cycleType
          ? {
              ...pick(filter, ['ongoing', 'archived', 'all']),
              [cycleType]: true,
            }
          : filter,
      });
    },
    hydrate: {
      reviewCycleCollection: {
        reviewCycles: {},
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      cycleType,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, cycleType }),
  })
)(ReviewCycleListWithPagination);
