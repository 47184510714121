import type { Occupation } from 'models';

import { isSameOrNextMonth } from 'helpers/date';

/**
 * @param occupations Array of occupations, ordered by start_date ascending
 * @returns Array of Array of occupations grouped by consecutive similar occupations.
 * If an occupation has the same name and is continuous with the previous one (based on start date), group them together.
 * Ex: [{title: "dev", startDate: "2020-01-01", endDate: "2020-02-02"}, {title: "dev", startDate: "2020-02-02", endDate: "2020-03-03"}, {title: "dev", startDate: "2021-12-12", endDate: "2022-00-00"}]
 * => [[{title: "dev", startDate: "2020-01-01", endDate: "2020-02-02"}, {title: "dev", startDate: "2020-02-02", endDate: "2020-03-03"}], [{title: "dev", startDate: "2021-12-12", endDate: "2022-00-00"}]]
 */
const groupConsecutiveSimilarOccupations = (occupations: Array<Occupation>) => {
  const groupedOccupations: Array<Array<Occupation>> = [];

  let previousOccupation: Occupation | null = null;
  occupations.forEach(occupation => {
    if (
      previousOccupation !== null &&
      previousOccupation.title === occupation.title &&
      !!previousOccupation.endDate &&
      isSameOrNextMonth(occupation.startDate, previousOccupation.endDate)
    ) {
      groupedOccupations[groupedOccupations.length - 1].push(occupation);
    } else {
      groupedOccupations.push([occupation]);
    }
    previousOccupation = occupation;
  });

  return groupedOccupations.map(group => group.reverse());
};

export default groupConsecutiveSimilarOccupations;
