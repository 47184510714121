import React from 'react';

import type { StepName } from '../../../helpers/steps';
import type { Organization, ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import { withOrganization } from 'helpers/withSessionProps';

import { BoldHtmlText, Button, Centered, Helper, Icon } from 'components';

import SummaryStep from 'scenes/components/Stepper/SummaryStep';

import getAllSummaryItems from './getAllSummaryItems';

type Props = {
  reviewCycle: ReviewCycle;
  launchReviewCycle: () => Promise<void>;
  goToStep: (name: StepName) => void;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

function Step6Summary({
  reviewCycle,
  launchReviewCycle,
  goToStep,
  organization,
}: AfterConnectProps) {
  const helperContent = reviewCycle.defaultTemplate?.hasSelfEvaluationEnabled
    ? __(
        'After clicking on "Launch the campaign", the launch e-mail defined in step 5 will be sent immediately to all participants (reviewees and responsibles). <b>It cannot be modified after the launch.</b>'
      )
    : __(
        'After clicking on "Launch the campaign", the launch e-mail defined in step 5 will be sent immediately to the responsibles. <b>It cannot be modified after the launch.</b>'
      );
  const items = getAllSummaryItems(reviewCycle, organization, goToStep);

  return (
    <SummaryStep titlePreset="24bs2" items={items}>
      <Centered>
        <Button color="primary" onClick={launchReviewCycle}>
          <Icon style={{ marginRight: 8 }} name="play_circle" />{' '}
          {__('Launch the campaign')}
        </Button>
      </Centered>

      <Helper
        style={{ marginTop: 32 }}
        testClassName="test-cycle-summary-helper"
      >
        <BoldHtmlText componentType="p" html={helperContent} />
      </Helper>
    </SummaryStep>
  );
}

export default withOrganization(Step6Summary);
