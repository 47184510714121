import React, { useContext } from 'react';

import { useOrganization } from 'helpers/hooks';

import { Column, Columns } from 'components';

import { DataContext } from '..';
import EditableCustomFieldValues from './EditableCustomFieldValues';
import EditableField from './EditableField';
import LastSyncStateCard from './LastSyncStateCard';

const EditionInputs = () => {
  const { user, attributes } = useContext(DataContext);
  const organization = useOrganization();

  return (
    <React.Fragment>
      <Columns additionalClassName="field-section">
        <Column>
          <EditableField field="firstName" />
          <EditableField field="email" />
          <EditableField field="jobTitle" />
          <EditableField field="workStartDate" />
          <EditableField field="team" />
          <EditableField field="department" />
          <EditableField field="locale" />

          {!user.external &&
            organization.ssoEnabled &&
            attributes.useSso !== undefined && <EditableField field="useSso" />}
        </Column>

        <Column>
          <EditableField field="lastName" />
          {organization.usernameEnabled && <EditableField field="username" />}
          <EditableField field="manager" />
          <EditableField field="level" />
          <EditableField field="registrationNumber" />

          {organization.plan.multiLevelAdminEnabled && (
            <EditableField field="entityId" />
          )}

          <EditableField field="region" />
          <EditableField field="service" />
        </Column>
      </Columns>

      {(attributes.customFieldValues || []).length !== 0 && (
        <EditableCustomFieldValues />
      )}

      {!!user.lastUserSync && <LastSyncStateCard />}
    </React.Fragment>
  );
};

export default EditionInputs;
