import React, { Fragment, useState } from 'react';

import { Tag, UserFilterSegment } from 'models';

import { isDefined } from 'helpers/array';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  ContentContainer,
  Label,
  Level,
  LevelLeft,
  PageHeader,
  PageTitle,
  Text,
} from 'components';

import TagPicker from 'scenes/components/TagPicker';
import UserFilter from 'scenes/components/UserFilter';

import PlannedActionsWithStats from './PlannedActionsWithStats';

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  tags: Array<Tag>;
};

const Index = ({ tags, refetchData }: AfterDataLoaderProps) => {
  const allTags = tags || [];
  const [filteredTagLabels, setFilteredTagLabels] = useState<Array<string>>([]);
  const [userFilter, setUserFilter] = useState<UserFilterSegment | undefined>();
  return (
    <Fragment>
      <PageTitle title={__('Actions plans')} />
      <PageHeader title={__('Actions plans')} />

      <ContentContainer>
        <Text>
          {__(
            'Actions are only visible to administrators and People Review responsibles. They are not displayed to employees.'
          )}
        </Text>

        <Level additionalClassName="my-4">
          <LevelLeft additionalClassName="space-x-2">
            <Label>{__('Type')}</Label>
            <TagPicker
              tags={allTags}
              selectedTags={filteredTagLabels
                .map(label => allTags.find(tag => tag.label === label))
                .filter(isDefined)}
              onTagChange={tags => {
                setFilteredTagLabels(tags.map(tag => tag.label));
              }}
              additionalClassName="w-64"
              placeholder={__('Select an action type')}
              preventCreation
            />
            <UserFilter segment={userFilter} onChange={setUserFilter} />
          </LevelLeft>
        </Level>

        <PlannedActionsWithStats
          tagLabels={filteredTagLabels}
          userFilter={userFilter}
          refetchTags={refetchData}
        />
      </ContentContainer>
    </Fragment>
  );
};
export default newDataLoader({
  fetch: () =>
    get('tags', {
      context: 'planned_action',
    }),
  hydrate: { tags: {} },
})(Index);
