import React from 'react';

import { SurveyAnswer, SurveyQuestionType } from 'models';

import { __ } from 'helpers/i18n';

import { Column, Flex, Text } from 'components';

import Sentiment from '../components/Sentiment';

type Props = {
  answer: SurveyAnswer;
  questionType: SurveyQuestionType;
};

const getNpsCategory = (score: number | null) => {
  if (score === null) return null;

  if (score <= 6) return __('Detractor');
  if (score <= 8) return __('Passive');
  return __('Promoter');
};

const getTranslatedSentimentCategory = (answer: SurveyAnswer) => {
  const sentimentCategory = answer.sentimentCategory;

  switch (sentimentCategory) {
    case 'positive':
      return __('Positive');
    case 'neutral':
      return __('Neutral');
    case 'negative':
      return __('Negative');
    case 'none':
      return __('No sentiment expressed');
    default:
      return null;
  }
};

const AnswerScore = ({ answer, questionType }: Props) => {
  const getResult = () => {
    switch (questionType) {
      case 'nps':
        return {
          score: answer.score,
          description: getNpsCategory(answer.score),
        };
      case 'multiple_choice':
      case 'single_choice':
        return {
          choices: answer.choices,
        };
      case 'text':
        return {
          score: (
            <Sentiment
              sentimentCategory={answer.sentimentCategory}
              sizeClassName="text-2xl"
            />
          ),
          description: getTranslatedSentimentCategory(answer),
        };
      default:
        return {
          score: answer.score,
        };
    }
  };

  const { score, description, choices } = getResult();
  const hasChoices = !!choices?.length;
  const hasScore = score !== null && score !== undefined;
  const hasDescription = !!description;

  return (
    <Column size={2} isVerticallyCentered>
      <Flex additionalClassName="flex-col">
        {hasChoices ? (
          choices.map(choice => <div>{choice.label}</div>)
        ) : (
          <Text preset="24bs2">{hasScore ? score : '-'}</Text>
        )}
        {hasDescription && <Text preset="14s6">{description}</Text>}
      </Flex>
    </Column>
  );
};

export default AnswerScore;
