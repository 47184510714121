// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ThreeSixtyUserReview } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n/index';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components';

type Props = {|
  userReview: ThreeSixtyUserReview,
|};

type AfterConnectProps = {|
  ...Props,
  sendSelfAssessmentReminder: () => any,
|};

function RemindSelfAssessmentAction({
  sendSelfAssessmentReminder,
}: AfterConnectProps) {
  return (
    <MenuItem onClick={sendSelfAssessmentReminder}>
      {__('Remind the reviewee to fill their self-assessment')}
    </MenuItem>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => ({
  sendSelfAssessmentReminder: () => {
    dispatch(
      sendReminder(
        ownProps.userReview.id,
        'three_sixty_reviewee_reminder_to_fill_self_assessment'
      )
    );
  },
});
export default (connect(
  null,
  mapDispatchToProps
)(RemindSelfAssessmentAction): React.ComponentType<Props>);
