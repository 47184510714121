import { useOrganization } from 'helpers/hooks';

/**
 * Should be deprecated as soon as one of the FF from the list
 * is enabled for everyone
 */
const useUserPanelFFEnabled = () => {
  const organization = useOrganization();
  const featureFlags = [
    'userHistory',
    'trainingSessionsInReview',
    'professionalBackgroundInUserPanel',
  ];
  return organization.featureFlags.some(flag => featureFlags.includes(flag));
};

export default useUserPanelFFEnabled;
