// @flow
import * as React from 'react';

import type { Objective, ObjectiveCollection, Team } from 'models';

import { Box, BoxList } from 'components';

import ObjectiveRowItem from './ObjectiveRowItem';

type Props<T> = {|
  objectives: Array<T>,
  objectiveCollection?: ObjectiveCollection,
  team?: Team,
  target?: string,
|};

export default function ObjectivesList<T: Objective>({
  objectives,
  objectiveCollection,
  team,
  target,
}: Props<T>) {
  return (
    <Box>
      <BoxList additionalClassName="objective-row-item-list">
        {objectives.map((objective, i) => {
          return (
            <ObjectiveRowItem
              objective={objective}
              key={objective.id}
              target={target}
              locked={
                !!objectiveCollection ? objectiveCollection.isLocked : false
              }
              lockedReason={
                !!objectiveCollection ? objectiveCollection.lockedReason : null
              }
            />
          );
        })}
      </BoxList>
    </Box>
  );
}
