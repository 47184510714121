// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ThreeSixtyUserReview } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n/index';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components';

type Props = {|
  userReview: ThreeSixtyUserReview,
|};

type AfterConnectProps = {|
  ...Props,
  sendNominatePeersReminder: () => any,
|};

function RemindPeersNominationAction({
  sendNominatePeersReminder,
}: AfterConnectProps) {
  return (
    <MenuItem onClick={sendNominatePeersReminder}>
      {__('Remind the reviewee to nominate their peers')}
    </MenuItem>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => ({
  sendNominatePeersReminder: () => {
    dispatch(
      sendReminder(
        ownProps.userReview.id,
        'three_sixty_reviewee_reminder_to_nominate_peers'
      )
    );
  },
});
export default (connect(
  null,
  mapDispatchToProps
)(RemindPeersNominationAction): React.ComponentType<Props>);
