import React, { useEffect } from 'react';

import { User } from 'models/User';

import { __ } from 'helpers/i18n';
import { pathToSimbelRedirection } from 'helpers/simbel';

import {
  Button,
  Flex,
  Link,
  Loading,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

type Props = {
  concernedUser?: User;
  isActive: boolean;
  onClose: () => void;
};

export default function TrainingRequestModal({ isActive, onClose }: Props) {
  const url = pathToSimbelRedirection({ intent: 'catalog' });
  const [isRedirecting, setIsRedirecting] = React.useState(true);
  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        window.open(url, '_blank');
        setIsRedirecting(false);
      }, 1500);
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshCurrentPage = () => {
    window.location.reload();
  };
  return (
    <ModalCard
      isBig={false}
      isActive={isActive}
      refreshContentOnOpening
      onClose={onClose}
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>
          {__('We are redirecting to your training management platform')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Flex style={{ marginBottom: 16 }} direction="column" verticalAlign>
          <Text preset="13s7">
            {__(
              'You can make a training request, check the catalog or manage your training sessions on your training management platform.'
            )}
          </Text>

          {isRedirecting ? (
            <Loading delay={0} />
          ) : (
            <Link style={{ marginTop: 16 }} to={url} openInNewTab>
              <Text preset="13s7">
                {__('Click here if you are not redirected automatically')}
              </Text>
            </Link>
          )}
        </Flex>{' '}
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={refreshCurrentPage}>
          {__('OK')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}
