// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { KeyResult, TeamObjective } from 'models';
import type { AppDispatch } from 'redux/actions';
import type { UpdatableObjectiveFields } from 'redux/actions/resources';

import { del, post, put } from 'redux/actions/api';

import { type Style } from 'components';

import { default as ObjectiveCard } from 'scenes/components/objectives/ObjectiveCard';

type Props = {|
  objective: TeamObjective,

  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
|};

type AfterConnectProps = {|
  ...Props,

  onUpdate: ($Shape<UpdatableObjectiveFields>) => Promise<*>,
  onDestroy: () => Promise<*>,

  onAddKeyResult: () => Promise<*>,
  onUpdateKeyResult: (keyResultId: string, $Shape<KeyResult>) => Promise<*>,
  onDestroyKeyResult: (keyResultId: string) => Promise<*>,
|};

function TeamObjectiveCard(props: AfterConnectProps) {
  return <ObjectiveCard {...props} />;
}

const mapDispatchProps = (dispatch: AppDispatch, { objective }: Props) => {
  return {
    onUpdate: (attributes: $Shape<UpdatableObjectiveFields>) =>
      dispatch(
        put(`team_objectives/${objective.id}`, { teamObjective: attributes })
      ),
    onDestroy: () => dispatch(del(`team_objectives/${objective.id}`)),
    onAddKeyResult: () =>
      dispatch(post(`team_objectives/${objective.id}/key_results`)),
    onUpdateKeyResult: (keyResultId: string, attributes: $Shape<KeyResult>) =>
      dispatch(
        put(`team_objectives/${objective.id}/key_results/${keyResultId}`, {
          keyResult: attributes,
        })
      ),
    onDestroyKeyResult: (keyResultId: string) =>
      dispatch(
        del(`team_objectives/${objective.id}/key_results/${keyResultId}`)
      ),
  };
};

export default (connect(
  null,
  mapDispatchProps
)(TeamObjectiveCard): React.ComponentType<Props>);
