// @flow
import * as React from 'react';

import type { TrainingPeriod } from 'models';

import invariant from 'helpers/invariant';

import { Select } from 'components';

type Props = {|
  currentTrainingPeriod: ?TrainingPeriod,
  trainingPeriods: ?Array<TrainingPeriod>,
  onChange: TrainingPeriod => void,
  isFetching: boolean,
|};

export default function SelectPicker({
  currentTrainingPeriod,
  trainingPeriods,
  onChange,
  isFetching,
}: Props) {
  const options = !!trainingPeriods
    ? trainingPeriods.map(period => ({
        label: period.name,
        value: period.id,
      }))
    : [];

  return (
    <Select
      value={
        !!currentTrainingPeriod
          ? {
              label: currentTrainingPeriod.name,
              value: currentTrainingPeriod.id,
            }
          : null
      }
      options={options}
      onChange={option => {
        invariant(
          option && !Array.isArray(option),
          'Option must exist and cannot be an array'
        );

        const period = !!trainingPeriods
          ? trainingPeriods.find(period => period.id === option.value)
          : undefined;
        invariant(period, 'Option ' + option.id + ' was not found');

        onChange(period);
      }}
      isMulti={false}
      isClearable={false}
      inModal
      isLoading={isFetching}
    />
  );
}
