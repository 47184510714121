import React from 'react';

import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { BoldHtmlText, Helper, Text, Title } from 'components';

import TemplatePicker from './TemplatePicker';

type Props = {
  reviewCycle: ReviewCycle;
  onTemplateUpdate: (reviewTemplateId: string) => Promise<void>;
};

const UniqueTemplatePicker = ({ reviewCycle, onTemplateUpdate }: Props) => (
  <div>
    <Title size={5}>{__('Unique template')}</Title>
    <Text>
      {__(
        'Choose the unique template you want to use for all the participants in this campaign'
      )}
    </Text>

    <Helper style={{ marginTop: 8, marginBottom: 16 }}>
      <ul>
        {!reviewCycle.visibleForReviewee && (
          <li>
            <BoldHtmlText
              html={__(
                'The review is not visible by the employee. <b>Templates where self-assessment or objectives are enabled will not be selectable and will not be visible.</b>'
              )}
            />
          </li>
        )}
        {reviewCycle.autoAddParticipantsEnabled && (
          <li>
            <BoldHtmlText
              html={__(
                'The automatic addition of employees is actived. <b>Templates where the evaluation of upcoming objectives is enabled will not be selectable and will not be visible.</b>'
              )}
            />
          </li>
        )}
        <li>
          {__(
            'The selected template cannot be changed once the campaign is launched'
          )}
          .
        </li>
      </ul>
    </Helper>

    <TemplatePicker
      reviewCycle={reviewCycle}
      currentTemplateId={reviewCycle.defaultTemplateId}
      onTemplateUpdate={onTemplateUpdate}
    />
  </div>
);

export default UniqueTemplatePicker;
