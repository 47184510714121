import React, { ReactNode } from 'react';

import invariant from 'helpers/invariant';

import { Checkbox, Icon, Text } from 'components';

import { __ } from '../../helpers/i18n';
import ConfirmationModal from './ConfirmationModal';

type Props = {
  onConfirm: () => void | Promise<void>;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  isActive?: boolean;
  sideEffects: ReactNode[];
  sideEffectsLabel?: ReactNode;
  title: string;
  message?: ReactNode;
  renderMessage?: () => ReactNode;
  irreversibleWarningLabel?: ReactNode;
};

type State = {
  checkedSideEffects: boolean[];
};

export default class ConfirmationModalWithSideEffects extends React.Component<
  Props,
  State
> {
  state = {
    checkedSideEffects: [],
  };

  componentDidMount() {
    const { sideEffects } = this.props;

    const checkedSideEffects = Array(sideEffects.length).fill(false);

    this.setState({
      checkedSideEffects,
    });
  }

  areAllChecked() {
    const { sideEffects } = this.props;

    return !sideEffects.find((_sideEffect, index) => !this.isChecked(index));
  }

  isChecked(sideEffectIndex: number) {
    return this.state.checkedSideEffects[sideEffectIndex];
  }

  setChecked(sideEffectIndex: number, isChecked: boolean) {
    this.setState(({ checkedSideEffects }) => {
      checkedSideEffects[sideEffectIndex] = isChecked;

      return {
        checkedSideEffects,
      };
    });
  }

  renderContent() {
    const {
      message,
      renderMessage,
      sideEffects,
      sideEffectsLabel,
      irreversibleWarningLabel,
    } = this.props;

    invariant(
      !!message || !!renderMessage,
      'No message is specified in ConfirmationModalWithSideEffects'
    );

    return (
      <div>
        {!!message ? <p>{message}</p> : (renderMessage as () => ReactNode)()}

        <p style={{ marginBottom: 8 }}>
          {sideEffectsLabel ||
            __('To confirm you understand, please check all checkboxes below:')}
        </p>

        <ul style={{ marginBottom: 8 }}>
          {sideEffects.map((sideEffect, index) => (
            <li style={{ padding: '4px 0' }} key={index}>
              <Checkbox
                isChecked={this.isChecked(index)}
                onChange={isChecked => this.setChecked(index, isChecked)}
                label={sideEffect}
              />
            </li>
          ))}
        </ul>

        <Text color="danger">
          <Icon style={{ marginBottom: 4 }} name="warning" />{' '}
          {irreversibleWarningLabel || __('This action cannot be undone')}
        </Text>
      </div>
    );
  }

  render() {
    const { onConfirm, onCancel, confirmLabel, cancelLabel, isActive, title } =
      this.props;
    return (
      <ConfirmationModal
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmLabel={confirmLabel}
        confirmDisabled={!this.areAllChecked()}
        cancelLabel={cancelLabel}
        isDanger
        isActive={isActive}
        refreshContentOnOpening={false}
        title={title}
      >
        {this.renderContent()}
      </ConfirmationModal>
    );
  }
}
