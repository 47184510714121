// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ThreeSixtyUserReview, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';
import type { ReduxStore } from 'redux/reducers/index';

import { __, n__ } from 'helpers/i18n';
import { getPendingEvaluations } from 'helpers/models/evaluation';
import { viewerRole } from 'helpers/models/userReview';
import { trackAction } from 'helpers/tracking';

import { releaseUserReviewWithNotice } from 'redux/actions/resources/userReview';
import { getActiveUser } from 'redux/reducers/index';

import { ConfirmationModal, Title5 } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';

type Props = {|
  isActive: boolean,
  onCancel: () => void,
  onAfterSubmit?: () => Promise<*>,
  userReview: ThreeSixtyUserReview,
  reviewee: User,
  isForcedRelease?: boolean,
|};

type AfterConnectProps = {|
  ...Props,
  activeUser: User,
  releaseUserReview: () => Promise<*>,
|};

const ReleaseReviewModal = ({
  isActive,
  isForcedRelease,
  onCancel,
  onAfterSubmit,
  userReview,
  releaseUserReview,
  reviewee,
  activeUser,
}: AfterConnectProps) => {
  const pendingEvaluations = getPendingEvaluations(userReview.evaluations);

  return (
    <ConfirmationModal
      isActive={isActive}
      cancelLabel={__('Cancel')}
      title={__('Release review')}
      confirmLabel={
        pendingEvaluations.length > 0
          ? __('Release results and close pending feedback')
          : __('Release feedback')
      }
      onConfirm={async () => {
        await releaseUserReview();
        if (onAfterSubmit) await onAfterSubmit();
        isForcedRelease &&
          trackAction('Review forced released', {
            activeUserRole: viewerRole(activeUser, userReview),
          });
      }}
      onCancel={onCancel}
      refreshContentOnOpening={false}
      isLarge
    >
      {pendingEvaluations.length > 0 ? (
        <React.Fragment>
          <Title5>
            {n__(
              'One peer did not complete their review yet:',
              '%1 peers have not completed their review yet:',
              pendingEvaluations.length,
              pendingEvaluations.length
            )}
          </Title5>
          <br />
          <ul>
            {pendingEvaluations.map(evaluation => (
              <li key={evaluation.id} style={{ marginBottom: 15 }}>
                <AvatarWithStatus
                  status={evaluation.status}
                  user={evaluation.reviewer}
                />
              </li>
            ))}
          </ul>
          <p>
            {__('Releasing will prevent them from finishing their review.')}
          </p>
          <p>
            {__(
              'Do you still want to go ahead and share with %1?',
              reviewee.fullName
            )}
          </p>
        </React.Fragment>
      ) : (
        <Title5>
          {__('You are about to share the feedback with %1', reviewee.fullName)}
        </Title5>
      )}
    </ConfirmationModal>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  activeUser: getActiveUser(state),
});

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => ({
  releaseUserReview: async () =>
    await dispatch(releaseUserReviewWithNotice(ownProps.userReview)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseReviewModal);
