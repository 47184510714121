// @flow
import * as React from 'react';

import type { User } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Avatar, Box, Column, Columns } from 'components';

import IndirectReportCardOverlay from './Overlay';

type Props = {|
  report: ?User,
  displayReportPlaceholder: boolean,
|};

export default class IndirectReportCard extends React.Component<Props> {
  render() {
    const { report, displayReportPlaceholder } = this.props;

    if (!report) {
      return displayReportPlaceholder ? <Placeholder /> : null;
    }

    invariant(
      report.manager,
      'An indirect report must have a manager with a name.'
    );
    const managerFullName = report.manager.fullName;

    return (
      <Box style={{ position: 'relative' }}>
        <Columns
          isGapLess
          style={{ marginBottom: 0, display: 'flex', alignItems: 'center' }}
        >
          <Column isNarrow style={{ marginRight: '20px' }}>
            <Avatar url={report.avatarUrl} size="normal" />
          </Column>
          <Column style={{ width: 'calc(100% - 80px)' }}>
            <div className="indirect-report-user-details-title has-text-overflow-ellipsis">
              <span className="is-text-overflow-ellipsis">
                {report.fullName}
              </span>
            </div>
            <div className="indirect-report-user-details-subtitle has-text-overflow-ellipsis">
              <span className="is-text-overflow-ellipsis">
                {__('Team of %1', managerFullName)}
              </span>
            </div>
          </Column>
        </Columns>

        <IndirectReportCardOverlay report={report} />
      </Box>
    );
  }
}

function Placeholder() {
  return (
    <div
      style={{
        height: 102,
        opacity: 0.5,
        borderRadius: 4,
        border: `2px solid ${colors.lightGrey}`,
      }}
    />
  );
}
