import React from 'react';

import type { ObjectiveStatus } from 'models';

import classNames from 'helpers/classNames';

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Link,
  Tag,
} from 'components';

type Props = {
  status: ObjectiveStatus;
  disabled: boolean;
  onChange: (status: ObjectiveStatus) => Promise<unknown>;
};

const availableStatuses: Array<ObjectiveStatus> = [
  null,
  'off_track',
  'progressing',
  'on_track',
];

const statusToColor = {
  off_track: 'danger' as const,
  progressing: 'warning' as const,
  on_track: 'success' as const,
};

type ObjectiveStatusTagProps = {
  status: ObjectiveStatus;
};
function ObjectiveStatusTag({ status }: ObjectiveStatusTagProps) {
  const statusColor = !!status ? statusToColor[status] : 'light';
  const statusClass = status || 'none';
  return (
    <Tag
      color={statusColor}
      additionalClassName={classNames('objective-status-tag', statusClass)}
    >
      <Icon size="small" name="flag" />
    </Tag>
  );
}

export default function ObjectiveStatusComponent({
  status,
  onChange,
  disabled,
}: Props) {
  if (disabled) {
    return <ObjectiveStatusTag status={status} />;
  }

  return (
    <Dropdown align="left" disabled={disabled}>
      <DropdownTrigger
        additionalClassName="objective-status-dropdown-trigger"
        render={() => (
          <Link className="objective-status-menu-item trigger">
            <ObjectiveStatusTag status={status} />
          </Link>
        )}
      />

      <DropdownMenu additionalClassName="objective-status-dropdown-menu">
        {availableStatuses
          .filter(availableStatus => availableStatus !== status)
          .map(availableStatus => {
            return (
              <DropdownMenuItem
                onClick={() => onChange(availableStatus)}
                key={availableStatus || 'empty'}
                additionalClassName="objective-status-menu-item"
              >
                <ObjectiveStatusTag status={availableStatus} />
              </DropdownMenuItem>
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
}
