import React, { ReactNode } from 'react';

import type { ReviewCycleIndexSerialization } from 'models';

import { __, date } from 'helpers/i18n';
import { pathToReviewCycle, pathToReviewCycleEdit } from 'helpers/navigation';

import { BoxListItem, Column, Columns, Link, Testable, Text } from 'components';

import ReviewCycleStatusTag from 'scenes/components/reviewCycle/ListItem/ReviewCycleStatusTag';

type Props = {
  reviewCycle: ReviewCycleIndexSerialization;
};

const ReviewCycleListItem = ({ reviewCycle }: Props) => {
  let dateInfo: ReactNode, linkTo: string;

  if (['draft', 'failed'].includes(reviewCycle.status)) {
    linkTo = pathToReviewCycleEdit(reviewCycle.id);
    dateInfo = (
      <React.Fragment>
        <Text color="info">{__('ReviewCycle|Created on:')}</Text>{' '}
        {date(reviewCycle.createdAt)}
      </React.Fragment>
    );
  } else {
    linkTo = pathToReviewCycle(reviewCycle.id);
    dateInfo = (
      <React.Fragment>
        <Text color="info">{__('ReviewCycle|Launched on:')}</Text>{' '}
        {date(reviewCycle.launchedAt)}
      </React.Fragment>
    );
  }

  return (
    <BoxListItem>
      <Testable name={'test-review-cycle-item'}>
        <Columns contentVerticallyAligned>
          <Column>
            <Testable name={'test-review-cycle-link'}>
              <Link to={linkTo} style={{ marginRight: 10 }}>
                {reviewCycle.name}
              </Link>
            </Testable>
            <ReviewCycleStatusTag status={reviewCycle.status} />
          </Column>
          <Column isNarrow>{dateInfo}</Column>
        </Columns>
      </Testable>
    </BoxListItem>
  );
};

export default ReviewCycleListItem;
