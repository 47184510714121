import React from 'react';

import type { Integration } from 'models';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import {
  ADPIntegrationSettings,
  BambooHRIntegrationSettings,
  EureciaIntegrationSettings,
  LuccaIntegrationSettings,
  PayfitIntegrationSettings,
  SFTPIntegrationSettings,
} from './IntegrationSettings';
import { IntegrationSyncTime } from './IntegrationSyncTime';

type Props = {
  integration: Integration;
  createIntegration?: boolean;
  onConfirm: (params: Partial<Integration>) => void;
  onClose: () => void;
};

const settingsTypes = {
  Payfit: PayfitIntegrationSettings,
  Lucca: LuccaIntegrationSettings,
  BambooHR: BambooHRIntegrationSettings,
  Eurecia: EureciaIntegrationSettings,
  SFTP: SFTPIntegrationSettings,
  ADP: ADPIntegrationSettings,
};

const IntegrationModal = ({
  integration,
  createIntegration,
  onConfirm,
  onClose,
}: Props) => {
  const [state, setState] = React.useState({
    id: integration.id,
    active: integration.active,
    integrationType: integration.integrationType,
    settings: integration.settings,
    syncTime: integration.syncTime,
  });

  const ComponentType =
    settingsTypes[state.integrationType as keyof typeof settingsTypes];

  const updateSetting = (setting: string, value: string) =>
    setState({
      ...state,
      settings: { ...state.settings, [setting]: value },
    });

  const updateSyncTime = (value: string) =>
    setState({ ...state, syncTime: value });

  const areIntegrationSettingsFilled = (): boolean => {
    for (const field in state.settings) {
      if (state.settings[field] === '') {
        return false;
      }
    }

    return !!state.syncTime;
  };

  return (
    <ModalCard isLarge refreshContentOnOpening isActive onClose={onClose}>
      <div className="integration-modal">
        <ModalCardHead onClose={onClose}>
          <ModalCardTitle>
            {createIntegration
              ? __('Add a %1 integration', integration.integrationType)
              : __('Edit your %1 integration', integration.integrationType)}
          </ModalCardTitle>
        </ModalCardHead>
        <ModalCardBody overflowVisible>
          <ComponentType
            // @ts-ignore: too much effort to fix
            settings={state.settings}
            onChange={(setting, value) => updateSetting(setting, value)}
          />
          <IntegrationSyncTime
            value={state.syncTime}
            onChange={updateSyncTime}
          />
        </ModalCardBody>
        <ModalCardFooter>
          <Button
            color="primary"
            disabled={!areIntegrationSettingsFilled()}
            onClick={() => {
              onClose();
              onConfirm(state);
            }}
          >
            <Text>
              {createIntegration
                ? __('Add integration')
                : __('Edit integration')}
            </Text>
          </Button>
        </ModalCardFooter>
      </div>
    </ModalCard>
  );
};

export default IntegrationModal;
