import React from 'react';

import BaseTextarea, {
  type Props as BaseProps,
} from '../bulmaElements/Textarea';
import RichTextEditor from './RichTextEditor';
import WithCharacterCountWrapper from './WithCharacterCountWrapper';

type Props = BaseProps & {
  richText?: boolean;
  mergeTags?: Array<{
    trigger: string;
    tags: Array<{ name: string; tag: string }>;
  }>;
  onSaveButtonClick?: () => any;
  richTextOptions?: {
    onEditorReady?: (editor: any) => void;
    toolbarAlwaysVisible?: boolean;
    allowImageUpload?: boolean;
    fixedTagSelector?: boolean;
  };
  hideSaveButton?: boolean;
};

export default function TextArea({
  richText,
  mergeTags,
  onSaveButtonClick,
  hideSaveButton,
  richTextOptions,
  ...baseProps
}: Props) {
  const {
    value,
    onChange,
    disabled,
    placeholder,
    autoFocus,
    onBlur,
    onEscapeKeyPress,
    style,
    additionalClassName,
    maxLength,
    onMaxLengthReached,
  } = baseProps;

  const textAreaRenderer = (maxLengthReached = false) => {
    const state = baseProps.state || (maxLengthReached ? 'danger' : undefined);

    return richText ? (
      <RichTextEditor
        {...richTextOptions}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onBlur={onBlur}
        onEscapeKeyPress={onEscapeKeyPress}
        style={style}
        onSaveButtonClick={onSaveButtonClick}
        additionalClassName={additionalClassName}
        mergeTags={mergeTags}
      />
    ) : (
      <BaseTextarea {...baseProps} state={state} />
    );
  };

  if (!!maxLength) {
    return (
      <WithCharacterCountWrapper
        render={textAreaRenderer}
        count={value?.length || 0}
        maxCount={maxLength}
        onMaxCountReached={onMaxLengthReached}
      />
    );
  }

  return textAreaRenderer();
}
