import React from 'react';
import { components } from 'react-select';

import type { OptionProps } from 'react-select';

import { Select } from 'components';

const CURRENCIES = [
  'GBP',
  'AUD',
  'CAD',
  'CHE',
  'CHF',
  'CHW',
  'CNY',
  'DKK',
  'EUR',
  'HUF',
  'INR',
  'JPY',
  'MXN',
  'MXV',
  'NOK',
  'PLN',
  'RON',
  'RSD',
  'SEK',
  'UAH',
  'USD',
  'ZAR',
];

type Props = {
  selectedCurrency: string;
  onCurrencyChange: (currency: string) => void;
};

const CustomOption = (props: OptionProps) => (
  <components.Option {...props} className="pl-2 py-2 text-sm">
    {props.label}
  </components.Option>
);

const CurrencySelect = ({ selectedCurrency, onCurrencyChange }: Props) => {
  const options = CURRENCIES.map(currency => ({
    value: currency,
    label: currency,
  }));
  const selectedOption = options.find(
    option => option.value === selectedCurrency
  );

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={option => option && onCurrencyChange(option.value)}
      components={{
        Option: CustomOption,
      }}
    />
  );
};

export default CurrencySelect;
