import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PaginationProps } from 'lib/dataLoader/pagination/types';

import {
  PaginatedCollection,
  SurveyQuestionStats,
  UserFilterSegment,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { get } from 'redux/actions/api';

import { InfiniteFetchContainer, Title5 } from 'components';

import Question from './Question';

type Props = {
  campaignId: string;
  formId: string | null | undefined;
  periodId: string | null | undefined;
  segment: UserFilterSegment | null;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    questionStatsCollection: PaginatedCollection<SurveyQuestionStats>;
  };

const FormStats = ({
  campaignId,
  getNextPage,
  periodId,
  isFetching,
  hasError,
  questionStatsCollection,
}: AfterDataLoaderProps) => {
  return (
    <Fragment>
      <Title5 additionalClassName="mb-4">{__('Results overview')}</Title5>

      <InfiniteFetchContainer
        isFetching={isFetching}
        hasError={hasError}
        collection={questionStatsCollection}
        getNextPage={getNextPage}
        render={questionStatsList =>
          questionStatsList.map((questionStats, index) => (
            <Question
              key={questionStats.question.id}
              questionNumber={index + 1}
              questionStats={questionStats}
              campaignId={campaignId}
              periodId={periodId}
            />
          ))
        }
      />
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withStatePagination({
    resetPageFor: ({ segment, countPerPage, formId }) => [
      segment,
      countPerPage,
      formId,
    ],
  }),
  newDataLoader({
    fetch: ({ page, formId, segment }: AfterPaginateProps) =>
      get(`survey/forms/${formId}/questions_stats`, {
        userFilter: segment,
        countPerPage: 5,
        page,
      }),
    hydrate: {
      questionStatsCollection: {
        items: {
          question: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage, formId, segment }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, formId, segment }),
  })
)(FormStats);
