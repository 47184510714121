import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, RawHtml, Text } from 'components';

import SkillsHeader from 'scenes/skills/components/SkillsHeader';

import PaginatedList from './PaginatedList';

const EmployeesOverview = () => {
  return (
    <Fragment>
      <SkillsHeader />
      <ContentContainer>
        <Text additionalClassName="mb-4">
          <RawHtml
            html={__(
              "Manage your team's development on the Employees page: quickly identify employees without a skills matrix and <b>associate them with the appropriate matrix.</b>"
            )}
          />
        </Text>
        <PaginatedList paginationType="url" />
      </ContentContainer>
    </Fragment>
  );
};

export default EmployeesOverview;
