import React, { Fragment } from 'react';

import { UserFilterSegment } from 'models';
import {
  ChoiceScore,
  ChoiceSpecificStats,
  NPSScore,
  NPSSpecificStats,
  ScaleScore,
  ScaleSpecificStats,
  SurveyAnswerSentimentCategory,
  SurveyChoiceQuestion,
  SurveyNPSQuestion,
  SurveyQuestionStats,
  SurveyScaleQuestion,
  SurveyTextQuestion,
  TextScore,
  TextSpecificStats,
} from 'models/surveys';

import Evolution from './Evolution';
import ChoiceStats from './stats/ChoiceStats';
import NPSStats from './stats/NPSStats';
import ScaleStats from './stats/ScaleStats';
import TextStats from './stats/TextStats';

type Props = {
  questionStats: SurveyQuestionStats;
  campaignId: string;
  selectedPeriodId: string;
  segment: UserFilterSegment | null;
  withEvolution: boolean;
};

const QuestionStats = ({
  questionStats,
  campaignId,
  selectedPeriodId,
  segment,
  withEvolution,
}: Props) => {
  const {
    question: { questionType },
    specificStats,
    score,
  } = questionStats;

  const evolutionYAxisProps = () => {
    const { minValue, maxValue } =
      questionStats.question as SurveyScaleQuestion;
    switch (questionType) {
      case 'nps':
        return {
          domain: [-100, 100],
        };
      case 'scale':
        return {
          domain: [minValue, maxValue],
        };
      default:
        return { domain: [minValue, maxValue] };
    }
  };

  const MainStatsBox = () => {
    switch (questionType) {
      case 'nps':
        return (
          <NPSStats
            stats={specificStats as NPSSpecificStats}
            score={score as NPSScore}
            question={questionStats.question as SurveyNPSQuestion}
          />
        );
      case 'scale':
        return (
          <ScaleStats
            question={questionStats.question as SurveyScaleQuestion}
            stats={specificStats as ScaleSpecificStats}
            score={score as ScaleScore}
          />
        );
      case 'text':
        return (
          <TextStats
            stats={specificStats as TextSpecificStats}
            score={score as TextScore}
            sentimentCategory={
              questionStats.sentimentCategory as SurveyAnswerSentimentCategory
            }
            question={questionStats.question as SurveyTextQuestion}
          />
        );
      case 'single_choice':
      case 'multiple_choice':
        return (
          <ChoiceStats
            stats={specificStats as ChoiceSpecificStats}
            score={score as ChoiceScore}
            question={questionStats.question as SurveyChoiceQuestion}
          />
        );
      default:
        return <Fragment></Fragment>;
    }
  };

  return (
    <Fragment>
      <MainStatsBox />
      {withEvolution &&
        (questionType === 'nps' || questionType === 'scale') && (
          <Evolution
            campaignId={campaignId}
            selectedPeriodId={selectedPeriodId}
            questionId={questionStats.question.id}
            yAxisProps={evolutionYAxisProps()}
            segment={segment}
          />
        )}
    </Fragment>
  );
};

export default QuestionStats;
