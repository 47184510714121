import { combineReducers } from 'redux';

import type { Action, Notice } from '../actions';
import type { DataState } from './DataStateType';
import type { AutoSaveState } from './autoSave';
import type { ActiveUser } from 'models';

import { hydrateFromStore, reducer as requests } from 'lib/dataLoader';

import application from './application';
import autoSave from './autoSave';
import data, * as dataSelectors from './data';

export type ApplicationState = {
  notice?: Notice;
  blockingMessage?: string;
};

export type ReduxStore = {
  readonly application: ApplicationState;
  readonly data: DataState;
  readonly requests: {};
  readonly autoSave: AutoSaveState;
};

const appReducer: (
  state: ReduxStore | undefined,
  action: Action
) => ReduxStore = combineReducers({
  application,
  data,
  requests,
  autoSave,
});

// Logic to nuke the store
const rootReducer = (state: ReduxStore, action: Action): ReduxStore => {
  if (action.type === 'CLEAR_STORE') {
    return appReducer(undefined, action);
  } else {
    return appReducer(state, action);
  }
};

export default rootReducer;

export const getActiveUser = (state: ReduxStore): ActiveUser | null => {
  const session = getCurrentSession(state);

  if (!session) return null;

  return session.user;
};

export const getOrganization = (state: ReduxStore) => {
  const session = getCurrentSession(state);

  if (!session) return null;

  return session.organization;
};

export const getCurrentSession = (state: ReduxStore) => {
  return hydrateFromStore(
    state.data,
    { resourceType: 'session', id: 'current' },
    {
      session: {
        user: {
          abilities: {},
        },
        organization: {
          abilities: {},
          plan: {},
          settings: {},
          customization: {},
          theme: {
            abilities: {},
          },
          homeMessages: {},
          userFilterableFields: {},
          surveyUserFilterableFields: {},
        },
      },
    }
  );
};

// For resources store using redux-thunk middleware
export const getResourceById = (
  dataState: DataState,
  resourceType: string,
  id: string
) => dataSelectors.getResourceById(dataState, resourceType, id);
export const getResourceByIds = (
  dataState: DataState,
  resourceType: string,
  ids: Array<string>
) => dataSelectors.getResourceByIds(dataState, resourceType, ids);
