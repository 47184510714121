import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { DesignSystem, PageTitle, Route, Switch } from 'components';

import AreaEditor from './AreaEditor';
import AreaList from './AreaList';
import AreaMatrixList from './AreaMatrixList';
import EmployeesOverview from './EmployeesOverview';
import MatrixEmployeesList from './MatrixEmployeesList';
import MatrixExplorer from './MatrixExplorer';
import MatrixList from './MatrixList';
import MatrixVisualizer from './MatrixVisualizer';

export default function SkillsRoutes() {
  const { path } = useRouteMatch();

  const organization = useOrganization();

  const canAccessToMatrixEdition = can({
    perform: 'show_matrix_edition_menu',
    on: organization,
  });

  return (
    <DesignSystem version={2}>
      <PageTitle title={[__('Matrices and skills'), __('Admin')]} />

      <Switch>
        <Route
          exact
          path={`${path}/matrices/explorer`}
          component={MatrixExplorer}
        />
        <Route
          exact
          path={`${path}/matrices/:id`}
          component={MatrixVisualizer}
        />
        {canAccessToMatrixEdition && (
          <React.Fragment>
            <Route exact path={`${path}/matrices`} component={MatrixList} />
            <Route
              path={`${path}/matrices/:id/edit`}
              render={props => <MatrixVisualizer editionMode {...props} />}
            />
            <Route
              path={`${path}/matrices/:id/employees`}
              component={MatrixEmployeesList}
            />
            <Route exact path={`${path}/areas`} component={AreaList} />
            <Route path={`${path}/areas/:id/edit`} component={AreaEditor} />
            <Route
              path={`${path}/areas/:id/matrices`}
              component={AreaMatrixList}
            />
            <Route path={`${path}/employees`} component={EmployeesOverview} />
          </React.Fragment>
        )}
        <Redirect to={`${path}/matrices/explorer`} />
      </Switch>
    </DesignSystem>
  );
}
