import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const ProfessionalBackground = () => {
  return (
    <EmptyStateWithIcon
      inBox={false}
      iconName="history_edu"
      title={__('No professional background')}
      additionalClassName="mt-4"
    />
  );
};

export default ProfessionalBackground;
