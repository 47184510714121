// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ObjectivePeriod, Team } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import { pathToTeamObjectivesForObjectivePeriod } from 'helpers/paths';

import { post } from 'redux/actions/api';

import { ConfirmationModal, Text } from 'components';

import TeamPicker from 'scenes/components/TeamPicker';

type Props = {|
  isActive: boolean,
  onClose: () => void,
  objectivePeriod: ObjectivePeriod,
  disabledTeamIds?: Array<string>,
|};

type AfterConnectProps = {
  ...Props,
  addTeamToPeriod: (teamSlug: string, periodSlug: string) => Promise<*>,
};

type State = {
  selectedTeam: ?Team,
};

class AddTeamModal extends React.Component<AfterConnectProps, State> {
  state = {
    selectedTeam: null,
  };

  onClose = () => {
    this.setState({ selectedTeam: null });
    this.props.onClose();
  };

  addTeamToPeriod = async () => {
    const { addTeamToPeriod, objectivePeriod } = this.props;

    invariant(this.state.selectedTeam, 'A team must be selected to submit.');
    const { slug: teamSlug } = this.state.selectedTeam;
    await addTeamToPeriod(teamSlug, objectivePeriod.slug);
    navigate(
      pathToTeamObjectivesForObjectivePeriod(objectivePeriod.slug, teamSlug)
    );
    this.onClose();
  };

  render() {
    const { isActive, objectivePeriod, disabledTeamIds } = this.props;
    const { selectedTeam } = this.state;

    return (
      <ConfirmationModal
        isActive={isActive}
        onCancel={this.onClose}
        refreshContentOnOpening={false}
        title={__('Add a team to %1', objectivePeriod.name)}
        onConfirm={this.addTeamToPeriod}
        confirmLabel={__('Add team')}
        confirmDisabled={!this.state.selectedTeam}
      >
        <div>
          <Text>{__('Create a new team or select an existing one:')}</Text>
        </div>
        <div>
          <TeamPicker
            value={selectedTeam ? selectedTeam.id : null}
            onChange={(teamId, team) => this.setState({ selectedTeam: team })}
            disabledTeamIds={disabledTeamIds}
            inModal
          />
        </div>
      </ConfirmationModal>
    );
  }
}
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addTeamToPeriod: (teamSlug: string, periodSlug: string) =>
    dispatch(post(`teams/${teamSlug}/objective_periods`, { periodSlug })),
});

export default (connect(
  null,
  mapDispatchToProps
)(AddTeamModal): React.ComponentType<Props>);
