import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, TrainingRequest } from 'models';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { navigate, pathToTrainingSessionDetails } from 'helpers/navigation';

import { newDataLoader, withPagination } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Button,
  Checkbox,
  Column,
  Columns,
  DatatableWrapper,
  Divider,
  FetchContainer,
  Label,
  Text,
} from 'components';

import AddParticipantsToSessionModal from './AddParticipantsToSessionModal';
import TrainingRequestListItem from './TrainingRequestListItem';
import TrainingRequestManagementModal from './TrainingRequestManagementModal';
import TrainingRequestNoRecordState from './TrainingRequestNoRecordState';

type Props = {
  currentTrainingPeriodSlug: string;
  onAfterAction?: () => Promise<void>;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingRequestCollection: PaginatedCollection<TrainingRequest>;
  };

const TrainingRequestList = ({
  trainingRequestCollection,
  currentTrainingPeriodSlug,
  isFetching,
  hasError,
  countPerPage,
  onFilterChange,
  onSearchChange,
  page,
  previousPageLink,
  nextPageLink,
  search,
  userFilter,
  onUserFilterChange,
  refetchData,
  onAfterAction,
  filter,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const createTrainingSession = async (request_ids: Array<string>) => {
    const response = await dispatch(
      post('training/sessions', { training_session: { request_ids } })
    );
    return response.response.body.data;
  };

  const [focusedTrainingRequest, setFocusedTrainingRequest] =
    React.useState<TrainingRequest | null>(null);
  const [selectedTrainingRequestIds, setSelectedTrainingRequestIds] =
    React.useState(new Set<string>());
  const [addToSessionModalIsActive, setAddToSessionModalIsActive] =
    React.useState(false);
  const [isAllChecked, setIsAllChecked] = React.useState(false);
  const selectableTrainingRequestIds = trainingRequestCollection?.items.reduce(
    (acc, trainingRequest) => {
      if (!!trainingRequest.session) {
        return acc;
      } else {
        return [...acc, trainingRequest.id];
      }
    },
    [] as Array<string>
  );

  React.useEffect(() => {
    if (
      !selectableTrainingRequestIds ||
      selectableTrainingRequestIds.length === 0
    ) {
      setIsAllChecked(false);
    } else if (
      selectableTrainingRequestIds.filter(
        id => !selectedTrainingRequestIds.has(id)
      ).length > 0
    ) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [selectableTrainingRequestIds, selectedTrainingRequestIds]);

  const toggleTrainingRequest = (trainingRequestId: string) => {
    setSelectedTrainingRequestIds(prevSelectedIds => {
      const newSelectedIds = new Set(prevSelectedIds);

      if (newSelectedIds.has(trainingRequestId)) {
        newSelectedIds.delete(trainingRequestId);
      } else {
        newSelectedIds.add(trainingRequestId);
      }

      return newSelectedIds;
    });
  };

  const toggleAllTrainingRequests = () => {
    setSelectedTrainingRequestIds(prevSelectedIds => {
      const newSelectedIds = new Set(prevSelectedIds);

      if (isAllChecked) {
        selectableTrainingRequestIds.forEach(idToDelete =>
          newSelectedIds.delete(idToDelete)
        );
      } else {
        selectableTrainingRequestIds.forEach(idToAdd =>
          newSelectedIds.add(idToAdd)
        );
      }

      return newSelectedIds;
    });
  };

  const createNewSessionWithSelectedRequests = async () => {
    const trainingSession = await createTrainingSession(
      Array.from(selectedTrainingRequestIds)
    );
    navigate(pathToTrainingSessionDetails(trainingSession.id));
  };

  return (
    <Fragment>
      <Box>
        <DatatableWrapper
          isFetching={isFetching}
          hasError={hasError}
          collectionInfo={trainingRequestCollection}
          countPerPage={countPerPage}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          page={page}
          search={search}
          previousPageLink={previousPageLink}
          nextPageLink={nextPageLink}
          userFilter={userFilter}
          onUserFilterChange={onUserFilterChange}
          totalCountRenderer={(count?: number | null) =>
            n__('%1 training request', '%1 training requests', count || 0)
          }
          renderNoRecord={() => (
            <Box>
              <TrainingRequestNoRecordState />
            </Box>
          )}
          filters={[
            {
              param: 'waiting_for_approval',
              label: __('Waiting for approval'),
            },
            { param: 'approved', label: __('Approved') },
            { param: 'refused', label: __('Refused') },
            { param: 'all', label: __('All') },
          ]}
          filter={filter}
        >
          <div className="training-requests-new-session-toolbar">
            <Label>
              {n__(
                '%1 selected',
                '%1 selected',
                selectedTrainingRequestIds.size
              )}
            </Label>
            {selectedTrainingRequestIds.size > 0 && (
              <Fragment>
                <Button
                  color="secondary"
                  onClick={createNewSessionWithSelectedRequests}
                  testClassName="test-create-new-session-button"
                >
                  {__('Create a training session')}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setAddToSessionModalIsActive(true)}
                  testClassName="test-add-requests-to-session-button"
                >
                  {__('Add to a training session')}
                </Button>
              </Fragment>
            )}
          </div>

          <BoxList>
            <BoxListItem>
              <Columns additionalClassName="training-requests-header">
                <Column
                  isNarrow
                  additionalClassName="training-request-checkbox"
                >
                  <Checkbox
                    onChange={toggleAllTrainingRequests}
                    disabled={selectableTrainingRequestIds?.length === 0}
                    isChecked={isAllChecked}
                    size="small"
                  />
                </Column>

                <Column>
                  <Text preset="14s6" color="light">
                    {__('Request summary')}
                  </Text>
                </Column>

                <Column>
                  <Text preset="14s6" color="light">
                    {__('Session name')}
                  </Text>
                </Column>

                <Column size={3}>
                  <Text preset="14s6" color="light">
                    {__('Employee')}
                  </Text>
                </Column>

                <Column
                  isNarrow
                  additionalClassName="training-requests-approval-column"
                  size={3}
                >
                  <Text preset="14s6" color="light">
                    {__('Status')}
                  </Text>
                </Column>
                <Column
                  isNarrow
                  additionalClassName="training-requests-action-column"
                />
              </Columns>
            </BoxListItem>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              loadingStyle="overlay"
              render={() => (
                <div>
                  {!!trainingRequestCollection &&
                    trainingRequestCollection.items.map(trainingRequest => (
                      <TrainingRequestListItem
                        key={trainingRequest.id}
                        currentTrainingPeriodSlug={currentTrainingPeriodSlug}
                        trainingRequest={trainingRequest}
                        onSelect={() =>
                          toggleTrainingRequest(trainingRequest.id)
                        }
                        selected={selectedTrainingRequestIds.has(
                          trainingRequest.id
                        )}
                        onClick={() =>
                          setFocusedTrainingRequest(trainingRequest)
                        }
                        onAfterDelete={async () => {
                          await refetchData();
                          !!onAfterAction && onAfterAction();
                        }}
                      />
                    ))}
                </div>
              )}
            />
          </BoxList>
        </DatatableWrapper>
      </Box>

      {focusedTrainingRequest && (
        <TrainingRequestManagementModal
          currentTrainingPeriodSlug={currentTrainingPeriodSlug}
          trainingRequest={focusedTrainingRequest}
          isActive
          onClose={() => {
            setFocusedTrainingRequest(null);
            refetchData();
            !!onAfterAction && onAfterAction();
          }}
        />
      )}

      {addToSessionModalIsActive && (
        <AddParticipantsToSessionModal
          trainingRequestIds={Array.from(selectedTrainingRequestIds)}
          onClose={() => setAddToSessionModalIsActive(false)}
          createNewSessionWithSelectedRequests={
            createNewSessionWithSelectedRequests
          }
        />
      )}
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      userFilter,
      filter,
      currentTrainingPeriodSlug,
    }: AfterPaginateProps) =>
      get(`training/periods/${currentTrainingPeriodSlug}/requests`, {
        page,
        countPerPage,
        search,
        userFilter,
        filter,
      }),
    hydrate: {
      trainingRequestCollection: {
        items: {
          creator: {},
          trainee: {
            manager: {},
          },
          trainingPeriod: {},
          session: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      userFilter,
      filter,
      currentTrainingPeriodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        userFilter,
        filter,
        currentTrainingPeriodSlug,
      }),
  })
)(TrainingRequestList);
