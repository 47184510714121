import React from 'react';

import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import {
  Box,
  CheckboxSwitch,
  Column,
  Columns,
  Level,
  LevelLeft,
  Text,
  Title,
} from 'components';

const TeamObjectivesFeatureCard = () => {
  const dispatch = useAppDispatch();
  const organization = useOrganization();
  const setTeamObjectivesEnabled = (areTeamObjectivesEnabled: boolean) =>
    dispatch(
      put(`organizations/${organization.id}/settings`, {
        settings: { areTeamObjectivesEnabled },
      })
    );

  return (
    <Box>
      <Columns>
        <Column>
          <Level style={{ marginBottom: 20 }}>
            <LevelLeft>
              <Title size={5}>{__('Team Objectives')}</Title>
              <Text
                size={6}
                style={{ marginLeft: 40 }}
                color={
                  organization.settings.areTeamObjectivesEnabled
                    ? 'success'
                    : 'grey'
                }
              >
                {organization.settings.areTeamObjectivesEnabled
                  ? __('On')
                  : __('Off')}
              </Text>
            </LevelLeft>
          </Level>
          <p>
            {__(
              'Enables you to create and communicate on company and teams objectives to all users. Once activated, team objectives will appear under the Objective tab. Only managers and admin can create and edit team objectives.'
            )}
          </p>
        </Column>
        <Column size={2} isVerticallyCentered contentIsPulledRight>
          <CheckboxSwitch
            value={organization.settings.areTeamObjectivesEnabled}
            onChange={setTeamObjectivesEnabled}
            size="medium"
          />
        </Column>
      </Columns>
    </Box>
  );
};

export default TeamObjectivesFeatureCard;
