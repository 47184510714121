// @flow
import * as React from 'react';

import type { ObjectivePeriod, TeamObjectivePeriod } from 'models/index';

import { __ } from 'helpers/i18n/index';

import { Button, Icon, Toggleable } from 'components/index';

import { default as AddTeamModal } from './AddTeamModal';

type Props = {|
  style?: Object,
  objectivePeriod: ObjectivePeriod,
  teamObjectivePeriods: Array<TeamObjectivePeriod>,
|};

type State = {|
  modalIsOpen: boolean,
|};

export default class AddTeamButton extends React.Component<Props, State> {
  state = {
    modalIsOpen: false,
  };

  render() {
    const { objectivePeriod, teamObjectivePeriods } = this.props;
    return (
      <Toggleable
        render={(isToggled, toggle) => (
          <React.Fragment>
            <Button
              onClick={toggle}
              size="small"
              color="secondary"
              style={this.props.style}
            >
              <Icon size="small" name="add" additionalClassName="mr-1" />
              <span>{__('Add a team')}</span>
            </Button>
            <AddTeamModal
              isActive={isToggled}
              onClose={toggle}
              objectivePeriod={objectivePeriod}
              disabledTeamIds={teamObjectivePeriods.map(
                teamObjectivePeriod => teamObjectivePeriod.team.id
              )}
            />
          </React.Fragment>
        )}
      />
    );
  }
}
