import React, { Fragment } from 'react';

import { useAppDispatch } from 'helpers/hooks';
import useCatchedAPIErrors from 'helpers/hooks/useCatchedAPIErrors';
import { __ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import { postFile } from 'redux/actions/api';

import {
  BoldHtmlText,
  Button,
  FieldError,
  FileInfo,
  Helper,
  Icon,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';
import { DragAndDropFileInput } from 'components/behaviors/FilesUploader';

const ACCEPTED_FILE_TYPES = [
  'text/csv',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

type Props = {
  cycleId: string;
  onClose: () => void;
};

const ImportParticipantsModal = ({ cycleId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = React.useState<File | null>(null);

  const [sendFile, { recordErrors, handledError }] = useCatchedAPIErrors(
    options => (action: 'validate' | 'import', file: File) =>
      dispatch(
        postFile(
          `people_review_cycles/${cycleId}/participants/${action}`,
          file,
          undefined,
          options
        )
      )
  );

  const closeModal = () => {
    onClose();
  };

  const validateFile = async (files: File[]) => {
    if (await sendFile('validate', files[0])) {
      setFile(files[0]);
    }
  };

  const importParticipants = async () => {
    if (!file) return;

    if (await sendFile('import', file)) {
      closeModal();
    }
  };

  const error = recordErrors.target_id
    ? __(
        'An addition of participants is in progress, please wait until it is completed'
      )
    : handledError?.message;

  const documentationURL = zendeskURL(
    {
      fr: '9509644754833-Param%C3%A9trer-une-campagne-de-People-Review',
      en: '9509644754833-Setup-of-a-People-Review-Campaign',
    },
    'h_01J06HNPJ17SK0PFK3XJ5T5XNA'
  );

  return (
    <ModalCard isActive={true} onClose={closeModal} isLarge>
      <ModalCardHead>
        <ModalCardTitle>{__('Add participants')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Text additionalClassName="mb-4 block">
          {__(
            'Prepare the list of participants you wish to add using our %1',
            <Link
              to={documentationURL}
              additionalClassName="text-primary"
              openInNewTab
            >
              {__('Help Center')}
            </Link>
          )}
        </Text>
        {file ? (
          <div className="flex flex-col gap-2">
            <FileInfo
              filename={file.name}
              onDelete={() => setFile(null)}
              hasError={!!error}
            />
            {!error && (
              <Helper>
                <BoldHtmlText
                  componentType="span"
                  html={__(
                    '<b>Reviewers</b> will receive an invitation email once you have validated.'
                  )}
                />
              </Helper>
            )}
          </div>
        ) : (
          <DragAndDropFileInput
            onFilesDrop={validateFile}
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
            maxFileSize={5}
          />
        )}
        <FieldError>
          {error ? (
            <Fragment>
              <Icon name="warning" /> {error}
            </Fragment>
          ) : null}
        </FieldError>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={closeModal}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!file || !!error}
          onClick={importParticipants}
        >
          {__('Add participants')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default ImportParticipantsModal;
