import { connect } from 'react-redux';

import type { ReduxStore } from '../redux/reducers';

import {
  getActiveUser,
  getCurrentSession,
  getOrganization,
} from '../redux/reducers';

export const withActiveUser = connect((state: ReduxStore) => ({
  activeUser: getActiveUser(state),
}));

export const withOrganization = connect((state: ReduxStore) => ({
  organization: getOrganization(state),
}));
export const withActiveUserAndOrganization = connect((state: ReduxStore) => ({
  activeUser: getActiveUser(state),
  organization: getOrganization(state),
}));
export const withSession = connect((state: ReduxStore) => ({
  session: getCurrentSession(state),
}));
