import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { DataLoaderProvidedProps } from 'lib/dataLoader/types';
import type { ENPSAnswer, ENPSPeriod, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader, withPagination } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  DatatableWrapper,
  FetchContainer,
  FilterBar,
  Flex,
  PullRight,
  Text,
} from 'components';

import EmptyState from './EmptyState';
import AnswersListItem from './ListItem';

type Props = WithPaginationProps & {
  enpsPeriod: ENPSPeriod;
};
type AfterPaginateProps = Props & PaginationProps;
type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    enpsAnswerCollection: PaginatedCollection<ENPSAnswer>;
  };

const Answers = ({
  enpsPeriod,
  isFetching,
  hasError,
  enpsAnswerCollection,
  page,
  filter,
  countPerPage,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterDataLoaderProps) => {
  return (
    <Box>
      <Text preset="18bs5" additionalClassName="block mb-4">
        {__('eNPS %1 Comments', enpsPeriod.name)}
      </Text>

      <div className="enps-answers-box">
        {/* @ts-ignore TSFIXME */}
        <DatatableWrapper
          collectionInfo={
            enpsAnswerCollection
              ? omit(enpsAnswerCollection, 'enpsAnswer')
              : null
          }
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          totalCountRenderer={(totalCount: number | null) => {
            return totalCount
              ? n__('%1 answer', '%1 answers', totalCount || 0)
              : null;
          }}
          page={page}
          countPerPage={countPerPage}
          previousPageLink={previousPageLink}
          nextPageLink={nextPageLink}
          getPreviousPage={getPreviousPage}
          getNextPage={getNextPage}
          onFilterChange={onFilterChange}
          filters={[
            { param: 'all', label: __('All') },
            { param: 'promoters', label: __('Promoters') },
            { param: 'passives', label: __('Passives') },
            { param: 'detractors', label: __('Detractors') },
          ]}
          filter={filter}
          isFetching={isFetching}
          hasError={hasError}
          renderHeader={({ filters, activeFilter, onFilterChange }) => {
            return (
              <div className="enps-answers-header">
                <PullRight style={{ textAlign: 'end' }}>
                  {!!filters && (
                    <FilterBar
                      display="link"
                      leftText={__('Show')}
                      filters={filters}
                      activeFilter={activeFilter}
                      // @ts-ignore TSFIXME: Fix strictNullChecks error
                      onFilterChange={onFilterChange}
                    />
                  )}
                </PullRight>
              </div>
            );
          }}
          renderNoResult={() => <EmptyState />}
        >
          <BoxList>
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              render={() => (
                <Flex direction="column">
                  {enpsAnswerCollection.items.map(answer => (
                    <AnswersListItem answer={answer} key={answer.id} />
                  ))}
                </Flex>
              )}
            />
          </BoxList>
        </DatatableWrapper>
      </div>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, filter, enpsPeriod }: AfterPaginateProps) =>
      get(`enps/periods/${enpsPeriod.id}/answers`, {
        page,
        countPerPage,
        filter,
      }),
    hydrate: {
      enpsAnswerCollection: {
        items: {},
      },
    },
    cacheKey: ({ page, countPerPage, filter }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, filter }),
  })
)(Answers);
