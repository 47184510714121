import React from 'react';

import type { Match } from 'helpers/paths';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PeopleUserReview } from 'models';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, PageTitle } from 'components';

import ReviewLayout from 'scenes/review/components/ReviewLayout';

import Content from './components/Content';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';

type Props = {
  match: Match<{
    reviewId: string;
  }>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    review: PeopleUserReview;
  };

function PeopleUserReviewComponent({
  review,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) {
  return (
    <div className="people-user-review">
      {review && (
        <PageTitle
          title={[review.user.fullName, review.peopleReviewCycleName]}
        />
      )}
      <FetchContainer
        hasError={hasError}
        isFetching={isFetching}
        render={() => (
          <ReviewLayout
            displaySideBarToRight={false}
            topbar={<Topbar review={review} refetchReview={refetchData} />}
            sidebar={<Sidebar review={review} />}
            content={<Content review={review} refetchReview={refetchData} />}
          />
        )}
      />
    </div>
  );
}

export default newDataLoader({
  fetch: ({ match }) => get(`people_user_reviews/${match.params.reviewId}`),
  hydrate: {
    review: {
      abilities: {},
      user: {
        abilities: {},
      },
      reviewedDimensionBlocks: {
        dimension: {},
        ratingOptions: {},
        rating: {},
      },
      finalCommentBlock: {},
      successorBlock: {
        peopleReviewSuccessors: {
          user: { currentCareerLevel: { skillsLevel: {} } },
          peopleGroups: {},
        },
      },
    },
  },
})(PeopleUserReviewComponent) as React.ComponentType<Props>;
