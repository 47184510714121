import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';

import { SurveyPollingPeriodStats, UserFilterSegment } from 'models';

import tailwindTheme from 'config/tailwindTheme';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  FetchContainer,
  FlatPieChart,
  Icon,
  MaterialIconName,
  Text,
} from 'components';

import NotEnoughParticipantsEmptyState from '../../components/NotEnoughParticipantEmptyState';
import NoParticipantEmptyState from './NoParticipantEmptyState';

type Props = {
  campaignId: string;
  isAnonymous: boolean;
  periodId: string;
  segment: UserFilterSegment | null;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    surveyPollingPeriodStats: SurveyPollingPeriodStats;
  };

const NumberWithLabel = ({
  count,
  label,
  iconName,
}: {
  count: number | string;
  label: string;
  iconName: MaterialIconName;
}) => (
  <div className="flex flex-col gap-2 items-center">
    <Text preset="24bs2">{count}</Text>
    <Text preset="14s6" color="light" additionalClassName="whitespace-nowrap">
      <Icon name={iconName} additionalClassName="mr-3" />
      {label}
    </Text>
  </div>
);

const StatsBox = ({
  isFetching,
  hasError,
  surveyPollingPeriodStats,
  isAnonymous,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const {
          sharedPercent,
          inProgressPercent,
          notStartedPercent,
          participantCount,
          respondentCount,
        } = surveyPollingPeriodStats;

        if (participantCount === 0) return <NoParticipantEmptyState />;
        if (participantCount < 3 && isAnonymous)
          return <NotEnoughParticipantsEmptyState />;

        const flatPieChartData = [
          {
            label: __('Shared'),
            value: sharedPercent,
            color: tailwindTheme.colors.indigo[800],
          },
          {
            label: __('In progress'),
            value: inProgressPercent,
            color: tailwindTheme.colors.indigo[400],
          },
          {
            label: __('Not started'),
            value: notStartedPercent,
            color: tailwindTheme.colors.indigo[300],
          },
        ];

        return (
          <Box>
            <div className="flex">
              <div className="flex-1 mr-16">
                <Text preset="18bs5" additionalClassName="mb-2 block">
                  {__('Completion')}
                </Text>
                <FlatPieChart data={flatPieChartData} />
              </div>
              <div className="flex flex-1 items-center justify-around border-0 border-l border-solid border-ui-separator-on-background pl-16">
                <NumberWithLabel
                  count={participantCount}
                  label={__('Participants')}
                  iconName="groups"
                />
                <NumberWithLabel
                  count={respondentCount}
                  label={n__(
                    'Shared|Singular',
                    'Shared|Plural',
                    respondentCount
                  )}
                  iconName="assignment_turned_in"
                />
              </div>
            </div>
          </Box>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ campaignId, periodId, segment }: Props) =>
    get(`survey/campaigns/${campaignId}/periods/${periodId}/stats`, {
      userFilter: segment,
    }),
  hydrate: {
    surveyPollingPeriodStats: {},
  },
  cacheKey: ({ campaignId, periodId, segment }: Props) =>
    compositeKey({ campaignId, periodId, segment }),
})(StatsBox) as React.ComponentType<Props>;
