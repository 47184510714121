import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyState } from 'components';
import emptyStatePng from 'components/TrainingRequestEmptyState/emptyState.png';

const TrainingRequestNoRecordState = () => {
  return (
    <EmptyState
      title={__('No training request have been created yet')}
      description={__(
        'As soon as a training request is created, it will be displayed in this screen.'
      )}
      src={emptyStatePng}
    />
  );
};

export default TrainingRequestNoRecordState;
