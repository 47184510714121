// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Organization } from 'models';
import type { AppDispatch } from 'redux/actions';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import {
  pathToOrganizationObjectivePeriod,
  pathToTeamObjectivesForObjectivePeriod,
} from 'helpers/paths';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get, post } from 'redux/actions/api';

import { Button, Can, PeriodPicker, Testable, Text } from 'components';

import CreatePeriodModal from '../components/CreateOrganizationPeriodModal';

type Props = {|
  selectedObjectivePeriodSlug: string,
  teamSlug: ?string,
|};

type AfterConnectProps = {|
  ...Props,
  isFetching: boolean,
  hasError: boolean,
  organization: Organization,
  activatePeriod: (id: string) => Promise<*>,
|};

type State = {|
  showCreatePeriodModal: boolean,
|};

class ObjectivePeriodPicker extends React.Component<AfterConnectProps, State> {
  state = {
    showCreatePeriodModal: false,
  };

  openCreatePeriodModal = () => this.setState({ showCreatePeriodModal: true });
  closeCreatePeriodModal = () =>
    this.setState({ showCreatePeriodModal: false });

  render() {
    const {
      selectedObjectivePeriodSlug,
      teamSlug,
      organization,
      isFetching,
      activatePeriod,
    } = this.props;
    if (isFetching) {
      return (
        <div>
          <PeriodPicker isFetching />
        </div>
      );
    }

    const { objectivePeriods } = organization;

    invariant(
      objectivePeriods,
      'Organization should contain the objective periods'
    );

    const currentObjectivePeriod = objectivePeriods.find(
      objectivePeriod => objectivePeriod.active
    );
    const selectedObjectivePeriod = objectivePeriods.find(
      objectivePeriod => objectivePeriod.slug === selectedObjectivePeriodSlug
    );

    invariant(
      selectedObjectivePeriod && currentObjectivePeriod,
      'There should always be one selected and one active objective period'
    );

    const periodsData = objectivePeriods.map(objectivePeriod => ({
      id: objectivePeriod.slug,
      name:
        objectivePeriod.name +
        (objectivePeriod.active ? ` ${__('(current period)')}` : ''),
    }));

    return (
      <React.Fragment>
        <div>
          <div className="flex">
            <PeriodPicker
              period={{
                id: selectedObjectivePeriod.slug,
                name: selectedObjectivePeriod.name,
              }}
              periods={periodsData}
              onChange={slug => {
                navigate(
                  teamSlug
                    ? pathToTeamObjectivesForObjectivePeriod(slug, teamSlug)
                    : pathToOrganizationObjectivePeriod(slug)
                );
              }}
              onPeriodCreationLinkClick={
                can({ perform: 'manage_team_objectives', on: organization })
                  ? this.openCreatePeriodModal
                  : undefined
              }
              periodCreationLinkClassName="test-add-period"
            />
            {selectedObjectivePeriod !== currentObjectivePeriod && (
              <Can perform="manage_team_objectives" on="organization">
                <Testable name="test-set-current-period-button">
                  <Button
                    additionalClassName="ml-2"
                    color="secondary"
                    onClick={() => activatePeriod(selectedObjectivePeriodSlug)}
                  >
                    {__('Set as current period')}
                  </Button>
                </Testable>
              </Can>
            )}
          </div>
          <div>
            {selectedObjectivePeriod === currentObjectivePeriod && (
              <Testable name="test-current-period-text">
                <Text color="info" preset="13s7">
                  {__('Current period')}
                </Text>
              </Testable>
            )}
          </div>
        </div>
        <CreatePeriodModal
          isActive={this.state.showCreatePeriodModal}
          onClose={this.closeCreatePeriodModal}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  activatePeriod: (slug: string) =>
    dispatch(post(`organization_objective_periods/${slug}/activate`)),
});

export default (compose(
  newDataLoader({
    fetch: () => get('organization_objective_periods'),
    hydrate: { organization: { objectivePeriods: {}, abilities: {} } },
  }),
  connect(null, mapDispatchToProps)
)(ObjectivePeriodPicker): React.ComponentType<Props>);
