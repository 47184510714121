// @flow
import * as React from 'react';

import type { ReviewCycle, User, UserReview } from 'models';
import type { ResponsibleRole } from 'models/UserReview/UserReview';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import ResponsiblePicker from 'scenes/components/ResponsiblePicker';

type Props = {|
  userReviewId: string,
  isActive?: boolean,
  userReview?: UserReview,
  reviewCycle: ReviewCycle,
  isFetching?: boolean,

  onSubmit: ({
    responsible: ?User,
    responsibleRole: ?ResponsibleRole,
  }) => Promise<*>,
  onCancel: () => any,
|};

type State = {|
  responsible: ?User,
  responsibleRole: ?ResponsibleRole,
|};

type AfterDataLoaderProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  userReview: UserReview,
|};

class DelegateModal extends React.Component<AfterDataLoaderProps, State> {
  state = {
    responsible: null,
    responsibleRole: null,
  };

  static getDerivedStateFromProps(
    { userReview, isFetching }: Props,
    state: State
  ) {
    return state.responsibleRole === null &&
      state.responsible === null &&
      !isFetching &&
      userReview
      ? {
          responsible: userReview.responsible,
          responsibleRole: userReview.responsibleRole,
        }
      : state;
  }

  render() {
    const {
      hasError,
      isFetching,
      userReview,
      isActive,
      onSubmit,
      onCancel,
      reviewCycle,
    } = this.props;

    const { responsible, responsibleRole } = this.state;
    const isDisabled = !responsible && responsibleRole !== 'manager';

    const onChange = async ({ responsible, responsibleRole }) =>
      this.setState({ responsible, responsibleRole });

    return (
      <ModalCard
        isActive={isActive}
        onClose={onCancel}
        refreshContentOnOpening={false}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            return (
              <React.Fragment>
                <ModalCardHead onClose={onCancel}>
                  <ModalCardTitle>
                    {__(
                      'Update responsible for %1’s review',
                      userReview.user.fullName
                    )}
                  </ModalCardTitle>
                </ModalCardHead>

                <ModalCardBody>
                  <p style={{ marginBottom: 16 }}>
                    {__(
                      'The responsible can assess feedback, assess and manage the entire review %1 for %2.',
                      <b>{reviewCycle.name}</b>,
                      <b>{userReview.user.fullName}</b>
                    )}
                  </p>

                  <ResponsiblePicker
                    reviewee={userReview.user}
                    reviewCycleResponsible={reviewCycle.responsible}
                    responsible={responsible}
                    responsibleRole={responsibleRole}
                    onChange={onChange}
                    inModal
                  />
                </ModalCardBody>

                <ModalCardFooter>
                  <Button
                    color="primary"
                    onClick={() => onSubmit({ responsible, responsibleRole })}
                    disabled={isDisabled}
                  >
                    {__('Update responsible')}
                  </Button>

                  <Button color="secondary" onClick={onCancel}>
                    {__('Cancel')}
                  </Button>
                </ModalCardFooter>
              </React.Fragment>
            );
          }}
        />
      </ModalCard>
    );
  }
}

export default (newDataLoader({
  fetch: ({ userReviewId }: Props) => get(`user_reviews/${userReviewId}`),
  hydrate: {
    userReview: {
      user: {
        manager: {},
      },
      reviewer: {},
      responsible: {},
    },
  },
})(DelegateModal): React.ComponentType<Props>);
