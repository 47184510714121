import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { OrganizationSync } from 'models';

import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Flex } from 'components';

import CreateUserButton from './CreateUserButton';
import ExportsButton from './ExportsButton';
import OrganizationSyncState from './OrganizationSyncState';

type Props = {};

type AfterDataloaderProps = DataLoaderProvidedProps &
  Props & {
    lastOrganizationSync: OrganizationSync | undefined | null;
  };

function Actions({
  isFetching,
  hasError,
  lastOrganizationSync,
}: AfterDataloaderProps) {
  const organization = useOrganization();
  const syncEnabled = organization.syncIntegrationInfo.isEnabled;

  // We try to avoid "big" loader as this is not the main content
  if (isFetching) {
    return null;
  }

  // Similarly we avoid big red banners on non-main contents
  if (hasError) {
    return __('Oops, something went wrong 🙁');
  }

  return (
    <Flex verticalAlign>
      {syncEnabled && (
        <OrganizationSyncState
          style={{ marginRight: 16, textAlign: 'end' }}
          organizationSync={lastOrganizationSync}
        />
      )}
      <CreateUserButton
        withUsernameOption={organization.usernameEnabled}
        style={{ marginRight: 16 }}
      />
      <ExportsButton />
    </Flex>
  );
}

export default newDataLoader({
  fetch: () => get('organization_syncs/last'),
  hydrate: {
    lastOrganizationSync: {},
  },
})(Actions) as React.ComponentType<Props>;
