import React, { ReactNode } from 'react';

import { DefaultErrorNotification, Loading, LoadingOverlay } from 'components';

type Props = {
  loadingDelay?: number;
  isFetching: boolean;
  hasError: boolean;
  loadingStyle: 'alone' | 'overlay' | 'none';
  errorMessage?: ReactNode;
  render: () => ReactNode;
  renderFetching: (props: Props) => ReactNode;
  renderError?: () => ReactNode;
};

type State = {
  hasJSError: boolean;
};

export default class FetchContainer extends React.Component<Props, State> {
  static defaultProps = {
    loadingStyle: 'alone',
    render: () => null,
    renderFetching: ({ loadingDelay }: Props) => (
      <Loading delay={loadingDelay} containerStyle={{ padding: 15 }} />
    ),
  };

  state = {
    hasJSError: false,
  };

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    this.setState({ hasJSError: true });
    window.logException(error, { extra: errorInfo });
  }

  render() {
    const {
      isFetching,
      hasError,
      errorMessage,
      render,
      loadingStyle,
      renderFetching,
      renderError,
    } = this.props;

    if (isFetching) {
      if (loadingStyle === 'alone') {
        return renderFetching(this.props);
      }

      if (loadingStyle === 'overlay') {
        return (
          <div style={{ position: 'relative' }}>
            {render()}
            <LoadingOverlay backgroundColor="white" />
          </div>
        );
      }

      return render();
    }

    if (hasError || this.state.hasJSError) {
      if (renderError !== undefined) {
        return renderError();
      } else {
        return <DefaultErrorNotification message={errorMessage} />;
      }
    }

    return render();
  }
}
