import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { AvatarSize } from 'components/Avatar';
import type { User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';

import { del, get } from 'redux/actions/api';

import { DirectUploader } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  user: User;
  size?: AvatarSize;
  withJobTitle?: boolean;
};

type AfterConnectProps = Props & {
  onUploadSuccess: () => Promise<void>;
  onDelete: () => Promise<void>;
};

function UserAvatarWithImagePicker({
  user,
  size,
  withJobTitle,
  onUploadSuccess,
  onDelete,
}: AfterConnectProps) {
  const organization = useOrganization();

  const hasAvatarUploadFeatureFlag =
    organization.featureFlags.includes('avatarUpload');

  return (
    <DirectUploader
      resourcePath={`/users/${user.id}`}
      method="PUT"
      attribute="avatar"
      model="User"
      onSuccess={onUploadSuccess}
      render={({ uploadFromDataURL }) => (
        <UserAvatar
          user={user}
          size={size}
          withJobTitle={withJobTitle}
          canUpload={
            hasAvatarUploadFeatureFlag &&
            can({ perform: 'update_profile_picture', on: user })
          }
          onUpload={uploadFromDataURL}
          onDelete={onDelete}
        />
      )}
    />
  );
}

function mapDispatchToProps(dispatch: AppDispatch, ownProps: Props) {
  return {
    onUploadSuccess: () => dispatch(get(`users/${ownProps.user.id}`)),
    onDelete: () => dispatch(del(`users/${ownProps.user.id}/avatar`)),
  };
}

export default compose(connect(null, mapDispatchToProps))(
  UserAvatarWithImagePicker
);
