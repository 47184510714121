import React, { ReactNode } from 'react';

import { Box, Icon, MaterialIconName, Text } from 'components';

export type Props = {
  iconName: MaterialIconName;
  title: string;
  description?: ReactNode;
  inBox?: boolean;
  additionalClassName?: string;
};

const EmptyStateWithIcon = ({
  iconName,
  title,
  description,
  additionalClassName,
  inBox = true,
}: Props) => {
  const emptyStateFragment = (
    <div
      className={`flex flex-col items-center ${
        inBox ? '' : additionalClassName
      }`}
    >
      <div className="w-12 h-12 mb-4 bg-gray-200 rounded-full flex justify-center items-center">
        <Icon name={iconName} />
      </div>

      <Text preset="14bs6">{title}</Text>
      {!!description && (
        <Text preset="13s7" additionalClassName="my-2">
          {description}
        </Text>
      )}
    </div>
  );
  return inBox ? (
    <Box additionalClassName={additionalClassName}>{emptyStateFragment}</Box>
  ) : (
    emptyStateFragment
  );
};

export default EmptyStateWithIcon;
