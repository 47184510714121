// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { OneOnOneUserReview } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n/index';

import { post } from 'redux/actions/api';

import { MenuItem, Toggleable } from 'components';

import ChangeReviewTemplateModal from './ChangeReviewTemplateModal';

type Props = {|
  userReview: OneOnOneUserReview,

  onAfterAction?: () => Promise<any>,
|};

type AfterConnectProps = {|
  ...Props,
  changeReviewTemplate: (templateId: ?string) => Promise<*>,
|};

function ChangeReviewTemplateAction({
  userReview,
  changeReviewTemplate,
}: AfterConnectProps) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          <MenuItem onClick={toggle}>{__('Change review template')}</MenuItem>

          {isToggled && (
            <ChangeReviewTemplateModal
              isActive={isToggled}
              userReview={userReview}
              onSubmit={async (templateId: ?string) => {
                await changeReviewTemplate(templateId);

                toggle();
              }}
              onCancel={toggle}
            />
          )}
        </React.Fragment>
      )}
    />
  );
}

function mapDispatchToProps(dispatch: AppDispatch, ownProps: Props) {
  return {
    changeReviewTemplate: async (templateId: ?string) => {
      await dispatch(
        post(`user_reviews/${ownProps.userReview.id}/change_review_template`, {
          templateId,
        })
      );

      !!ownProps.onAfterAction && (await ownProps.onAfterAction());
    },
  };
}

export default (connect(
  null,
  mapDispatchToProps
)(ChangeReviewTemplateAction): React.ComponentType<Props>);
