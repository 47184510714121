import React, { Fragment, useState } from 'react';

import type { SkillsMatrix } from 'models';

import { useOrganization } from 'helpers/hooks';

import { FeatureFlagged } from 'components';

import AreaCreationFromMatrixModal from './AreaCreationFromMatrixModal';
import AreaImportModal from './AreaImportModal';
import DomainImportModal from './DomainImportModal';
import DropdownButton from './DropdownButton';

export type AdditionType = 'import' | 'create' | 'import_domain' | null;
export type ModalParams = null | {
  position: number;
  type: AdditionType;
};
export type ModalProps = {
  matrix: SkillsMatrix;
  modalParams: ModalParams;
  setModalParams: (params: ModalParams) => void;
};

export default function useAreaAction(matrix: SkillsMatrix) {
  const [modalParams, setModalParams] = useState<ModalParams>(null);

  const { settings } = useOrganization();

  const disableAreaAction =
    !!matrix.areaMappings &&
    matrix.areaMappings.length >= settings.skillsMaxAreasInMatrix;

  return {
    renderDropdownButton: (params: { position: number }) => (
      <DropdownButton
        disabled={disableAreaAction}
        onClick={(type: AdditionType) => {
          setModalParams({ position: params.position, type });
        }}
      />
    ),
    renderModal: () => (
      <Fragment>
        {modalParams?.type === 'create' && (
          <AreaCreationFromMatrixModal
            matrix={matrix}
            modalParams={modalParams}
            setModalParams={setModalParams}
          />
        )}
        {modalParams?.type === 'import' && (
          <AreaImportModal
            matrix={matrix}
            modalParams={modalParams}
            setModalParams={setModalParams}
          />
        )}
        <FeatureFlagged flag="skillsDomains">
          {modalParams?.type === 'import_domain' && (
            <DomainImportModal
              matrix={matrix}
              modalParams={modalParams}
              setModalParams={setModalParams}
            />
          )}
        </FeatureFlagged>
      </Fragment>
    ),
  };
}
