import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import type {
  TextareaColor,
  TextareaSize,
  TextareaState,
} from 'components/types/textarea';

import { bulmaColorClassName, bulmaSizeClassName } from 'helpers/bulma';
import classNames from 'helpers/classNames';

export type Props = {
  value: string | null | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  rows?: number;
  maxRows?: number;
  hasFixedSize?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  onMaxLengthReached?: () => any;

  color?: TextareaColor;
  size?: TextareaSize;
  state?: TextareaState;

  onChange: (value: string) => any;
  onEnterKeyPress?: () => void | Promise<void>;
  onEscapeKeyPress?: () => void;
  onBlur?: () => any;
  onFocus?: () => any;

  additionalClassName?: string;
  style?: {};
};

function Textarea({
  hasFixedSize,
  value,

  onChange,
  onEnterKeyPress,
  onEscapeKeyPress,
  onBlur,
  onFocus,

  color,
  size,
  state,
  additionalClassName,
  ...otherProps
}: Props) {
  const stateClassName = classNames({
    'is-hovered': state === 'hovered',
    'is-focused': state === 'focused',
    'is-active': state === 'active',
    'is-danger': state === 'danger',
  });

  const className = classNames(
    'textarea',
    additionalClassName,
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    stateClassName,
    {
      'has-fixed-size': hasFixedSize,
    }
  );

  return (
    <TextareaAutosize
      className={className}
      onChange={e => onChange(e.target.value)}
      onKeyDown={e => {
        onEnterKeyPress && e.keyCode === 13 && !e.shiftKey && onEnterKeyPress();
        onEscapeKeyPress && e.keyCode === 27 && onEscapeKeyPress();
      }}
      onFocus={e => {
        const target = e.target as HTMLTextAreaElement;
        target.setSelectionRange(target.value.length, target.value.length);
        onFocus && onFocus();
      }}
      onBlur={onBlur}
      value={value || ''}
      {...otherProps}
    />
  );
}

Textarea.defaultProps = {
  disabled: false,
  readOnly: false,
  size: 'normal',
  state: 'normal',
  rows: 2,
};

export default Textarea;
