import React from 'react';

import type { ReviewCycleIndexSerialization } from 'models';

import { FetchContainer } from 'components';

import ReviewCycleListItem from './ReviewCycleListItem';

type Props = {
  reviewCycles: Array<ReviewCycleIndexSerialization>;
  isFetching: boolean;
  hasError: boolean;
};

const ReviewCycleList = ({ reviewCycles, isFetching, hasError }: Props) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => {
        return (
          <div className="review-cycle-list">
            {reviewCycles.map(reviewCycle => (
              <ReviewCycleListItem
                key={reviewCycle.id}
                reviewCycle={reviewCycle}
              />
            ))}
          </div>
        );
      }}
    />
  );
};

export default ReviewCycleList;
