import React from 'react';

import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import {
  CheckboxSwitch,
  Column,
  Columns,
  Flex,
  PageTitle,
  Text,
  Title,
} from 'components';

const ObjectivesSettings = () => {
  const dispatch = useAppDispatch();
  const organization = useOrganization();
  const setTeamObjectivesEnabled = (areTeamObjectivesEnabled: boolean) =>
    dispatch(
      put(`organizations/${organization.id}/settings`, {
        settings: { areTeamObjectivesEnabled },
      })
    );
  const objectivesEnabled = organization.settings.areTeamObjectivesEnabled;

  return (
    <React.Fragment>
      <PageTitle title={[__('Team Objectives'), __('Settings')]} />
      <div className="bg-white rounded p-5 max-w-[1000px] mx-auto mt-8 md:mt-0">
        <Columns contentVerticallyAligned>
          <Column>
            <Flex verticalAlign additionalClassName="mb-2">
              <Title
                size={5}
                weight="semibold"
                additionalClassName="!text-basic-800 mr-5"
              >
                {__('Team Objectives')}
              </Title>
              <Text color={objectivesEnabled ? 'success' : 'grey'}>
                {objectivesEnabled
                  ? __('Enabled_masculine')
                  : __('Disabled_masculine')}
              </Text>
            </Flex>
            <Text>
              {__(
                'Enables you to create and communicate on company and teams objectives to all users. Once activated, team objectives will appear under the Objective tab. Only managers and admin can create and edit team objectives.'
              )}
            </Text>
          </Column>
          <Column isNarrow>
            <CheckboxSwitch
              value={objectivesEnabled}
              onChange={enabled => setTeamObjectivesEnabled(enabled)}
              size="small"
            />
          </Column>
        </Columns>
      </div>
    </React.Fragment>
  );
};

export default ObjectivesSettings;
