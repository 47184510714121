import React from 'react';
import { Route, Link as RouterLink } from 'react-router-dom';

import type { MaterialIconName } from 'components/Icon';
import type { TextPreset } from 'components/text/Text';

import { Icon, Link, Testable, Text } from 'components';

type NavigationItemBase = {
  to?: string | undefined | null;
  onClick?: () => void;
  label: string;
  icon?: MaterialIconName;
  testName?: string;
  rightContent?: React.ReactNode;
  labelPreset?: TextPreset;
};

type ClickableNavigationItem = NavigationItemBase & {
  onClick: NonNullable<NavigationItemBase['onClick']>;
  isActive?: boolean;
};

type LinkNavigationItem = NavigationItemBase & {
  to: NonNullable<NavigationItemBase['to']>;
  exact?: boolean;
};

export type NavigationItem = ClickableNavigationItem | LinkNavigationItem;

const hasOnClick = (item: NavigationItem): item is ClickableNavigationItem => {
  return !!item.onClick;
};

const hasLink = (item: NavigationItem): item is LinkNavigationItem => {
  return !!item.to;
};

type Props = {
  items: Array<NavigationItem>;
};

export default function NavigationList({ items }: Props) {
  return (
    <ul>
      {items.map(item => {
        if (hasOnClick(item)) {
          const { label, icon, rightContent, testName, onClick, isActive } =
            item;
          return (
            <li key={label} className={isActive ? 'is-active' : ''}>
              <Testable name={`test-link ${testName || ''}`}>
                {icon && <Icon size="small" name={icon} />}
                <Link className={null} onClick={onClick}>
                  <Text preset={item.labelPreset}>{label}</Text>
                  {rightContent}
                </Link>
              </Testable>
            </li>
          );
        } else if (hasLink(item)) {
          const { to, label, icon, exact, rightContent, testName } = item;
          return (
            <Route
              key={label}
              path={to.split('?')[0]}
              exact={!!exact}
              children={({ match: isActive }) => {
                return (
                  <li className={isActive ? 'is-active' : ''}>
                    <Testable
                      name={`test-link ${isActive ? 'active' : ''} ${
                        testName || ''
                      }`}
                    >
                      <RouterLink to={to}>
                        {icon && <Icon size="small" name={icon} />}
                        <Text preset={item.labelPreset}>{label}</Text>
                        {rightContent}
                      </RouterLink>
                    </Testable>
                  </li>
                );
              }}
            />
          );
        } else {
          throw new Error(
            'Navigation item should have either `onClick` or `to`'
          );
        }
      })}
    </ul>
  );
}
