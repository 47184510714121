import React from 'react';

import type { TrainingParticipant } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del } from 'redux/actions/api';

import { BoldHtmlText, ConfirmationModal, Icon, Text } from 'components';

type Props = {
  trainingParticipant: TrainingParticipant;
  setIsRemoveModalActive: (value: boolean) => void;
  trainingSessionName: string | undefined | null;
  onAfterRemove: () => Promise<void>;
};

function RemoveFromSessionModal({
  trainingParticipant,
  setIsRemoveModalActive,
  trainingSessionName,
  onAfterRemove,
}: Props) {
  const dispatch = useAppDispatch();
  const removeFromSession = async () => {
    await dispatch(del(`training/participants/${trainingParticipant.id}`));
    !!onAfterRemove && (await onAfterRemove());
  };

  const shouldCancelMeeting =
    !!trainingParticipant.meeting && !trainingParticipant.meeting.canceledAt;

  const participantFullName = trainingParticipant.user.fullName;

  const removeParticipantWarning = (
    <BoldHtmlText
      html={__(
        'The initial request will be visible in the "Training requests" tab <b>but the related costs and funding sources will be deleted,</b> having an impact on the budget.'
      )}
    />
  );

  return (
    <ConfirmationModal
      isDanger
      isActive
      onCancel={() => setIsRemoveModalActive(false)}
      onConfirm={removeFromSession}
      cancelLabel={__('Cancel')}
      confirmLabel={__('Remove from session')}
      title={__('Remove %1 from the session', participantFullName)}
      refreshContentOnOpening={true}
      inlineButtonIcon={
        shouldCancelMeeting ? (
          <Icon style={{ marginRight: 5 }} name="send" />
        ) : null
      }
    >
      <p>
        {__(
          'This action will remove %1 from the training session',
          participantFullName
        )}
        {trainingSessionName ? ` ${trainingSessionName}.` : '.'}
      </p>
      {shouldCancelMeeting ? (
        <ul style={{ listStyle: 'disc', padding: 24, paddingRight: 0 }}>
          <li>{removeParticipantWarning}</li>
          <li>
            {__('%1 will receive an event cancellation.', participantFullName)}
          </li>
        </ul>
      ) : (
        <p style={{ padding: '24px 0px' }}>{removeParticipantWarning}</p>
      )}
      <p>
        {__(
          'Are you sure you want to remove %1 from this training session?',
          participantFullName
        )}
      </p>
      <div style={{ marginTop: 16 }}>
        <Text color="danger">
          <Icon style={{ marginRight: 8 }} name="warning" />
          {__('This action cannot be undone')}
        </Text>
      </div>
    </ConfirmationModal>
  );
}

export default RemoveFromSessionModal;
