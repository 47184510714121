import React from 'react';

import type { ReviewCycle, User, UserReview } from 'models';
import type { ReleaseActionInfo } from 'scenes/review/Review/ReleaseAction';

import breadcrumb from 'helpers/breadcrumb';
import { __, n__ } from 'helpers/i18n';
import { pathToUser } from 'helpers/paths';

import {
  Avatar,
  BackButton,
  Button,
  Flex,
  FullWidthSubmenuBar,
  Icon,
  Link,
  PageHeader,
  PageTitle,
  ProductIcon,
  SavingStatusLabel,
  Text,
  Title,
  Tooltip,
} from 'components';

import MoreOptionsButton from './MoreOptionsButton';
import PrintActionButton from './PrintActionButton';

type Props = {
  reviewee?: User | null;
  reviewCycle?: ReviewCycle | null;
  review?: UserReview | null;
  backButtonTarget?: string;
  showSavingStatus?: boolean;
  shareAction: ReleaseActionInfo;
  refreshReview: () => Promise<void>;
  displaySideBarToRight?: boolean;
};

export default function Topbar({
  reviewee,
  reviewCycle,
  review,
  backButtonTarget,
  showSavingStatus,
  refreshReview,
  shareAction,
  displaySideBarToRight,
}: Props) {
  const shareButton = (
    <Button
      color="primary"
      disabled={!shareAction.isEnabled}
      onClick={!!shareAction.trigger ? shareAction.trigger : undefined}
    >
      <Icon style={{ margin: '-1px 4px 0 0' }} name="send" />
      {__('Share')}
    </Button>
  );

  const shareActionComponent = shareAction.isShown ? (
    shareAction.isEnabled ? (
      shareButton
    ) : (
      <Tooltip
        placement="bottom-end"
        content={
          shareAction.disabledReasons &&
          shareAction.disabledReasons.length > 0 && (
            <div style={{ maxWidth: '350px' }}>
              <Text size={6} weight="bold">
                {__('Sharing is not possible yet:')}
              </Text>
              {shareAction.disabledReasons &&
                shareAction.disabledReasons.map(reason => (
                  <li key={reason}>{reason}</li>
                ))}
            </div>
          )
        }
        style={{ marginTop: 16 }}
      >
        {shareButton}
      </Tooltip>
    )
  ) : null;

  return displaySideBarToRight ? (
    <PageHeader
      children={
        <div className="flex items-center">
          {!!review?.interactionType && (
            <ProductIcon productName={review.interactionType} />
          )}
          <div className="ml-4">
            <Text
              additionalClassName="page-header__title-and-actions-container__title"
              preset="24bs2"
              testClassName="test-page-header__title-and-actions-container__title"
            >
              {reviewCycle?.name}
            </Text>
            {reviewee && (
              <div className="flex flex-col">
                <Text preset="16bs5.5">
                  <Link
                    additionalClassName="text-blue-elevo"
                    to={pathToUser(reviewee.id)}
                  >
                    {reviewee.fullName}
                  </Link>
                </Text>
                <Text preset="13s7">{reviewee.jobTitle}</Text>
              </div>
            )}
          </div>
        </div>
      }
      actions={
        <React.Fragment>
          {review && <PrintActionButton review={review} />}

          {review && reviewCycle && (
            <MoreOptionsButton
              review={review}
              reviewCycle={reviewCycle}
              refreshReview={refreshReview}
            />
          )}

          {shareActionComponent}
        </React.Fragment>
      }
      statusInfo={
        !!showSavingStatus && (
          <SavingStatusLabel
            failedText={count =>
              n__(
                'One comment could not be saved',
                '%1 comments could not be saved',
                count
              )
            }
          />
        )
      }
      withBackButton
      backButtonProps={{
        target: backButtonTarget || breadcrumb.getAnchor('user_review_list'),
        children: __('Back'),
      }}
    />
  ) : (
    <FullWidthSubmenuBar
      backButton={
        <BackButton
          target={backButtonTarget}
          fallbackTarget={breadcrumb.getAnchor('user_review_list')}
        />
      }
      statusInfo={
        !!showSavingStatus && (
          <SavingStatusLabel
            failedText={count =>
              n__(
                'One comment could not be saved',
                '%1 comments could not be saved',
                count
              )
            }
          />
        )
      }
      actions={
        <React.Fragment>
          {review && <PrintActionButton review={review} />}

          {review && reviewCycle && (
            <MoreOptionsButton
              review={review}
              reviewCycle={reviewCycle}
              refreshReview={refreshReview}
            />
          )}

          {shareActionComponent}
        </React.Fragment>
      }
    >
      <Flex verticalAlign>
        {reviewee && (
          <Avatar
            url={reviewee.avatarUrl}
            size="normal"
            style={{ marginRight: 16 }}
            isSquared
          />
        )}
        <div>
          {review && <Title size={4}>{review.name}</Title>}
          {reviewee && (
            <Text color="info" size={7}>
              {__(
                'Evaluation of %1',
                <Link to={pathToUser(reviewee.id)}>{reviewee.fullName}</Link>
              )}
            </Text>
          )}
          {review && reviewee && (
            <PageTitle title={[reviewee.fullName, review.name]} />
          )}
        </div>
      </Flex>
    </FullWidthSubmenuBar>
  );
}
