import React, { Fragment } from 'react';

import type { Priority, TrainingPeriod, TrainingRequest } from 'models';

import { FormErrors } from 'helpers/api';
import { __, dateRelative } from 'helpers/i18n';

import {
  Column,
  Columns,
  Field,
  FieldError,
  Input,
  Label,
  Text,
  Textarea,
} from 'components';

import PriorityPicker from 'scenes/components/PriorityPicker';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import UserPicker from 'scenes/components/UserPicker';

type Props = {
  trainingRequest: TrainingRequest;
  errors: FormErrors;
  onChange: (trainingRequestParams: Partial<TrainingRequest>) => void;
};

const RequestDetails = ({ trainingRequest, errors, onChange }: Props) => {
  return (
    <Fragment>
      <Field>
        <Label>{__('Request summary')}</Label>
        <Input
          value={trainingRequest.title}
          placeholder={__('ex: Learn the basics of Microsoft Excel')}
          onChange={title => onChange({ title })}
        />
        <FieldError>{errors.title}</FieldError>
      </Field>
      <Field style={{ marginBottom: 0 }}>
        <Label>{__('Additional information about the request')}</Label>
        <Textarea
          value={trainingRequest.description}
          placeholder={__(
            '- What is the purpose of this request?\n' +
              '- Why is it important for your employee’s development?\n' +
              'You can suggest one or several training organizations'
          )}
          rows={5}
          maxRows={10}
          onChange={description => onChange({ description })}
        />
        <FieldError>{errors.description}</FieldError>
      </Field>
      <div style={{ marginBottom: 8, textAlign: 'right' }}>
        <Text color="light" size={7}>
          {__(
            'Created by %1 %2',
            trainingRequest.creator.fullName,
            dateRelative(trainingRequest.requestedAt)
          )}
        </Text>
      </div>
      <Columns>
        <Column>
          <Field>
            <Label>{__('Employee')}</Label>
            <UserPicker
              value={trainingRequest.trainee}
              fetchParams={{ permission: 'create_training_request?' }}
              onChange={() => {}}
              disabled
            />
            <FieldError>{errors.trainee}</FieldError>
          </Field>
        </Column>
        <Column>
          <Field>
            <Label>{__('Request priority (details modal)')}</Label>
            <PriorityPicker
              value={trainingRequest.priority}
              onChange={(priority: Priority) => onChange({ priority })}
            />
            <FieldError>{errors.priority}</FieldError>
          </Field>
        </Column>
      </Columns>
      <Columns>
        <Column size={6}>
          <Field>
            <Label>{__('Period')}</Label>
            <TrainingPeriodPicker
              renderingStyle="select"
              currentTrainingPeriodSlug={trainingRequest.trainingPeriod.slug}
              onChange={(trainingPeriod: TrainingPeriod) =>
                onChange({ trainingPeriod })
              }
            />
          </Field>
        </Column>
      </Columns>
    </Fragment>
  );
};

export default RequestDetails;
