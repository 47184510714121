// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import type { QuickLink, User } from 'models';

import classNames from 'helpers/classNames';
import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToUserObjectiveHistory, pathToUserReviews } from 'helpers/paths';

import {
  Button,
  Hoverable,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  type Style,
  Text,
} from 'components';

type Props = {|
  report: User,
|};

export default class DirectReportUserActions extends React.Component<Props> {
  render() {
    const { report } = this.props;
    invariant(report.quickLinks, 'Direct report needs quick links');
    const quickLinks = report.quickLinks;
    const objectivesQuickLink: ?QuickLink = quickLinks.find(
      link => link.target === 'objectives'
    );
    const reviewsQuickLink: ?QuickLink = quickLinks.find(
      link => link.target === 'reviews'
    );
    invariant(reviewsQuickLink && objectivesQuickLink, 'Missing a quickLink');

    return (
      <React.Fragment>
        <DirectReportQuickLink
          title={n__(
            '%1 ongoing review',
            '%1 ongoing reviews',
            reviewsQuickLink.info.count
          )}
          subtitle={__('See reviews')}
          target={pathToUserReviews(report.id)}
          style={{ marginBottom: 4 }}
        />
        <DirectReportQuickLink
          title={n__(
            '%1 ongoing objective',
            '%1 ongoing objectives',
            objectivesQuickLink.info.count
          )}
          subtitle={__('See objectives')}
          target={pathToUserObjectiveHistory(report.id)}
        />
      </React.Fragment>
    );
  }
}

type DirectReportQuickLinkProps = {|
  title: string,
  subtitle: string,
  target: string,
  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
|};

function DirectReportQuickLink({
  title,
  subtitle,
  target,
  style,
}: DirectReportQuickLinkProps) {
  return (
    <Hoverable
      render={isHovered => (
        <Link
          className={classNames(
            'direct-report-quick-link',
            isHovered && 'is-hovered'
          )}
          to={target}
          style={style}
        >
          <Level>
            <LevelLeft
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Text
                color={isHovered ? 'darkest-grey' : 'pelo-light-grey'}
                transformation="uppercase"
                weight="semibold"
                size={7}
              >
                {title}
              </Text>
              <Text
                color={isHovered ? 'darkest-grey' : 'pelo-light-grey'}
                size={6.5}
                style={{ marginTop: -3 }}
              >
                {subtitle}
              </Text>
            </LevelLeft>
            <LevelRight>
              <Button
                size="small"
                color="primary"
                additionalClassName={isHovered ? 'is-hovered' : undefined}
                style={{ opacity: 1 }}
              >
                <Icon size="small" name="arrow_forward" />
              </Button>
            </LevelRight>
          </Level>
        </Link>
      )}
    />
  );
}
