export const formatFilterToParamsHash = (
  filter: string
): { [key: string]: boolean } => ({ [filter]: true });

export const formatParamsHashToFilterName = (
  filterParams:
    | {
        [key: string]: boolean | { [key: string]: boolean };
      }
    | undefined
): string | null => {
  let selectedFilterName = null;
  for (let key in filterParams) {
    if (filterParams[key] === true) {
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      selectedFilterName = key;
    }
  }
  return selectedFilterName;
};
