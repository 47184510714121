import React from 'react';

import type { UserReview } from 'models';

import { useActiveUser } from 'helpers/hooks';
import invariant from 'helpers/invariant';
import { pathToReviewOrEvaluation } from 'helpers/paths';

import { BoxListItem, Column, Columns, Date, Text } from 'components';

import ReviewCycleIcon from '../../components/reviewCycle/ListItem/ReviewCycleIcon';
import ReviewCycleStatusTag from '../../components/reviewCycle/ListItem/ReviewCycleStatusTag';

type Props = {
  userReview: UserReview;
};

const UserReviewListItem = ({ userReview }: Props) => {
  const activeUser = useActiveUser();
  const { reviewCycle } = userReview;

  invariant(reviewCycle, 'A user review must have a review cycle');

  return (
    <BoxListItem isSmall testClassName="test-link-profile-reviews">
      <Columns>
        <Column isVerticallyCentered>
          <ReviewCycleIcon reviewCycle={reviewCycle} />
          <Text
            style={{ margin: '0 15px' }}
            linkTo={pathToReviewOrEvaluation(userReview, activeUser)}
          >
            {reviewCycle.name}
          </Text>
          <ReviewCycleStatusTag status={reviewCycle.status} />
        </Column>
        <Column size={4} isVerticallyCentered contentIsPulledRight>
          <Text>
            <Date value={reviewCycle.launchedAt} format="MMMM YYYY" />
          </Text>
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default UserReviewListItem;
