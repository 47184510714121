// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToOrganizationObjectivePeriod } from 'helpers/paths';

import { post } from 'redux/actions/api';
import { getAvailablePeriods as getAvailablePeriodsAction } from 'redux/actions/resources';

import { ConfirmationModal, Field, Select, Text } from 'components';

type Props = {|
  isActive: boolean,
  onClose: () => void,
|};

type AfterConnectProps = {|
  ...Props,
  getAvailablePeriods: () => Promise<*>,
  createObjectivePeriod: (name: string) => Promise<*>,
|};

type State = {
  selectedPeriodName: ?string,
  availablePeriods: Array<string>,
};

class CreatePeriodModal extends React.Component<AfterConnectProps, State> {
  state = {
    selectedPeriodName: null,
    availablePeriods: [],
  };

  async componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      const { response } = await this.props.getAvailablePeriods();
      this.setState({
        availablePeriods: response.body,
        selectedPeriodName: response.body[0],
      });
    }
  }

  onClose = () => {
    this.setState({ selectedPeriodName: null });
    this.props.onClose();
  };

  createObjectivePeriod = async () => {
    const { onClose, createObjectivePeriod } = this.props;
    const { selectedPeriodName } = this.state;

    if (!selectedPeriodName) return;

    try {
      const { response } = await createObjectivePeriod(selectedPeriodName);

      const objectivePeriods = response.body.included;
      navigate(
        pathToOrganizationObjectivePeriod(
          objectivePeriods[objectivePeriods.length - 1].attributes.slug
        )
      );
      onClose();
    } finally {
      this.setState({ selectedPeriodName: null });
    }
  };

  render() {
    const { isActive } = this.props;
    const { selectedPeriodName, availablePeriods } = this.state;

    return (
      <ConfirmationModal
        isActive={isActive}
        onCancel={this.onClose}
        refreshContentOnOpening={false}
        title={__('Create a new period')}
        confirmDisabled={!selectedPeriodName}
        onConfirm={this.createObjectivePeriod}
        confirmLabel={__('Create period')}
      >
        <div>
          <p style={{ marginBottom: 20 }}>
            <Text>
              {__(
                'This new period will be common for all teams. ' +
                  'Once the new period has been created, each team can add ' +
                  'its own objectives for this period.'
              )}
            </Text>
          </p>
          <Field>
            <Select
              options={availablePeriods.map(periodName => ({
                value: periodName,
                label: periodName,
              }))}
              value={{
                value: selectedPeriodName || '',
                label: selectedPeriodName || '',
              }}
              onChange={option =>
                this.setState({
                  selectedPeriodName:
                    option && !Array.isArray(option) ? option.value : null,
                })
              }
              styles={{
                menuPortal: styles => ({
                  ...styles,
                  zIndex: 802,
                }),
              }}
            />
          </Field>
        </div>
      </ConfirmationModal>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAvailablePeriods: () => dispatch(getAvailablePeriodsAction()),
  createObjectivePeriod: (name: string) =>
    dispatch(
      post(
        'organization_objective_periods',
        { name },
        {
          successMessage: __(
            'Objective period <b>%1</b> has been created. You can now add teams to it and create objectives.',
            name
          ),
        }
      )
    ),
});

export default (connect(
  null,
  mapDispatchToProps
)(CreatePeriodModal): React.ComponentType<Props>);
