// @flow
import * as React from 'react';

import type { User } from 'models';

import { viewPortAware } from 'components';

import MobileVersion from './MobileVersion';
import TabletVersion from './TabletVersion';

type Props = {|
  activeUser: User,
|};

export default (viewPortAware({
  mobile: MobileVersion,
  tablet: TabletVersion,
}): React.ComponentType<Props>);
