// @flow
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import { __ } from 'helpers/i18n';

import { Label, Text } from 'components';

type Props = {
  color: string,
  onChange: (c: string) => void,
};

function BackgroundImageColorPicker({ color, onChange }: Props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <div className="mb-4 relative">
      <Label>{__('Choose the background color of your image')}</Label>
      <Text style={{ whiteSpace: 'pre-wrap' }} preset="13s7">
        {__(
          'If your image has a transparent background you can configure the color displayed under the image.'
        )}
      </Text>
      <div className="flex items-center mt-1">
        <span className="mr-4 pt-1">
          {color.startsWith('#') ? color.toUpperCase() : __('Gradient')}
        </span>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="inline-block bg-white border-1 border-solid border-gray-300 p-1 rounded-sm cursor-pointer"
          onClick={() => setDisplayColorPicker(true)}
        >
          <div
            className="w-8 h-4 rounded-sm"
            style={{
              background: color,
            }}
          />
        </div>
      </div>
      {displayColorPicker && (
        <div className="absolute z-10">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="fixed inset-0"
            onClick={() => setDisplayColorPicker(false)}
          />
          <SketchPicker color={color} onChange={color => onChange(color.hex)} />
        </div>
      )}
    </div>
  );
}

export default BackgroundImageColorPicker;
