import React from 'react';

import { SurveyCampaign } from 'models';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

type Props = {
  campaign: SurveyCampaign;
};

const NoPeriodEmptyStateStats = ({ campaign }: Props) => {
  const title =
    campaign.status === 'launching'
      ? __('This survey is being launched')
      : __('This survey has not been launched yet');
  const description =
    campaign.status === 'launching'
      ? __('This may take a couple of minutes. Come back soon 🙂.')
      : __(
          "Once launched, you'll find the overall results of your survey here."
        );
  return (
    <EmptyStateWithIcon
      iconName="equalizer"
      title={title}
      description={description}
    />
  );
};

export default NoPeriodEmptyStateStats;
