import React, { Fragment } from 'react';

import { TrainingSession } from 'models';

import can from 'helpers/can';
import { __, n__ } from 'helpers/i18n';
import { pathToTrainingSessionDetails } from 'helpers/paths';

import { Date as DateComponent, Link, Tag, Text } from 'components';

type Props = {
  item: TrainingSession;
};

const TrainingSessionItem = ({ item }: Props) => {
  const canUpdate = can({
    perform: 'update',
    on: item,
  });

  const durationText = item.durationInHours
    ? n__('%1 hour', '%1 hours', parseFloat(item.durationInHours))
    : null;
  const hasDate = !!(item.startDate || item.endDate);
  const isLater = new Date(item.startDate || item.endDate || '') > new Date();

  return (
    <div className="flex mt-4">
      <div className="flex-grow flex flex-col gap-1">
        <Text preset="14bs6">
          {canUpdate ? (
            <Text color="primary">
              <Link
                to={pathToTrainingSessionDetails(item.id)}
                hasInheritedColor
                openInNewTab
                withOpenInNewTabIcon={false}
              >
                {item.name}
              </Link>
            </Text>
          ) : (
            item.name
          )}
        </Text>
        <Text preset="13s7" color="grey">
          {hasDate
            ? __(
                'From %1 to %2',
                <DateComponent value={item.startDate} />,
                <DateComponent value={item.endDate} />
              )
            : __('No date')}
          {durationText && (
            <Fragment>
              {' • '}
              <Text preset="13bs7">{durationText}</Text>
            </Fragment>
          )}
        </Text>
      </div>
      {isLater && (
        <Tag isRounded style={{ textTransform: 'none' }}>
          {__('Upcoming')}
        </Tag>
      )}
    </div>
  );
};

export default TrainingSessionItem;
