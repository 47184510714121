import React from 'react';

import type { BudgetItem } from 'models';

import can from 'helpers/can';
import { n__ } from 'helpers/i18n';

import { Button, Icon } from 'components';

type Props = {
  budgetItem: BudgetItem;
  onDestroy: (budgetItem: BudgetItem) => Promise<void>;
};

const BudgetItemDeletionButton = ({ budgetItem, onDestroy }: Props) => {
  return (
    <Button
      style={{ border: 'none' }}
      onClick={() => onDestroy(budgetItem)}
      disabled={
        !can({
          perform: 'destroy',
          on: budgetItem,
        })
      }
      disabledExplanation={n__(
        'The funding source cannot be edited or deleted as it is used in %1 training participant over this period.',
        'The funding source cannot be edited or deleted as it is used in %1 training participants over this period.',
        budgetItem.trainingParticipantCount
      )}
      tooltipProps={{
        isLight: true,
        style: { maxWidth: 340 },
      }}
    >
      <Icon name="close" />
    </Button>
  );
};

export default BudgetItemDeletionButton;
