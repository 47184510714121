// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Team } from '../../models';
import type { Action } from 'components/formElements/advancedElements/Select';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api/methods';

import { FieldError, Select, type Style } from 'components';

type Props = {|
  value: ?string,
  onChange: (string | null, Team | null) => void,
  error?: ?string,
  autoFocus?: boolean,
  onBlur?: () => void,
  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
  isClearable?: boolean,
  disabled?: boolean,
  disabledTeamIds?: Array<string>,
  inModal?: boolean,
|};

type AfterConnectProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  teams: Array<Team>,
  createTeam: (teamName: string) => Promise<*>,
|};

type State = {
  isLoading: boolean,
};

type TeamOption = {
  label: string,
  value: string,
  team: Team,
};

class TeamPicker extends React.Component<AfterConnectProps, State> {
  static defaultProps = {
    isClearable: true,
  };

  onChange = async (
    option: TeamOption | Array<TeamOption> | null | void,
    { action }: Action
  ) => {
    const { createTeam, refetchData } = this.props;
    invariant(!Array.isArray(option), 'Team option cannot be an array');

    if (option && option.value && action === 'create-option') {
      const { response } = await createTeam(option.value);
      await refetchData();
      const team = {
        id: response.body.data.id,
        name: response.body.data.attributes.name,
        slug: response.body.data.attributes.slug,
      };
      this.props.onChange(team.id, team);
    } else {
      option
        ? this.props.onChange(option.value, option.team)
        : this.props.onChange(null, null);
    }
  };

  isTeamDisabled(team: Team) {
    const { disabledTeamIds } = this.props;
    return disabledTeamIds && disabledTeamIds.includes(team.id);
  }

  render() {
    const {
      value,
      error,
      isFetching,
      hasError,
      onBlur,
      autoFocus,
      style,
      teams,
      isClearable,
      inModal,
    } = this.props;

    const currentTeam =
      !isFetching && !hasError && !!teams
        ? teams.find((team: Team) => team.id === value)
        : null;

    const teamOptions: Array<TeamOption> =
      !isFetching && teams
        ? teams.map((team: Team): TeamOption => ({
            value: team.id,
            label: team.name,
            team: team,
            isDisabled: this.isTeamDisabled(team),
          }))
        : [];

    return (
      <div style={style}>
        <Select
          onBlur={onBlur}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          value={
            currentTeam
              ? {
                  value: currentTeam.id,
                  label: currentTeam.name,
                  team: currentTeam,
                }
              : null
          }
          options={teamOptions}
          onChange={this.onChange}
          isLoading={isFetching}
          isCreatable
          formatCreateLabel={value => __('Create team %1', value)}
          placeholder={__('Select or create a team')}
          noOptionsMessage={__('There is no team yet')}
          isClearable={isClearable}
          inModal={inModal}
        />
        {error ? <FieldError>{error}</FieldError> : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createTeam: (name: string) =>
    dispatch(
      post(
        'teams',
        { name },
        {
          errorMessage: __('A team with the same name already exists.'),
        }
      )
    ),
});

export default (compose(
  newDataLoader({
    fetch: () => get('teams'),
    hydrate: {
      teams: {},
    },
  }),
  connect(null, mapDispatchToProps)
)(TeamPicker): React.ComponentType<Props>);
