// @flow
import * as React from 'react';

import { type MeasurablePosition } from 'components';

type Props = {
  completion: ?number,
  targetPosition: MeasurablePosition,
  onDismiss: () => void,
  onSubmit: (completion: ?number) => Promise<any>,
};

export class ObjectiveCompletionPopover extends React.Component<Props> {
  render() {
    return 'deprecated';
  }
}
