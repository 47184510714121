// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ThreeSixtyUserReview } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components/navigation/Menus';

type Props = {|
  userReview: ThreeSixtyUserReview,
|};

type AfterConnectProps = {|
  ...Props,
  sendFeedbackReminderToPeers: () => any,
|};

function RemindPeersFeedbackAction({
  sendFeedbackReminderToPeers,
}: AfterConnectProps) {
  return (
    <MenuItem onClick={sendFeedbackReminderToPeers}>
      {__('Remind the peers to complete their feedback')}
    </MenuItem>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => ({
  sendFeedbackReminderToPeers: () => {
    dispatch(
      sendReminder(
        ownProps.userReview.id,
        'three_sixty_reminder_for_peer_feedback'
      )
    );
  },
});

export default (connect(
  null,
  mapDispatchToProps
)(RemindPeersFeedbackAction): React.ComponentType<Props>);
