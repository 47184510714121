// @flow
import * as React from 'react';

import type { PeopleReviewCycle } from 'models';

import { __, date } from 'helpers/i18n';
import { pathToPeopleReviewCycleReviews } from 'helpers/navigation';

import { BoxListItem, Column, Columns, Text } from 'components';

import PeopleReviewCycleStatusTag from 'scenes/components/peopleReviewCycle/ListItem/PeopleReviewCycleStatusTag';

type Props = {|
  peopleReviewCycle: PeopleReviewCycle,
|};

export default function PeopleReviewCycleListItem({
  peopleReviewCycle,
}: Props) {
  const { id, name, status, launchedAt, createdAt } = peopleReviewCycle;
  const linkTo = pathToPeopleReviewCycleReviews(id);

  return (
    <BoxListItem testClassName="test-people-review-cycle-item">
      <Columns contentVerticallyAligned>
        <Column>
          <Text linkTo={linkTo}>{name}</Text>
        </Column>
        <Column>
          <PeopleReviewCycleStatusTag status={status} />
        </Column>
        <Column isNarrow>
          <React.Fragment>
            <Text color="info">
              {launchedAt
                ? __('ReviewCycle|Launched on:')
                : __('ReviewCycle|Created on:')}
            </Text>{' '}
            {date(launchedAt || createdAt)}
          </React.Fragment>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
