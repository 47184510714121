import React from 'react';
import { connect } from 'react-redux';

import type { TrainingGroup, TrainingRequest, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { assert } from 'helpers/invariant';

import { hydrateFromResponse } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import { ModalCard } from 'components';

import CreateContent from './CreateContent';
import SuccessContent from './SuccessContent';
import {
  CreateTrainingRequestFormObject,
  CreateTrainingRequestParams,
} from './types';

type Props = {
  currentTrainingPeriodSlug: string;
  concernedUser?: User;
  isActive: boolean;
  createdFromReview?: boolean;
  onClose: () => void;
  onConfirmationClose?: () => void;
  onAfterSubmit?: () => void;
};

type AfterConnectProps = Props & {
  onSubmit: (
    trainingRequest: CreateTrainingRequestFormObject
  ) => Promise<TrainingRequest | TrainingGroup>;
};

function TrainingRequestModal({
  currentTrainingPeriodSlug,
  concernedUser,
  onClose,
  onConfirmationClose,
  onSubmit,
  isActive,
  onAfterSubmit,
}: AfterConnectProps) {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [trainee, setTrainee] = React.useState(null);
  const [trainingRequestsCount, setTrainingRequestsCount] = React.useState(0);

  const handleSubmit = async (
    trainingRequest: CreateTrainingRequestFormObject
  ) => {
    const trainingRequestFromResponse = await onSubmit(trainingRequest);
    !!onAfterSubmit && onAfterSubmit();

    setIsSuccess(true);
    if (trainingRequestFromResponse.type === 'trainingRequest') {
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      setTrainee(trainingRequestFromResponse.trainee);
    }

    if (trainingRequestFromResponse.type === 'trainingGroup') {
      setTrainingRequestsCount(
        trainingRequestFromResponse.trainingRequestsCount
      );
    }
  };

  return (
    <ModalCard
      isBig={!isSuccess}
      isActive={isActive}
      refreshContentOnOpening
      onClose={onClose}
    >
      {isSuccess ? (
        <SuccessContent
          trainee={trainee}
          onClose={onConfirmationClose || onClose}
          trainingRequestsCount={trainingRequestsCount}
        />
      ) : (
        <CreateContent
          currentTrainingPeriodSlug={currentTrainingPeriodSlug}
          concernedUser={concernedUser}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
    </ModalCard>
  );
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { createdFromReview }: Props
) {
  return {
    onSubmit: async ({
      title,
      description,
      priority,
      trainee,
      periodSlug,
      traineeIds,
      collaboratorsChoice,
    }: CreateTrainingRequestFormObject) =>
      dispatch(async (dispatch, getState) => {
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        const params: CreateTrainingRequestParams =
          collaboratorsChoice === 'single'
            ? {
                title,
                description,
                priority,
                createdFromReview,
                traineeId: assert(trainee, 'Trainee exists on single choice')
                  .id,
              }
            : {
                title,
                description,
                priority,
                createdFromReview,
                traineeIds: Array.from(traineeIds),
              };
        const { response } = await dispatch(
          post(`training/periods/${periodSlug}/requests`, params)
        );
        return response.body.data.type === 'trainingRequest'
          ? hydrateFromResponse(
              getState().data,
              response.body,
              { trainingRequest: { trainee: {} } },
              response.body.data.id
            )
          : hydrateFromResponse(
              getState().data,
              response.body,
              { trainingGroup: {} },
              response.body.data.id
            );
      }),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(TrainingRequestModal) as React.ComponentType<Props>;
