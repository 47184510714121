import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, TrainingSession } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader, withPagination } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Column,
  Columns,
  DatatableWrapper,
  FetchContainer,
  Text,
} from 'components';

import TrainingSessionListItem from './TrainingSessionListItem';

type Props = {
  periodSlug: string;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingSessionCollection: PaginatedCollection<TrainingSession>;
  };

const TrainingSessionList = ({
  trainingSessionCollection,
  isFetching,
  hasError,
  countPerPage,
  onFilterChange,
  onSearchChange,
  page,
  previousPageLink,
  nextPageLink,
  search,
  refetchData,
}: AfterDataLoaderProps) => {
  return (
    <Box>
      <DatatableWrapper
        isFetching={isFetching}
        hasError={hasError}
        collectionInfo={trainingSessionCollection}
        countPerPage={countPerPage}
        onFilterChange={onFilterChange}
        onSearchChange={onSearchChange}
        page={page}
        search={search}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        totalCountRenderer={(count?: number | null) =>
          n__('%1 training session', '%1 training sessions', count || 0)
        }
      >
        <BoxList>
          <BoxListItem>
            <Columns isMobile>
              <Column size={5}>
                <Text preset="14s6" color="light">
                  {__('Training session name')}
                </Text>
              </Column>
              <Column size={3}>
                <Text preset="14s6" color="light">
                  {__('Participants')}
                </Text>
              </Column>
              <Column size={3}>
                <Text preset="14s6" color="light">
                  {__('Training dates')}
                </Text>
              </Column>
              <Column size={1} />
            </Columns>
          </BoxListItem>
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <div>
                {!!trainingSessionCollection &&
                  trainingSessionCollection.items.map(trainingSession => (
                    <TrainingSessionListItem
                      key={trainingSession.id}
                      trainingSession={trainingSession}
                      onAfterDestroy={refetchData}
                    />
                  ))}
              </div>
            )}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
};
export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      periodSlug,
    }: AfterPaginateProps) =>
      get(`training/sessions`, {
        page,
        countPerPage,
        search,
        filter,
        periodSlug,
      }),
    hydrate: {
      trainingSessionCollection: {
        items: {
          participants: {
            user: {},
            meeting: {},
          },
          abilities: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      periodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        periodSlug,
      }),
  })
)(TrainingSessionList);
