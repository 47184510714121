import React from 'react';

import type { TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { Field, FieldError, Input, Label, Tooltip } from 'components';

import FloatInput from 'scenes/admin/components/FloatInput';
import MoneyInput from 'scenes/admin/components/MoneyInput';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import SimpleSelect from '../../TrainingRequestManagementModal/SimpleSelect';
import translatedTrainingSessionFormats from '../../components/translatedTrainingSessionFormats';
import EndDatePicker from './EndDatePicker';
import StartDatePicker from './StartDatePicker';

type Props = {
  trainingSession: TrainingSession;
  setTrainingSession: (trainingSession: TrainingSession) => void;
  onChange: (trainingSession?: TrainingSession) => void;
  errors: FormErrors;
};

const EditableFields = ({
  trainingSession,
  setTrainingSession,
  onChange,
  errors,
}: Props) => {
  const formDisabled = !can({ perform: 'update', on: trainingSession });
  const canUpdateLocation = can({
    perform: 'update_dates_and_location',
    on: trainingSession,
  });

  const locationDisabledContent = canUpdateLocation
    ? null
    : __(
        'Dates and location of the training session cannot be edited once the calendar invitation has been sent'
      );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
      <Field>
        <Label>{__('Session name')}</Label>
        <Input
          value={trainingSession.name || ''}
          onBlur={() => onChange()}
          onChange={name => setTrainingSession({ ...trainingSession, name })}
          disabled={formDisabled}
          testClassName="test-name-field"
        />
        <FieldError>{errors.name}</FieldError>
      </Field>
      <Field>
        <Label>{__('Training organization')}</Label>
        <Input
          value={trainingSession.trainingOrganization || ''}
          onBlur={() => onChange()}
          onChange={trainingOrganization =>
            setTrainingSession({
              ...trainingSession,
              trainingOrganization,
            })
          }
          disabled={formDisabled}
        />
        <FieldError>{errors.trainingOrganization}</FieldError>
      </Field>
      <StartDatePicker
        trainingSession={trainingSession}
        onChange={onChange}
        errors={errors}
        disabled={formDisabled}
      />
      <EndDatePicker
        trainingSession={trainingSession}
        onChange={onChange}
        errors={errors}
        disabled={formDisabled}
      />
      <Field>
        <Label>{__('Location of the training')}</Label>
        <Tooltip content={locationDisabledContent}>
          <Input
            value={trainingSession.location || ''}
            onBlur={() => onChange()}
            onChange={location => {
              setTrainingSession({
                ...trainingSession,
                location,
              });
            }}
            disabled={formDisabled || !canUpdateLocation}
          />
        </Tooltip>
        <FieldError>{errors.location}</FieldError>
      </Field>
      <Field>
        <Label>{__('Number of hours')}</Label>
        <FloatInput
          value={
            trainingSession.durationInHours
              ? Number(trainingSession.durationInHours)
              : null
          }
          onInputChange={(durationInHours: number | null) => {
            onChange({
              ...trainingSession,
              durationInHours: `${durationInHours || ''}`,
            });
          }}
          isDisabled={formDisabled}
          saveOnlyOnBlur
        />
        <FieldError>{errors.durationInHours}</FieldError>
      </Field>
      <Field>
        <Label>{__('Type')}</Label>
        <SimpleSelect
          value={trainingSession.trainingType}
          values={{ intra: __('Intra'), inter: __('Inter') }}
          placeholder={__('Select a type')}
          onChange={trainingType => {
            onChange({
              ...trainingSession,
              trainingType,
            });
          }}
          isDisabled={formDisabled}
        />
        <FieldError>{errors.trainingType}</FieldError>
      </Field>
      <Field>
        <Label>{__('Format')}</Label>
        <SimpleSelect
          value={trainingSession.trainingFormat}
          values={translatedTrainingSessionFormats()}
          placeholder={__('Select a format')}
          onChange={trainingFormat => {
            onChange({
              ...trainingSession,
              trainingFormat,
            });
          }}
          isDisabled={formDisabled}
        />
        <FieldError>{errors.trainingFormat}</FieldError>
      </Field>
      <Field>
        <Label>{__('Period')}</Label>
        <TrainingPeriodPicker
          renderingStyle="select"
          currentTrainingPeriodSlug={trainingSession.period.slug}
          onChange={trainingPeriod =>
            onChange({ ...trainingSession, period: trainingPeriod })
          }
        />
      </Field>
      <Field>
        <Label>{__('Total budget')}</Label>
        <MoneyInput
          amount={trainingSession.totalBudgetCents}
          placeholder={__('Enter the total budget')}
          onAmountChange={totalBudgetCents => {
            onChange({
              ...trainingSession,
              totalBudgetCents,
            });
          }}
          currency={trainingSession.totalBudgetCurrency}
          isDisabled={formDisabled}
          saveOnlyOnBlur
        />
        <FieldError>{errors.totalBudget}</FieldError>
      </Field>
    </div>
  );
};

export default EditableFields;
