import { getCurrentLocale } from './i18n';

/**
 * @param amountCents integer or undefined or null
 * @param currency symbol of a currency like '€'
 * @deprecated Use the formatMoney instead
 * This method doesn't fully localize curreny (in english the currency symbol is placed before the number, in french after)
 */
export const deprecatedFormatMoney = (
  amountCents: number | null | undefined,
  currency: string
): string => {
  const formattedNumber = amountCents
    ? (amountCents / 100).toLocaleString(getCurrentLocale() || undefined)
    : '-';
  return `${formattedNumber} ${currency}`;
};

/**
 * @param amountCents integer
 * @param currency is the ISO code of a currency like 'EUR' or 'USD'
 * This method doesn't fully localize curreny (in english the currency symbol is placed before the number, in french after)
 */
export const formatMoney = (amountCents: number, currency: string): string =>
  (amountCents / 100.0).toLocaleString(getCurrentLocale(), {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'code',
  });
