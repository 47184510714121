import React from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToTrainings } from 'helpers/paths';

import { DesignSystem, PageTitle, Redirect, Route, Switch } from 'components';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import TrainingPeriodCreator from './TrainingPeriodCreator';
import TrainingPeriodLanding from './TrainingPeriodLanding';
import TrainingPeriodRedirector from './TrainingPeriodRedirector';
import TrainingPeriodRequests from './TrainingPeriodRequests';
import TrainingSessionManagement from './TrainingSessionManagement';
import TrainingSessions from './TrainingSessions';

function TrainingRoutes(props: RouteComponentProps) {
  const { path } = props.match;

  return (
    <DesignSystem version={2}>
      <PageTitle title={[__('Trainings'), __('Admin')]} />

      <Switch>
        <Route
          path={`${path}/sessions/:id`}
          component={TrainingSessionManagement}
        />

        <Route>
          <Switch>
            <Route
              exact
              path={`${path}`}
              component={TrainingPeriodRedirector}
            />

            <Route
              path={`${path}/periods/new`}
              component={TrainingPeriodCreator}
            />
            <Route path={`${path}/landing`} component={TrainingPeriodLanding} />
            <Route
              path={`${path}/:trainingPeriodSlug/requests`}
              component={TrainingPeriodRequests}
            />

            <Route
              path={`${path}/:trainingPeriodSlug/sessions`}
              component={TrainingSessions}
            />

            <Redirect to={pathToTrainings()} />
          </Switch>
        </Route>
      </Switch>
    </DesignSystem>
  );
}

export default function Routes(props: RouteComponentProps) {
  const organization = useOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes {...props} />;
  } else {
    return <TrainingRoutes {...props} />;
  }
}
