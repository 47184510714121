// @flow
import * as React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingPeriod } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import { get } from 'redux/actions/api';

import PopoverPicker from 'scenes/components/TrainingPeriodPicker/PopoverPicker';
import SelectPicker from 'scenes/components/TrainingPeriodPicker/SelectPicker';

type Props = {|
  renderingStyle: 'select' | 'popover',
  currentTrainingPeriodSlug: string,
  onChange: (period: TrainingPeriod) => void,
|};

type AfterDataLoaderProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  trainingPeriods: Array<TrainingPeriod>,
|};

function TrainingPeriodPicker({
  renderingStyle,
  currentTrainingPeriodSlug,
  trainingPeriods,
  onChange,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  const Picker = renderingStyle === 'select' ? SelectPicker : PopoverPicker;

  let currentTrainingPeriod = null;

  if (!isFetching && !!trainingPeriods) {
    currentTrainingPeriod = trainingPeriods.find(
      period => period.slug === currentTrainingPeriodSlug
    );
  }

  if (hasError) {
    return (
      <p className="has-text-danger">
        {__('An error has occurred while fetching training periods.')}
      </p>
    );
  }

  return (
    <Picker
      currentTrainingPeriod={currentTrainingPeriod}
      trainingPeriods={trainingPeriods}
      onChange={onChange}
      isFetching={isFetching}
    />
  );
}

const cacheKey = 'training_period_ids';

// $FlowFixMe: Migrate to TS
export async function refetchTrainingPeriods(dispatch: AppDispatch) {
  await dispatch(requestStarted(cacheKey));
  const response = await dispatch(get('training/periods'));
  await dispatch(requestSucceeded(cacheKey, response.response.body));
}

export default (newDataLoader({
  fetch: () => get('training/periods'),
  hydrate: {
    trainingPeriods: {},
  },
  cacheKey: () => cacheKey,
})(TrainingPeriodPicker): React.ComponentType<Props>);
