// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { UserReview } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';
import { successNotice } from 'redux/actions/application';

import { ConfirmationModal } from 'components';

type Props = {|
  isActive: boolean,
  toggleModal: () => void,
  userReview: UserReview,
  onAfterAction?: () => Promise<any>,
|};

type AfterConnectProps = {|
  ...Props,
  unreleaseUserReview: () => Promise<*>,
|};

const Unrelease360UserReviewModal = ({
  isActive,
  toggleModal,
  userReview,
  unreleaseUserReview,
}: AfterConnectProps) => (
  <ConfirmationModal
    isActive={isActive}
    cancelLabel={__('Cancel')}
    confirmLabel={__('Re-open and send emails')}
    title={__('Re-open 360 feedback')}
    onConfirm={async () => {
      await unreleaseUserReview();
      toggleModal();
    }}
    onCancel={toggleModal}
    refreshContentOnOpening={false}
  >
    {__(
      'Re-opening this 360 feedback review will allow peers that did not have time to respond to complete and submit their feedback. These peers will be informed by email of the possibility to complete their feedback.'
    )}
  </ConfirmationModal>
);

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => ({
  unreleaseUserReview: async () => {
    const { userReview, onAfterAction } = ownProps;
    const reviewee = userReview.user;

    await dispatch(post(`user_reviews/${userReview.id}/unrelease`));

    if (onAfterAction) {
      await Promise.resolve(onAfterAction());
    }
    reviewee &&
      (await dispatch(
        successNotice(
          __('You re-opened the 360 feedback about %1', reviewee.fullName)
        )
      ));
  },
});

export default connect(null, mapDispatchToProps)(Unrelease360UserReviewModal);
