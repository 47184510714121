import React from 'react';

import {
  SurveyAnswer,
  SurveyAnswerUpdatableParams,
  SurveyInstructionFormStep,
  SurveyQuestionFormStep,
  isQuestionFormStep,
} from 'models/surveys';

import { Box, Text } from 'components';

import Question from './Question';

type QuestionProps = {
  formStep: SurveyQuestionFormStep;
  onChange: (answer: SurveyAnswerUpdatableParams) => Promise<void>;
  isDisabled: boolean;
  answer: SurveyAnswer | null;
};

type InstructionProps = {
  formStep: SurveyInstructionFormStep;
  onChange?: undefined;
  isDisabled?: undefined;
  answer?: undefined;
};

type Props = QuestionProps | InstructionProps;

const Step = ({ formStep, onChange, isDisabled, answer }: Props) => {
  const { title, description } = formStep.question || formStep.instruction;

  return (
    <Box additionalClassName="max-md:p-2">
      <div className="flex flex-col">
        <Text preset="18bs5" additionalClassName="whitespace-pre-line">
          {title}
        </Text>
        {description && (
          <Text preset="14s6" additionalClassName="mt-4 whitespace-pre-line">
            {description}
          </Text>
        )}

        {isQuestionFormStep(formStep) && (
          <Question
            key={formStep.id}
            question={formStep.question}
            onChange={onChange as QuestionProps['onChange']}
            answer={answer as QuestionProps['answer']}
            commentEnabled={formStep.commentEnabled}
            isDisabled={isDisabled as QuestionProps['isDisabled']}
          />
        )}
      </div>
    </Box>
  );
};

export default Step;
