import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps, PaginationProps } from 'lib/dataLoader';
import type { CollectionInfo, SkillsArea } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
} from 'components';

import SkillAreaListItem from './SkillAreaListItem';
import Header from './TableHeader';

type Props = {
  domainId: string | null;
  areaIds: Array<string>;
  onUpdate: (ids: Array<string>) => void;
};

type AfterPaginateProps = Props & PaginationProps;

type AreaCollection = {
  items: Array<SkillsArea>;
} & CollectionInfo;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    areaCollection: AreaCollection;
  };

const SkillsPicker = ({
  areaIds,
  domainId,
  areaCollection,
  onUpdate,
  ...dataLoaderProps
}: AfterDataLoaderProps) => {
  return (
    <DatatableWrapper
      collectionInfo={areaCollection}
      {...dataLoaderProps}
      onUserFilterChange={undefined}
      showTotalRecordCount={false}
      renderNoResult={filter => (
        <EmptyState
          description={
            filter === 'all'
              ? __('No skill could be found.')
              : __('No skill is available.')
          }
        />
      )}
      filters={[
        { param: 'all', label: __('DomainAreas|All') },
        { param: 'available', label: __('Available') },
      ]}
      renderHeader={({
        search,
        onSearchChange,
        filters,
        activeFilter,
        onFilterChange,
      }) => (
        <Header
          search={search}
          onSearchChange={onSearchChange}
          filters={filters}
          activeFilter={activeFilter}
          onFilterChange={onFilterChange}
          skillCount={areaIds.length}
        />
      )}
    >
      <Box isInset>
        <BoxList additionalClassName="participant-list">
          <FetchContainer
            isFetching={dataLoaderProps.isFetching}
            hasError={dataLoaderProps.hasError}
            loadingStyle="overlay"
            render={() => {
              return areaCollection?.items.map(area => (
                <SkillAreaListItem
                  key={area.id}
                  area={area}
                  selectable={
                    !area.domain || (!!domainId && domainId === area.domain.id)
                  }
                  unselectableMessage={__('Already linked to a domain')}
                  selected={areaIds.includes(area.id)}
                  onCheckboxClick={checked =>
                    onUpdate(
                      checked
                        ? areaIds.concat(area.id)
                        : areaIds.filter(id => id !== area.id)
                    )
                  }
                />
              ));
            }}
          />
        </BoxList>
      </Box>
    </DatatableWrapper>
  );
};

export default compose<React.ComponentType<AfterPaginateProps>>(
  withStatePagination({
    defaultPaginationParams: () => ({
      filter: { available: true },
      countPerPage: 4,
    }),
  }),
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      domainId,
    }: AfterPaginateProps) =>
      get('skills/domains/areas', {
        page,
        countPerPage,
        search,
        filter,
        domainId,
      }),
    hydrate: {
      areaCollection: {
        items: {
          domain: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      domainId,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, domainId }),
  })
)(SkillsPicker) as React.ComponentType<Props>;
