// @flow
import * as React from 'react';

import type {
  UserFilterSegment,
  UserFilterableFieldType,
  UserFilterableSource,
} from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Notification, Select, type Style, Text } from 'components';

type Props = {|
  filterType: UserFilterableFieldType,
  filteredSource?: UserFilterableSource,
  segment: ?UserFilterSegment,
  onChange: (userFilter: ?UserFilterSegment) => void,
  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
|};

type AfterConnectProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  segments: Array<UserFilterSegment>,
|};

function UserFilterSegmentPicker({
  isFetching,
  hasError,
  filterType,
  segment,
  segments,
  onChange,
  style,
}: AfterConnectProps) {
  if (hasError) return null;

  const options = segments
    ? segments
        .filter(segment => segment.label)
        .map(segment => ({
          value: segment.value,
          label: ((segment.label: any): string),
        }))
    : ([]: Array<any>);

  const selectedValue =
    !!segment && !!options
      ? options.filter(option => option.value === segment.value)
      : null;

  const onValueChange = option => {
    invariant(
      !Array.isArray(option),
      'SegmentPicker does not support multi select'
    );

    const segment = !!option
      ? segments.find(segment => segment.value === option.value)
      : null;

    onChange(segment);
  };

  return (
    <div style={style}>
      <Text weight="bold">{filterType.label}</Text>

      {!isFetching && options.length === 0 ? (
        <Notification>{__('No value is defined on this field')}</Notification>
      ) : (
        <Select
          options={options}
          value={selectedValue}
          onChange={onValueChange}
          placeholder={__('Search')}
          isLoading={isFetching}
        />
      )}
    </div>
  );
}

export default (newDataLoader({
  fetch: ({ filterType, filteredSource }: Props) =>
    get(`filters/${filterType.slug}/segments`, { filteredSource }),
  hydrate: {
    segments: {},
  },
  cacheKey: ({ filterType, filteredSource }) =>
    `user_filter_segment_${filterType.slug}_${filteredSource}`,
})(UserFilterSegmentPicker): React.ComponentType<Props>);
