import React from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { UserFilterSegment } from 'models';

import { __, n__ } from 'helpers/i18n';

import { BoldHtmlText, Flex, LevelItem, Select } from 'components';

import UserFilter from 'scenes/components/UserFilter';

type SelectOption = {
  value: any;
  label: any;
};

type Props = {
  totalCount: number | null;
  matrixSelectValues: SelectOption[];
  selectedMatrixId: null | string;
  setSelectedMatrixId: (string) => void;
  levelSelectValues: SelectOption[];
  selectedLevelId: null | string;
  setSelectedLevelId: (string) => void;
  userFilter: UserFilterSegment | null;
  setUserFilter: UserFilterChangeMethod;
};

const FiltersRenderer = ({
  totalCount,
  selectedMatrixId,
  matrixSelectValues,
  setSelectedMatrixId,
  selectedLevelId,
  levelSelectValues,
  setSelectedLevelId,
  userFilter,
  setUserFilter,
}: Props) => {
  const onMatrixChange = option => {
    setSelectedLevelId(null);
    setSelectedMatrixId(option?.value);
  };
  return (
    <React.Fragment>
      <Flex>
        <Select
          value={
            matrixSelectValues.find(
              option => option.value === selectedMatrixId
            ) || null
          }
          placeholder={__('Select a skills matrix')}
          options={matrixSelectValues}
          onChange={onMatrixChange}
          additionalClassName="w-1/3 mr-4"
          isDisabled={matrixSelectValues.length < 1}
        />
        <Select
          value={
            levelSelectValues.find(
              option => option.value === selectedLevelId
            ) || null
          }
          placeholder={__('All levels combined')}
          options={levelSelectValues}
          onChange={option => setSelectedLevelId(option?.value)}
          additionalClassName="w-1/3"
          isClearable
          isDisabled={!selectedMatrixId}
        />
        <LevelItem additionalClassName="ml-4">
          <UserFilter segment={userFilter} onChange={setUserFilter} />
        </LevelItem>
      </Flex>
      {!!selectedMatrixId && totalCount !== null && (
        <div className="mt-4">
          <BoldHtmlText
            html={n__(
              '<b>%1 employee</b> matching your filters',
              '<b>%1 employees</b> matching your filters',
              totalCount
            )}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FiltersRenderer;
