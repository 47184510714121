import type { ObjectiveStatus, PersonalObjective } from 'models';

import { post } from '../api';

export type UpdatableObjectiveFields = {
  title: string | null | undefined;
  description: string | null | undefined;
  status: ObjectiveStatus;
  dueDate: string | null | undefined;
  active: boolean;
  completion: number | null | undefined;
  weight: number | null | undefined;
  position: number;
  published: boolean;
};

export const createPersonalObjective = (
  collectionId: string,
  objective: Partial<PersonalObjective>
) =>
  post(`${collectionId}`, {
    ...objective,
  });
