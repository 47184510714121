import React from 'react';

import { __ } from 'helpers/i18n';

import { Flex, Tag, Text } from 'components';

type Props = {
  warningText?: string;
  style?: React.CSSProperties;
};

export default function RequiredQuestionWarning({ warningText, style }: Props) {
  return (
    <Flex
      style={{
        justifyContent: warningText ? 'space-between' : 'flex-end',
        ...style,
      }}
      testClassName="test-mandatory-tag"
      additionalClassName="mandatory-tag"
    >
      {warningText && <Text color="danger">{warningText}</Text>}

      <Tag color="danger" isRounded>
        {__('Required')}
      </Tag>
    </Flex>
  );
}
