import React from 'react';

import type { SelectedDimensionIds } from '..';
import type {
  PeopleReviewCycle,
  PeopleReviewMappingCellData,
  ReviewedDimension,
  UserFilterSegment,
} from 'models';

import { useAppSelector } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { ReduxStore } from 'redux/reducers';

import { NoNullableValues } from 'types/predicates/NoNullValues';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import PeopleUserReviewList from './PeopleUserReviewList';

type Props = {
  cycleId: string;
  dimensionIds: NoNullableValues<SelectedDimensionIds>;
  dimensionValues: { x: number; y: number };
  dimensionLabels: { x: string; y: string };
  cellData: PeopleReviewMappingCellData;
  onClose: () => void;
  userFilter: UserFilterSegment | undefined;
};

const getReviewedDimensionFromStore = (state: ReduxStore, id: string) =>
  state.data.reviewedDimension?.records?.[id] as unknown as ReviewedDimension;

const HeatmapCellDetailsModal = ({
  dimensionIds,
  dimensionValues,
  dimensionLabels,
  cycleId,
  cellData,
  onClose,
  userFilter,
}: Props) => {
  const cycle = useAppSelector(
    state => state.data.peopleReviewCycle?.records?.[cycleId]
  ) as unknown as PeopleReviewCycle;
  invariant(cycle, 'People review cycle must be defined');
  const dimensions = useAppSelector((state: ReduxStore) => ({
    x: getReviewedDimensionFromStore(state, dimensionIds.x),
    y: getReviewedDimensionFromStore(state, dimensionIds.y),
  }));
  invariant(dimensions.x && dimensions.y, 'Dimensions must be defined');

  return (
    <ModalCard isActive onClose={onClose} isLarge>
      <ModalCardHead>
        <ModalCardTitle>{__('Employees details')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Text preset="14s6" additionalClassName="block mb-2">
          <b>
            {userFilter
              ? __(
                  '%1% of the filtered users of %2',
                  cellData.value,
                  cycle.name
                )
              : __('%1% of %2', cellData.value, cycle.name)}
          </b>
          &nbsp;
          {n__(
            'i.e. %1 employee got:',
            'i.e. %1 employees got:',
            cellData.reviewCount
          )}
        </Text>
        <Text preset="14s6">
          {__('%1: %2', dimensions.x.label, dimensionLabels.x)}
        </Text>
        <Text preset="13s7" color="light">
          &nbsp;{__('(Score: %1)', dimensionValues.x)}
        </Text>
        <br />
        <Text>{__('%1: %2', dimensions.y.label, dimensionLabels.y)}</Text>
        <Text preset="13s7" color="light">
          &nbsp;{__('(Score: %1)', dimensionValues.y)}
        </Text>

        <div className="my-4">
          <PeopleUserReviewList
            paginationType="state"
            defaultPaginationParams={{
              countPerPage: 5,
            }}
            userFilter={userFilter}
            cycleId={cycleId}
            dimensionIds={dimensionIds}
            dimensionValues={dimensionValues}
          />
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default HeatmapCellDetailsModal;
