// @flow
import React from 'react';

import { Box, Icon, Text } from 'components';
import materialIconMapping from 'components/Icon/materialIconMapping';
import NavItem from 'components/appLayout/AppBar/NavItem';

type Item = {|
  count?: number,
  title: string,
  path: string,
  show: boolean,
  testClassName?: string,
|};

type Props = {|
  title: string,
  iconName?: $Keys<typeof materialIconMapping>,
  items: Array<Item>,
  additionalClassName?: string,
  testClassName?: string,
|};

export default function HomeNavigationBox({
  iconName,
  title,
  items,
  testClassName,
  additionalClassName,
}: Props) {
  return (
    <Box
      additionalClassName={additionalClassName}
      testClassName={testClassName}
    >
      <div className="box-title">
        <Text preset="13buppers7">
          {iconName && <Icon name={iconName} />}
          {title}
        </Text>
      </div>
      {items
        .filter(i => i.show)
        .map((item, idx) => (
          <NavItem
            key={idx}
            title={item.title}
            count={item.count}
            path={item.path}
            titleClassName={item.count === 0 && 'has-text-weight-normal'}
            testClassName={item.testClassName}
          />
        ))}
    </Box>
  );
}
