// @flow
import * as React from 'react';

import type { ObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';

import { Can, HorizontalEmptyStateWithSvg, Toggleable } from 'components';

import emptyStateSvg from 'scenes/objectives/team/components/TeamSvg';

import AddTeamModal from '../components/AddTeamButton/AddTeamModal';

type Props = {|
  objectivePeriod: ObjectivePeriod,
|};

export default function TeamListEmptyState({ objectivePeriod }: Props) {
  return (
    <Can
      perform="manage_team_objectives"
      on="organization"
      alternativeChildren={
        <HorizontalEmptyStateWithSvg
          svg={emptyStateSvg}
          description={__('This period does not have any team yet')}
        />
      }
    >
      <Toggleable
        render={(isToggled, toggle) => (
          <React.Fragment>
            {!isToggled && (
              <HorizontalEmptyStateWithSvg
                svg={emptyStateSvg}
                description={__('This period does not have any team yet')}
                action={{
                  text: __('Add a team'),
                  onTrigger: toggle,
                  testableName: 'test-add-team-button',
                }}
              />
            )}
            {isToggled && (
              <AddTeamModal
                isActive={isToggled}
                onClose={toggle}
                objectivePeriod={objectivePeriod}
              />
            )}
          </React.Fragment>
        )}
      />
    </Can>
  );
}
