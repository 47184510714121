import React, { useState } from 'react';

import { SurveyQuestionChoice } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';

import {
  Button,
  DragAndDropItem,
  FieldError,
  Icon,
  Input,
  Text,
  Tooltip,
} from 'components';

type Props = {
  choice: SurveyQuestionChoice;
  deletable: boolean;
  onLabelUpdate: (value: string) => Promise<void> | void;
  onDelete: () => void;
};

const QuestionChoice = ({
  choice,
  deletable,
  onLabelUpdate,
  onDelete,
}: Props) => {
  const [label, setLabel] = useState(choice.label);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleError = (apiCall: () => Promise<void> | void) => {
    return handleFormErrors(async () => {
      await apiCall();
      setErrors({});
    }, setErrors);
  };

  return (
    <div>
      <DragAndDropItem
        key={choice.id}
        itemId={choice.id}
        position={choice.position}
        handlePosition="left"
        alwaysDisplayHandle
        disabled={false}
      >
        <div className="flex items-center gap-3 survey-question-choice">
          <Text preset="13s7" additionalClassName="w-3">{`${
            choice.position + 1
          }.`}</Text>
          <Input
            value={label}
            onChange={value => setLabel(value)}
            onBlur={() => handleError(() => onLabelUpdate(label))}
            additionalClassName="max-w-[400px]"
          />
          <Tooltip
            content={__('You must have at least one answer choice.')}
            enabled={!deletable}
          >
            <Button
              hasIconOnly
              isText
              disabled={!deletable}
              additionalClassName="survey-question-choice-delete no-underline"
              onClick={() => handleError(onDelete)}
            >
              <Icon name="close" additionalClassName="text-text-light" />
            </Button>
          </Tooltip>
        </div>
      </DragAndDropItem>
      <FieldError additionalClassName="ml-6">
        {errors['label'] || errors['base']}
      </FieldError>
    </div>
  );
};

export default QuestionChoice;
