// @flow
import * as React from 'react';

import type { Props as TagProps } from 'components/Tag';
import type { PeopleReviewCycleStatus } from 'models/PeopleReviewCycle';

import { __ } from 'helpers/i18n';

import { Tag } from 'components';

type Props = {
  status: PeopleReviewCycleStatus,
};

export default function PeopleReviewCycleStatusTag({ status }: Props) {
  let text = __('unknown');
  // $FlowFixMe Type not found until TypeScript migration
  let props: TagProps = {
    isRounded: true,
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: 'white',
    },
    color: 'primary',
  };

  switch (status) {
    case 'draft':
      text = __('draft');
      props.style = { ...props.style, opacity: '50%' };
      props.testClassName = 'test-is-draft';
      break;
    case 'active':
      text = __('ongoing');
      props.testClassName = 'test-is-ongoing';
      break;
    case 'finalized':
      props.color = 'light';
      props.style = { ...props.style };
      text = __('finalized');
      break;
    default:
      props.color = 'warning';
      break;
  }

  return <Tag {...props}>{text}</Tag>;
}
