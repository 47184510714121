// @flow
import * as React from 'react';

import type { ThreeSixtyUserReview } from 'models/index';

import { __ } from 'helpers/i18n/index';

import { MenuItem, Toggleable } from 'components/index';

import Unrelease360UserReviewModal from './Unrelease360UserReviewModal';

type Props = {|
  userReview: ThreeSixtyUserReview,
  onAfterAction?: () => Promise<*>,
|};

export default function UnreleaseAction({ userReview, onAfterAction }: Props) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          <MenuItem onClick={toggle}>{__('Re-open 360 feedback')}</MenuItem>
          <Unrelease360UserReviewModal
            isActive={isToggled}
            toggleModal={toggle}
            userReview={userReview}
            onAfterAction={onAfterAction}
          />
        </React.Fragment>
      )}
    />
  );
}
