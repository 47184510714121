import React, { CSSProperties, MouseEventHandler } from 'react';

import colors from 'styles/colors';

import classNames from 'helpers/classNames';

import DesignSystem from 'components/DesignSystem';

import materialIconMapping from './materialIconMapping';

export type MaterialIconName = keyof typeof materialIconMapping;

type Size = 'tiny' | 'small' | 'normal' | 'medium' | 'large';
export type Props = {
  name: MaterialIconName;
  style?: CSSProperties;
  additionalClassName?: string;
  filled?: boolean;
  color?: string;
};

type OldProps = Props & {
  size?: Size;
  iconStyle?: {};
  onClick?: MouseEventHandler<HTMLSpanElement>;
  isInfo?: boolean;
  isWarning?: boolean;
  isDanger?: boolean;
  isSpinning?: boolean;
};

function OldIcon({
  color,
  isInfo,
  isWarning,
  isDanger,
  style = {},
  onClick,
  name,
  additionalClassName,
  ...otherProps
}: OldProps) {
  let { size, iconStyle, filled } = otherProps;

  const className = classNames('icon', additionalClassName || '', {
    [`is-${size || ''}`]: !!size,
  });

  let customColor = color;
  if (isInfo) customColor = colors.mediumGrey;
  if (isWarning) customColor = colors.orangeWarning;
  if (isDanger) customColor = colors.redFuckup;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span
      className={className}
      style={{ color: customColor, ...style }}
      onClick={onClick}
    >
      <i
        style={{
          ...iconStyle,
          fontVariationSettings: `'FILL' ${filled ? 1 : 0}`,
        }}
        className="material-icons"
      >
        {name}
      </i>
    </span>
  );
}

export default function Icon(props: OldProps) {
  return (
    <DesignSystem
      render={version => {
        if (version === 1) {
          return <OldIcon {...props} />;
        }

        const { style, additionalClassName, name, color, filled, isSpinning } =
          props;

        const colorClassName = classNames({
          'has-text-primary': color && color === 'primary',
          'has-text-info': color && color === 'info',
          'has-text-danger': color && color === 'danger',
          'has-text-warning': color && color === 'warning',
          'has-text-success': color && color === 'success',
          'animate-[spin_2s_linear_reverse_infinite]': isSpinning,
        });

        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <i
            style={{
              ...style,
              fontVariationSettings: `'FILL' ${filled ? 1 : 0}`,
            }}
            className={classNames(
              'material-icons',
              colorClassName,
              additionalClassName
            )}
            onClick={props.onClick}
          >
            {name}
          </i>
        );
      }}
    />
  );
}
