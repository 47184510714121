// @flow
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import type { User, UserObjectivePeriod } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { get, post } from 'redux/actions/api';

import {
  Button,
  Control,
  Flex,
  Helper,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import UserObjectivePeriodPicker from 'scenes/components/UserObjectivePeriodPicker';
import UserPicker from 'scenes/components/UserPicker';

import ObjectiveList from './ObjectiveList';

type Props = {|
  isActive: boolean,
  toTargetUserObjectivePeriod: UserObjectivePeriod,

  onClose: () => void,
  afterImport?: () => Promise<*>,
|};

type AfterConnectProps = {|
  ...Props,
  fetchDefaultUserObjectivePeriod: (
    User,
    UserObjectivePeriod
  ) => Promise<?UserObjectivePeriod>,
  importObjectives: (
    objectiveIds: Array<string>,
    shouldReinitializeCompletion: boolean
  ) => Promise<UserObjectivePeriod>,
|};

function ImportObjectivesModal({
  toTargetUserObjectivePeriod,
  isActive,
  onClose,
  fetchDefaultUserObjectivePeriod,
  importObjectives,
}: AfterConnectProps) {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedObjectivePeriod, setSelectedObjectivePeriod] =
    React.useState(null);
  const [selectedObjectiveIds, setSelectedObjectiveIds] = React.useState([]);
  const [shouldReinitializeCompletion, setShouldReinitializeCompletion] =
    React.useState(false);

  React.useEffect(
    () => {
      if (isActive && selectedUser) {
        setSelectedObjectivePeriod(null);
        setSelectedObjectiveIds([]);
        setShouldReinitializeCompletion(false);
        fetchDefaultUserObjectivePeriod(
          selectedUser,
          toTargetUserObjectivePeriod
        ).then(userObjectivePeriod =>
          setSelectedObjectivePeriod(userObjectivePeriod)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUser, isActive]
  );

  React.useEffect(() => setSelectedObjectiveIds([]), [selectedObjectivePeriod]);

  const handleObjectiveCheckboxClick = objectiveId => {
    if (selectedObjectiveIds.includes(objectiveId)) {
      setSelectedObjectiveIds(
        selectedObjectiveIds.filter(id => id !== objectiveId)
      );
    } else {
      setSelectedObjectiveIds([...selectedObjectiveIds, objectiveId]);
    }
  };

  return (
    <ModalCard
      isActive={isActive}
      refreshContentOnOpening
      onClose={onClose}
      isLarge
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('Objectives import')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <Helper style={{ marginBottom: 16 }}>
          <p>
            {__(
              'You can only import objectives from users whom you can view their objectives.'
            )}
          </p>
        </Helper>

        <Flex verticalAlign style={{ marginBottom: 16 }}>
          <Label style={{ marginRight: 16, marginBottom: 0 }}>
            {__('From user:')}
          </Label>
          <Control style={{ flexGrow: 2, maxWidth: 200 }}>
            <UserPicker
              fetchParams={{ permission: 'show_objectives?' }}
              value={selectedUser}
              disabled={users =>
                users?.length === 1 && users[0].id === selectedUser?.id
              }
              onChange={user => {
                invariant(
                  user && !Array.isArray(user),
                  'onChange should return a unique defined user'
                );
                setSelectedUser(user);
              }}
            />
          </Control>
        </Flex>

        {selectedUser && (
          <Flex verticalAlign style={{ marginBottom: 16 }}>
            <Label style={{ marginRight: 16 }}>
              {__('Objective periods:')}
            </Label>
            <Control style={{ maxWidth: 250 }}>
              <UserObjectivePeriodPicker
                user={selectedUser}
                userObjectivePeriod={selectedObjectivePeriod}
                onChange={setSelectedObjectivePeriod}
              />
            </Control>
          </Flex>
        )}

        {!!selectedObjectivePeriod && (
          <ObjectiveList
            userObjectivePeriod={selectedObjectivePeriod}
            selectedObjectiveIds={selectedObjectiveIds}
            onObjectiveCheckboxClick={handleObjectiveCheckboxClick}
            shouldReinitializeCompletion={shouldReinitializeCompletion}
            onReinitializeCompletionCheckboxClick={
              setShouldReinitializeCompletion
            }
          />
        )}
      </ModalCardBody>

      <ModalCardFooter>
        <Button
          color="primary"
          testClassName="test-confirm-import-button"
          disabled={selectedObjectiveIds.length === 0}
          onClick={async () => {
            await importObjectives(
              selectedObjectiveIds,
              shouldReinitializeCompletion
            );
            onClose();
          }}
        >
          {n__(
            'Import %1 objective',
            'Import %1 objectives',
            selectedObjectiveIds.length
          )}
        </Button>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { toTargetUserObjectivePeriod, afterImport }: Props
) => ({
  fetchDefaultUserObjectivePeriod: async (
    user: User,
    targetObjectivePeriod: UserObjectivePeriod
  ) => {
    const { response } = await dispatch(
      get(`users/${user.id}/objective_periods`)
    );
    const objectivePeriods = response.body.data.map(({ id, attributes }) => ({
      id,
      ...attributes,
    }));

    if (objectivePeriods.length === 1) return objectivePeriods[0];

    const previousObjectivePeriods = objectivePeriods.filter(
      objectivePeriod =>
        moment(objectivePeriod.createdAt) <
        moment(targetObjectivePeriod.createdAt)
    );

    const previousObjectivePeriod = previousObjectivePeriods
      .sort(period => moment(period.createdAt))
      .reverse()[0];
    return previousObjectivePeriod;
  },

  importObjectives: async (
    objectiveIds: Array<String>,
    reinitializeCompletion: boolean
  ) => {
    await dispatch(
      post(
        `user_objective_periods/${toTargetUserObjectivePeriod.id}/import_objectives`,
        {
          objectiveIds,
          reinitializeCompletion,
        },
        {
          successMessage: __(
            'The objectives have been imported into the period "%1"',
            toTargetUserObjectivePeriod.name
          ),
        }
      )
    );

    !!afterImport && (await afterImport());
  },
});

export default connect(null, mapDispatchToProps)(ImportObjectivesModal);
