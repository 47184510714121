// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate, pathToTrainingPeriodRequests } from 'helpers/navigation';

import { withGenericErrorHandling } from 'lib/api/errorHandler';
import * as api from 'lib/api/genericFetch';
import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  Icon,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  Text,
} from 'components';

import { refetchTrainingPeriods } from './TrainingPeriodPicker';

type Props = {|
  onClose: () => void,
|};

type AfterConnectProps = {|
  ...Props,
  createPeriod: (name: string) => Promise<*>,
  getNextPeriodsName: () => Promise<*>,
|};

function TrainingPeriodCreationModal({
  onClose,
  createPeriod,
  getNextPeriodsName,
}: AfterConnectProps) {
  const [nextPeriodsName, setNextPeriodsName] = React.useState([]);
  const [selectedPeriodName, setSelectedPeriodName] = React.useState(null);

  React.useEffect(() => {
    const setInitialState = async () => {
      const { response } = await getNextPeriodsName();
      setNextPeriodsName(response.body);
      setSelectedPeriodName(response.body[0]);
    };

    setInitialState();
  }, [getNextPeriodsName]);

  const onCreate = () => {
    invariant(selectedPeriodName, 'Period name must exist at this point');
    createPeriod(selectedPeriodName);

    onClose();
  };

  return (
    <ModalCard refreshContentOnOpening isActive onClose={onClose}>
      <div className="training-period-creation-modal">
        <ModalCardHead onClose={onClose}>
          <ModalCardTitle>{__('Create a new period')}</ModalCardTitle>
        </ModalCardHead>
        <ModalCardBody overflowVisible>
          <Field>
            <Text>
              {__(
                'This new period will be shared with all administrators. Once created, managers and administrators will be able to create training requests by selecting this period.'
              )}
            </Text>
          </Field>
          <Field>
            <Label>{__('Choose a period')}</Label>

            <Select
              value={{
                label: selectedPeriodName || '',
                value: selectedPeriodName || '',
              }}
              options={nextPeriodsName.map(name => ({
                label: name,
                value: name,
              }))}
              onChange={option => {
                invariant(
                  option && !Array.isArray(option),
                  'Option must exist and cannot be an array'
                );
                setSelectedPeriodName(option.value);
              }}
              isMulti={false}
              isClearable={false}
              inModal
            />
          </Field>
        </ModalCardBody>
        <ModalCardFooter>
          <div style={{ marginTop: 16 }}>
            <Button onClick={onClose} color="secondary">
              {__('Cancel')}
            </Button>
            <Button
              onClick={onCreate}
              color="primary"
              disabled={!selectedPeriodName}
            >
              <Icon style={{ marginRight: 8 }} name="add" />
              {__('Create period')}
            </Button>
          </div>
        </ModalCardFooter>
      </div>
    </ModalCard>
  );
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    createPeriod: async name => {
      await dispatch(post('training/periods', { name }));

      await dispatch(refetchTrainingPeriods);

      navigate(pathToTrainingPeriodRequests(name));
    },
    getNextPeriodsName: () => {
      const getData = () => (dispatch: AppDispatch) =>
        withGenericErrorHandling(
          api.genericFetch({
            method: 'GET',
            url: 'training/periods/next',
          })
        )(dispatch).then(response => ({ response }));

      return dispatch(getData());
    },
  };
}

export default (connect(
  null,
  mapDispatchToProps
)(TrainingPeriodCreationModal): React.ComponentType<Props>);
