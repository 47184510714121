import moment from 'moment';
import React from 'react';

import type { TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import {
  computeTimesForFiltering,
  dateFromDefaultDateString,
  dateToDefaultDateTimeStringFormat,
} from 'helpers/date';
import { __ } from 'helpers/i18n';

import {
  DatePicker,
  Field,
  FieldError,
  Label,
  TimePicker,
  Tooltip,
} from 'components';

type Props = {
  trainingSession: TrainingSession;
  onChange: (trainingSession?: TrainingSession) => void;
  errors: FormErrors;
  disabled: boolean;
};

const StartDatePicker = ({
  trainingSession,
  onChange,
  errors,
  disabled,
}: Props) => {
  const canUpdateDate = can({
    perform: 'update_dates_and_location',
    on: trainingSession,
  });
  const filterStartTime = time => {
    if (!trainingSession.endDate) return true;

    const { referenceTime, currentTime } = computeTimesForFiltering(
      time,
      trainingSession.startDate,
      trainingSession.endDate
    );

    return referenceTime > currentTime;
  };

  const handleOnChange = (date, timeChanged) => {
    let startDate: string | undefined | null = null;
    let endDate = trainingSession.endDate;

    if (date) {
      let mStartDate = moment(date);

      if (!trainingSession.startDate && !timeChanged) {
        mStartDate.set({ h: 9, m: 0 });
      }

      if (endDate && mStartDate.isAfter(endDate)) {
        endDate = null;
      }

      startDate = dateToDefaultDateTimeStringFormat(mStartDate.toDate());
    }

    onChange({ ...trainingSession, startDate, endDate });
  };

  const tooltipContent = canUpdateDate
    ? null
    : __(
        'Dates and location of the training session cannot be edited once the calendar invitation has been sent'
      );

  return (
    <div>
      <Label>{__('Start date and time')}</Label>
      <div className="training-session-datetime">
        <Field additionalClassName="date-picker">
          <Tooltip content={tooltipContent}>
            <DatePicker
              value={dateFromDefaultDateString(trainingSession.startDate)}
              onChange={date => handleOnChange(date, false)}
              placeholder={__('Choose a start date')}
              disabled={disabled || !canUpdateDate}
            />
          </Tooltip>
        </Field>
        <Field additionalClassName="time-picker">
          <Tooltip content={tooltipContent}>
            <TimePicker
              value={dateFromDefaultDateString(trainingSession.startDate)}
              filterTime={filterStartTime}
              onChange={time => handleOnChange(time, true)}
              disabled={disabled || !canUpdateDate}
            />
          </Tooltip>
          <FieldError>{errors.startDate}</FieldError>
        </Field>
      </div>
    </div>
  );
};

export default StartDatePicker;
