import React from 'react';

import { PeopleReviewSuccessor } from 'models';

import colors from 'styles/colors';
import { opacify } from 'styles/functions';

import { useOrganization } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { concatenatedSkillLevelWithMatrixTitle } from 'helpers/models/skills/skillLevel';

import { del } from 'redux/actions/api';

import {
  Button,
  Column,
  Columns,
  Flex,
  Icon,
  Text,
  Tooltip,
  TooltipOnEllipsis,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import PeopleGroupTagList from 'scenes/peopleReview/cycles/Manage/components/PeopleGroupTagList';

type Props = {
  reviewId: string;
  successor: PeopleReviewSuccessor;
  isClosed: boolean;
};

const SuccessorListItem = ({ reviewId, successor, isClosed }: Props) => {
  const {
    plan: { skillsAndCareersEnabled },
  } = useOrganization();

  const groups = successor.peopleGroups;

  const hasPeopleGroups = groups.length > 0;

  const level = successor.user.currentCareerLevel?.skillsLevel;

  const dispatch = useAppDispatch();

  const deleteSuccessor = (successor: PeopleReviewSuccessor) =>
    dispatch(del(`people_user_reviews/${reviewId}/successors/${successor.id}`));

  return (
    <Flex verticalAlign style={{ width: '100%' }}>
      <Text preset="14s6" additionalClassName="w-4">
        {successor.position + 1}.
      </Text>
      <div style={{ width: '100%' }}>
        <Columns
          additionalClassName="m-2 px-4 py-2 gap-3 rounded-lg"
          style={{ boxShadow: `0 0 0.75em 0 ${opacify(colors.darkGrey, 0.5)}` }}
        >
          <Column isVerticallyCentered size={4}>
            <UserAvatar withJobTitle user={successor.user} />
          </Column>
          {skillsAndCareersEnabled && (
            <Column isVerticallyCentered size={3}>
              {level ? (
                <TooltipOnEllipsis lineClamp={2}>
                  {concatenatedSkillLevelWithMatrixTitle(level)}
                </TooltipOnEllipsis>
              ) : (
                <Text color="grey">-</Text>
              )}
            </Column>
          )}

          <Column isVerticallyCentered>
            {hasPeopleGroups ? (
              <PeopleGroupTagList peopleGroups={groups} />
            ) : (
              <Text color="grey">-</Text>
            )}
          </Column>
          <Column isVerticallyCentered isNarrow>
            {isClosed ? (
              <Tooltip
                content={__(
                  'You cannot remove a potential successor from a closed review.'
                )}
              >
                <Icon name={'delete'} color="info" />
              </Tooltip>
            ) : (
              <Button
                hasIconOnly
                additionalClassName="border-none bg-transparent"
                onClick={() => {
                  deleteSuccessor(successor);
                }}
              >
                <Icon name={'delete'} />
              </Button>
            )}
          </Column>
        </Columns>
      </div>
    </Flex>
  );
};
export default SuccessorListItem;
