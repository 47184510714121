import React from 'react';

import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { anyTemplateWithSkillsInCycle } from 'helpers/models/reviewCycle';
import {
  pathToManagerReviewCycle,
  pathToManagerReviewCycleAnalytics,
  pathToManagerReviewCycleResults,
  pathToManagerReviewCycleSkills,
  pathToMyTeamReviews,
} from 'helpers/paths';
import { Match } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  DesignSystem,
  FeatureFlagged,
  FetchContainer,
  PageHeader,
  PageTitle,
  Redirect,
  Route,
  Switch,
} from 'components';

import ExportMenuButton from 'scenes/admin/reviewCycles/Manage/Header/ExportMenuButton';
import LaunchingCycle from 'scenes/admin/reviewCycles/Manage/LaunchingCycle';

import ManagerCycleAnalytics from './ManagerCycleAnalytics';
import ManagerCycleResults from './ManagerCycleResults';
import ManagerCycleSkills from './ManagerCycleSkills';

type RouterProps = {
  match: Match<{ id: string }>;
};
type Props = RouterProps;

function ReviewCycleIndex({ match, reviewCycle, isFetching, hasError }) {
  const organization = useOrganization();
  const shouldShowSkillsTab =
    organization.featureFlags.includes(
      'mockDataForSkillsAndCareersAnalytics'
    ) || organization.featureFlags.includes('skillsAndCareersAnalytics');
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (reviewCycle.status === 'launching') {
          return (
            <React.Fragment>
              <PageTitle title={[reviewCycle.name, __('Manager')]} />
              <LaunchingCycle reviewCycle={reviewCycle} />
            </React.Fragment>
          );
        }

        const tabItems = [
          {
            label: __('Progress'),
            to: pathToManagerReviewCycleAnalytics(match.params.id),
            testName: 'test-progress-link',
          },
          {
            label: __('Results'),
            to: pathToManagerReviewCycleResults(match.params.id),
            testName: 'test-results-link',
          },
        ];

        if (shouldShowSkillsTab && anyTemplateWithSkillsInCycle(reviewCycle)) {
          tabItems.push({
            label: __('Skills'),
            to: pathToManagerReviewCycleSkills(match.params.id),
            testName: 'test-skills-link',
          });
        }

        return (
          <DesignSystem version={2}>
            <PageHeader
              title={reviewCycle.name}
              withBackButton
              backButtonProps={{
                target: pathToMyTeamReviews(reviewCycle.interactionType),
                children: __('Back to home'),
              }}
              tabItems={tabItems}
              actions={[
                <FeatureFlagged flag={'exportForManagers'}>
                  <ExportMenuButton
                    reviewCycle={reviewCycle}
                    formats={['xlsx']}
                  />
                </FeatureFlagged>,
              ]}
            />

            <Switch>
              <Route
                exact
                path={`${match.path}/analytics`}
                render={() => (
                  <ManagerCycleAnalytics reviewCycle={reviewCycle} />
                )}
              />
              <Route
                exact
                path={`${match.path}/results`}
                render={() => <ManagerCycleResults reviewCycle={reviewCycle} />}
              />
              <Route
                exact
                path={`${match.path}/skills`}
                render={() => <ManagerCycleSkills reviewCycle={reviewCycle} />}
              />

              <Redirect to={pathToManagerReviewCycle(match.params.id)} />
            </Switch>
          </DesignSystem>
        );
      }}
    />
  );
}

export default newDataLoader({
  fetch: (ownProps: Props) => get(`review_cycles/${ownProps.match.params.id}`),
  hydrate: {
    reviewCycle: {
      abilities: {},
      defaultTemplate: {},
      templates: {},
      lastPendingExportAction: {},
    },
  },
})(ReviewCycleIndex);
