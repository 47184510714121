// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { User } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { del } from 'redux/actions/api';

import { ConfirmationModal } from 'components';

type Props = {|
  isActive: boolean,
  onDismiss: () => void,
  user: ?User,
|};

type AfterConnectProps = {|
  ...Props,
  confirm: () => Promise<any>,
|};

function SuspendUserModal({
  confirm,
  isActive,
  onDismiss,
  user,
}: AfterConnectProps) {
  const displayName = user ? user.fullName : '';
  return (
    <ConfirmationModal
      isDanger
      isActive={isActive}
      onCancel={onDismiss}
      onConfirm={confirm}
      title={__('Suspend %1', displayName)}
      confirmLabel={__('Suspend %1', displayName)}
      refreshContentOnOpening={false}
    >
      <p>{__("This user won't be able to connect anymore.")}</p>
      <p>{__('Do you still want to proceed?')}</p>
    </ConfirmationModal>
  );
}

export default (connect(null, (dispatch: AppDispatch, { user }: Props) => ({
  confirm: async () => {
    invariant(user, 'User should be defined');
    await dispatch(del(`users/${user.id}`));
    window.location.reload();
  },
}))(SuspendUserModal): React.ComponentType<Props>);
