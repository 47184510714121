import React from 'react';

import type { Integration, OrganizationSync } from 'models';

import { date } from 'helpers/i18n';

import {
  BoxListItem,
  CheckboxSwitch,
  Column,
  type ColumnSize,
  Columns,
  Control,
  HamburgerMenu,
  MenuItem,
  MenuList,
  PullRight,
  Text,
} from 'components';

import IntegrationSyncStatusText from './IntegrationSyncStatusText';

type DataColumnProps = {
  data: string;
  size?: ColumnSize;
};

type ActivationColumnProps = {
  size?: ColumnSize;
  integration: Integration;
  onUpdate: (value: Partial<Integration>) => Promise<void>;
};

type StatusColumnProps = {
  size?: ColumnSize;
  lastSync: OrganizationSync | undefined | null;
};

function DataColumn({ data, size }: DataColumnProps) {
  return (
    <Column size={size} isVerticallyCentered ellipsisOnLineOverflow>
      <Text title={data}>{data}</Text>
    </Column>
  );
}

function StatusColumn({ size, lastSync }: StatusColumnProps) {
  return (
    <Column size={size} isVerticallyCentered ellipsisOnLineOverflow>
      <IntegrationSyncStatusText sync={lastSync} />
    </Column>
  );
}

function ActivationColumn({
  size,
  integration,
  onUpdate,
}: ActivationColumnProps) {
  return (
    <Column size={size} isVerticallyCentered ellipsisOnLineOverflow>
      <CheckboxSwitch
        value={integration.active}
        onChange={() =>
          onUpdate({ id: integration.id, active: !integration.active })
        }
        size="small"
        style={{ marginTop: -2 }}
        id="integration-activated-switch"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="integration-activated-switch" className="switch-label" />
    </Column>
  );
}

function ActionsColumn({ children, visible }) {
  return (
    <Column
      isNarrow
      isVerticallyCentered
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <PullRight>{children}</PullRight>
    </Column>
  );
}

type Props = {
  integration: Integration;
  actions: Array<React.ReactElement<typeof MenuItem>>;
  onUpdate: (integration: Partial<Integration>) => Promise<void>;
};

export default function IntegrationListItem({
  integration,
  actions,
  onUpdate,
}: Props) {
  return (
    <BoxListItem key={integration.id}>
      <Columns isMobile>
        <DataColumn size={2} data={integration.integrationType} />
        <StatusColumn size={4} lastSync={integration.lastSync} />
        <DataColumn
          size={3}
          data={date(integration.lastSync && integration.lastSync.endedAt)}
        />
        <ActivationColumn
          size={2}
          integration={integration}
          onUpdate={onUpdate}
        />
        <Column contentIsPulledRight>
          <Columns isMobile>
            <ActionsColumn visible={actions.length > 0}>
              <Control>
                <HamburgerMenu align="right">
                  <MenuList>{actions}</MenuList>
                </HamburgerMenu>
              </Control>
            </ActionsColumn>
          </Columns>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
