// @flow
import type {
  PaginationSearchParams,
  QueryParams,
} from 'lib/dataLoader/pagination/types';

import { castHashValuesToBoolean } from 'helpers/hash';

export const DEFAULT_PAGINATION: PaginationSearchParams = {
  page: 1,
  countPerPage: 10,
  search: '',
  filter: { all: true },
  userFilter: null,
};

export function parsePaginationParams(
  queryParams: QueryParams
): $Shape<PaginationSearchParams> {
  const parsedParams: $Shape<PaginationSearchParams> = { ...queryParams };

  if (queryParams.page) {
    parsedParams.page = parseInt(queryParams.page, 10);
  }

  if (queryParams.countPerPage) {
    parsedParams.countPerPage = parseInt(queryParams.countPerPage, 10);
  }

  if (queryParams.search && typeof queryParams.search === 'string') {
    parsedParams.search = queryParams.search;
  }

  if (queryParams.userFilter && typeof queryParams.userFilter === 'object') {
    parsedParams.userFilter = (queryParams.userFilter: any);
  }

  if (queryParams.filter && typeof queryParams.filter === 'object') {
    parsedParams.filter = castHashValuesToBoolean(queryParams.filter);
  }

  return parsedParams;
}
