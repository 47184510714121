import React, { ReactNode } from 'react';

import classNames from 'helpers/classNames';

import { Box, ReadMore, SubTitle, Tag, Title } from 'components';

import RichTextContent from './RichTextContent';

type Props = {
  title: string | null | undefined;
  description: string | null | undefined;
  tag?: string;
  children: ReactNode;
  additionalClassName?: string;
  testClassName?: string;
  withRichText?: boolean;
};

export default function QuestionBlock({
  title,
  description,
  tag,
  children,
  additionalClassName,
  testClassName,
  withRichText,
}: Props) {
  return (
    <Box
      additionalClassName={classNames(
        'question-block block-content-wrapper',
        additionalClassName
      )}
      testClassName={testClassName || 'test-question-block'}
    >
      <div className="presentational-information">
        {!!tag && (
          <div className="block-tag" style={{ float: 'right' }}>
            <Tag color="primary" isRounded>
              {tag}
            </Tag>
          </div>
        )}

        <Title
          size={5}
          weight="bold"
          preserveLinebreaks
          additionalClassName="is-spaced block-title"
          testClassName="test-block-title"
        >
          <RichTextContent content={title} />
        </Title>

        {!!description && (
          <SubTitle
            size={6}
            preserveLinebreaks
            additionalClassName="block-description"
            testClassName="test-block-description"
          >
            <ReadMore>
              <RichTextContent
                content={description}
                withRichText={withRichText}
              />
            </ReadMore>
          </SubTitle>
        )}
      </div>

      {children}
    </Box>
  );
}
