// @flow
import * as React from 'react';

import type { Organization, TrainingPeriod } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { withOrganization } from 'helpers/withSessionProps';

import { PeriodPicker } from 'components';

import TrainingPeriodCreationModal from 'scenes/components/TrainingPeriodCreationModal';

type Props = {|
  currentTrainingPeriod: ?TrainingPeriod,
  trainingPeriods: ?Array<TrainingPeriod>,
  onChange: TrainingPeriod => void,
  isFetching: boolean,
|};

type AfterConnectProps = {|
  ...Props,
  organization: Organization,
|};

function PopoverPicker({
  currentTrainingPeriod,
  trainingPeriods,
  organization,
  onChange,
  isFetching,
}: AfterConnectProps) {
  const [createPeriodModalIsActive, setCreatePeriodModalIsActive] =
    React.useState(false);

  if (isFetching || !trainingPeriods) {
    return (
      <PeriodPicker
        label={__('Loading…')}
        period={null}
        periods={[]}
        isLoading
        onChange={() => {}}
      />
    );
  }

  const canCreatePeriod = can({
    perform: 'create_training_period',
    on: organization,
  });

  return (
    <React.Fragment>
      <PeriodPicker
        period={
          !!currentTrainingPeriod
            ? {
                id: currentTrainingPeriod.id,
                name: currentTrainingPeriod.name,
              }
            : null
        }
        periods={trainingPeriods.map(period => ({
          id: period.id,
          name: period.name,
        }))}
        onChange={periodId => {
          const period = trainingPeriods.find(period => period.id === periodId);

          invariant(period, 'Training period must exist at this point');

          onChange(period);
        }}
        onPeriodCreationLinkClick={
          canCreatePeriod ? () => setCreatePeriodModalIsActive(true) : null
        }
      />

      {createPeriodModalIsActive && (
        <TrainingPeriodCreationModal
          onClose={() => setCreatePeriodModalIsActive(false)}
        />
      )}
    </React.Fragment>
  );
}

export default (withOrganization(PopoverPicker): React.ComponentType<Props>);
