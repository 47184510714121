import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import type { ReviewCycle, User, UserFilterType } from 'models';

import {
  formatFilterToParamsHash,
  formatParamsHashToFilterName,
} from 'helpers/filter';
import { __ } from 'helpers/i18n';
import useUrlPersistedState from 'helpers/useUrlPersistedState';
import { withActiveUser } from 'helpers/withSessionProps';

import {
  ContentContainer,
  FilterBar,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  PageTitle,
} from 'components';

import ParticipantList from 'scenes/admin/reviewCycles/Manage/Participants';
import UserFilter from 'scenes/components/UserFilter';

import ManagerReviewCycleAnalytics from './components/ManagerReviewCycleAnalytics';

type Props = {
  reviewCycle: ReviewCycle;
};

type AfterConnectProps = Props & {
  activeUser: User;
};

function ManagerCycleAnalytics({ reviewCycle, activeUser }: AfterConnectProps) {
  const defaultUserFilter = {
    filterLabel: __('Direct reports of'),
    filterType: 'direct_reports' as UserFilterType,
    label: activeUser.fullName,
    value: activeUser.id,
  };
  const filters = [
    { param: 'active', label: __('Active') },
    { param: 'suspended', label: __('Suspended') },
    { param: 'all', label: __('All') },
  ];

  const [filter, setFilter] = useUrlPersistedState<{
    [key: string]: { [key: string]: boolean } | boolean;
  }>('filter', { all: true, user: { active: true } }, true);

  const [userFilter, setUserFilter] = useUrlPersistedState(
    'userFilter',
    defaultUserFilter
  );
  const activeFilter = formatParamsHashToFilterName(
    filter.user as { [key: string]: boolean }
  );

  return (
    <ContentContainer>
      <PageTitle title={[reviewCycle.name, __('Progress')]} />

      <Level style={{ marginBottom: 16 }}>
        <LevelLeft>
          <LevelItem>
            <UserFilter segment={userFilter} onChange={setUserFilter} />
          </LevelItem>
        </LevelLeft>
        <LevelRight style={{ textAlign: 'end' }}>
          <FilterBar
            display="link"
            leftText={__('Show')}
            filters={filters}
            activeFilter={activeFilter}
            onFilterChange={(param: string) => {
              setFilter({ ...filter, user: formatFilterToParamsHash(param) });
            }}
          />
        </LevelRight>
      </Level>

      <ManagerReviewCycleAnalytics
        reviewCycleId={reviewCycle.id}
        reviewCycleType={reviewCycle.interactionType}
        filter={{ user: filter.user }}
        userFilter={userFilter}
        onUserFilterChange={setUserFilter}
      />

      <ParticipantList
        reviewCycle={reviewCycle}
        hideUserFilters
        filter={filter}
        userFilter={userFilter}
        paginationType="url"
        defaultPaginationParams={{ userFilter, filter }}
      />
    </ContentContainer>
  );
}

export default compose<React.ComponentType<Props>>(
  withRouter,
  withActiveUser
)(ManagerCycleAnalytics);
