import React, { UIEvent } from 'react';
import { NavLink } from 'react-router-dom';

import colors from 'styles/colors';

import classNames from 'helpers/classNames';

import { Link } from 'components';

type Props = {
  children: React.ReactNode;
  onClick?: (e: UIEvent) => void;
  onMouseDown?: () => void;
  to?: string;
  isDanger?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  isInfo?: boolean;
  isPrimary?: boolean;
  style?: React.CSSProperties;
  isActive?: boolean;
  disabled?: boolean;
  additionalClassName?: string;
  testClassName?: string;
};

export default function DropdownMenuItem({
  children,
  onClick,
  onMouseDown,
  to,
  isDanger,
  isWarning,
  isSuccess,
  style,
  isInfo,
  isPrimary,
  isActive,
  disabled,
  additionalClassName,
  testClassName,
}: Props) {
  let mergedStyle = { ...style };
  if (isDanger) mergedStyle = { ...mergedStyle, color: colors.redFuckup };
  if (isWarning) mergedStyle = { ...mergedStyle, color: colors.orangeWarning };
  if (isSuccess) mergedStyle = { ...mergedStyle, color: colors.greenSuccess };
  if (isInfo) mergedStyle = { ...mergedStyle, color: colors.mediumGrey };
  if (isPrimary) mergedStyle = { ...mergedStyle, color: colors.primaryColor };

  const className = classNames(
    'dropdown-item',
    additionalClassName,
    testClassName,
    {
      'is-active': isActive,
      disabled,
    }
  );

  if (to) {
    return (
      <NavLink tabIndex={0} to={to} className={className} style={mergedStyle}>
        {children}
      </NavLink>
    );
  }

  return (
    <Link
      className={className}
      onClick={e => !disabled && onClick && onClick(e)}
      onMouseDown={() => !disabled && onMouseDown?.()}
      style={mergedStyle}
      tabIndex={0}
      onKeyDown={(e: React.KeyboardEvent) =>
        e.key === 'Enter' && onClick && !disabled && onClick(e)
      }
      disabled={disabled}
    >
      {children}
    </Link>
  );
}
