// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { OneOnOneUserReview, User } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n/index';

import { put } from 'redux/actions/api';

import { MenuItem, Toggleable } from 'components';

import UpdateModal from './UpdateModal';

type Props = {|
  userReview: OneOnOneUserReview,
  onAfterAction: () => Promise<any>,
|};

type AfterConnectProps = {|
  ...Props,
  updateAdditionalReviewer: (additionalReviewer: ?User) => Promise<*>,
|};

function UpdateAdditionalReviewerAction({
  userReview,
  updateAdditionalReviewer,
}: AfterConnectProps) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          <MenuItem onClick={toggle}>
            {userReview.additionalReviewer
              ? __('Edit or delete additional reviewer')
              : __('Add an additional reviewer')}
          </MenuItem>

          {isToggled && (
            <UpdateModal
              isActive
              userReview={userReview}
              onSubmit={async (additionalReviewer: ?User) => {
                await updateAdditionalReviewer(additionalReviewer);

                toggle();
              }}
              onClose={toggle}
            />
          )}
        </React.Fragment>
      )}
    />
  );
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { onAfterAction, userReview }: Props
) {
  return {
    updateAdditionalReviewer: async (additionalReviewer: ?User) => {
      await dispatch(
        put(`user_reviews/${userReview.id}/additional_reviewer`, {
          additional_reviewer_id: additionalReviewer?.id,
        })
      );
      await onAfterAction();
    },
  };
}

export default (connect(
  null,
  mapDispatchToProps
)(UpdateAdditionalReviewerAction): React.ComponentType<Props>);
