import React, { CSSProperties } from 'react';

import { __ } from 'helpers/i18n';

import { Column, Columns, FeatureFlagged, Link } from 'components';

import { noAnswerColor } from './SimplePieChart';

export type LegendItemData = {
  color: string;
  label: string | null;
  value: number;
};

type Props = {
  data: Array<LegendItemData>;
  style?: CSSProperties;
  onLegendItemClick: (label: string | null) => void;
};

type LegendItemProps = LegendItemData & {
  onClick: (label: string | null) => void;
};

const LegendItem = ({ color, label, onClick }: LegendItemProps) => {
  const isNoAnswer = label === null;

  return (
    <div className="legend-item">
      <div
        className="legend-dot"
        style={{
          backgroundColor: isNoAnswer ? noAnswerColor : color,
        }}
      />
      {isNoAnswer ? (
        <Link
          style={{ fontStyle: 'italic' }}
          onClick={() => onClick(null)}
          hasInheritedColor
        >
          {__('No answer')}
        </Link>
      ) : (
        <FeatureFlagged
          flag={'listFeedbackResultsPerOption'}
          alternativeChildren={label}
        >
          <Link onClick={() => onClick(label)} hasInheritedColor>
            {label}
          </Link>
        </FeatureFlagged>
      )}
    </div>
  );
};

const Legend = ({ data, style, onLegendItemClick }: Props) => {
  const halfCount = Math.ceil(data.length / 2);
  const firstColumn = data.slice(0, halfCount);
  const secondColumn = data.slice(halfCount, data.length);

  return (
    <Columns style={style} additionalClassName="legend">
      <Column size={6}>
        {firstColumn.map(entry => (
          <LegendItem
            color={entry.color}
            label={entry.label}
            value={entry.value}
            onClick={onLegendItemClick}
            key={entry.label}
          />
        ))}
      </Column>
      <Column size={6}>
        {secondColumn.map(entry => (
          <LegendItem
            color={entry.color}
            label={entry.label}
            value={entry.value}
            onClick={onLegendItemClick}
            key={entry.label}
          />
        ))}
      </Column>
    </Columns>
  );
};

export default Legend;
