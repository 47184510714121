import React, { ReactElement } from 'react';

import type { ApprovalStatus, TrainingRequest } from 'models';

import { FormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { approvalStatusLabel } from 'helpers/models/trainingRequest';

import {
  Column,
  Columns,
  Field,
  FieldError,
  Icon,
  Label,
  Select,
  Text,
} from 'components';

type Option = {
  value: ApprovalStatus;
  label: string;
  icon: ReactElement;
};

type Props = {
  trainingRequest: TrainingRequest;
  errors: FormErrors;
  onChange: (trainingRequestParams: Partial<TrainingRequest>) => void;
};

const ApprovalPicker = ({ trainingRequest, errors, onChange }: Props) => {
  const options: Array<Option> = [
    {
      value: 'waiting_for_approval',
      label: approvalStatusLabel('waiting_for_approval'),
      icon: <Icon name="timer" />,
    },
    {
      value: 'approved',
      label: approvalStatusLabel('approved'),
      icon: <Icon color="success" name="thumb_up" />,
    },
    {
      value: 'refused',
      label: approvalStatusLabel('refused'),
      icon: <Icon color="danger" name="thumb_down" />,
    },
  ];

  return (
    <Columns
      style={{
        backgroundColor: '#EFF1F6',
        marginLeft: '-40px',
        marginRight: '-40px',
        padding: '0 24px',
      }}
    >
      <Column size="half">
        <Field>
          <Label transformation="uppercase" style={{ marginBottom: 0 }}>
            {__('Request approval')}
          </Label>
          <div style={{ marginBottom: 8 }}>
            <Text color="light" size={7}>
              {__('Visible to the employee from their profile')}
            </Text>
          </div>

          <Select
            value={
              options.find(
                option => option.value === trainingRequest.approvalStatus
              ) || null
            }
            options={options}
            onChange={option => {
              invariant(
                option && !Array.isArray(option),
                'Option must exist and cannot be an array'
              );
              onChange({ approvalStatus: option.value });
            }}
            isMulti={false}
            isClearable={false}
            inModal
          />
          <FieldError>{errors.approvalStatus}</FieldError>
        </Field>
      </Column>
    </Columns>
  );
};

export default ApprovalPicker;
