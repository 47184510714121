import type { NavItemProps } from './index';
import type { NavActionsCount, Organization, User } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import {
  pathToAudits,
  pathToENPSForm,
  pathToENPSResults,
  pathToFeedbackRequests,
  pathToMatrixExplorer,
  pathToMatrixList,
  pathToMyReviews,
  pathToMyTeamReviews,
  pathToObjectivesDashboard,
  pathToOtherActions,
  pathToPeopleReviewActions,
  pathToPeopleReviewCycles,
  pathToPeopleReviewDimensions,
  pathToPeopleReviewsToComplete,
  pathToPersonalObjectives,
  pathToReviewCycles,
  pathToSurveyCampaigns,
  pathToSurveyConversations,
  pathToSurveysToComplete,
  pathToTeamObjectives,
  pathToTemplates,
  pathToTrainings,
  pathToUserCareer,
  pathToUserTrainings,
} from 'helpers/paths';

export type NavItemLink = {
  name: string;
  items: Array<Omit<NavItemProps, 'enabled'>>;
};

type ReturnType = {
  // eslint-disable-next-line no-unused-vars
  [key in
    | 'oneOnOneItems'
    | 'threeSixtyItems'
    | 'objectivesItems'
    | 'trainingItems'
    | 'enpsItems'
    | 'peopleReviewItems'
    | 'skillsAndCareerItems'
    | 'auditsItems'
    | 'surveyItems']: NavItemLink;
};

export default function getNavItemsLinks(
  badgeItemsCount: NavActionsCount | undefined | null,
  activeUser: User,
  organization: Organization,
  currentTrainingPeriodSlug: string | undefined | null,
  openTrainingModal: () => void
): ReturnType {
  const settings = organization.settings;

  const filterNavItems = (
    list: Array<NavItemProps>
  ): Array<Omit<NavItemProps, 'enabled'>> => {
    return (
      list
        .filter(item => {
          if (Object.prototype.hasOwnProperty.call(item, 'enabled'))
            return item.enabled;
          return true;
        })
        // remove 'enabled' attribute since it's only used for filtering
        .map(({ enabled, ...rest }) => rest)
    );
  };

  const getOneOnOneItems = (): Array<NavItemProps> => [
    {
      title: __('My Reviews'),
      path: pathToMyReviews('one_on_one'),
      count: badgeItemsCount?.myOneOnOneReviews || 0,
    },
    {
      title: __('My team'),
      path: pathToMyTeamReviews('one_on_one'),
      enabled: activeUser.isManager,
      count: badgeItemsCount?.myOneOnOneTeamReviews || 0,
    },
    {
      title: __('Other reviews'),
      path: pathToOtherActions('one_on_one'),
      enabled: !Object.is(badgeItemsCount?.myOneOnOneOtherReviews, null),
      count: badgeItemsCount?.myOneOnOneOtherReviews || 0,
    },
    {
      title: __('Campaigns'),
      path: pathToReviewCycles('one_on_one'),
      enabled: can({
        perform: 'show_review_campaign_menu',
        on: organization,
      }),
    },
    {
      title: __('Templates'),
      path: pathToTemplates('one_on_one'),
      enabled: can({
        perform: 'show_review_template_menu',
        on: organization,
      }),
    },
  ];

  const getThreeSixtyItems = (): Array<NavItemProps> => [
    {
      title: __('My 360 Feedback'),
      path: pathToMyReviews('360'),
      count: badgeItemsCount?.my360Reviews || 0,
    },
    {
      title: __('My team'),
      path: pathToMyTeamReviews('360'),
      enabled: activeUser.isManager,
      count: badgeItemsCount?.my360TeamReviews || 0,
    },
    {
      title: __('Delegations'),
      path: pathToOtherActions('360'),
      enabled: !Object.is(badgeItemsCount?.my360OtherReviews, null),
      count: badgeItemsCount?.my360OtherReviews || 0,
    },
    {
      title: __('Requests'),
      path: pathToFeedbackRequests(),
      count: badgeItemsCount?.feedbackRequests || 0,
    },
    {
      title: __('Campaigns'),
      path: pathToReviewCycles('360'),
      enabled: can({
        perform: 'show_review_campaign_menu',
        on: organization,
      }),
    },
    {
      title: __('Templates'),
      path: pathToTemplates('360'),
      enabled: can({
        perform: 'show_review_template_menu',
        on: organization,
      }),
    },
  ];

  const getObjectivesItems = (): Array<NavItemProps> => [
    {
      title: __('Personal'),
      path: pathToPersonalObjectives(),
    },
    {
      title: __('Company'),
      path: pathToTeamObjectives(),
      enabled: settings.areTeamObjectivesEnabled && !activeUser.external,
      matchFullPath: true,
    },
    {
      title: __('Follow-up'),
      path: pathToObjectivesDashboard(),
      enabled: can({
        perform: 'show_dashboard_objective_menu',
        on: organization,
      }),
    },
  ];

  const getTrainingItems = (): Array<NavItemProps> => [
    {
      title: __('My requests'),
      path: pathToUserTrainings(activeUser.id),
    },
    {
      title: __('Make a request'),
      onClick: () => {
        openTrainingModal();
      },
      enabled:
        !!currentTrainingPeriodSlug &&
        can({ perform: 'create_training_request_from_home', on: organization }),
    },
    {
      title: __('Training plan'),
      path: pathToTrainings(),
      enabled: can({ perform: 'show_training_plan_menu', on: organization }),
      matchFullPath: true,
    },
  ];

  const getEnpsItems = (): Array<NavItemProps> => [
    {
      title: __('Ongoing'),
      path: pathToENPSForm(),
      enabled: !Object.is(badgeItemsCount?.enpsToComplete, null),
      count: badgeItemsCount?.enpsToComplete || 0,
    },
    {
      title: __('Results'),
      path: pathToENPSResults(),
      enabled: activeUser.isOrganizationAdmin,
    },
  ];

  const getPeopleReviewItems = (): Array<NavItemProps> => [
    {
      title: __('To complete'),
      count: badgeItemsCount?.myPeopleReviewsAsReviewer || 0,
      path: pathToPeopleReviewsToComplete(),
      enabled: !Object.is(badgeItemsCount?.myPeopleReviewsAsReviewer, null),
    },
    {
      title: __('Campaigns'),
      path: pathToPeopleReviewCycles(),
    },
    {
      title: __('Dimensions'),
      path: pathToPeopleReviewDimensions(),
      enabled:
        organization.featureFlags.includes('peopleReviewDimensions') &&
        can({ perform: 'show_dimensions_menu', on: organization }),
    },
    {
      title: __('Actions plans'),
      path: pathToPeopleReviewActions(),
      enabled: organization.featureFlags.includes('peopleReviewActionPlans'),
    },
  ];

  const getSkillsAndCareerItems = (): Array<NavItemProps> => [
    {
      title: __('Matrices and skills'),
      enabled: can({ perform: 'show_matrix_edition_menu', on: organization }),
      path: pathToMatrixList(),
    },
    {
      title: __('My career'),
      enabled: can({ perform: 'show_career', on: activeUser }),
      path: pathToUserCareer(activeUser.id),
    },
    {
      title: __('Career explorer'),
      path: pathToMatrixExplorer(),
    },
  ];

  const getAuditsItems = (): Array<NavItemProps> => [
    {
      title: __('Audits'),
      enabled: can({ perform: 'show_audit_reports_menu', on: organization }),
      path: pathToAudits(),
    },
  ];

  const getSurveyItems = (): Array<NavItemProps> => [
    {
      title: __('To complete'),
      path: pathToSurveysToComplete(),
      count: badgeItemsCount?.surveysToComplete || 0,
    },
    {
      title: __('Conversations'),
      path: pathToSurveyConversations(),
      enabled: organization.featureFlags.includes('surveyMessagingChatRooms'),
    },
    {
      title: __('Campaigns'),
      path: pathToSurveyCampaigns(),
      enabled: can({
        perform: 'show_survey_management_menu',
        on: organization,
      }),
    },
  ];

  return {
    oneOnOneItems: {
      name: __('Reviews'),
      items: filterNavItems(getOneOnOneItems()),
    },
    threeSixtyItems: {
      name: __('360 Feedback'),
      items: filterNavItems(getThreeSixtyItems()),
    },
    objectivesItems: {
      name: __('Objectives'),
      items: filterNavItems(getObjectivesItems()),
    },
    trainingItems: {
      name: __('Training'),
      items: filterNavItems(getTrainingItems()),
    },
    enpsItems: {
      name: __('eNPS'),
      items: filterNavItems(getEnpsItems()),
    },
    peopleReviewItems: {
      name: __('People Review'),
      items: filterNavItems(getPeopleReviewItems()),
    },
    skillsAndCareerItems: {
      name: __('Skills & Career'),
      items: filterNavItems(getSkillsAndCareerItems()),
    },
    auditsItems: {
      name: __('Audits'),
      items: filterNavItems(getAuditsItems()),
    },
    surveyItems: {
      name: __('Surveys'),
      items: filterNavItems(getSurveyItems()),
    },
  };
}
