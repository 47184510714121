import React from 'react';
import { compose } from 'redux';

import type { SessionStats } from 'models';
import type { ComponentType } from 'react';
import type { AppDispatch } from 'redux/actions';

import compositeKey from 'helpers/compositeKey';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import BudgetCard from '../BudgetCard';

type Props = {
  periodSlug: string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    sessionStats: SessionStats;
  };

const TrainingSessionStats = ({
  periodSlug,
  sessionStats,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => {
        return !!sessionStats ? (
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="md:col-span-2">
              <BudgetCard
                periodSlug={periodSlug}
                overallStats={sessionStats.overallBudgetStats}
              />
            </div>
          </div>
        ) : null;
      }}
    />
  );
};

const cacheKeyId = (slug: string) =>
  compositeKey({ slug, viewId: 'trainingSessionStats' });

const urlToTrainingSessionStats = (slug: string) =>
  `training/periods/${slug}/session_stats`;

export function refetchTrainingSessionStats(periodSlug: string) {
  const requestId = cacheKeyId(periodSlug);

  return async function (dispatch: AppDispatch) {
    await dispatch(requestStarted(requestId));
    const response = await dispatch(get(urlToTrainingSessionStats(periodSlug)));
    await dispatch(requestSucceeded(requestId, response.response.body));
  };
}

export default compose(
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(urlToTrainingSessionStats(periodSlug)),
    hydrate: {
      sessionStats: {},
    },
    cacheKey: ({ periodSlug }) => cacheKeyId(periodSlug),
  })
)(TrainingSessionStats) as ComponentType<Props>;
