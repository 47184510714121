import { isEmpty } from 'lodash';
import React from 'react';

import type { StepName } from '../../../helpers/steps';
import type {
  Organization,
  ReviewCycle,
  ReviewTemplate,
  ThreeSixtyReviewCycle,
} from 'models';

import { __, n__ } from 'helpers/i18n';
import { pathToReviewTemplate } from 'helpers/paths';

import { Link, Tag } from 'components';

import { Props as SummaryItemProps } from 'scenes/components/Stepper/SummaryStep/SummaryItem';

import getSummaryDeadlinesItems from './getSummaryDeadlinesItems';

const getAllSummaryItems = (
  reviewCycle: ReviewCycle,
  organization: Organization,
  goToStep: (name: StepName) => void
) => {
  const { templates } = reviewCycle;

  const templateContent =
    !!templates && templates.length > 0
      ? templates.map((template: ReviewTemplate) => (
          <React.Fragment key={template.id}>
            <Link to={pathToReviewTemplate(template.id)} openInNewTab>
              {template.name}
            </Link>
            <br />
          </React.Fragment>
        ))
      : __('No template selected yet');

  const participantCount = reviewCycle.plannedParticipantIds.length;

  const responsibleContent =
    reviewCycle.responsibleRole === 'manager'
      ? __('Direct manager of the participants')
      : reviewCycle.responsible?.fullName || '';

  const autoAddRulesCount = reviewCycle.autoAddRules?.length;
  const autoAddRuleContent =
    reviewCycle.autoAddMode === 'static' ? (
      n__(
        '%1 calendar day after addition of the employee in the cycle',
        '%1 calendar days after addition of the employee in the cycle',
        reviewCycle.addParticipantsBasedOnWorkStartDateAfterDays || 0
      )
    ) : (
      <Link onClick={() => goToStep('participants')}>
        {n__('%1 rule', '%1 rules', autoAddRulesCount)}
      </Link>
    );

  const reviewVisibilityContent = () => {
    if (reviewCycle.visibleForSuperiors && reviewCycle.visibleForReviewee) {
      return __('Admins, direct managers, supervisors and employees');
    } else if (reviewCycle.visibleForSuperiors) {
      return __('Admins, direct managers and supervisors');
    } else if (reviewCycle.visibleForReviewee) {
      return __('Admins, evaluators and employees');
    } else {
      return __('Admins and evaluators');
    }
  };

  const emailsSummaryContent = reviewCycle.emailsModified
    ? __('Personalized')
    : __('Default');

  const items: Array<SummaryItemProps> = [
    { label: __('Campaign name:'), children: reviewCycle.name },
    {
      label: __('Template:'),
      children: templateContent,
    },
    { label: __('Participants count:'), children: participantCount },
    {
      label: __("Default review's responsible:"),
      children: responsibleContent,
    },
    {
      label: __('Campaign addition rules:'),
      hidden: !reviewCycle.autoAddParticipantsEnabled,
      children: autoAddRuleContent,
    },
    {
      label: __('Review visible by:'),
      children: reviewVisibilityContent(),
    },
    {
      label: __('Emails:'),
      children: emailsSummaryContent,
      testClassName: 'test-emails-summary-content',
    },
    ...getSummaryDeadlinesItems(reviewCycle),
    {
      label: __('Peers nomination instructions:'),
      children: (reviewCycle as ThreeSixtyReviewCycle)
        .computedNominatePeersInstruction,
      hidden:
        reviewCycle.interactionType !== '360' ||
        !reviewCycle.computedNominatePeersInstruction,
    },
  ];

  if (
    organization.settings.auditReportsEnabled &&
    organization.featureFlags.includes('audits') &&
    reviewCycle.interactionType === 'one_on_one'
  ) {
    const tagsContent = isEmpty(reviewCycle.tags)
      ? __('None')
      : reviewCycle.tags.map(tag => (
          <Tag
            additionalClassName="mr-2 mb-2"
            key={tag.id}
            style={{ textTransform: 'none' }}
          >
            {tag.label}
          </Tag>
        ));

    items.push({
      label: n__('Related tag:', 'Related tags:', reviewCycle.tags.length),
      children: tagsContent,
    });
  }

  return items;
};

export default getAllSummaryItems;
