import React, { useState } from 'react';

import {
  DraftSurveyCampaign,
  SurveyCampaign,
  SurveyCampaignUpdatableAttributes,
} from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { BigSelectableButton, Helper, Text } from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';

import ExcludeParticipants from './ExcludeParticipants';
import IncludeParticipants from './IncludeParticipants';

export const canContinue = (
  participantAdditionMode: DraftSurveyCampaign['participantAdditionMode'],
  includedParticipantsCount: number
) => {
  return (
    participantAdditionMode !== 'included' || includedParticipantsCount > 0
  );
};

type Props = {
  campaignId: string;
  isAnonymous: boolean;
  participantAdditionMode: SurveyCampaign['participantAdditionMode'];
  includedParticipantsCount: number;
  excludeParticipantModeEnabled: boolean;
  onUpdate: (params: SurveyCampaignUpdatableAttributes) => Promise<void>;
  goToNextStep: () => void;
};

const StepParticipants = ({
  campaignId,
  isAnonymous,
  participantAdditionMode,
  includedParticipantsCount,
  excludeParticipantModeEnabled,
  onUpdate,
  goToNextStep,
}: Props) => {
  const [activeTab, setActiveTab] = useState(participantAdditionMode);
  const activeUser = useActiveUser();

  return (
    <StepContainer
      stepName="participants"
      onContinueClick={async () => {
        await onUpdate({ setupStepIndex: 3 });
        goToNextStep();
      }}
      canPassStep={canContinue(
        participantAdditionMode,
        includedParticipantsCount
      )}
    >
      <Text preset="18bs5">{__('Survey participants')}</Text>
      {isAnonymous && (
        <Helper additionalClassName="mt-2 mb-4">
          {__(
            'To preserve the anonymity of respondents, a minimum of 3 participants will be required to access the results.'
          )}
        </Helper>
      )}
      {activeUser.isOrganizationAdmin && (
        <div className="flex gap-4 mt-4 mb-4">
          <div className="flex-1">
            <BigSelectableButton
              emoji="✏️"
              title={__('Select from the employee list')}
              subtitle={__('Recommended for one-off surveys')}
              onClick={async () => {
                if (activeTab === 'included') return;

                await onUpdate({
                  participantAdditionMode: 'included',
                });
                setActiveTab('included');
              }}
              isActive={activeTab === 'included'}
            />
          </div>
          <div className="flex-1">
            <BigSelectableButton
              emoji="✂️"
              title={__('Select employees to exclude')}
              subtitle={__('Recommended for recurrent surveys')}
              isDisabled={!excludeParticipantModeEnabled}
              disabledExplanation={
                !excludeParticipantModeEnabled
                  ? __(
                      'This option is disabled for campaigns created by entity admins.'
                    )
                  : undefined
              }
              tooltipProps={{ triggerAdditionalClassName: 'flex-1' }}
              onClick={async () => {
                if (activeTab === 'excluded') return;

                await onUpdate({
                  participantAdditionMode: 'excluded',
                });
                setActiveTab('excluded');
              }}
              isActive={activeTab === 'excluded'}
            />
          </div>
        </div>
      )}
      {activeTab === 'included' ? (
        <IncludeParticipants
          campaignId={campaignId}
          includedParticipantsCount={includedParticipantsCount}
        />
      ) : (
        <ExcludeParticipants campaignId={campaignId} />
      )}
    </StepContainer>
  );
};

export default StepParticipants;
