import React, { useState } from 'react';

import { PeopleUserReview, PlannedAction } from 'models';

import { handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import PeopleReviewActionForm from 'scenes/peopleReview/components/PeopleReviewActionForm';

type Props = {
  review: PeopleUserReview;
  isActive: boolean;
  onClose: () => void;
  afterCreate: () => any;
};

function ActionPlanCreationModal({
  review,
  isActive,
  onClose,
  afterCreate,
}: Props) {
  const defaultPlannedActionObject = {
    title: '',
    tagLabels: [],
    description: '',
    dueDate: '',
    status: 'to_do',
  } as Partial<PlannedAction>;
  const dispatch = useAppDispatch();
  const [errors, setErrors] = React.useState<Partial<PlannedAction>>({});
  const [peopleReviewPlannedAction, setPeopleReviewPlannedAction] = useState(
    defaultPlannedActionObject
  );

  const handleSubmit = () =>
    handleFormErrors(async () => {
      await dispatch(
        post(
          'planned/actions',
          {
            ...peopleReviewPlannedAction,
            resourceId: review.id,
            resourceType: 'PeopleUserReview',
            type: 'Planned::PeopleReviewAction',
          },
          {
            successMessage: __(
              '<b>Action for %1 planned</b></br>You can find this action in the Actions Plans menu to edit it',
              review.user.fullName
            ),
          }
        )
      );
      setPeopleReviewPlannedAction(defaultPlannedActionObject);
      afterCreate();
    }, setErrors);
  return (
    <ModalCard
      isActive={isActive}
      onClose={onClose}
      refreshContentOnOpening
      isLarge
    >
      <ModalCardHead>
        <ModalCardTitle>
          {__('Plan an action for %1', review.user.fullName)}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <PeopleReviewActionForm
          plannedActionObject={peopleReviewPlannedAction}
          setPlannedActionObject={setPeopleReviewPlannedAction}
          errors={errors}
        />
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!peopleReviewPlannedAction.title}
        >
          {__('Plan action')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}

export default ActionPlanCreationModal;
