import React from 'react';

import { ObjectiveVersion } from 'models';

import { __ } from 'helpers/i18n';

import BoldHtmlText from 'components/BoldHtmlText';

import AvatarDescription from './components/AvatarDescription';

type Props = {
  version: ObjectiveVersion;
};

const KeyResultVersion = ({ version }: Props) => {
  const computeEventText = (version: ObjectiveVersion) => {
    const fullName = version.user.fullName;
    const title =
      version.jsonObject?.title || // updated key result
      version.objectChanges?.title?.[0]; // deleted key result
    const hasTitle = title && title.length > 0;

    switch (version.event) {
      case 'create':
        return __('%1 created a <b>key result</b>', fullName);
      case 'destroy':
        return hasTitle
          ? __('%1 destroyed the key result <b>%2</b>', fullName, title)
          : __('%1 destroyed a <b>key result</b>', fullName);
      case 'update':
        return hasTitle
          ? __('%1 updated the key result <b>%2</b>', fullName, title)
          : __('%1 updated a <b>key result</b>', fullName);
      default:
        return '';
    }
  };

  return (
    <div className="mb-4">
      <AvatarDescription
        version={version}
        text={
          <BoldHtmlText componentType="div" html={computeEventText(version)} />
        }
      />
    </div>
  );
};

export default KeyResultVersion;
