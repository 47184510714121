import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  FundingSourceStats,
  TrainingOverallBudgetStats,
  User,
} from 'models';

import { __ } from 'helpers/i18n';
import { withActiveUser } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  CompletionProgressBar,
  Divider,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import FundingSourceDetails from './FundingSourceDetails';

type Props = {
  periodSlug: string;
  isActive: boolean;
  canManageBudget: boolean;
  overallStats: TrainingOverallBudgetStats;
  onClose: () => void;
  onOpenBudgetManagementModal: () => void;
};

type AfterWithActiveUserProps = Props & {
  activeUser: User;
};

type AfterDataLoaderProps = AfterWithActiveUserProps &
  DataLoaderProvidedProps & {
    fundingSourcesStats: Array<FundingSourceStats>;
  };

type OverallStatsProps = {
  overallStats: TrainingOverallBudgetStats;
};

function OverallStats({ overallStats }: OverallStatsProps) {
  const {
    provisionedBudgetCents,
    formattedProvisionedBudget,
    usedBudgetCents,
    formattedUsedBudget,
  } = overallStats;

  const completion =
    usedBudgetCents &&
    provisionedBudgetCents &&
    (usedBudgetCents * 100) / provisionedBudgetCents;

  return (
    <div>
      <Text size={7} weight="semibold">
        {__('Budget spent')}
      </Text>
      <div>
        <Text size={4} weight="semibold" color="primary">
          {formattedUsedBudget}
        </Text>

        <Text size={7} weight="light" color="light">
          &nbsp;
          {__('of')}
          &nbsp;
          {formattedProvisionedBudget}
        </Text>

        {<CompletionProgressBar completion={completion} />}
      </div>

      <Divider />
    </div>
  );
}

function BudgetDetailsModal({
  isActive,
  canManageBudget,
  overallStats,
  onClose,
  onOpenBudgetManagementModal,
  fundingSourcesStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  return (
    <ModalCard
      isActive={isActive}
      refreshContentOnOpening
      onClose={onClose}
      additionalClassName="budget-details-modal"
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('My budget')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="alone"
          render={() => {
            return (
              <div>
                {!!overallStats.provisionedBudgetCents &&
                  +overallStats.provisionedBudgetCents > 0 && (
                    <OverallStats overallStats={overallStats} />
                  )}

                {fundingSourcesStats.map(fundingSourcesStat => (
                  <FundingSourceDetails
                    key={fundingSourcesStat.id}
                    fundingSourcesStat={fundingSourcesStat}
                  />
                ))}
              </div>
            );
          }}
        />
      </ModalCardBody>

      <ModalCardFooter>
        {canManageBudget && (
          <Button
            onClick={() => {
              onClose();
              onOpenBudgetManagementModal();
            }}
            color="secondary"
          >
            {__('Edit')}
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}

export default compose<React.ComponentType<Props>>(
  withActiveUser,
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(`training/periods/${periodSlug}/funding_source_stats`),
    hydrate: {
      fundingSourcesStats: {
        budgetItem: {
          abilities: {},
        },
      },
    },
  })
)(BudgetDetailsModal);
