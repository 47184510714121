import React, { useContext } from 'react';

import { SkillsDomain, SkillsMatrix } from 'models/skills';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { post } from 'redux/actions/api';

import { BoldHtmlText, Button, Icon, Text } from 'components';

import { DataContext } from 'scenes/skills/Index/MatrixVisualizer';

type Props = { domain: SkillsDomain; matrix: SkillsMatrix };

const DeleteButton = ({ domain, matrix }: Props) => {
  const { refetchData } = useContext(DataContext);
  const dispatch = useAppDispatch();

  const removeDomain = async () => {
    await dispatch(
      post(
        `skills/matrices/${matrix.id}/remove_domain`,
        { domainId: domain.id },
        {
          successMessage: __(
            '<b>The skills domain %1 has been successfully removed from the matrix.</b><br/>Its associated skills are no longer visible in this matrix.',
            domain.title
          ),
        }
      )
    );
    refetchData();
  };

  const onClick = () => {
    return confirmAsync(
      __('Remove the skills domain from the matrix'),
      <React.Fragment>
        <p>
          {__(
            'Removing the %1 skills domain will result in the removal of its associated skills from the skills matrix.',
            <b>{domain.title}</b>
          )}
        </p>
        <p>
          <BoldHtmlText
            html={__(
              'Those skills will no longer be used <b>to evaluate employees associated to the matrix</b>.'
            )}
          />
        </p>
        <br />
        <p>
          {__(
            'Are you sure you want to remove this skills domain from the matrix?'
          )}
        </p>
        <div className="mt-4 mb-2">
          <Text color="danger">
            <Icon color="danger" name="warning" additionalClassName="mr-4" />
            {__('This action cannot be undone')}
          </Text>
        </div>
      </React.Fragment>,
      {
        confirmLabel: __('Remove the domain'),
        isDanger: true,
        onConfirm: removeDomain,
      }
    );
  };

  return (
    <Button
      isText
      hasIconOnly
      additionalClassName="no-underline hover:!bg-ui-body-bg"
      onClick={onClick}
    >
      <Icon name="delete" />
    </Button>
  );
};

export default DeleteButton;
