import { isEqual } from 'lodash';
import * as React from 'react';
import { compose } from 'redux';

import type { FormContent, TrainingPeriod, UserReview } from 'models';
import type { ReleaseActionInfo } from 'scenes/review/Review/ReleaseAction';

import can from 'helpers/can';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FeatureFlagged } from 'components';

import AttachFilesSection from './AttachFilesSection';
import ScheduleMeetingSection from './ScheduleMeetingSection';
import TableOfContentsSection from './TableOfContentsSection';
import TrainingRequestCreationSection from './TrainingRequestCreationSection';

type Props = {
  content: FormContent;
  userReview: UserReview;
  isPreview?: boolean;
  shareAction: ReleaseActionInfo;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    currentTrainingPeriod: TrainingPeriod;
  };

function Sidebar({
  content,
  userReview,
  isPreview,
  shareAction,
  currentTrainingPeriod,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  const blocksForTable = content.blocks.filter(
    block =>
      [
        'header_message',
        'title',
        'objectives_module',
        'final_comment',
        'share_evaluation',
        'signature',
        'training_request_history',
        'skills_career_level',
      ].includes(block.blockType) ||
      ('release_review' === block.blockType && shareAction.isShown)
  );

  const displayScheduleMeetingSection = !isPreview;
  const displayTrainingRequestCreationSection =
    !isPreview &&
    !!currentTrainingPeriod &&
    can({ perform: 'show_create_training_request_button', on: userReview });
  const trainingRequestHistoryBlockPresent = !!blocksForTable.find(
    block => block.blockType === 'training_request_history'
  );

  const displayAttachFilesSection =
    !isPreview && userReview.interactionType === 'one_on_one';

  return (
    <div className="side-bar">
      <TableOfContentsSection blocks={blocksForTable} />
      <FeatureFlagged flag="attachFilesToReviews">
        {displayAttachFilesSection && (
          <React.Fragment>
            <hr />
            <AttachFilesSection userReview={userReview} />
          </React.Fragment>
        )}
      </FeatureFlagged>
      {displayScheduleMeetingSection && (
        <React.Fragment>
          <hr />
          <ScheduleMeetingSection userReview={userReview} />
        </React.Fragment>
      )}

      <FeatureFlagged flag="createTrainingRequestFromReview">
        {!isFetching && !hasError && displayTrainingRequestCreationSection && (
          <React.Fragment>
            <hr />
            <TrainingRequestCreationSection
              currentTrainingPeriod={currentTrainingPeriod}
              reviewee={userReview.user}
              onConfirm={() => {
                !userReview.isReleased &&
                  trainingRequestHistoryBlockPresent &&
                  window.location.reload();
              }}
            />
          </React.Fragment>
        )}
      </FeatureFlagged>
    </div>
  );
}

export default React.memo(
  compose(
    newDataLoader({
      fetch: () => get('training/periods/current'),
      hydrate: {
        currentTrainingPeriod: {},
      },
    })
  )(Sidebar),
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
) as React.ComponentType<Props>;
