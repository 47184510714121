import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';
import {
  pathToTrainingPeriodRequests,
  pathToTrainingSessionIndex,
  pathToTrainings,
} from 'helpers/paths';

import { PageHeader } from 'components';

type Props = {
  trainingPeriodSlug?: string;
  actions: ReactNode;
};

const TrainingPageHeader = ({ trainingPeriodSlug, actions }: Props) => {
  const tabItems = [
    {
      label: ' ' + __('Training requests'),
      to: trainingPeriodSlug
        ? pathToTrainingPeriodRequests(trainingPeriodSlug)
        : pathToTrainings(),
    },
    {
      label: ' ' + __('Training sessions'),
      to: trainingPeriodSlug
        ? pathToTrainingSessionIndex(trainingPeriodSlug)
        : pathToTrainings(),
    },
  ];
  return (
    <PageHeader title={__('Trainings')} actions={actions} tabItems={tabItems} />
  );
};

export default TrainingPageHeader;
