import React from 'react';

import {
  DraftSurveyCampaignStatus,
  OneOffSurveyCampaignStatus,
  RecurrentSurveyCampaignStatus,
} from 'models';

import { __ } from 'helpers/i18n';

import Tag from './Tag';

type Props = {
  status:
    | DraftSurveyCampaignStatus
    | OneOffSurveyCampaignStatus
    | RecurrentSurveyCampaignStatus;
};

const CampaignStatusTag = ({ status }: Props) => {
  const tagColor = {
    draft: 'light' as const,
    scheduled: 'dark' as const,
    launching: 'dark' as const,
    in_progress: 'blue' as const,
    ended: 'success' as const,
    archived: 'grey' as const,
    inactive: 'warning' as const,
  }[status];

  const statusText = {
    draft: __('survey_campaign_draft'),
    scheduled: __('survey_campaign_scheduled'),
    launching: __('survey_campaign_launching'),
    in_progress: __('survey_campaign_in_progress'),
    ended: __('survey_campaign_ended'),
    archived: __('survey_campaign_archived'),
    inactive: __('survey_campaign_inactive'),
  }[status];

  const iconName = {
    draft: 'edit' as const,
    scheduled: 'timer' as const,
    launching: 'timer' as const,
    in_progress: 'play_circle' as const,
    ended: 'check_circle' as const,
    archived: 'inbox' as const,
    inactive: 'pause_circle' as const,
  }[status];

  const isIconFilled = iconName === 'check_circle';

  return (
    <Tag color={tagColor} icon={iconName} iconFilled={isIconFilled}>
      {statusText}
    </Tag>
  );
};

export default CampaignStatusTag;
