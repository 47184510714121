import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle } from 'components/index';

import MyList from './MyList';

const ChatRooms = () => (
  <Fragment>
    <PageTitle title={[__('Surveys'), __('Conversations')]} />
    <PageHeader title={__('Conversations')} />

    <ContentContainer>
      <MyList paginationType="state" />
    </ContentContainer>
  </Fragment>
);

export default ChatRooms;
