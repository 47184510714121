import React, { ReactNode } from 'react';

import type { MaterialIconName } from 'components/Icon';

import classNames from 'helpers/classNames';

import { Icon, Text } from 'components';

type Color =
  | 'light'
  | 'grey'
  | 'dark'
  | 'blue'
  | 'danger'
  | 'warning'
  | 'success';

export type Props = {
  children: ReactNode;
  color: Color;
  icon?: MaterialIconName;
  iconFilled?: boolean;
};

const Tag = ({ children, color, icon, iconFilled }: Props) => {
  const colorClassName = {
    light: {
      background: 'bg-text-lightest/30',
      icon: 'text-text-light',
      text: 'text-text-main',
    },
    grey: {
      background: 'bg-text-light/30',
      icon: 'text-text-soften',
      text: 'text-text-main',
    },
    dark: {
      background: 'bg-text-main/60',
      icon: 'text-white',
      text: 'text-white',
    },
    blue: {
      background: 'bg-blue-elevo/30',
      icon: 'text-blue-elevo',
      text: 'text-text-main',
    },
    danger: {
      background: 'bg-red-fuckup/30',
      icon: 'text-red-fuckup',
      text: 'text-text-main',
    },
    warning: {
      background: 'bg-orange-warning/30',
      icon: 'text-yellow-400',
      text: 'text-text-main',
    },
    success: {
      background: 'bg-green-success/30',
      icon: 'text-green-success',
      text: 'text-text-main',
    },
  }[color];

  return (
    <span
      className={classNames(
        'tag is-rounded normal-case max-w-full',
        colorClassName.background
      )}
    >
      {icon && (
        <Icon
          style={{ marginRight: 5 }}
          name={icon}
          filled={iconFilled || false}
          additionalClassName={colorClassName.icon}
        />
      )}
      <Text
        preset="14s6"
        additionalClassName={classNames(
          'text-text-main is-text-overflow-ellipsis',
          colorClassName.text
        )}
      >
        {children}
      </Text>
    </span>
  );
};

export default Tag;
