import React, { Fragment, ReactNode, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { SurveyCampaign, UserFilterSegment } from 'models';

import can from 'helpers/can';
import { useAppDispatch, useOrganization } from 'helpers/hooks';
import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';
import {
  Match,
  pathToSurveyCampaignOverview,
  pathToSurveyCampaignResults,
  pathToSurveyCampaigns,
} from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Button,
  ButtonMenu,
  FeatureFlagged,
  FetchContainer,
  MenuItem,
  MenuList,
  PageHeader,
  PageTitle,
  Route,
  Switch,
  Text,
} from 'components';
import { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import ExportModal from 'scenes/admin/components/ExportModal';

import CampaignStatusTag from '../components/CampaignStatusTag';
import DetailedResults from './DetailedResults';
import ExportButton from './ExportButton';
import Overview from './Overview';
import {
  pollingPeriodLaunchInfo,
  pollingPeriodNextLaunchInfo,
  pollingPeriodParticipationCreationInfo,
} from './helpers/pollingPeriodLaunchInfo';
import useUpdateCampaignStatus from './hooks/useUpdateCampaignStatus';

const TitleWithStatusAndPeriodInfo = ({
  surveyCampaign,
}: {
  surveyCampaign: SurveyCampaign;
}) => {
  let periodInfo: ReactNode;
  if (surveyCampaign.status === 'in_progress') {
    if (surveyCampaign.anyInProgressParticipationCreation) {
      periodInfo = pollingPeriodParticipationCreationInfo(surveyCampaign);
    } else {
      periodInfo = pollingPeriodLaunchInfo(surveyCampaign);
    }
  } else {
    periodInfo = pollingPeriodNextLaunchInfo(surveyCampaign);
  }

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Text preset="24bs2" additionalClassName="mr-4">
          {surveyCampaign.name}
        </Text>

        <CampaignStatusTag status={surveyCampaign.status} />
      </div>

      {(surveyCampaign.status === 'in_progress' ||
        surveyCampaign.status === 'scheduled') && (
        <Text preset="13s7" additionalClassName="mt-2">
          {periodInfo}
        </Text>
      )}
    </div>
  );
};

type RouterProps = RouteComponentProps & {
  match: Match<{
    campaignId: string;
  }>;
};
type Props = RouterProps;
type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    surveyCampaign: SurveyCampaign;
  };

const Campaign = ({
  match,
  isFetching,
  hasError,
  surveyCampaign,
  refetchData,
}: AfterDataLoaderProps) => {
  const { campaignId } = match.params;
  const organization = useOrganization();
  const surveyDetailedResultsEnabled = organization.featureFlags.includes(
    'surveyDetailedResults'
  );

  const { urlQueryParams } = useUrlQueryParams();
  const questionId = urlQueryParams().questionId;
  const periodId = urlQueryParams().periodId || surveyCampaign?.lastPeriodId;
  const correlationSlug = urlQueryParams().correlationSlug;

  const tabItems: NavigationItem[] = [
    {
      label: __('Overview'),
      to: pathToSurveyCampaignOverview(campaignId, periodId),
    },
    {
      label: surveyDetailedResultsEnabled
        ? __('Detailed results')
        : __('Detailed results (Coming soon)'),
      to: pathToSurveyCampaignResults(campaignId, questionId, periodId),
      // TO DO: Remove when FF is true for everyone
      ...(!surveyDetailedResultsEnabled && { onClick: () => {} }),
    },
  ];
  const dispatch = useAppDispatch();
  const [exportModalIsActive, setExportModalIsActive] = useState(false);

  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(null);

  const { confirmLaunch, launchCampaign, handleSubmitErrors } =
    useUpdateCampaignStatus(campaignId);

  const handleLaunchCampaignButton = async () => {
    try {
      if (!(await confirmLaunch())) return;
      await launchCampaign();
      await refetchData();
    } catch (error: unknown) {
      handleSubmitErrors(error);
    }
  };

  const handleSendReminder = async () => {
    await confirmAsync(
      __('Send a reminder'),
      __(
        'Send a reminder email to participants who have not sent their answers?'
      ),
      {
        confirmLabel: __('Send a reminder'),
        onConfirm: async () => {
          await dispatch(
            post(
              `survey/campaigns/${surveyCampaign.id}/send_reminder`,
              undefined,
              {
                successMessage: __('Emails are on their way! 📧'),
              }
            )
          );
        },
      }
    );
  };

  const exportSurveyCampaign = async () => {
    await dispatch(post(`survey/campaigns/${surveyCampaign.id}/export`));

    setExportModalIsActive(true);
  };

  return (
    <Fragment>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        renderFetching={() => (
          <PageHeader
            title={__('Loading…')}
            tabItems={tabItems}
            withBackButton
          />
        )}
        render={() => {
          const otherActionsItems: ReactNode[] = [];

          if (
            can({
              perform: 'send_reminder',
              on: surveyCampaign,
            })
          ) {
            otherActionsItems.push(
              <MenuItem key="reminder" onClick={handleSendReminder}>
                <Text>{__('Send reminder')}</Text>
              </MenuItem>
            );
          }

          return (
            <Fragment>
              <PageTitle title={[__('Surveys'), surveyCampaign.name]} />
              <PageHeader
                tabItems={tabItems}
                withBackButton
                backButtonProps={{
                  children: __('Back to campaigns'),
                  target: pathToSurveyCampaigns(),
                }}
                actions={
                  <Fragment>
                    {otherActionsItems.length > 0 && (
                      <ButtonMenu text={__('Other actions')}>
                        <MenuList>{otherActionsItems}</MenuList>
                      </ButtonMenu>
                    )}

                    {can({ perform: 'export', on: surveyCampaign }) && (
                      <ExportButton
                        lastPendingExportAction={
                          surveyCampaign.lastPendingExportAction
                        }
                        exportSurveyCampaign={exportSurveyCampaign}
                      />
                    )}

                    {can({ perform: 'launch', on: surveyCampaign }) && (
                      <Button
                        color="primary"
                        onClick={handleLaunchCampaignButton}
                      >
                        {__('Launch campaign')}
                      </Button>
                    )}
                  </Fragment>
                }
              >
                <TitleWithStatusAndPeriodInfo surveyCampaign={surveyCampaign} />
              </PageHeader>

              <div className="m-4 md:m-6">
                <Switch>
                  <Route
                    path={`${match.path}/overview`}
                    render={() => (
                      <Overview
                        surveyCampaign={surveyCampaign}
                        periodId={periodId}
                        userFilter={userFilter}
                        onUserFilterChange={setUserFilter}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/results`}
                    render={() => (
                      <FeatureFlagged
                        flag="surveyDetailedResults"
                        alternativeChildren={<div>Results</div>}
                      >
                        <DetailedResults
                          questionId={questionId}
                          selectedPeriodId={periodId}
                          userFilter={userFilter}
                          onUserFilterChange={setUserFilter}
                          correlationSlug={correlationSlug}
                          campaign={surveyCampaign}
                        />
                      </FeatureFlagged>
                    )}
                  />
                </Switch>
              </div>
            </Fragment>
          );
        }}
      />

      {exportModalIsActive && (
        <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
      )}
    </Fragment>
  );
};

export default newDataLoader({
  fetch: ({ match }: Props) =>
    get(`survey/campaigns/${match.params.campaignId}`),
  hydrate: {
    surveyCampaign: {
      abilities: {},
      lastPendingExportAction: {},
      pollingPeriods: { abilities: {} },
    },
  },
})(Campaign) as React.ComponentType<Props>;
