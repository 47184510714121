import React from 'react';

import { __ } from 'helpers/i18n';

import {
  ContentContainer,
  DesignSystem,
  PageHeader,
  PageTitle,
} from 'components';

import UserListWithPagination from 'scenes/components/user/UserListWithPagination';

import Actions from './Actions';

const UserList = () => {
  return (
    <DesignSystem version={2}>
      <PageTitle title={__('Directory')} />
      <PageHeader title={__('Directory')} actions={<Actions />} />
      <ContentContainer>
        <UserListWithPagination
          defaultPaginationParams={{ filter: { active: true } }}
          fetchPath="users"
          fetchParams={{ permission: 'list_in_directory?' }}
          paginationType="url"
        />
      </ContentContainer>
    </DesignSystem>
  );
};

export default UserList;
