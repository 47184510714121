import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Objective, User, UserObjectivePeriod } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import transformProps from 'helpers/transformProps';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Button,
  Can,
  Column,
  Columns,
  DesignSystem,
  FeatureFlagged,
  FetchContainer,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  Loading,
  PullRight,
} from 'components';
import RightSidebar from 'components/RightSidebar';

import UserObjectivePeriodPicker from 'scenes/components/UserObjectivePeriodPicker';
import ObjectiveVersions from 'scenes/objectives/ObjectiveVersions';
import { ObjectiveContext } from 'scenes/objectives/objectiveContext';

import ImportObjectivesModal from '../ImportObjectivesModal';
import PersonalObjectivesDetails from './PersonalObjectivesDetails';

type Props = {
  user: User;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    userObjectivePeriod: UserObjectivePeriod;
  };

type AfterTransformProps = {
  currentUserObjectivePeriod: UserObjectivePeriod;
} & AfterDataLoaderProps;

type AfterConnectProps = AfterTransformProps & {
  createObjective: (
    userObjectivePeriod: UserObjectivePeriod
  ) => Promise<unknown>;
};

const PersonalObjectives = ({
  isFetching,
  hasError,
  currentUserObjectivePeriod,
  user,
  createObjective,
}: AfterConnectProps) => {
  const [selectedUserObjectivePeriod, setSelectedUserObjectivePeriod] =
    useState<UserObjectivePeriod | null | undefined>(null);
  const [importModalActive, setImportModalActive] = useState<boolean>(false);
  const [objective, setObjective] = useState<Objective | null>(null);

  const onChange = (userObjectivePeriod: UserObjectivePeriod) =>
    setSelectedUserObjectivePeriod(userObjectivePeriod);

  const addObjectiveButtons = (
    <Fragment>
      {/** @ts-ignore TSFIXME: Fix strictNullChecks error **/}
      <Can perform="import_objectives" on={selectedUserObjectivePeriod}>
        <Button
          color="secondary"
          onClick={() => setImportModalActive(true)}
          style={{ marginRight: 16 }}
        >
          <Icon style={{ marginRight: 8 }} name="content_copy" />
          {__('Import')}
        </Button>
      </Can>
      {/** @ts-ignore TSFIXME: Fix strictNullChecks error **/}
      <Can perform="create_objective" on={selectedUserObjectivePeriod}>
        <Button
          testClassName="test-create-objective-button"
          color="primary"
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          onClick={() => createObjective(selectedUserObjectivePeriod)}
        >
          <Icon style={{ marginRight: 8 }} name="add" />
          {__('Add')}
        </Button>
      </Can>
    </Fragment>
  );

  return (
    <ObjectiveContext.Provider value={{ objective, setObjective }}>
      <DesignSystem version={2}>
        <div className="personal-objectives test-personal-objectives">
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            render={() => {
              if (!selectedUserObjectivePeriod) {
                onChange(currentUserObjectivePeriod);

                return <Loading containerStyle={{ padding: 15 }} />;
              }

              return (
                <React.Fragment>
                  <Columns style={{ marginBottom: 24 }}>
                    <Column size={8} style={{ paddingBottom: 0 }}>
                      <Level>
                        <LevelLeft>
                          <UserObjectivePeriodPicker
                            user={user}
                            userObjectivePeriod={selectedUserObjectivePeriod}
                            onChange={onChange}
                          />
                        </LevelLeft>

                        <FeatureFlagged
                          flag="draftObjectives"
                          inverseDefaultBehavior
                        >
                          <LevelRight>{addObjectiveButtons}</LevelRight>
                        </FeatureFlagged>
                      </Level>
                    </Column>
                  </Columns>

                  <PersonalObjectivesDetails
                    // @ts-ignore TSFIXME: Fix strictNullChecks error
                    userObjectivePeriodId={selectedUserObjectivePeriod.id}
                    onCreate={createObjective}
                    footer={<PullRight>{addObjectiveButtons}</PullRight>}
                    revieweeFullName={user.fullName}
                  />

                  <Can
                    perform="import_objectives"
                    on={selectedUserObjectivePeriod}
                  >
                    <ImportObjectivesModal
                      isActive={importModalActive}
                      onClose={() => setImportModalActive(false)}
                      toTargetUserObjectivePeriod={selectedUserObjectivePeriod}
                    />
                  </Can>
                </React.Fragment>
              );
            }}
          />
        </div>
      </DesignSystem>

      <RightSidebar
        title={objective?.title || ''}
        isOpen={!!objective}
        onClose={() => setObjective(null)}
      >
        {!!objective && <ObjectiveVersions objective={objective} />}
      </RightSidebar>
    </ObjectiveContext.Provider>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createObjective: (userObjectivePeriod: UserObjectivePeriod) =>
    dispatch(post(`objective_periods/${userObjectivePeriod.id}/objectives`)),
});

function propsTransformer({ userObjectivePeriod }: AfterDataLoaderProps) {
  return {
    currentUserObjectivePeriod: userObjectivePeriod,
  };
}

export default compose(
  newDataLoader({
    fetch: ({ user }: Props) =>
      get(`users/${user.id}/objective_periods/current`),
    hydrate: {
      userObjectivePeriod: {
        abilities: {},
        user: {},
      },
    },
  }),
  transformProps(propsTransformer),
  connect(null, mapDispatchToProps)
)(PersonalObjectives) as React.ComponentType<Props>;
