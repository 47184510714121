// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { User } from 'models';
import type { ReduxStore } from 'redux/reducers';

import { __ } from 'helpers/i18n';
import { withActiveUser } from 'helpers/withSessionProps';

import { getRequestStatus } from 'lib/dataLoader/reducer';

import {
  DeprecatedContainerElevo,
  DesignSystem,
  PageTitle,
  Text,
} from 'components';

import DirectReports, { DIRECT_REPORTS_REQUEST_ID } from './DirectReports';
import IndirectReports from './IndirectReports';

type Props = {||};

type AfterConnectProps = {|
  ...Props,
  isFetching: boolean,
  hasError: boolean,
  activeUser: User,
|};

class MyReports extends React.Component<AfterConnectProps> {
  render() {
    const { hasError, activeUser, isFetching } = this.props;

    if (hasError) {
      return null;
    }

    return (
      <DesignSystem version={2}>
        <DeprecatedContainerElevo additionalClassName="py-12 mt-5">
          <PageTitle title={__('My team')} />

          <div className="mb-4">
            <Text preset="16s5.5" additionalClassName="subtitle mt-10 md:mt-0">
              {__('My team')}
            </Text>
          </div>

          <DirectReports activeUser={activeUser} />

          {!isFetching && (
            <div style={{ paddingTop: '40px' }}>
              <IndirectReports activeUser={activeUser} />
            </div>
          )}
        </DeprecatedContainerElevo>
      </DesignSystem>
    );
  }
}

export default compose(
  withActiveUser,
  connect((state: ReduxStore) => ({
    ...getRequestStatus(state, DIRECT_REPORTS_REQUEST_ID),
  }))
)(MyReports);
