import React from 'react';

import type { ReviewCycle } from 'models/ReviewCycle';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate, pathToReviewCycles } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';
import { trackAction } from 'helpers/tracking';

import { put } from 'redux/actions/api';

import { DropdownMenuItem } from 'components';

type Props = {
  reviewCycle: ReviewCycle;
};

const ArchiveCampaignItem = ({ reviewCycle }: Props) => {
  const dispatch = useAppDispatch();
  const confirmArchiveReviewCycle = () =>
    confirmAsync(
      __('Archive review campaign?'),
      __(
        'By doing so your review campaign will be archived and ongoing reviews will be closed.'
      ),
      {
        confirmLabel: __('Archive campaign'),
        onConfirm: async () => {
          await archiveCampaign();
          navigate(pathToReviewCycles(reviewCycle.interactionType));
          trackAction('Review cycle ended', {
            reviewCycleId: reviewCycle.id,
          });
        },
      }
    );

  const archiveCampaign = async () => {
    const reviewCycleName = reviewCycle.name;
    invariant(reviewCycleName, 'reviewCycle should have a name');
    await dispatch(
      put(
        `review_cycles/${reviewCycle.id}`,
        { status: 'ended' },
        {
          errorMessage: __("Sorry, we couldn't archive the campaign"),
          successMessage: __(
            '<b>%1 has been archived</b>👍 <p>Corresponding reviews have been archived for participants of this campaign.</p>',
            reviewCycle.name
          ),
        }
      )
    );
  };

  return (
    <DropdownMenuItem onClick={() => confirmArchiveReviewCycle()}>
      {__('Archive campaign')}
    </DropdownMenuItem>
  );
};

export default ArchiveCampaignItem;
