// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { HomeMessage, NavActionsCount, Organization, User } from 'models';
import type { ComponentType } from 'react';
import type { ReduxStore } from 'redux/reducers';

import { withActiveUserAndOrganization } from 'helpers/withSessionProps';

import { hydrateFromStore } from 'lib/dataLoader';
import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { DesignSystem } from 'components';

import HomeBanner from './HomeBanner';
import HomeLogo from './HomeLogo';
import HomeMainGrid from './components/HomeMainGrid';

type Props = {||};
type AfterConnectProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  organization: Organization,
  activeUser: User,
  navActionsCount: NavActionsCount,
  homeMessage: HomeMessage,
|};

const Homepage = ({
  navActionsCount,
  organization,
  activeUser,
  homeMessage,
  isFetching,
  hasError,
  refetchData,
}: AfterConnectProps) => {
  return (
    <DesignSystem version={2}>
      <div className="homepage mt-14 md:mt-0">
        <HomeBanner />
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <HomeLogo />
        </div>
        <div className="mx-auto max-w-7xl mt-8 px-4 sm:px-6 lg:px-8">
          <HomeMainGrid
            organization={organization}
            user={activeUser}
            actionsCount={navActionsCount}
            homeMessage={homeMessage}
            isFetchingHomeMessage={isFetching}
            hasError={hasError}
            refetchHomeMessage={refetchData}
          />
        </div>
      </div>
    </DesignSystem>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    navActionsCount: hydrateFromStore(
      state.data,
      {
        resourceType: 'navActionsCount',
        id: 'nav_actions_count',
      },
      { navActionsCount: {} }
    ),
  };
};

export default (compose(
  withActiveUserAndOrganization,
  connect(mapStateToProps),
  newDataLoader({
    fetch: () => get('/home_messages/current'),
    hydrate: {
      homeMessage: {},
    },
  })
)(Homepage): ComponentType<Props>);
