import React, { Fragment } from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { TrainingPeriod } from 'models/TrainingPeriod';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import { pathToTrainingPeriodRequests } from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, ContentContainer, Flex, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import TrainingPageHeader from './TrainingPageHeader';
import TrainingRequestList from './TrainingRequestList';
import TrainingRequestStats from './TrainingRequestStats';
import { refetchTrainingPeriodStats } from './TrainingRequestStats';

type Props = RouteComponentProps<{ trainingPeriodSlug: string }>;

function TrainingPeriodRequests({ match }: Props) {
  const [createModalIsActive, setCreateModalIsActive] = React.useState(false);
  const [exportModalIsActive, setExportModalIsActive] = React.useState(false);
  const { trainingPeriodSlug } = match.params;

  const dispatch = useAppDispatch();
  const onExport = async () => {
    const { trainingPeriodSlug } = match.params;
    invariant(trainingPeriodSlug, 'Training period must be defined.');
    return dispatch(
      post(`training/periods/${trainingPeriodSlug}/requests/export`)
    );
  };
  const refreshStats = () => {
    const { trainingPeriodSlug } = match.params;
    invariant(trainingPeriodSlug, 'Training period must be defined.');
    return dispatch(refetchTrainingPeriodStats(trainingPeriodSlug));
  };

  invariant(trainingPeriodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await onExport();
    setExportModalIsActive(true);
  };

  return (
    <Fragment>
      <TrainingPageHeader
        actions={
          <Fragment>
            <Button color="secondary" onClick={handleExport}>
              <Icon name="file_download" />
              <span>{__('Export all requests')}</span>
            </Button>
            <Button
              color="primary"
              onClick={() => setCreateModalIsActive(true)}
            >
              <Icon name="add" />
              <span>{__('Make a request')}</span>
            </Button>
          </Fragment>
        }
        trainingPeriodSlug={trainingPeriodSlug}
      />

      <ContentContainer>
        <Flex direction="row">
          <div style={{ width: 157 }}>
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentTrainingPeriodSlug={trainingPeriodSlug}
              onChange={(period: TrainingPeriod) =>
                navigate(pathToTrainingPeriodRequests(period.slug))
              }
            />
          </div>
        </Flex>

        <TrainingRequestStats currentTrainingPeriodSlug={trainingPeriodSlug} />

        <TrainingRequestList
          currentTrainingPeriodSlug={trainingPeriodSlug}
          paginationType="url"
          onAfterAction={refreshStats}
          defaultPaginationParams={{
            filter: { waiting_for_approval: true },
          }}
        />

        {createModalIsActive && (
          <TrainingRequestModal
            currentTrainingPeriodSlug={trainingPeriodSlug}
            isActive
            onClose={() => setCreateModalIsActive(false)}
            onAfterSubmit={() => window.location.reload()}
          />
        )}

        {exportModalIsActive && (
          <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
        )}
      </ContentContainer>
    </Fragment>
  );
}

export default TrainingPeriodRequests;
