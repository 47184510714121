// @flow
import React, { useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ReviewTemplate, UserReview } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import ReviewTemplatePicker from 'scenes/components/ReviewTemplatePicker';

type Props = {|
  userReview: UserReview,
  isActive?: boolean,
  onSubmit: (templateId: ?string) => Promise<*>,
  onCancel: () => any,
|};

type AfterDataLoaderProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  reviewTemplates: Array<ReviewTemplate>,
|};

function ChangeReviewTemplateModal({
  userReview,
  reviewTemplates,
  isActive,
  isFetching,
  hasError,
  onSubmit,
  onCancel,
}: AfterDataLoaderProps) {
  const [templateId, setTemplateId] = useState(userReview.reviewTemplateId);
  const reviewTemplate =
    isFetching || hasError
      ? null
      : reviewTemplates.find(({ id }) => id === templateId);

  return (
    <ModalCard isActive={isActive} onClose={onCancel} refreshContentOnOpening>
      <ModalCardHead onClose={onCancel}>
        <ModalCardTitle>
          {__('Change the template for %1’s review', userReview.user.fullName)}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <p style={{ marginBottom: 16 }}>
          <b>
            {__(
              'By changing the model, the data collected in the current review will be deleted.'
            )}
          </b>
          <br />

          {__('If needed, you can export the data before changing template.')}
        </p>

        <ReviewTemplatePicker
          reviewTemplate={reviewTemplate}
          reviewTemplates={reviewTemplates}
          isLoading={isFetching}
          inModal
          onChange={template => {
            invariant(template, 'template must be defined.');

            setTemplateId(template.id);
          }}
        />
      </ModalCardBody>

      <ModalCardFooter>
        <Button
          color="primary"
          onClick={() => onSubmit(templateId)}
          disabled={!templateId}
        >
          {__('Change template')}
        </Button>

        <Button color="secondary" onClick={onCancel}>
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}

export default newDataLoader({
  fetch: ({ userReview }: Props) =>
    get(`user_reviews/${userReview.id}/compatible_templates`),
  hydrate: {
    reviewTemplates: {},
  },
})(ChangeReviewTemplateModal);
