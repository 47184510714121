import React from 'react';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { useQuery } from 'helpers/navigation';
import { navigate, pathToNewReviewCycle } from 'helpers/navigation';

import {
  Button,
  ContentContainer,
  DesignSystem,
  Icon,
  PageHeader,
  PageTitle,
  Testable,
} from 'components';

import ReviewCycleListWithPagination from './ReviewCycleListWithPagination';

const ReviewCycleIndex = () => {
  const query = useQuery();
  const type = query.get('type');
  const organization = useOrganization();
  const canCreateCampaigns = can({
    perform: 'create_review_campaigns',
    on: organization,
  });

  return (
    <DesignSystem version={2}>
      <PageHeader
        title={__('Review campaigns')}
        actions={
          canCreateCampaigns && (
            <Button
              color="primary"
              onClick={() => navigate(pathToNewReviewCycle(type))}
            >
              <Testable name="test-creation-button">
                <Icon name="add" />
                <span>{__('New Review Campaign')}</span>
              </Testable>
            </Button>
          )
        }
      />
      <PageTitle title={[__('Review campaigns'), __('Admin')]} />
      <ContentContainer>
        <ReviewCycleListWithPagination
          paginationType="url"
          cycleType={type}
          defaultPaginationParams={{
            filter: { ongoing: true },
          }}
        />
      </ContentContainer>
    </DesignSystem>
  );
};

export default ReviewCycleIndex;
