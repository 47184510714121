import React, { Fragment } from 'react';

import type {
  FeedbackableBlock,
  OneOnOneReviewTemplate,
  ReviewTemplate,
  ThreeSixtyReviewTemplate,
} from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { put } from 'redux/actions/api';

import { BoldHtmlText, Box } from 'components';

import OneOnOneTemplateSettings from './OneOnOneTemplateSettings';
import ThreeSixtyTemplateSettings from './ThreeSixtyTemplateSettings';

type Props = {
  template: ReviewTemplate;
  settingsDisabled: boolean;
};

type TemplateAttributes =
  | Partial<ThreeSixtyReviewTemplate>
  | Partial<OneOnOneReviewTemplate>;

const Settings = ({ template, settingsDisabled }: Props) => {
  const dispatch = useAppDispatch();

  const updateReviewTemplate = async (attributes: TemplateAttributes) =>
    dispatch(
      put(
        `review_templates/${template.id}`,
        { reviewTemplate: attributes },
        {
          errorMessage: __('The template could not be updated.'),
        }
      )
    );

  const willDisableSelfEvaluation = (attributes: TemplateAttributes) =>
    typeof attributes.hasSelfEvaluationEnabled !== 'undefined' &&
    !attributes.hasSelfEvaluationEnabled;

  const shouldConfirmUpdate = (attributes: TemplateAttributes) => {
    if (
      template.interactionType !== 'one_on_one' ||
      !willDisableSelfEvaluation(attributes)
    ) {
      return false;
    }

    const feedbackableBlockTypes = [
      'question',
      'textQuestion',
      'dropdownQuestion',
      'multipleScaleQuestion',
      'reviewedObjectivesModule',
    ];
    const feedbackableBlocks = template.reviewStructure.reviewBlocks.filter(
      reviewBlock => feedbackableBlockTypes.includes(reviewBlock.blockType)
    ) as Array<FeedbackableBlock>;

    return (
      feedbackableBlocks.find(reviewBlock => {
        const feedbackType =
          reviewBlock.blockType === 'textQuestion' ? 'text' : 'rating';
        const feedbackEnabled = `${feedbackType}Enabled`;

        return !reviewBlock.reviewerFeedbackOptions[feedbackEnabled];
      }) !== undefined
    );
  };

  const showConfirmUpdateModal = (attributes: TemplateAttributes) =>
    confirmAsync(
      __('Confirm template change to disable self-assessment'),
      <Fragment>
        <p>
          {__(
            'Some questions in your template could only be answered by the reviewee.'
          )}
        </p>
        <br />
        <BoldHtmlText
          componentType="p"
          html={__(
            '<b>Do you want to configure the reviewer as being able to answer all questions</b> so that you can disable self-assessment?'
          )}
        />
      </Fragment>,
      {
        onConfirm: () => updateReviewTemplate(attributes),
        confirmLabel: __('Yes, I confirm'),
        cancelLabel: __('Cancel'),
      }
    );

  const onFieldChange = (
    attributes:
      | Partial<ThreeSixtyReviewTemplate>
      | Partial<OneOnOneReviewTemplate>
  ) => {
    if (shouldConfirmUpdate(attributes)) {
      return showConfirmUpdateModal(attributes);
    } else {
      return updateReviewTemplate(attributes);
    }
  };

  return (
    <div className="template-settings-panel">
      <Box testClassName="test-template-settings-panel">
        {template.interactionType === 'one_on_one' && (
          <OneOnOneTemplateSettings
            template={template}
            settingsDisabled={settingsDisabled}
            onFieldChange={onFieldChange}
          />
        )}

        {template.interactionType === '360' && (
          <ThreeSixtyTemplateSettings
            template={template}
            settingsDisabled={settingsDisabled}
            onFieldChange={onFieldChange}
          />
        )}
      </Box>
    </div>
  );
};

export default Settings;
