import React from 'react';
import LazyLoad from 'react-lazyload';

import type { LegacyQuestionBlock, UserFilterSegment } from 'models';

import QuestionBlockComponent from 'scenes/components/review/QuestionBlock';

import AnswersExplorer from '../AnswersExplorer';
import FeedbackPerOptionModal from '../FeedbackPerOptionModal';
import QuestionPieChart from '../QuestionPieChart';

type Props = {
  block: LegacyQuestionBlock;
  reviewCycleId: string;
  userFilter: UserFilterSegment | undefined | null;
};

const LegacyQuestionBlockResults = ({
  block,
  reviewCycleId,
  userFilter,
}: Props) => {
  const [lastLegendSelected, setLastLegendSelected] = React.useState<
    string | null
  >(null);
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalDefaultFilter, setModalDefaultFilter] = React.useState<{
    [key: string]: boolean;
  }>({
    all: true,
  });

  const toggleModal = () => setIsModalActive(!isModalActive);

  const onLegendItemClick = (
    filter: {
      [key: string]: boolean;
    },
    label: string | null
  ) => {
    setModalDefaultFilter(filter);
    setLastLegendSelected(label);
    toggleModal();
  };

  const { id, content, revieweeFeedbackOptions, reviewerFeedbackOptions } =
    block;
  const { title, description, richTextEnabled } = content;

  const revieweeRatingEnabled =
    !!revieweeFeedbackOptions && revieweeFeedbackOptions.ratingEnabled;
  const revieweeTextEnabled =
    !!revieweeFeedbackOptions && revieweeFeedbackOptions.textEnabled;

  return (
    <QuestionBlockComponent
      title={title}
      description={description}
      testClassName="test-legacy-question-block"
      withRichText={richTextEnabled}
    >
      {(revieweeRatingEnabled || reviewerFeedbackOptions.ratingEnabled) && (
        <LazyLoad height={400} offset={200}>
          <QuestionPieChart
            blockId={id}
            reviewCycleId={reviewCycleId}
            userFilter={userFilter}
            onLegendItemClick={onLegendItemClick}
          />
        </LazyLoad>
      )}

      {(revieweeTextEnabled || reviewerFeedbackOptions.textEnabled) && (
        <LazyLoad height={650} offset={200}>
          <AnswersExplorer
            block={block}
            reviewCycleId={reviewCycleId}
            userFilter={userFilter}
          />
        </LazyLoad>
      )}
      {isModalActive && !!lastLegendSelected && (
        <FeedbackPerOptionModal
          reviewCycleId={reviewCycleId}
          block={block}
          userFilter={userFilter}
          defaultFilter={modalDefaultFilter}
          option={lastLegendSelected}
          onClose={toggleModal}
        />
      )}
    </QuestionBlockComponent>
  );
};

export default LegacyQuestionBlockResults;
