import React from 'react';

import type { TrainingSession } from 'models';

import classNames from 'helpers/classNames';
import { startToEndDateLabel } from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Text } from 'components';

import translatedTrainingSessionFormats from './translatedTrainingSessionFormats';

type Props = {
  trainingSession: TrainingSession;
  additionalClassName?: string;
  sessionNameDisplayed?: boolean;
};

const TrainingSessionSummary = ({
  trainingSession,
  additionalClassName,
  sessionNameDisplayed = true,
}: Props) => {
  const notSpecifiedText = (
    <Text transformation="italic" color="light">
      {__('Not specified')}
    </Text>
  );
  const translatedFormats = translatedTrainingSessionFormats();

  return (
    <div className={classNames('flex flex-col', additionalClassName)}>
      {sessionNameDisplayed && (
        <span>
          {__('Session name:')} {trainingSession.name || notSpecifiedText}
        </span>
      )}
      <span>
        {__('Training organization:')}{' '}
        {trainingSession.trainingOrganization || notSpecifiedText}
      </span>
      <span>
        {__('Location:')} {trainingSession.location || notSpecifiedText}
      </span>
      <span>
        {__('Date:')}{' '}
        {trainingSession.startDate || trainingSession.endDate
          ? startToEndDateLabel(
              trainingSession.startDate,
              trainingSession.endDate,
              {
                fromToLabelsAlwaysVisible: true,
              }
            )
          : notSpecifiedText}
      </span>
      <span>
        {__('Number of hours:')}{' '}
        {trainingSession.durationInHours || notSpecifiedText}
      </span>
      <span>
        {__('Format:')}{' '}
        {trainingSession.trainingFormat
          ? translatedFormats[trainingSession.trainingFormat]
          : notSpecifiedText}
      </span>
    </div>
  );
};

export default TrainingSessionSummary;
