// @flow
import React from 'react';

import { EmptyState, Testable } from 'components';

import emptyStateImg from './reviews_emptystate.png';

type Props = {|
  title: string,
  inBoxList?: boolean,
|};

export default function ReviewEmptyState({ title, inBoxList }: Props) {
  return (
    <Testable name="test-review-empty-state">
      <EmptyState inBoxList={inBoxList} src={emptyStateImg} title={title} />
    </Testable>
  );
}
