import React, { Fragment, ReactNode } from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';

import {
  UserWithCareerLevelAndAssignationCriterion,
  UserWithCareerLevelCollection,
} from 'models/User';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BoldHtmlText,
  Box,
  BoxList,
  BoxListItem,
  Column,
  Columns,
  DatatableWrapper,
  FetchContainer,
  Icon,
  Text,
  Tooltip,
} from 'components';

import useAssignationEnabled from '../../helpers/userAssignation/useAssignationEnabled';
import useGetAssignationCriterionLabel from '../../helpers/userAssignation/useGetAssignationCriterionLabel';
import ListItem from './ListItem';

type Props = WithPaginationProps;

type AfterDataLoaderProps = Props &
  PaginationProps &
  DataLoaderProvidedProps & {
    users: UserWithCareerLevelCollection;
  };

function PaginatedList({ users, ...otherProps }: AfterDataLoaderProps) {
  const assignationEnabled = useAssignationEnabled();
  const assignationCriterionLabel = useGetAssignationCriterionLabel();
  const filters = [
    { param: 'all', label: __('All') },
    { param: 'with_matrix', label: __('With matrix') },
    { param: 'without_matrix', label: __('Without matrix') },
  ];

  const textWithTooltip = (text: string, tooltipText: ReactNode) => (
    <Text preset="14bs6" color="light">
      {text}
      <Tooltip content={tooltipText} triggerAdditionalClassName="ml-2">
        <Icon name="info" />
      </Tooltip>
    </Text>
  );

  return (
    <Box additionalClassName="mt-8">
      <DatatableWrapper
        collectionInfo={users}
        filters={filters}
        {...otherProps}
        totalCountRenderer={count =>
          n__('%1 employee', '%1 employees', count || 0)
        }
      >
        <BoxList>
          <FetchContainer
            {...otherProps}
            loadingStyle="overlay"
            render={() =>
              !!users?.items && (
                <Fragment>
                  <BoxListItem>
                    <Columns>
                      <Column isVerticallyCentered>
                        <Text preset="14bs6" color="light">
                          {__('Employee')}
                        </Text>
                      </Column>
                      <Column isVerticallyCentered>
                        <Text preset="14bs6" color="light">
                          {__('Skills matrix & level')}
                        </Text>
                      </Column>
                      {assignationEnabled && (
                        <Fragment>
                          <Column isVerticallyCentered>
                            {textWithTooltip(
                              __('Assignation'),
                              <BoldHtmlText
                                html={__(
                                  'Indicates how the matrix was assigned:<br/>- Manually from <b>My career</b> tab<br/>- Automatically according to the profile field: <b>%1</b>',
                                  assignationCriterionLabel
                                )}
                              />
                            )}
                          </Column>
                          <Column isVerticallyCentered>
                            {textWithTooltip(
                              assignationCriterionLabel as string,
                              __(
                                'Determines the automatic employees assignation to their skills matrix depending on their profile field: %1',
                                <b>{assignationCriterionLabel}</b>
                              )
                            )}
                          </Column>
                        </Fragment>
                      )}
                    </Columns>
                  </BoxListItem>

                  {users.items.map(
                    (user: UserWithCareerLevelAndAssignationCriterion) => (
                      <ListItem key={user.id} user={user} />
                    )
                  )}
                </Fragment>
              )
            }
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      search,
      page,
      countPerPage,
      userFilter,
      filter,
    }: PaginationProps) =>
      get(`skills/users_overview`, {
        search,
        page,
        countPerPage,
        userFilter,
        filter,
      }),
    hydrate: {
      users: {
        items: { currentCareerLevel: { skillsLevel: {} } },
      },
    },
    cacheKey: ({ page, countPerPage, search, userFilter, filter }) =>
      compositeKey({ page, countPerPage, search, userFilter, filter }),
  })
)(PaginatedList);
