import React from 'react';

import type { ReviewCycle, TemplateAssignmentStrategy } from 'models';

import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { BigSelectableButton, Column, Columns } from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';

import dynamicSvg from '../../../components/svg/dynamicSvg';
import uniqueSvg from '../../../components/svg/uniqueSvg';
import DynamicTemplateModal from './DynamicTemplateModal';
import DynamicTemplatePicker from './DynamicTemplatePicker';
import UniqueTemplatePicker from './UniqueTemplatePicker';

type Props = {
  reviewCycle: ReviewCycle;
  onTemplateUpdate: (reviewTemplateId: string) => Promise<void>;
  onContinueClick: () => void;
};

const TemplateSelector = ({
  reviewCycle,
  onTemplateUpdate,
  onContinueClick,
}: Props) => {
  const { templateAssignmentStrategy } = reviewCycle;
  const organization = useOrganization();
  const autoAddParticipantsEnabled =
    organization.plan.autoAddParticipantsEnabled &&
    reviewCycle.autoAddParticipantsEnabled;
  const dispatch = useAppDispatch();

  const onTemplateAssignmentStrategyChange = (
    templateAssignmentStrategy: TemplateAssignmentStrategy | null
  ) =>
    dispatch(
      put(`review_cycles/${reviewCycle.id}`, {
        templateAssignmentStrategy,
        setupStepIndex: 2,
      })
    );

  return (
    <StepContainer
      title={__('Choose a review template')}
      titlePreset="24bs2"
      subtitle={__(
        'How do you want to assign review templates to participants?'
      )}
      onContinueClick={onContinueClick}
      canPassStep={!!reviewCycle.defaultTemplate}
      stepName="templates"
    >
      <Columns>
        <Column>
          <BigSelectableButton
            style={{ height: 240 }}
            svg={uniqueSvg}
            title={__('Unique template')}
            subtitle={__(
              'With this option on, you choose a review template that is going to be used for every participant'
            )}
            isActive={templateAssignmentStrategy === 'unique'}
            isColumn
            onClick={() => onTemplateAssignmentStrategyChange('unique')}
            testClassName="test-unique-template-button"
          />
        </Column>
        <Column>
          {!autoAddParticipantsEnabled && (
            <BigSelectableButton
              style={{ height: 240 }}
              svg={dynamicSvg}
              title={__('Dynamic template')}
              subtitle={__(
                "With this option on, you use multiple review templates that are going to be assigned automatically depending on the participant's profile"
              )}
              tag={
                !organization.plan.dynamicTemplateEnabled
                  ? __('Upgrade')
                  : undefined
              }
              isActive={templateAssignmentStrategy === 'dynamic'}
              isColumn
              onClick={() => onTemplateAssignmentStrategyChange('dynamic')}
              testClassName="test-dynamic-template-button"
            />
          )}
        </Column>
      </Columns>

      {templateAssignmentStrategy === 'unique' && (
        <UniqueTemplatePicker
          reviewCycle={reviewCycle}
          onTemplateUpdate={onTemplateUpdate}
        />
      )}

      <DynamicTemplateModal
        isActive={
          templateAssignmentStrategy === 'dynamic' &&
          !organization.plan.dynamicTemplateEnabled
        }
        onClose={() => onTemplateAssignmentStrategyChange(null)}
        mainButtonText={
          organization.plan.dynamicTemplateEnabled
            ? __('Contact an Elevo expert to complete the setup')
            : __('Contact an Elevo expert to access this feature')
        }
      />

      {templateAssignmentStrategy === 'dynamic' &&
        organization.plan.dynamicTemplateEnabled && (
          <DynamicTemplatePicker reviewCycle={reviewCycle} />
        )}
    </StepContainer>
  );
};

export default TemplateSelector;
