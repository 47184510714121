import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import type { AppDispatch } from 'redux/actions/types';

import { largeMargin } from 'styles/uiConstants';

import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToSignin } from 'helpers/paths';
import queryString from 'helpers/queryString';
import transformProps from 'helpers/transformProps';

import { htmlSuccessNotice } from 'redux/actions';
import { post } from 'redux/actions/api';

import {
  Button,
  Centered,
  Control,
  Field,
  Form,
  Input,
  PageTitle,
  Splash,
  Title3,
  ValueManager,
} from 'components';

type Props = {
  location: Location;
};

type AfterConnectProps = {
  login: string;
  sendResetPasswordEmail: (login: string | null | undefined) => Promise<void>;
};

const successMessage = () => {
  return (
    "<ul style='margin-top: 0; margin-left: 0'>" +
    '<ul>' +
    '<li>' +
    `${__('If you entered your professional email address:')}` +
    '<ul>' +
    `<li>${__(
      'Please follow the instructions given by email to reset your password.'
    )}</li>` +
    `<li>${__(
      'If you didn’t receive an email, please verify that the email address you are using is the same you used to login.'
    )}</li>` +
    '</ul>' +
    `<li>${__(
      'If you entered another identifier, please contact your internal admin, who will provide you the instructions to follow to reset your password.'
    )}</li>` +
    `<li>${__(
      'If your organization uses a SSO, please try to connect through the SSO.'
    )}</li>` +
    '</ul>'
  );
};

function ForgotPassword({ login, sendResetPasswordEmail }: AfterConnectProps) {
  return (
    <Splash>
      <PageTitle title={__('Forgot your password?')} />

      <Centered>
        <Title3>{__('Forgot your password?')}</Title3>
        <ValueManager
          initialValue={login}
          render={(setLogin, login) => (
            <React.Fragment>
              <Form
                onSubmit={() => sendResetPasswordEmail(login)}
                style={{ marginTop: largeMargin, width: '100%' }}
              >
                <Field>
                  <Control leftIcon="person">
                    <Input
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      placeholder={__('Login')}
                      onChange={setLogin}
                      value={login || ''}
                    />
                  </Control>
                </Field>

                <Button
                  isExpanded
                  color="primary"
                  onClick={() => sendResetPasswordEmail(login)}
                  disabled={!login}
                >
                  {__('Reset password')}
                </Button>
              </Form>
              <div style={{ marginTop: largeMargin }}>
                <p className="has-text-centered">
                  <Link to={pathToSignin(login)}>{__('Back to sign-in')}</Link>
                </p>
              </div>
            </React.Fragment>
          )}
        />
      </Centered>
    </Splash>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendResetPasswordEmail: async (login: string | null | undefined) => {
    await dispatch(post('users/password/reset', { user: { login } }));
    navigate(pathToSignin(login));
    return dispatch(htmlSuccessNotice(successMessage()));
  },
});

export default compose<React.ComponentType<Props>>(
  transformProps(({ location }: Props) => ({
    login:
      queryString.parse(location.search).login ||
      queryString.parse(location.search).email,
  })),
  connect(null, mapDispatchToProps)
)(ForgotPassword);
