// @flow
import React from 'react';

import { __ } from 'helpers/i18n';

import { Button, Icon } from 'components';

type Props = {|
  setIsUpdateHomeMessageModalOpened: boolean => any,
|};

const EmptyHomeMessageContent = ({
  setIsUpdateHomeMessageModalOpened,
}: Props) => (
  <div className="empty-home-message">
    <div className="icon-circle-background">
      <Icon name="edit_note" />
    </div>
    <p>
      <b>{__('No welcome message')}</b>
    </p>
    <p>
      {__(
        'This block will only be visible to employees if you set up a welcome message'
      )}
    </p>
    <Button
      color="secondary"
      onClick={() => setIsUpdateHomeMessageModalOpened(true)}
    >
      {__('Write a message')}
    </Button>
  </div>
);

export default EmptyHomeMessageContent;
