import * as React from 'react';

import type {
  OneOnOneReviewCycle,
  OneOnOneUserReview,
  ThreeSixtyReviewCycle,
} from 'models';

import { __ } from 'helpers/i18n';
import { pathToManagerReviewCycleAnalytics } from 'helpers/paths';

import { Box, Button, Column, Columns, Date, Text, Title } from 'components';

import InsightsIcon from './InsightsIcon';

export * from './ReviewCycleStatusTag';
export * from './ReviewCycleIcon';

type ThreeSixtyProps = {
  reviewCycle: ThreeSixtyReviewCycle;
  hasManagerAnalytics: boolean;
  children: React.ReactNode;
};

export function ThreeSixtyReviewCycleItem(props: ThreeSixtyProps) {
  const { reviewCycle, hasManagerAnalytics, children } = props;
  return (
    <Box testClassName={`test-review-cycle-item-${reviewCycle.id}`}>
      <Columns contentVerticallyAligned style={{ marginBottom: 16 }}>
        <Column>
          <Title size={5} testClassName="test-review-cycle-name">
            {reviewCycle.name}
          </Title>
        </Column>
        {hasManagerAnalytics && (
          <Column isNarrow>
            <Button
              color="secondary"
              size="small"
              to={pathToManagerReviewCycleAnalytics(reviewCycle.id)}
              testClassName="test-analytics-button"
            >
              <InsightsIcon />
              <span style={{ marginLeft: 8 }}>{__('Follow-up')}</span>
            </Button>
          </Column>
        )}
      </Columns>

      <Columns isMobile isGapLess style={{ marginBottom: 16 }}>
        <Column size="full">
          <Text color="soften">
            {__(
              'Ends on: %1',
              <Date value={reviewCycle.dateReviewRevieweeEnd} />
            )}
          </Text>
        </Column>
      </Columns>

      {children}
    </Box>
  );
}

type OneOnOneProps = {
  reviewCycle: OneOnOneReviewCycle;
  userReview?: OneOnOneUserReview;
  displayDeadlines: boolean;
  hasManagerAnalytics: boolean;
  children: React.ReactNode;
};

export function OneOnOneReviewCycleItem({
  reviewCycle,
  userReview,
  displayDeadlines,
  hasManagerAnalytics,
  children,
}: OneOnOneProps) {
  return (
    <Box testClassName={`test-review-cycle-item-${reviewCycle.id}`}>
      <Columns additionalClassName="mb-4" contentVerticallyAligned>
        <Column>
          <Title size={5} testClassName="test-review-cycle-name">
            {reviewCycle.name}
          </Title>
        </Column>
        {hasManagerAnalytics && (
          <Column isNarrow>
            <Button
              color="secondary"
              size="small"
              to={pathToManagerReviewCycleAnalytics(reviewCycle.id)}
            >
              <InsightsIcon />
              <span style={{ marginLeft: 8 }}>{__('Follow-up')}</span>
            </Button>
          </Column>
        )}
      </Columns>

      {!!displayDeadlines && (
        <Columns isGapLess style={{ marginBottom: 16 }}>
          <Column size="half">
            <Text color="soften">
              {__('Self-assessment deadline:')}{' '}
              <Date
                value={
                  (!!userReview && userReview.revieweeDeadline) ||
                  reviewCycle.dateReviewRevieweeEnd
                }
              />
            </Text>
          </Column>
          <Column size="half">
            <Text color="soften">
              {__('Reviewer assessment deadline:')}{' '}
              <Date
                value={
                  (!!userReview && userReview.reviewerDeadline) ||
                  reviewCycle.dateReviewReviewerEnd
                }
              />
            </Text>
          </Column>
        </Columns>
      )}

      {children}
    </Box>
  );
}
