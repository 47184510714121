import React from 'react';

import { __, date, n__ } from 'helpers/i18n';

import {
  BoldHtmlText,
  Icon,
  PageHeader,
  SavingStatusLabel,
  Text,
} from 'components';

type Props = {
  title: string;
  isAnonymous: boolean;
  endDate: string;
  daysUntilTheEnd: number;
  isCompleted: boolean;
  isPreview?: boolean;
};

const SurveyPageHeader = ({
  title,
  isAnonymous,
  endDate,
  daysUntilTheEnd,
  isCompleted,
  isPreview,
}: Props) => {
  const anonymousWording = isAnonymous
    ? __('Your response <b>is anonymous</b>')
    : __('Your response <b>is not anonymous</b>');

  return (
    <PageHeader
      title={title}
      statusInfo={
        !!isPreview
          ? undefined
          : [
              <SavingStatusLabel
                key="survey_status_label"
                failedText={() => __('Your response could not be saved')}
              />,
            ]
      }
      description={
        <div className="flex flex-col md:flex-row justify-between mb-6 w-full">
          <div>
            <Icon
              name={isAnonymous ? 'visibility_off' : 'visibility'}
              additionalClassName="mr-1 align-text-bottom"
            />
            <Text preset="14s6" additionalClassName="font-normal">
              <BoldHtmlText html={anonymousWording} />
            </Text>
          </div>
          {!isCompleted && (
            <div>
              <Icon name="timer" additionalClassName="mr-1 align-text-bottom" />
              <Text preset="14s6" additionalClassName="font-normal">
                <BoldHtmlText
                  html={n__(
                    'You have until <b>%2</b> (%1 day left) to send your response',
                    'You have until <b>%2</b> (%1 days left) to send your response',
                    daysUntilTheEnd,
                    date(endDate, 'LL')
                  )}
                />
              </Text>
            </div>
          )}
        </div>
      }
    />
  );
};

export default SurveyPageHeader;
