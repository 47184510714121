import React from 'react';
import { connect } from 'react-redux';

import type { PersonalObjective, UserObjectivePeriod } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { Can, DragAndDropContainer, DragAndDropItem } from 'components';

import EmptyState from 'scenes/components/objectives/EmptyState';
import ObjectiveCardPlaceholder from 'scenes/components/objectives/ObjectiveCard/Placeholder';

import ObjectiveCreatorWithSvg from '../ObjectiveCreatorWithSvg';
import PersonalObjectiveCard from '../PersonalObjectiveCard';

export function PersonalObjectivesListPlaceholder() {
  return (
    <div>
      <ObjectiveCardPlaceholder style={{ marginBottom: 16 }} />
      <ObjectiveCardPlaceholder />
    </div>
  );
}

type Props = {
  objectives: PersonalObjective[];
  userObjectivePeriod: UserObjectivePeriod;
  onCreate: (userObjectivePeriod: UserObjectivePeriod) => Promise<unknown>;
  revieweeFullName?: string;
};

type AfterConnectProps = Props & {
  onPositionUpdate: (
    collectionPath: string,
    objectiveId: string,
    position: number
  ) => Promise<unknown>;
};

function PersonalObjectivesList({
  objectives,
  userObjectivePeriod,
  onCreate,
  onPositionUpdate,
  revieweeFullName,
}: AfterConnectProps) {
  const { objectiveCollection } = userObjectivePeriod;
  const canUpdateObjective = can({
    perform: 'update_objective',
    on: objectiveCollection,
  });

  const { collectionPath } = objectiveCollection;
  const onChangePosition = (objectiveId, position) =>
    onPositionUpdate(collectionPath, objectiveId, position);

  if (objectives.length === 0) {
    return (
      <Can
        perform="create_objective"
        on={userObjectivePeriod}
        alternativeChildren={
          <EmptyState title={__('There is no objectives in this period')} />
        }
      >
        <ObjectiveCreatorWithSvg
          onCreate={() => onCreate(userObjectivePeriod)}
          style={{ padding: 24, backgroundColor: '#FBFCFE' }}
          description={__(
            'Add a new objective and improve the way you track your advancement'
          )}
        />
      </Can>
    );
  }

  return (
    <DragAndDropContainer onChangePosition={onChangePosition}>
      {objectives.map(objective => (
        <DragAndDropItem
          key={objective.id}
          itemId={objective.id}
          position={objective.position}
          style={{ marginBottom: 16 }}
          disabled={!canUpdateObjective}
          handlePosition="top"
          alwaysDisplayHandle
        >
          <PersonalObjectiveCard
            key={objective.id}
            objective={objective}
            objectiveCollection={objectiveCollection}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            revieweeFullName={revieweeFullName}
          />
        </DragAndDropItem>
      ))}
    </DragAndDropContainer>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPositionUpdate: (collectionPath, objectiveId, position) =>
    dispatch(put(`${collectionPath}/${objectiveId}`, { position })),
});

export default connect(
  null,
  mapDispatchToProps
)(PersonalObjectivesList) as React.ComponentType<Props>;
