// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Team, TeamObjectivePeriod, User } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post, put } from 'redux/actions/api';

import {
  Can,
  DragAndDropContainer,
  DragAndDropItem,
  FetchContainer,
  HorizontalEmptyStateWithSvg,
  PageTitle,
  SubTitle,
  Testable,
  Title,
} from 'components';

import {
  ObjectiveCreatorWithSvg,
  TeamObjectiveCard,
} from 'scenes/components/objectives';
import emptyStateSvg from 'scenes/objectives/team/components/TeamSvg';

type Props = {|
  match: Match<{
    teamSlug: string,
    objectivePeriodSlug: string,
  }>,
  activeUser: User,
|};

type AfterDataLoaderProps = {|
  ...Props,
  ...DataLoaderProvidedProps,
  teamObjectivePeriod: TeamObjectivePeriod,
|};

type AfterConnectProps = {|
  ...AfterDataLoaderProps,
  onFieldChange: (teamId: string, attributes: $Shape<Team>) => Promise<any>,
  addTeamToPeriod: (teamSlug: string, periodSlug: string) => Promise<*>,
  createObjective: () => Promise<*>,
  onObjectivePositionUpdate: (
    objectiveId: string,
    position: number
  ) => Promise<*>,
|};

function TeamObjectivesPage({
  teamObjectivePeriod,
  match,
  isFetching,
  hasError,
  noContent,
  addTeamToPeriod,
  createObjective,
  onFieldChange,
  onObjectivePositionUpdate,
}: AfterConnectProps) {
  const handleAddTeamToPeriod = async () => {
    const { teamSlug, objectivePeriodSlug } = match.params;

    await addTeamToPeriod(teamSlug, objectivePeriodSlug);
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (!!noContent) {
          return (
            <Can
              perform="manage_team_objectives"
              on="organization"
              render={isAuthorized => (
                <HorizontalEmptyStateWithSvg
                  svg={emptyStateSvg}
                  description={__('This team is not in the selected period')}
                  style={{ marginTop: 28 }}
                  action={
                    isAuthorized
                      ? {
                          text: __('Add team to the period'),
                          testableName: 'test-add-team-to-period-button',
                          onTrigger: handleAddTeamToPeriod,
                        }
                      : undefined
                  }
                />
              )}
            />
          );
        }

        invariant(
          teamObjectivePeriod,
          'Team objective period must be present at this point'
        );
        const { team, objectives } = teamObjectivePeriod;

        return (
          <React.Fragment>
            <PageTitle
              title={__(
                '%1 Objectives - %2',
                teamObjectivePeriod.name,
                team.name
              )}
            />
            <div
              style={{
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              <Testable name="test-team-page-title">
                <Can
                  perform="manage_team_objectives"
                  on="organization"
                  render={isAuthorized => (
                    <Title
                      size={3.5}
                      weight="light"
                      additionalClassName="is-spaced"
                      color="pelo-grey"
                      isEditable={isAuthorized}
                      editableOptions={{
                        onChange: name =>
                          onFieldChange(team.slug, { name: name || '' }),
                        value: team.name,
                        type: 'singlelineText',
                      }}
                      style={{ marginBottom: 2 }}
                    />
                  )}
                />
              </Testable>
              <Testable name="test-team-page-subtitle">
                <SubTitle
                  size={7}
                  color="light"
                  transformation="uppercase"
                  weight="semibold"
                >
                  {n__('%1 objective', '%1 objectives', objectives.length)}
                </SubTitle>
              </Testable>
            </div>

            <DragAndDropContainer onChangePosition={onObjectivePositionUpdate}>
              {objectives.map(objective => (
                <DragAndDropItem
                  key={objective.id}
                  itemId={objective.id}
                  position={objective.position}
                  style={{ marginBottom: 16 }}
                  handlePosition="top"
                  alwaysDisplayHandle
                >
                  <TeamObjectiveCard
                    key={objective.id}
                    objective={objective}
                    style={{ marginBottom: 20 }}
                  />
                </DragAndDropItem>
              ))}
            </DragAndDropContainer>

            <Can perform="manage_team_objectives" on="organization">
              <ObjectiveCreatorWithSvg
                onCreate={createObjective}
                description={__(
                  'Add a new objective and improve the way you communicate within your company'
                )}
              />
            </Can>
          </React.Fragment>
        );
      }}
    />
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { match, teamObjectivePeriod, refetchData }: AfterDataLoaderProps
) => ({
  onFieldChange: async (teamSlug: string, attributes: $Shape<Team>) =>
    dispatch(
      put(`teams/${teamSlug}`, attributes, {
        errorMessage: __('The team name must be defined.'),
      })
    ),

  addTeamToPeriod: async (teamSlug: string, periodSlug: string) => {
    await dispatch(
      post(`teams/${teamSlug}/objective_periods`, { periodSlug: periodSlug })
    );
    refetchData();
  },
  createObjective: async () =>
    dispatch(
      post('team_objectives', {
        completion: 0,
        teamObjectivePeriodId: teamObjectivePeriod.id,
      })
    ),
  onObjectivePositionUpdate: (objectiveId, position) =>
    dispatch(put(`team_objectives/${objectiveId}`, { position })),
});

export default compose(
  newDataLoader({
    fetch:
      ({ match }: Props) =>
      async (dispatch: AppDispatch) => {
        return dispatch(
          get(
            `teams/${match.params.teamSlug}/objective_periods/${match.params.objectivePeriodSlug}`
          )
        );
      },
    hydrate: {
      teamObjectivePeriod: {
        team: {},
        objectives: {
          abilities: {},
          keyResults: {},
        },
      },
    },
    cacheKey: ({ match }: Props) => JSON.stringify(match.params),
  }),
  connect(null, mapDispatchToProps)
)(TeamObjectivesPage);
