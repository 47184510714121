// @flow
import * as React from 'react';

import type { User } from 'models';

import { clipArray } from 'helpers/array';
import { __, n__ } from 'helpers/i18n';

import { PlusXLeftAvatar, SelectToken } from 'components';
import { AvatarTitle } from 'components/Avatar';

import UserAvatar from 'scenes/components/UserAvatar';

import UserPicker from '../UserPicker';

type SelectedPeerProps = { user: User, onRemove?: (user: User) => void };

const SelectedPeer = ({ user, onRemove }: SelectedPeerProps) => {
  const onDelete = onRemove && (() => onRemove && onRemove(user));
  return (
    <SelectToken onDelete={onDelete}>
      <UserAvatar user={user} style={{ marginBottom: 0 }} />
    </SelectToken>
  );
};

type RemainingPeersProps = { peers: User[] };

const RemainingPeers = ({ peers }: RemainingPeersProps) => (
  <SelectToken>
    <PlusXLeftAvatar
      count={peers.length}
      tooltipContent={
        <React.Fragment>
          {peers.map(peer => (
            <div style={{ marginBottom: '5px' }} key={peer.id}>
              <UserAvatar user={peer} />
            </div>
          ))}
        </React.Fragment>
      }
    >
      <AvatarTitle>
        {n__('and one other', 'and %1 others', peers.length)}
      </AvatarTitle>
    </PlusXLeftAvatar>
  </SelectToken>
);

type PeersListProps = {
  peers: User[],
  onChange?: (users: Array<User>) => void,
  editable?: boolean,
  reviewee?: User,
};
export default function PeersList({
  peers,
  onChange,
  editable,
  reviewee,
}: PeersListProps) {
  const maxExplicitelyDisplayedPeers = 6;

  const [explicitelyDisplayedPeers, implicitelyDisplayedPeers] = clipArray(
    peers,
    maxExplicitelyDisplayedPeers
  );

  if (!editable) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {explicitelyDisplayedPeers.map(user => (
          <SelectedPeer user={user} key={user.id} />
        ))}{' '}
        {implicitelyDisplayedPeers.length > 0 ? (
          <RemainingPeers peers={implicitelyDisplayedPeers} />
        ) : (
          ''
        )}
      </div>
    );
  } else {
    return (
      <UserPicker
        disabledUserIds={reviewee && [reviewee.id]}
        placeholder={__('Select participants')}
        value={peers}
        onChange={users => Array.isArray(users) && onChange && onChange(users)}
        isMulti
        inModal
      />
    );
  }
}
