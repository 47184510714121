// @flow
import React from 'react';

import { EmptyState } from 'components';

import emptyStateImg from 'scenes/admin/EntityIndex/emptystate.png';

type Props = {| title: string |};

export default function IntegrationEmptyState({ title }: Props) {
  return <EmptyState src={emptyStateImg} title={title} />;
}
