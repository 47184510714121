// @flow
import * as React from 'react';

import type { User } from 'models';

import { pathToUser } from 'helpers/paths';

import { Avatar, Link, type Style, Text } from 'components';

type Props = {|
  report: User,
  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
|};

export default class DirectReportUserDetails extends React.Component<Props> {
  render() {
    const { report, style } = this.props;
    return (
      <div style={style}>
        <Avatar
          url={report.avatarUrl}
          size="normal"
          style={{
            margin: '0 auto',
            marginBottom: 10,
            justifyContent: 'center',
          }}
        />
        <DirectReportUserDetailsTitle style={{ marginBottom: 2 }}>
          <Link to={pathToUser(report.id)}>{report.fullName}</Link>
        </DirectReportUserDetailsTitle>
        {report.jobTitle ? (
          <DirectReportUserDetailsSubtitle>
            {report.jobTitle}
          </DirectReportUserDetailsSubtitle>
        ) : (
          <div style={{ height: '12px' }} />
        )}
      </div>
    );
  }
}

type TitleProps = {|
  children: React.Node,
  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
|};

const DirectReportUserDetailsTitle = ({ children, style }: TitleProps) => {
  return (
    <div className="flex items-center justify-center">
      <Text preset="18bs5">{children}</Text>
    </div>
  );
};

const DirectReportUserDetailsSubtitle = ({ children }: TitleProps) => {
  return (
    <div className="flex items-center justify-center">
      <Text preset="13s7">{children}</Text>
    </div>
  );
};
