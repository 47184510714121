import classNames from 'classnames';
import React, { Fragment, ReactNode } from 'react';

import useIsMobile from 'helpers/hooks/useIsMobile';

import { DesignSystem, Text, TooltipOnEllipsis } from 'components';

type Falsy = null | undefined | false;

export const filterDefinedColumns = <T,>(columns: T[]): Exclude<T, Falsy>[] => {
  return columns.filter(column => column) as Exclude<T, Falsy>[];
};

type ColumnDefinition<TRow> = {
  header: string; // Later can React.Node probably
  cell: (row: TRow) => React.ReactNode;
};

export type Props<TRow> = {
  rows: Array<TRow>;
  keyFn: (row: TRow) => string;
  emptyState?: React.ReactNode;
  footer?: React.ReactNode;
  additionalClassName?: string;
  columns: Array<ColumnDefinition<TRow> | Falsy>;
  withBorder?: boolean;
  highlightedRowFn?: (row: TRow) => boolean;
  highlightExplanation?: ReactNode;
  useSmallerYPadding?: boolean;
  lineCountPerRow?: number;
};

const SimpleTable = <TRow,>({
  columns,
  rows,
  keyFn,
  emptyState,
  footer,
  additionalClassName,
  withBorder,
  highlightedRowFn,
  highlightExplanation,
  useSmallerYPadding,
  lineCountPerRow,
}: Props<TRow>) => {
  const isMobile = useIsMobile();
  const filteredColumns = filterDefinedColumns(columns);
  const borderClasses = withBorder
    ? 'border-[1px] border-solid border-text-lightest/60 rounded-lg'
    : null;
  const gridTemplateColumns =
    isMobile || columns.length === 1
      ? '100%'
      : Array(columns.length)
          .fill('minmax(0px, max-content)')
          .join(' minmax(0px, 1fr) ');

  if (rows.length === 0) {
    return (
      <DesignSystem version={2}>
        <div>
          {emptyState}
          {!!footer && footer}
        </div>
      </DesignSystem>
    );
  }

  const anyHighlightedRow =
    !!highlightedRowFn && rows.some(row => highlightedRowFn(row));

  // *2 to skip the 1fr gaps | First grid column is 1
  const gridColumnIndex = (index: number) => 2 * index + 1;

  return (
    <DesignSystem version={2}>
      <div
        className={classNames(
          'simple-table grid items-center',
          borderClasses,
          additionalClassName
        )}
        style={{
          gridTemplateColumns,
          gridAutoRows: 'min-content',
          justifyItems: 'space-between',
        }}
      >
        <Row isHeader>
          {filteredColumns.map((column, index) => (
            <Fragment key={column.header}>
              <Cell
                columnIndex={gridColumnIndex(index)}
                useSmallerYPadding={useSmallerYPadding}
              >
                <Text preset="14bs6" color="light">
                  <TooltipOnEllipsis plainText lineClamp={lineCountPerRow}>
                    {column.header}
                  </TooltipOnEllipsis>
                </Text>
              </Cell>
              {index !== columns.length - 1 && (
                <FillerCell columnIndex={gridColumnIndex(index) + 1} />
              )}
            </Fragment>
          ))}
        </Row>
        {anyHighlightedRow && (
          <Row isHighlighted isFirstHighlighted>
            <Cell fullWidth useSmallerYPadding={useSmallerYPadding}>
              {highlightExplanation}
            </Cell>
          </Row>
        )}
        {rows.map((row, rowIndex) => {
          const isHighlighted = !!highlightedRowFn && highlightedRowFn(row);
          const isLastHighlighted =
            isHighlighted &&
            (rowIndex === rows.length - 1 ||
              !highlightedRowFn(rows[rowIndex + 1]));

          return (
            <Row
              key={keyFn(row)}
              isHighlighted={isHighlighted}
              isLastHighlighted={isLastHighlighted}
            >
              {filteredColumns.map((column, index) => (
                <Fragment key={column.header}>
                  <Cell
                    columnIndex={gridColumnIndex(index)}
                    useSmallerYPadding={useSmallerYPadding}
                  >
                    <TooltipOnEllipsis plainText lineClamp={lineCountPerRow}>
                      {column.cell(row)}
                    </TooltipOnEllipsis>
                  </Cell>
                  {index !== columns.length - 1 && (
                    <FillerCell columnIndex={gridColumnIndex(index) + 1} />
                  )}
                </Fragment>
              ))}
            </Row>
          );
        })}
        {footer && (
          <Row>
            <Cell fullWidth useSmallerYPadding={useSmallerYPadding}>
              {footer}
            </Cell>
          </Row>
        )}
      </div>
    </DesignSystem>
  );
};

type CellProps =
  | {
      fullWidth?: false;
      columnIndex: number;
      children: ReactNode;
      useSmallerYPadding?: boolean;
    }
  | {
      fullWidth: true;
      children: ReactNode;
      useSmallerYPadding?: boolean;
    };

const Cell = ({ children, useSmallerYPadding, ...props }: CellProps) => {
  const isMobile = useIsMobile();

  let gridColumn: string;
  if (props.fullWidth || isMobile) {
    gridColumn = '1 / -1';
  } else {
    gridColumn = `${props.columnIndex}`;
  }

  return (
    <div
      className={classNames('px-4', {
        'py-3': !isMobile && !useSmallerYPadding,
        'py-2': !isMobile && useSmallerYPadding,
      })}
      style={{ gridColumn }}
    >
      {children}
    </div>
  );
};

const FillerCell = ({ columnIndex }: { columnIndex: number }) => (
  <div style={{ gridColumn: `${columnIndex}` }} />
);

type RowProps = {
  isHeader?: boolean;
  children: ReactNode;
  isHighlighted?: boolean;
  isFirstHighlighted?: boolean;
  isLastHighlighted?: boolean;
};

const Row = ({
  isHeader,
  children,
  isHighlighted,
  isFirstHighlighted,
  isLastHighlighted,
}: RowProps) => {
  return (
    <Fragment>
      {!isHeader && !isFirstHighlighted && (
        <RowDelimiter isHighlighted={isHighlighted} />
      )}
      <div
        className={classNames('contents', {
          'highlighted-row': isHighlighted,
          'first-highlighted-row': isFirstHighlighted,
          'last-highlighted-row': isLastHighlighted,
        })}
      >
        {children}
      </div>
    </Fragment>
  );
};

const RowDelimiter = ({ isHighlighted }: { isHighlighted?: boolean }) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={classNames('h-[1px]', {
        'my-3': isMobile,
        'bg-ui-body-bg': !isHighlighted,
        'bg-transparent border-x-[1px] border-solid border-0 border-primary-20':
          isHighlighted,
      })}
      style={{
        gridColumn: '1 / -1',
      }}
    />
  );
};

export default SimpleTable;
