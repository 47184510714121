// @flow
import * as React from 'react';

import type { ReviewCycleStatus } from 'models/ReviewCycle';

import { __ } from 'helpers/i18n';

import { Tag } from 'components';

type Props = {
  status: ReviewCycleStatus,
  expired?: boolean,
  additionalClassName?: string,
};

export default function ReviewCycleStatusTag({
  status,
  expired,
  additionalClassName,
}: Props) {
  if (expired) {
    return <Tag additionalClassName={additionalClassName}>{__('expired')}</Tag>;
  }

  switch (status) {
    case 'draft':
      return <Tag additionalClassName={additionalClassName}>{__('draft')}</Tag>;
    case 'launching':
      return (
        <Tag color="success" additionalClassName={additionalClassName}>
          {__('launching')}
        </Tag>
      );
    case 'active':
      return (
        <Tag color="success" additionalClassName={additionalClassName}>
          {__('active')}
        </Tag>
      );
    case 'failed':
      return (
        <Tag color="danger" additionalClassName={additionalClassName}>
          {__('failed')}
        </Tag>
      );
    case 'ended':
      return <Tag additionalClassName={additionalClassName}>{__('ended')}</Tag>;
    default:
      return (
        <Tag additionalClassName={additionalClassName}>{__('unknown')}</Tag>
      );
  }
}
