import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Organization, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import { navigate, pathToUser } from 'helpers/navigation';
import { trackAction } from 'helpers/tracking';
import { withActiveUserAndOrganization } from 'helpers/withSessionProps';

import { post } from 'redux/actions/api';
import { htmlErrorNotice } from 'redux/actions/application';

import { hasShape, numberType, unknownType } from 'types/predicates/WithShape';

import {
  Button,
  Checkbox,
  Control,
  Field,
  FieldError,
  Helper,
  Input,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  SupportLink,
  Text,
} from 'components';

import EntityPicker from 'scenes/components/EntityPicker';

type Props = {
  withUsername: boolean;
  onClose: () => void;
};

type InviteParams = {
  firstName: string;
  lastName: string;
  email?: string;
  entityId: string;
  external: boolean;
  username?: string;
};

type AfterConnectProps = Props & {
  organization: Organization;
  activeUser: User;
  inviteUser: (params: InviteParams) => Promise<string>;
  notifyError: (msg: string) => void;
};

type Errors = {
  firstName?: string;
  lastName?: string;
  email?: string;
  external?: string;
  username?: string;
};

type State = {
  isSubmitting: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  username?: string;
  entityId: string;
  external: boolean;
  errors: Errors;
};

class InviteUserModal extends React.Component<AfterConnectProps, State> {
  state = {
    isSubmitting: false,
    firstName: '',
    lastName: '',
    email: undefined,
    username: undefined,
    entityId: '',
    external: false,
    errors: {} as Errors,
  };

  setFirstName(firstName: string) {
    this.setState({ firstName: firstName || '' });
  }

  setLastName(lastName: string) {
    this.setState({ lastName: lastName || '' });
  }

  setEmail(email: string) {
    this.setState({ email: email || undefined });
  }

  setUsername(username: string) {
    this.setState({ username: username || undefined });
  }

  setEntityId(entityId: string) {
    this.setState({ entityId: entityId || '' });
  }

  setExternal(external: boolean) {
    this.setState({ external });
  }

  handleError(error: unknown) {
    this.setState({ isSubmitting: false });

    if (
      hasShape(error, { response: { statusCode: numberType } }) &&
      error.response.statusCode === 401
    ) {
      return;
    }

    const { notifyError, organization, withUsername } = this.props;
    const { email, username } = this.state;
    const errors = hasShape(error, {
      response: { body: { errors: unknownType } },
    })
      ? (error.response.body.errors as Errors)
      : undefined;

    if (
      errors &&
      (errors.firstName ||
        errors.lastName ||
        errors.email ||
        ((username || withUsername) && errors.username))
    ) {
      this.setState({ errors });
    } else {
      window.logException(error);
      notifyError(
        __(
          '<b>There was an error and %1 could not be invited to join %2.</b><br />Try again or contact us at support@elevo.fr if the problem persists.',
          email || username || '',
          organization.name
        )
      );
    }
  }

  inviteUser = async () => {
    const { onClose, inviteUser } = this.props;
    this.setState({ isSubmitting: true });

    try {
      const newUserId = await inviteUser({
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        entityId: this.state.entityId,
        external: this.state.external,
        username: this.state.username,
      });

      navigate(pathToUser(newUserId));
      onClose();
    } catch (e) {
      this.handleError(e);
    }
  };

  render() {
    const { onClose, organization, activeUser, withUsername } = this.props;
    const {
      email,
      firstName,
      lastName,
      entityId,
      errors,
      isSubmitting,
      external,
      username,
    } = this.state;

    return (
      <ModalCard
        isActive={true}
        onClose={onClose}
        refreshContentOnOpening={false}
      >
        <ModalCardHead>
          <ModalCardTitle>{__('Add user')}</ModalCardTitle>
        </ModalCardHead>
        <ModalCardBody>
          <Helper>
            {__(
              '%1 directly for adding users in bulk.',
              <SupportLink>{__('Contact us')}</SupportLink>
            )}
          </Helper>

          {organization.syncIntegrationInfo.isEnabled && (
            <p className="-mt-4 mb-4">
              {__(
                "Please note that employees who are manually created won't be updated by your %1 integration.",
                organization.syncIntegrationInfo.name
              )}
            </p>
          )}

          <form
            onSubmit={e => {
              e.preventDefault();
              this.inviteUser();
            }}
          >
            <Field>
              <Control>
                <Input
                  value={firstName}
                  onChange={value => this.setFirstName(value)}
                  placeholder={__('First name (optional)')}
                  name="firstName"
                />
              </Control>
              {errors.firstName && <FieldError>{errors.firstName}</FieldError>}
            </Field>
            <Field>
              <Control>
                <Input
                  value={lastName}
                  onChange={value => this.setLastName(value)}
                  placeholder={__('Last name (optional)')}
                  name="lastName"
                />
              </Control>
              {errors.lastName && <FieldError>{errors.lastName}</FieldError>}
            </Field>
            {!withUsername && (
              <Field>
                <Control>
                  <Input
                    value={email}
                    onChange={value => this.setEmail(value)}
                    placeholder={__('Email (mandatory)')}
                    name="email"
                  />
                </Control>
                {errors.email && <FieldError>{errors.email[0]}</FieldError>}
              </Field>
            )}
            {organization.usernameEnabled && (
              <Field>
                <Control>
                  <Input
                    value={username}
                    onChange={value => this.setUsername(value)}
                    placeholder={__(
                      'Username (%1)',
                      withUsername ? __('mandatory') : __('optional')
                    )}
                    name="username"
                  />
                </Control>
                {errors.username && (
                  <FieldError>{errors.username[0]}</FieldError>
                )}
              </Field>
            )}
            {organization.plan.multiLevelAdminEnabled && (
              <Field>
                <EntityPicker
                  selectedEntityId={entityId}
                  onChange={id => this.setEntityId(id)}
                  placeholder={__(
                    'Select an entity (%1)',
                    activeUser.isOrganizationAdmin
                      ? __('optional')
                      : __('mandatory')
                  )}
                  emptyStateRender={() => null}
                />
              </Field>
            )}
            <Field>
              <Checkbox
                isChecked={external}
                onChange={value => this.setExternal(value)}
                label={
                  <Text>
                    <strong>{__('External user to the organization')}</strong>
                  </Text>
                }
                subLabel={
                  <Text color="info">
                    {__(
                      'External users can only participate as peers to 360 feedback'
                    )}
                  </Text>
                }
              />
            </Field>
            {/* This <button> is only for the Enter keypress to submit the form */}
            <button style={{ display: 'none' }} />
          </form>
        </ModalCardBody>
        <ModalCardFooter>
          <Button
            color="primary"
            onClick={this.inviteUser}
            isLoading={isSubmitting}
            disabled={withUsername ? !username : !email}
          >
            {__('Add user')}
          </Button>
          <Button color="secondary" onClick={onClose}>
            {__('Cancel')}
          </Button>
        </ModalCardFooter>
      </ModalCard>
    );
  }
}

const successMessage = (
  organization: Organization,
  { email, username, external }: InviteParams
) => {
  const login = email || username;

  const message = __(
    '<b>%1 was successfully added to join %2!</b>',
    login,
    organization.name
  );

  if (email && (!organization.ssoEnabled || external)) {
    return (
      message +
      __(
        '<br />An invitation email will be sent when %1 is added to a review campaign.',
        login
      )
    );
  }

  if (!organization.ssoEnabled || external) {
    return (
      message +
      __(
        '<br />You can generate an access code for this employee to activate their profile.'
      )
    );
  }

  return message;
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  {
    organization,
  }: Props & {
    organization: Organization;
  }
) => ({
  inviteUser: async (props: InviteParams) => {
    const response = await dispatch(
      post('users', props, {
        withDefaultErrorHandling: false,
        successMessage: successMessage(organization, props),
      })
    );
    trackAction('Single user created', {
      firstNameIsDefined: !!props.firstName,
      lastNameIsDefined: !!props.lastName,
    });

    return response.response.body.data.id;
  },
  notifyError: (msg: string) => dispatch(htmlErrorNotice(msg)),
});

export default compose(
  withActiveUserAndOrganization,
  // @ts-expect-error: For some reason TS doesn't like when we use connect with compose
  connect(null, mapDispatchToProps)
)(InviteUserModal) as React.ComponentType<Props>;
