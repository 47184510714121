import React from 'react';

import type { Tag } from 'models';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

type TagOption = {
  value: string;
  label: string;
};

type Props = {
  tags: Tag[];
  selectedTags: Tag[];
  onTagChange: (value: Tag[]) => void;
  additionalClassName?: string;
  placeholder?: string;
  preventCreation?: boolean;
};

const convertTagToOptions = (tags: Tag[]): TagOption[] =>
  tags.map(
    tag =>
      ({
        value: tag.label,
        label: tag.label,
      } as TagOption)
  );
const convertOptionsToTag = (options: TagOption[]): Tag[] =>
  options.map(option => ({ label: option.label } as Tag));

const TagPicker = ({
  tags,
  selectedTags,
  onTagChange,
  additionalClassName,
  placeholder,
  preventCreation,
}: Props) => {
  const value = convertTagToOptions(selectedTags);
  const options = convertTagToOptions(tags);
  const onChange = (options: TagOption[]) => {
    const newTags: Tag[] = convertOptionsToTag(options);

    return onTagChange(newTags);
  };

  return (
    <Select
      value={value}
      options={options}
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      onChange={onChange}
      placeholder={placeholder || __('Select...')}
      noOptionsMessage={__('No tag found')}
      formatCreateLabel={(newLabel: string) => __('Create "%1"', newLabel)}
      isMulti
      inModal
      isClearable
      isCreatable={!preventCreation}
      additionalClassName={additionalClassName}
    />
  );
};
export default TagPicker;
