// @flow
import * as React from 'react';

import type { ThreeSixtyUserReview } from 'models/index';

import { __ } from 'helpers/i18n/index';

import { MenuItem, Toggleable } from 'components/index';

import ReleaseReviewModal from './ReleaseReviewModal';

type Props = {|
  userReview: ThreeSixtyUserReview,
  renderAction: (onClick: () => void) => React.Node,
  onAfterAction?: () => Promise<*>,
|};

export default function ReleaseAction({
  renderAction,
  userReview,
  onAfterAction,
}: Props) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          {renderAction(toggle)}
          <ReleaseReviewModal
            isActive={isToggled}
            onCancel={toggle}
            userReview={userReview}
            reviewee={userReview.user}
            onAfterSubmit={onAfterAction}
            isForcedRelease
          />
        </React.Fragment>
      )}
    />
  );
}

ReleaseAction.defaultProps = {
  renderAction: onClick => (
    <MenuItem onClick={onClick}>{__('Share feedback with reviewee')}</MenuItem>
  ),
};
