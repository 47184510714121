// @flow
import * as React from 'react';

import type {
  UserFilterSegment,
  UserFilterableFieldType,
  UserFilterableSource,
} from 'models';

import { __ } from 'helpers/i18n';

import { Button, Centered, Icon, Link, type Style } from 'components';

import UserFilterSegmentPicker from 'scenes/components/UserFilterSegmentPicker';

type Props = {|
  filterType: UserFilterableFieldType,
  filteredSource?: UserFilterableSource,
  segment: ?UserFilterSegment,
  onSubmit: (userFilter: ?UserFilterSegment) => void,
  onBack: () => void,
  // $FlowFixMe Use CSSProperties from react with TypeScript
  style?: Style,
|};

type State = {|
  segment: ?UserFilterSegment,
|};

export default class SelectedFilter extends React.Component<Props, State> {
  state = {
    segment: null,
  };

  handleSegmentChange = (segment: ?UserFilterSegment) =>
    this.setState({ segment });

  componentDidMount(): void {
    this.setState({ segment: this.props.segment });
  }

  render() {
    const { filterType, filteredSource, onSubmit, onBack, style } = this.props;
    const { segment } = this.state;

    return (
      <div className="selected-filter" style={style}>
        <Link
          className="back-button"
          onClick={onBack}
          style={{ marginLeft: -4 }}
        >
          <Icon style={{ marginBottom: 2 }} name="keyboard_arrow_left" />
          <span>{__('back')}</span>
        </Link>

        <UserFilterSegmentPicker
          filterType={filterType}
          filteredSource={filteredSource}
          segment={segment}
          onChange={this.handleSegmentChange}
          style={{ marginTop: 16 }}
        />

        <Centered style={{ marginTop: 16 }}>
          <Button
            color="primary"
            onClick={() => onSubmit(segment)}
            disabled={
              !segment || (!!segment && segment.filterType !== filterType.slug)
            }
          >
            {__('Apply')}
          </Button>
        </Centered>
      </div>
    );
  }
}
