// @flow
import { omit } from 'lodash';
import * as React from 'react';
import { compose } from 'redux';

import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithPaginationProps } from 'lib/dataLoader/pagination/withPagination';
import type { PaginatedCollection, PeopleReviewCycle } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { withPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper, FetchContainer } from 'components';

import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

import PeopleReviewCycleListItem from './PeopleReviewCycleListItem';

type Props = {|
  ...WithPaginationProps,
|};

type AfterPaginateProps = {|
  ...Props,
  ...PaginationProps,
|};

type AfterConnectProps = {|
  ...AfterPaginateProps,
  peopleReviewCycleCollection: PaginatedCollection<PeopleReviewCycle>,
  isFetching: boolean,
  hasError: boolean,
|};

function PeopleReviewCycleListWithPagination({
  search,
  page,
  filter,
  countPerPage,
  peopleReviewCycleCollection,
  isFetching,
  hasError,
  onSearchChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterConnectProps) {
  const peopleReviewCycles = peopleReviewCycleCollection
    ? peopleReviewCycleCollection.items
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          peopleReviewCycleCollection
            ? omit(peopleReviewCycleCollection, 'peopleReviewCycles')
            : null
        }
        totalCountRenderer={(totalCount: ?number) => {
          return n__(
            '%1 people review campaign',
            '%1 people review campaigns',
            totalCount || 0
          );
        }}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'ongoing', label: __('Active or draft') },
          { param: 'finalized', label: __('Finalized') },
          { param: 'all', label: __('PeopleReviewCycle|All') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <ReviewEmptyState
            title={__('No review campaign matches your search')}
            inBoxList
          />
        )}
      >
        <BoxList>
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => {
              return (
                <div className="people-review-cycle-list">
                  {peopleReviewCycles.map(peopleReviewCycle => (
                    <PeopleReviewCycleListItem
                      key={peopleReviewCycle.id}
                      peopleReviewCycle={peopleReviewCycle}
                    />
                  ))}
                </div>
              );
            }}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default (compose(
  withPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, search, filter }: AfterPaginateProps) =>
      get('people_review_cycles', {
        page,
        countPerPage,
        search,
        filter,
      }),
    hydrate: {
      peopleReviewCycleCollection: {
        items: {},
      },
    },
    cacheKey: ({ page, countPerPage, search, filter }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter }),
  })
)(PeopleReviewCycleListWithPagination): React.ComponentType<Props>);
