import React, { useEffect, useState } from 'react';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Button, ConfirmationModal, Flex, Icon, Tag } from 'components';

import TagPicker from 'scenes/components/TagPicker';

const TagsBlock = ({ reviewCycle, onFieldChange, readOnly, tags }) => {
  const [isTagsModalActive, setIsTagsModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    setSelectedTags(reviewCycle.tags);
  }, [reviewCycle.tags]);
  const removeTag = (tagLabel: string) => {
    onFieldChange({
      tagLabels: reviewCycle.tags
        .map(tag => tag.label)
        .filter(tag => tag !== tagLabel),
    });
  };
  const addTags = () => {
    onFieldChange({
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      tagLabels: selectedTags.map(tag => tag.label),
    });
    setIsTagsModalActive(false);
  };
  return (
    <Flex
      style={{ flexDirection: 'column', alignItems: 'start' }}
      additionalClassName="gap-1"
    >
      {reviewCycle.tags.map((tag, index) => (
        <Tag key={`tag-${index}`}>
          {tag.label}
          {!readOnly && (
            <Button
              hasIconOnly
              isText
              style={{ backgroundColor: 'transparent' }}
              additionalClassName="no-underline"
              size="small"
              onClick={() => removeTag(tag.label)}
            >
              <Icon size="tiny" name="close" />
            </Button>
          )}
        </Tag>
      ))}
      {!readOnly && (
        <Button
          onClick={() => setIsTagsModalActive(true)}
          size="small"
          color="secondary"
          additionalClassName="no-underline"
        >
          <Icon size="tiny" additionalClassName="mr-1" name="add_circle" />
          {__('Add')}
        </Button>
      )}

      {isTagsModalActive && !readOnly && (
        <ConfirmationModal
          isActive={isTagsModalActive}
          onConfirm={addTags}
          onCancel={() => setIsTagsModalActive(false)}
          confirmLabel={__('Add')}
          title={__('Add tags to this campaign')}
          refreshContentOnOpening
          isLarge
          overflowVisible
        >
          <p>{__('Choose one or many tags to add to this campaign')}</p>
          <div style={{ margin: '1rem 0' }}>
            {!!tags && (
              <TagPicker
                tags={tags}
                selectedTags={selectedTags}
                // @ts-ignore TSFIXME: Fix strictNullChecks error
                onTagChange={setSelectedTags}
              />
            )}
          </div>
        </ConfirmationModal>
      )}
    </Flex>
  );
};

export default newDataLoader({
  fetch: () =>
    get('tags', {
      context: 'review_cycle',
    }),
  hydrate: { tags: {} },
})(TagsBlock);
