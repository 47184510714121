import React from 'react';

import { ChoiceScore } from 'models/surveys';

import { Text, Tooltip } from 'components';

type Props = {
  score: ChoiceScore;
};

const MAX_LABEL_LENGTH = 20;

const ChoiceScoreComponent = ({ score }: Props) => {
  const labelIsTooLong = score.label.length > MAX_LABEL_LENGTH;

  return (
    <div className="flex flex-col text-center max-w-40">
      {labelIsTooLong ? (
        <Tooltip content={score.label}>
          <Text preset="16bs5.5" additionalClassName="line-clamp-3">
            {score.label}
          </Text>
        </Tooltip>
      ) : (
        <Text preset="18bs5">{score.label}</Text>
      )}

      <Text preset="18bs5" additionalClassName="mt-2">
        {score.percent}%
      </Text>
    </div>
  );
};

export default ChoiceScoreComponent;
