import { useOrganization } from 'helpers/hooks';

const useAssignationEnabled = () => {
  const organization = useOrganization();
  const {
    settings: { skillsMatrixAssignationCriterion },
  } = organization;

  return (
    !!skillsMatrixAssignationCriterion &&
    organization.featureFlags.includes('manageMatrixAssignmentRules')
  );
};

export default useAssignationEnabled;
