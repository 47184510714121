import { kebabCase } from 'lodash';
import React, { Fragment } from 'react';

import { SurveyQuestionStats } from 'models';

import classNames from 'helpers/classNames';
import { useOrganization } from 'helpers/hooks';
import { n__ } from 'helpers/i18n';
import { pathToSurveyCampaignResults } from 'helpers/paths';

import { Box, BoxVerticalSeparator, Icon, Link, Text } from 'components';

import QuestionScore from '../../components/QuestionScore';
import { getStepContentStyles } from '../../helpers/steps';

type Props = {
  campaignId: string;
  periodId: string | null | undefined;
  questionNumber: number;
  questionStats: SurveyQuestionStats;
};

const Question = ({
  campaignId,
  periodId,
  questionNumber,
  questionStats,
}: Props) => {
  const { question } = questionStats;
  const { questionType } = question;
  const { iconName } = getStepContentStyles()[questionType];

  const typeClass = `survey-box-question--${kebabCase(questionType)}`;
  const organization = useOrganization();
  const surveyDetailedResultsEnabled = organization.featureFlags.includes(
    'surveyDetailedResults'
  );

  return (
    <Link
      to={
        surveyDetailedResultsEnabled
          ? pathToSurveyCampaignResults(
              campaignId,
              questionStats.question.id,
              periodId
            )
          : ''
      }
      additionalClassName="no-underline"
    >
      <Box
        additionalClassName={classNames(
          'survey-box-question',
          typeClass,
          'flex mb-4'
        )}
      >
        <div className="flex flex-grow items-center">
          <Icon
            name={iconName}
            additionalClassName="survey-box-question-icon text-xl shrink-0"
          />
          <div className="flex-grow">
            <Text preset="16bs5.5">
              {questionNumber} - {questionStats.question.title}
            </Text>
            <div className="text-text-light">
              {n__('%1 answer', '%1 answers', questionStats.answersCount)}
              {questionStats.commentsCount !== null && (
                <React.Fragment>
                  {' · '}
                  {n__(
                    '%1 comment',
                    '%1 comments',
                    questionStats.commentsCount
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {(questionType !== 'text' ||
          (organization.aiFeaturesEnabled &&
            question.textSentimentEnabled)) && (
          <Fragment>
            <BoxVerticalSeparator />

            <QuestionScore
              question={question}
              score={questionStats.score}
              sentimentCategory={questionStats.sentimentCategory}
              additionalClassName="mr-8 inline-flex items-center justify-center min-w-40"
            />
          </Fragment>
        )}
      </Box>
    </Link>
  );
};

export default Question;
