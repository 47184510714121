import moment from 'moment';
import React from 'react';

import { DraftSurveyCampaign, SurveyCampaignUpdatableAttributes } from 'models';

import { handleFormErrors } from 'helpers/api';
import { FormErrors } from 'helpers/api';
import { dateToDefaultDateStringFormat } from 'helpers/date';
import { __, date, n__ } from 'helpers/i18n';

import {
  BigSelectableButton,
  Control,
  DatePicker,
  Field,
  FieldError,
  Icon,
  Label,
  Notification,
  NumberInput,
  Select,
  Text,
} from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';

export const canContinue = (
  recurrenceType: DraftSurveyCampaign['recurrenceType'],
  recurrenceValue: DraftSurveyCampaign['recurrenceValue'],
  recurrenceOffset: DraftSurveyCampaign['recurrenceOffset'],
  nextPeriodAt: DraftSurveyCampaign['nextPeriodAt'],
  daysToComplete: DraftSurveyCampaign['daysToComplete']
) => {
  if (!daysToComplete) return false;
  if (recurrenceType === 'one_off') return !!nextPeriodAt;
  if (recurrenceType === 'daily') return !!recurrenceValue;
  if (recurrenceType === 'weekly')
    return !!recurrenceValue && !!recurrenceOffset;
  if (recurrenceType === 'monthly')
    return !!recurrenceValue && !!recurrenceOffset;
  throw new Error('Unknown recurrence type');
};

type FrequencyParams = {
  recurrenceType: DraftSurveyCampaign['recurrenceType'];
  recurrenceValue: DraftSurveyCampaign['recurrenceValue'];
  recurrenceOffset: DraftSurveyCampaign['recurrenceOffset'];
  daysToComplete: DraftSurveyCampaign['daysToComplete'];
};

type Props = FrequencyParams & {
  onUpdate: (
    params: SurveyCampaignUpdatableAttributes,
    options?: { withDefaultErrorHandling: boolean }
  ) => Promise<void>;
  nextPeriodAt: DraftSurveyCampaign['nextPeriodAt'];
  canLaunchToday: boolean;
  goToNextStep: (params?: { requestAlreadySent?: boolean }) => void;
};

const StepFrequency = ({
  recurrenceType: recurrenceTypeFromProps,
  recurrenceValue: recurrenceValueFromProps,
  recurrenceOffset: recurrenceOffsetFromProps,
  daysToComplete: daysToCompleteFromProps,
  nextPeriodAt,
  goToNextStep,
  onUpdate,
  canLaunchToday,
}: Props) => {
  const [frequencyParams, setFrequencyParams] = React.useState<FrequencyParams>(
    {
      recurrenceType: recurrenceTypeFromProps,
      recurrenceValue: recurrenceValueFromProps,
      recurrenceOffset: recurrenceOffsetFromProps,
      daysToComplete: daysToCompleteFromProps,
    }
  );
  const { recurrenceType, recurrenceValue, recurrenceOffset, daysToComplete } =
    frequencyParams;
  const [errors, setErrors] = React.useState<FormErrors>({});
  const isOneOff = recurrenceType === 'one_off';

  const updateSurvey = (
    params: SurveyCampaignUpdatableAttributes,
    throwException?: boolean
  ) => {
    return handleFormErrors(
      async () => {
        await onUpdate(
          { setupStepIndex: 3, ...params },
          { withDefaultErrorHandling: false }
        );
        setErrors({});
      },
      setErrors,
      throwException
    );
  };

  const setFrequencyParamsAndUpdateSurvey = (
    params: Partial<FrequencyParams>
  ) => {
    setFrequencyParams(prevState => ({ ...prevState, ...params }));
    updateSurvey({
      ...frequencyParams,
      ...params,
    });
  };

  const recurrenceTypeOptions = [
    {
      value: 'daily' as const,
      label: n__('day', 'days', recurrenceValue || 2),
    },
    {
      value: 'weekly' as const,
      label: n__('week', 'weeks', recurrenceValue || 2),
    },
    {
      value: 'monthly' as const,
      label: n__('month', 'months', recurrenceValue || 2),
    },
  ];
  const weeklyOffsetOptions = [
    { value: 1, label: __('Monday') },
    { value: 2, label: __('Tuesday') },
    { value: 3, label: __('Wednesday') },
    { value: 4, label: __('Thursday') },
    { value: 5, label: __('Friday') },
  ];

  const maxDaysToComplete = () => {
    if (!recurrenceValue) return undefined;
    if (recurrenceType === 'daily') return recurrenceValue;
    if (recurrenceType === 'weekly') return recurrenceValue * 7;
    if (recurrenceType === 'monthly') return recurrenceValue * 28;
  };

  // Defined in app/validators/survey_campaign_validator.rb
  const maxRecurrenceValues = { daily: 30, weekly: 15, monthly: 36 };

  return (
    <StepContainer
      stepName="frequency"
      onContinueClick={async () => {
        try {
          await updateSurvey({ setupStepIndex: 4 }, true);
          goToNextStep({ requestAlreadySent: true });
        } catch (_) {
          // Prevent goToNextStep, error handled by setErrors
        }
      }}
      canPassStep={canContinue(
        recurrenceType,
        recurrenceValue,
        recurrenceOffset,
        nextPeriodAt,
        daysToComplete
      )}
    >
      <Text preset="18bs5">{__('Survey frequency')}</Text>

      <div className="flex gap-4 mt-4 mb-4">
        <BigSelectableButton
          emoji="📑"
          title={__('One-off survey')}
          subtitle={__('Define a launch date and response time')}
          style={{ minHeight: '100%', flex: 1 }}
          onClick={() => {
            setFrequencyParamsAndUpdateSurvey({
              recurrenceType: 'one_off',
              recurrenceValue: null,
              recurrenceOffset: null,
              daysToComplete: 7,
            });
          }}
          isActive={isOneOff}
        />
        <BigSelectableButton
          emoji="📅"
          title={__('Recurrent survey')}
          subtitle={__('Customize the frequency and response time')}
          style={{ minHeight: '100%', flex: 1 }}
          onClick={() => {
            setFrequencyParams(previousParams => ({
              ...previousParams,
              recurrenceType: 'monthly',
              recurrenceValue: 1,
              recurrenceOffset: 1,
              daysToComplete: 7,
            }));
            updateSurvey({
              recurrenceType: 'monthly',
              recurrenceValue: 1,
              recurrenceOffset: 1,
              daysToComplete: 7,
              nextPeriodAt: null,
            });
          }}
          isActive={!isOneOff}
        />
      </div>

      <div className="flex flex-col gap-3">
        {isOneOff ? (
          <React.Fragment>
            <Field additionalClassName="mb-0">
              <div className="flex">
                <Label labelAdditionalClassName="w-max mr-2 mb-0 self-center">
                  {__('Launch date:')}
                </Label>
                <Control
                  isClearable={!!nextPeriodAt}
                  onClear={() => updateSurvey({ nextPeriodAt: null })}
                >
                  <DatePicker
                    minDate={(canLaunchToday
                      ? moment()
                      : moment().add(1, 'days')
                    ).toDate()}
                    value={!!nextPeriodAt ? new Date(nextPeriodAt) : null}
                    onChange={date =>
                      updateSurvey({
                        nextPeriodAt: dateToDefaultDateStringFormat(
                          date || null
                        ),
                      })
                    }
                  />
                </Control>
              </div>
              <FieldError>{errors.nextPeriodAt}</FieldError>
            </Field>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Field additionalClassName="mb-0">
              <Text preset="14bs6" additionalClassName="flex items-center">
                {__(
                  'Repeats every %1 %2',
                  <NumberInput
                    min={1}
                    max={maxRecurrenceValues[recurrenceType]}
                    additionalClassName="w-12 mr-2 ml-2"
                    style={{ MozAppearance: 'textfield' }}
                    value={recurrenceValue || undefined}
                    onChange={newValue =>
                      setFrequencyParams(prevState => ({
                        ...prevState,
                        recurrenceValue: newValue || null,
                      }))
                    }
                    onBlur={() => updateSurvey(frequencyParams)}
                  />,
                  <Select
                    options={recurrenceTypeOptions}
                    value={recurrenceTypeOptions.find(
                      option => option.value === recurrenceType
                    )}
                    onChange={selectedType => {
                      if (!!selectedType)
                        setFrequencyParamsAndUpdateSurvey({
                          recurrenceType: selectedType.value,
                          recurrenceOffset: 1,
                          daysToComplete:
                            selectedType.value === 'monthly' ? 7 : null,
                        });
                    }}
                  />
                )}
              </Text>
              <FieldError>{errors.recurrenceType}</FieldError>
              <FieldError>{errors.recurrenceValue}</FieldError>
            </Field>
            {recurrenceType === 'monthly' && (
              <Field additionalClassName="mb-0">
                <Text preset="14bs6" additionalClassName="flex items-center">
                  {__(
                    'Starts the %1 of each month',
                    <NumberInput
                      min={1}
                      max={28}
                      additionalClassName="w-12 mr-2 ml-2"
                      style={{ MozAppearance: 'textfield' }}
                      value={recurrenceOffset || undefined}
                      onChange={newValue =>
                        setFrequencyParams(prevState => ({
                          ...prevState,
                          recurrenceOffset: newValue || null,
                        }))
                      }
                      onBlur={() => updateSurvey(frequencyParams)}
                    />
                  )}
                </Text>
                <FieldError>{errors.recurrenceOffset}</FieldError>
              </Field>
            )}
            {recurrenceType === 'weekly' && (
              <Field additionalClassName="mb-0">
                <Text preset="14bs6" additionalClassName="flex items-center">
                  {__(
                    'Starts the %1',
                    <Select
                      additionalClassName="mx-2"
                      options={weeklyOffsetOptions}
                      value={weeklyOffsetOptions.find(
                        option => option.value === recurrenceOffset
                      )}
                      onChange={newValue => {
                        if (!!newValue)
                          setFrequencyParamsAndUpdateSurvey({
                            recurrenceOffset: newValue.value,
                          });
                      }}
                    />
                  )}
                </Text>
                <FieldError>{errors.recurrenceOffset}</FieldError>
              </Field>
            )}
          </React.Fragment>
        )}
        <Field additionalClassName="mb-0">
          <Text preset="14bs6" additionalClassName="flex items-center">
            {n__(
              'Responses will be open for %2 calendar day',
              'Responses will be open for %2 calendar days',
              daysToComplete || 2,
              <NumberInput
                min={1}
                max={maxDaysToComplete()}
                value={daysToComplete || undefined}
                additionalClassName="w-12 mr-2 mx-2"
                style={{ MozAppearance: 'textfield' }}
                onChange={newValue =>
                  setFrequencyParams(prevState => ({
                    ...prevState,
                    daysToComplete: newValue || null,
                  }))
                }
                onBlur={() => updateSurvey(frequencyParams)}
              />
            )}
          </Text>
          <FieldError>{errors.daysToComplete}</FieldError>
        </Field>
        {recurrenceType !== 'one_off' && nextPeriodAt && (
          <Notification kind="primary">
            <Icon name="info" style={{ marginBottom: 2 }} />
            <Text preset="14bs6" additionalClassName="mx-2">
              {__('Next launch date:')}
            </Text>
            {date(nextPeriodAt, 'LL')} ({date(nextPeriodAt, 'dddd')})
          </Notification>
        )}
      </div>
    </StepContainer>
  );
};

export default StepFrequency;
