import React from 'react';
import { compose } from 'redux';

import type { UpdatableReviewCycleFields } from '../../index';
import type { OneOnOneReviewCycle, Tag } from 'models';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Icon, Tooltip } from 'components';

import TagPicker from 'scenes/components/TagPicker';

import OptionWrapper from './OptionWrapper';

type Props = {
  reviewCycle: OneOnOneReviewCycle;
  updateReviewCycle: (
    reviewCycle: Partial<UpdatableReviewCycleFields>
  ) => Promise<void>;
};

type AfterLoadProps = Props &
  DataLoaderProvidedProps & {
    tags: Array<Tag>;
  };

const TagsOption = ({
  reviewCycle,
  updateReviewCycle,
  tags = [],
}: AfterLoadProps) => {
  const onInputChange = (tags: Tag[]) =>
    updateReviewCycle({ tagLabels: tags.map(tag => tag.label) });

  return (
    <OptionWrapper
      title={
        <React.Fragment>
          {__('Tags')}
          <Tooltip
            content={__(
              'Tags allow you to identify your campaigns. They can be used in creating your audit reports.'
            )}
            style={{ maxWidth: 340 }}
            isLight
          >
            <Icon style={{ marginLeft: 8 }} name="info" />
          </Tooltip>
        </React.Fragment>
      }
      subtitle={__('Define the tag(s) to associate with this campaign.')}
    >
      <TagPicker
        tags={tags}
        selectedTags={reviewCycle.tags || []}
        onTagChange={onInputChange}
      />
    </OptionWrapper>
  );
};

export default compose(
  newDataLoader({
    fetch: () =>
      get('tags', {
        context: 'review_cycle',
      }),
    hydrate: { tags: {} },
  })
)(TagsOption);
