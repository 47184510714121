import React from 'react';

import { DraftSurveyCampaign, RecurrentSurveyCampaign } from 'models';

import { isToday } from 'helpers/date';
import { __, dateCalendar } from 'helpers/i18n';
import {
  navigate,
  pathToSurveyCampaignOverview,
  pathToSurveyCampaignPreview,
} from 'helpers/navigation';

import { Button, Centered, Helper, Link } from 'components';

import SummaryStep from 'scenes/components/Stepper/SummaryStep';

import { humanizeFrequency } from '../../helpers/humanizeFrequency';
import useUpdateCampaignStatus from '../../hooks/useUpdateCampaignStatus';

type Props = {
  campaignId: string;
  name: DraftSurveyCampaign['name'];
  nextPeriodAt: DraftSurveyCampaign['nextPeriodAt'];
  participantAdditionMode: DraftSurveyCampaign['participantAdditionMode'];
  selectedUsersCount: number;
  recurrenceType: DraftSurveyCampaign['recurrenceType'];
  recurrenceValue: DraftSurveyCampaign['recurrenceValue'];
  anonymous: DraftSurveyCampaign['anonymous'];
  allStepCompleted: boolean;
};

const StepSummary = ({
  campaignId,
  name,
  nextPeriodAt,
  participantAdditionMode,
  selectedUsersCount,
  recurrenceType,
  recurrenceValue,
  anonymous,
  allStepCompleted,
}: Props) => {
  const launchToday = !!nextPeriodAt && isToday(nextPeriodAt);

  const items = [
    { label: __('Campaign name:'), children: name },
    {
      label: __('Launch date:'),
      children: dateCalendar(nextPeriodAt, { hours: false }),
    },
    {
      label:
        participantAdditionMode === 'excluded'
          ? __('Number of excluded participants:')
          : __('Number of participants:'),
      children: selectedUsersCount,
    },
    {
      label: __('Form:'),
      children: (
        <Link to={pathToSurveyCampaignPreview(campaignId)} openInNewTab>
          {__('Preview')}
        </Link>
      ),
    },
    {
      label: __('Frequency:'),
      children: humanizeFrequency(
        recurrenceType as RecurrentSurveyCampaign['recurrenceType'],
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        recurrenceValue
      ),
      hidden: recurrenceType === 'one_off',
    },
    {
      label: __('Identity of respondants:'),
      children: anonymous ? __('Anonymous') : __('Non-anonymous'),
    },
  ];

  const { launchCampaign, scheduleCampaign, handleSubmitErrors } =
    useUpdateCampaignStatus(campaignId);

  const submit = async () => {
    try {
      if (launchToday) {
        await launchCampaign();
      } else {
        await scheduleCampaign();
      }
      navigate(pathToSurveyCampaignOverview(campaignId));
    } catch (error: unknown) {
      handleSubmitErrors(error);
    }
  };

  return (
    <SummaryStep items={items}>
      <Centered>
        <Button color="primary" onClick={submit} disabled={!allStepCompleted}>
          {launchToday
            ? __('Launch the campaign')
            : __('Schedule the campaign')}
        </Button>
      </Centered>
      {launchToday && (
        <Helper additionalClassName="mt-4">
          {__(
            'After clicking on "Launch the campaign", participants will receive a launch email and will be able to participate in the survey.'
          )}
        </Helper>
      )}
    </SummaryStep>
  );
};

export default StepSummary;
