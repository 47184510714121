import React from 'react';

import { User } from 'models/User';

import { useOrganization } from 'helpers/hooks';

import SimbelTrainingRequestModal from 'scenes/components/simbel/TrainingRequestModal';

import OriginalTrainingRequestModal from './TrainingRequestModal';

type Props = {
  currentTrainingPeriodSlug: string;
  concernedUser?: User;
  isActive: boolean;
  createdFromReview?: boolean;
  onClose: () => void;
  onConfirmationClose?: () => void;
  onAfterSubmit?: () => void;
};

export default function TrainingRequestModal(props: Props) {
  const organization = useOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRequestModal {...props} />;
  } else {
    return <OriginalTrainingRequestModal {...props} />;
  }
}
