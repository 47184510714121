import React, {
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import classNames from 'helpers/classNames';
import createBodyPortal from 'helpers/react/createBodyPortal';

import { Box, DesignSystem } from 'components';

type Props = {
  renderTrigger: (onTrigger: () => void, isOpened: boolean) => ReactNode;
  render: (onClose: () => void, update: () => any) => ReactNode;
  additionalClassName?: string;
};

const transitionDurationInMs = 200;

export default function Popover({
  renderTrigger,
  render,
  additionalClassName,
}: Props) {
  const timeoutHandler = useRef<number | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const clearExistingTimeoutHandler = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }

    timeoutHandler.current = null;
  };

  const setTimeout = (fn: () => any, duration: number) => {
    timeoutHandler.current = window.setTimeout(fn, duration);
  };

  const close = () => {
    clearExistingTimeoutHandler();
    setIsOpened(false);
    setTimeout(() => {
      setIsOpened(false);
      setIsMounted(false);
    }, transitionDurationInMs);
  };

  const open = () => {
    clearExistingTimeoutHandler();
    setIsMounted(true);
    setTimeout(() => {
      setIsOpened(true);
      setIsMounted(true);
    }, 0);
  };

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && isOpened) close();
    };

    document.addEventListener('keydown', onKeyPress, false);

    return () => document.removeEventListener('keydown', onKeyPress, false);
  });

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const {
    styles,
    attributes,
    update: popperUpdate,
  } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const fallbackUpdate = () => {};
  const update = popperUpdate || fallbackUpdate;

  useLayoutEffect(() => {
    if (isOpened) {
      // We have to manually ask popper to update to correctly place
      // the popper element
      !!update && update();
    }
  }, [isOpened, update]);

  return (
    <DesignSystem version={2}>
      <div>
        <div ref={setReferenceElement} className={additionalClassName}>
          {renderTrigger(() => (isOpened ? close() : open()), isMounted)}
        </div>

        {isMounted &&
          createBodyPortal(
            <DesignSystem version={2}>
              <div
                className={classNames(
                  'popover',
                  isOpened && 'is-opened',
                  additionalClassName
                )}
              >
                <React.Fragment>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div className="popover-backdrop" onClick={close} />
                  <div
                    {...attributes.popper}
                    className="popover-content"
                    ref={setPopperElement}
                    style={styles.popper}
                  >
                    <Box isInset>{render(close, update)}</Box>
                  </div>
                </React.Fragment>
              </div>
            </DesignSystem>
          )}
      </div>
    </DesignSystem>
  );
}
