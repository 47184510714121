import React, { useState } from 'react';
import { connect } from 'react-redux';

import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { trackAction } from 'helpers/tracking';
import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api/createUrl';
import { get, post } from 'redux/actions/api';

import {
  Button,
  CircularProgress,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Poller,
} from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';

type ExportFormat = 'csv' | 'xlsx';
type Props = {};

type AfterConnectProps = Props & {
  prepareExport: (format: ExportFormat) => Promise<any>;
  getExportAction: (actionId: string) => Promise<any>;
  prepareAccessCodesExport: () => Promise<void>;
  prepareProfessionalBackgroundExport: () => Promise<void>;
};

function ExportsButton({
  prepareExport,
  getExportAction,
  prepareAccessCodesExport,
  prepareProfessionalBackgroundExport,
}: AfterConnectProps) {
  const [currentExportId, setCurrentExportId] = useState<null | string>(null);
  const [isPreparing, setIsPreparing] = useState(false);
  const [isExportModalActive, setIsExportModalActive] = React.useState(false);

  const organization = useOrganization();
  const displayAccessCodesExport = organization.usernameUsed;

  const canExportProfessionalBackground = can({
    perform: 'export_professional_background',
    on: organization,
  });

  const handleClick = async (format: ExportFormat) => {
    setIsPreparing(true);

    const { response } = await prepareExport(format);
    setCurrentExportId(response.body.data.id);

    trackAction(`User ${format} export prepared`);
  };

  const handleAccessCodesExportClick = async () => {
    setIsExportModalActive(true);

    await prepareAccessCodesExport();

    trackAction(`Access codes export prepared`);
  };

  const handleExportProfessionalBackgroundClick = async () => {
    setIsExportModalActive(true);

    await prepareProfessionalBackgroundExport();

    trackAction(`Professional background export prepared`);
  };

  const poll = async () => {
    if (!currentExportId) return;

    const { response } = await getExportAction(currentExportId);
    const { status } = response.body.data.attributes;

    if (status === 'completed') {
      setIsPreparing(false);

      const url = addToken(createUrl(`exports/${currentExportId}/download`));
      window.location.replace(url);

      setCurrentExportId(null);
    }

    if (status === 'failed') {
      setIsPreparing(false);
      setCurrentExportId(null);

      throw new Error('Failed to generate export');
    }
  };

  return (
    <React.Fragment>
      {isPreparing && <Poller poll={poll} everyMs={1000} />}
      <Dropdown align="right">
        <DropdownTrigger
          testClassName="test-export-actions"
          render={dropdownIsOpened => (
            <Button color="info" isOutlined={!dropdownIsOpened}>
              {isPreparing ? (
                <CircularProgress size="small" style={{ marginRight: 4 }} />
              ) : (
                <Icon name="file_download" />
              )}
              <span>{__('Export')}</span>
              <Icon name="keyboard_arrow_down" />
            </Button>
          )}
        />
        <DropdownMenu>
          <DropdownMenuItem onClick={() => handleClick('csv')}>
            {__('All active users to CSV')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleClick('xlsx')}>
            {__('All active users to Excel')}
          </DropdownMenuItem>
          {displayAccessCodesExport && (
            <DropdownMenuItem
              testClassName="test-export-action-access-codes"
              onClick={() => handleAccessCodesExportClick()}
            >
              {__('Access codes for users without email')}
            </DropdownMenuItem>
          )}
          {canExportProfessionalBackground && (
            <DropdownMenuItem
              onClick={() => handleExportProfessionalBackgroundClick()}
            >
              {__("All users' professional background to Excel")}
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      </Dropdown>

      <ExportModal
        isActive={isExportModalActive}
        onClose={() => setIsExportModalActive(false)}
      />
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    prepareExport: (format: ExportFormat) =>
      dispatch(post('users/prepare_export', { format })),
    getExportAction: (ExportId: string) => dispatch(get(`exports/${ExportId}`)),
    prepareAccessCodesExport: () =>
      dispatch(post('users/access_codes/prepare_bulk_export')),
    prepareProfessionalBackgroundExport: () =>
      dispatch(post('occupations/prepare_bulk_export')),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ExportsButton) as React.ComponentType<Props>;
