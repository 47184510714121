import React, { Fragment, useEffect, useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader/types';
import type { ReviewedDimension, UserFilterSegment } from 'models';

import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { hasNoNullableValues } from 'types/predicates/NoNullValues';

import { EmptyStateWithIcon, FetchContainer, Text } from 'components';

import UserFilter from 'scenes/components/UserFilter';

import DimensionPicker from './DimensionPicker';
import PeopleReviewCycleHeatmap from './PeopleReviewCycleHeatmap';

type Props = {
  cycleId: string;
};

export type SelectedDimensionIds = {
  x: null | string;
  y: null | string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    reviewedDimensions: Array<ReviewedDimension>;
  };

const PeopleReviewCycleMapping = ({
  cycleId,
  reviewedDimensions,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  const { urlQueryParams, replaceHistory } = useUrlQueryParams();
  const [selectedDimensionIds, setSelectedDimensionIds] =
    useState<SelectedDimensionIds>({
      x: urlQueryParams().x || null,
      y: urlQueryParams().y || null,
    });

  const [userFilter, setUserFilter] = useState<UserFilterSegment | undefined>(
    urlQueryParams().userFilter || undefined
  );

  useEffect(() => {
    replaceHistory({
      ...urlQueryParams(),
      x: selectedDimensionIds.x,
      y: selectedDimensionIds.y,
    });
  }, [
    urlQueryParams,
    replaceHistory,
    selectedDimensionIds.x,
    selectedDimensionIds.y,
  ]);

  const onUserFilterChange = (newUserFilter: UserFilterSegment | undefined) => {
    setUserFilter(newUserFilter);
    replaceHistory({ ...urlQueryParams(), userFilter: newUserFilter });
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <Fragment>
          <Text>
            {__(
              'View the position of your employees by selecting two dimensions:'
            )}
          </Text>
          <div className="flex items-end justify-between mt-4">
            <DimensionPicker
              reviewedDimensions={reviewedDimensions}
              selectedDimensionIds={selectedDimensionIds}
              setSelectedDimensionIds={setSelectedDimensionIds}
            />
            <UserFilter segment={userFilter} onChange={onUserFilterChange} />
          </div>
          <div className="mt-4">
            {hasNoNullableValues(selectedDimensionIds) ? (
              <PeopleReviewCycleHeatmap
                cycleId={cycleId}
                selectedDimensionIds={selectedDimensionIds}
                userFilter={userFilter}
              ></PeopleReviewCycleHeatmap>
            ) : (
              <EmptyStateWithIcon
                title={__('Start analyzing your people reviews')}
                iconName="equalizer"
                description={__(
                  'Select two dimensions using the drop-down lists above'
                )}
              ></EmptyStateWithIcon>
            )}
          </div>
        </Fragment>
      )}
    />
  );
};

export default newDataLoader({
  fetch: ({ cycleId }: Props) =>
    get(`people_review_cycles/${cycleId}/reviewed_dimensions`),
  hydrate: {
    reviewedDimensions: {},
  },
})(PeopleReviewCycleMapping) as React.ComponentType<Props>;
