import React, { Fragment } from 'react';

import type { TrainingPeriod } from 'models';
import type { RouteComponentProps } from 'react-router-dom';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import { pathToTrainingSessionIndex } from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, ContentContainer, Flex, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import TrainingPageHeader from '../TrainingPageHeader';
import TrainingSessionList from './TrainingSessionList';
import TrainingSessionStats from './TrainingSessionStats';

type RouterProps = RouteComponentProps<{ trainingPeriodSlug: string }>;

const TrainingSessions = ({ match }: RouterProps) => {
  const { trainingPeriodSlug } = match.params;
  const [exportModalIsActive, setExportModalIsActive] = React.useState(false);
  const dispatch = useAppDispatch();

  invariant(trainingPeriodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await dispatch(
      post(`training/periods/${trainingPeriodSlug}/requests/export`)
    );
    setExportModalIsActive(true);
  };

  return (
    <Fragment>
      <TrainingPageHeader
        actions={
          <Button
            color="secondary"
            onClick={handleExport}
            style={{ marginLeft: 'auto' }}
          >
            <Icon name="file_download" />
            <span>{__('Export')}</span>
          </Button>
        }
        trainingPeriodSlug={trainingPeriodSlug}
      />
      <ContentContainer>
        <Flex direction="row" additionalClassName="my-4">
          <div className="w-40">
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentTrainingPeriodSlug={trainingPeriodSlug}
              onChange={(period: TrainingPeriod) =>
                navigate(pathToTrainingSessionIndex(period.slug))
              }
            />
          </div>
        </Flex>
        <div className="my-4">
          <TrainingSessionStats periodSlug={trainingPeriodSlug} />
        </div>
        <TrainingSessionList
          periodSlug={trainingPeriodSlug}
          paginationType="url"
        />
      </ContentContainer>
      {exportModalIsActive && (
        <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
      )}
    </Fragment>
  );
};

export default TrainingSessions;
