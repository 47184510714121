import React, { ReactElement } from 'react';

import type { ReviewCycle, UserReview } from 'models';

import can from 'helpers/can';
import { getAbilityIds } from 'helpers/models/abilities';
import { pathToHome, pathToNewReviewResults } from 'helpers/paths';

import { FeatureFlagged, type GroupedMenuItems } from 'components';

import {
  ManagePeersAction,
  ReleaseAction,
  RemindPeersFeedbackAction,
  RemindPeersNominationAction,
  RemindSelfAssessmentAction,
  ReopenPeersFeedbackAction,
  UnreleaseAction,
} from 'scenes/components/userReview/360/actions';
import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';
import { RemoveParticipantAction } from 'scenes/components/userReview/common/actions';
import {
  ChangeReviewTemplateAction,
  SendReminder,
  SendSignatureReminder,
  ShareEvaluation,
  UnshareEvaluation,
  UpdateAdditionalReviewerAction,
} from 'scenes/components/userReview/oneOnOne/actions';

function redirectUser(userReview: UserReview) {
  const shouldRedirectToUserReview = can({
    perform: 'redirect_to_user_reviews_after_responsible_update',
    on: userReview,
  });

  const goToUrl = shouldRedirectToUserReview
    ? pathToNewReviewResults(userReview.id)
    : pathToHome();

  window.location.replace(goToUrl);
}

type Props = {
  userReview: UserReview;
  reviewCycle: ReviewCycle;
  refreshReviewContent: () => Promise<any>;
};

export function getAvailableActions({
  userReview,
  reviewCycle,
  refreshReviewContent,
}: Props): GroupedMenuItems {
  const itemGroups: {
    remind: ReactElement[];
    unshare: ReactElement[];
    other: ReactElement[];
    remove: ReactElement[];
  } = {
    remind: [],
    unshare: [],
    other: [],
    remove: [],
  };

  if (
    userReview.interactionType === '360' &&
    reviewCycle.interactionType === '360'
  ) {
    const abilityIds = getAbilityIds(userReview);
    for (let abilityId of abilityIds) {
      switch (abilityId) {
        case 'view_results':
          // We are already looking at results
          break;
        case 'show_action_to_release':
          // This is displayed with a button
          break;
        case 'unrelease':
          itemGroups.other.push(
            <UnreleaseAction
              key="unreleaseAction"
              userReview={userReview}
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'remind_peers_nomination':
          itemGroups.remind.push(
            <RemindPeersNominationAction
              userReview={userReview}
              key="remindPeersNominationAction"
            />
          );
          break;
        case 'remind_self_assessment':
          itemGroups.remind.push(
            <RemindSelfAssessmentAction
              userReview={userReview}
              key="remindSelfAssessmentAction"
            />
          );
          break;
        case 'remind_peers_feedback':
          itemGroups.remind.push(
            <RemindPeersFeedbackAction
              userReview={userReview}
              key="remindPeersFeedbackAction"
            />
          );
          break;
        case 'nominate_peers':
        case 'change_peers_after_validation':
        case 'manage_peers':
        case 'validate_peers':
          itemGroups.other.push(
            <ManagePeersAction
              key="managePeersAction"
              userReview={userReview}
              reviewCycle={reviewCycle}
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'show_secondary_action_in_review_to_release':
          itemGroups.other.push(
            <ReleaseAction
              onAfterAction={refreshReviewContent}
              userReview={userReview}
              key="releaseAction"
            />
          );
          break;
        case 'reopen_peers_feedback':
          itemGroups.other.push(
            <ReopenPeersFeedbackAction
              key="reopenPeersFeedback"
              userReview={userReview}
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'remove_participant':
          itemGroups.remove.push(
            <RemoveParticipantAction
              key="removeParticipantAction"
              userReview={userReview}
              reviewCycle={reviewCycle}
            />
          );
          break;
        case 'update_responsible':
          itemGroups.other.push(
            <UpdateResponsibleAction
              userReview={userReview}
              reviewCycle={reviewCycle}
              onAfterAction={refreshReviewContent}
              key="updateResponsibleAction"
            />
          );
          break;
        case 'choose_peers':
        default:
          break;
      }
    }
  } else if (
    userReview.interactionType === 'one_on_one' &&
    reviewCycle.interactionType === 'one_on_one'
  ) {
    const abilityIds = getAbilityIds(userReview);

    for (let abilityId of abilityIds) {
      switch (abilityId) {
        case 'show_review':
        case 'view_results':
        case 'export_pdf':
          break;
        case 'schedule_meeting':
          break;
        case 'change_review_template':
          itemGroups.other.push(
            <ChangeReviewTemplateAction
              key="changeReviewTemplateAction"
              userReview={userReview}
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'update_additional_reviewer':
          itemGroups.other.push(
            <FeatureFlagged
              flag="reviewWithAdditionalReviewer"
              key="updateAdditionalReviewerAction"
            >
              <UpdateAdditionalReviewerAction
                userReview={userReview}
                key="updateAdditionalReviewerAction"
                onAfterAction={refreshReviewContent}
              />
            </FeatureFlagged>
          );
          break;
        case 'remind_reviewer_signature':
          itemGroups.remind.push(
            <SendSignatureReminder
              userReview={userReview}
              target="reviewer"
              key="sendSignatureReminderToReviewer"
            />
          );
          break;
        case 'remind_reviewee_signature':
          itemGroups.remind.push(
            <SendSignatureReminder
              userReview={userReview}
              target="reviewee"
              key="sendSignatureReminderToReviewee"
            />
          );
          break;
        case 'remind_reviewer_evaluation':
          itemGroups.remind.push(
            <SendReminder
              userReview={userReview}
              target="reviewer"
              key="sendReminderToReviewer"
            />
          );
          break;
        case 'remind_self_assessment':
          itemGroups.remind.push(
            <SendReminder
              userReview={userReview}
              target="reviewee"
              key="sendReminderToReviewee"
            />
          );
          break;
        case 'unshare_self_assessment':
          itemGroups.unshare.push(
            <UnshareEvaluation
              userReview={userReview}
              target="reviewee"
              key="unshareRevieweeEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'unshare_main_reviewer_evaluation':
          itemGroups.unshare.push(
            <UnshareEvaluation
              userReview={userReview}
              target="reviewer"
              key="unshareReviewerEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'unshare_additional_reviewer_evaluation':
          itemGroups.unshare.push(
            <UnshareEvaluation
              userReview={userReview}
              target="additional_reviewer"
              key="unshareAdditionalReviewerEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'unshare_reviewers_evaluations':
          itemGroups.unshare.push(
            <UnshareEvaluation
              userReview={userReview}
              target="reviewers"
              key="unshareReviewersEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'share_main_reviewer_evaluation':
          itemGroups.other.push(
            <ShareEvaluation
              userReview={userReview}
              target="reviewer"
              key="shareReviewerEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'share_reviewers_evaluations':
          itemGroups.other.push(
            <ShareEvaluation
              userReview={userReview}
              target="reviewers"
              key="shareReviewersEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'share_self_assessment':
          itemGroups.other.push(
            <ShareEvaluation
              userReview={userReview}
              target="reviewee"
              key="shareRevieweeEvaluation"
              onAfterAction={refreshReviewContent}
            />
          );
          break;
        case 'update_responsible':
          itemGroups.other.push(
            <UpdateResponsibleAction
              userReview={userReview}
              reviewCycle={reviewCycle}
              key="updateResponsibleAction"
              onAfterAction={() => redirectUser(userReview)}
            />
          );
          break;
        case 'remove_participant':
          itemGroups.remove.push(
            <RemoveParticipantAction
              key="removeParticipantAction"
              userReview={userReview}
              reviewCycle={reviewCycle}
            />
          );
          break;
        case 'redirect_to_user_reviews_after_responsible_update':
        default:
          break;
      }
    }
  }

  return itemGroups;
}
