// @flow
import * as React from 'react';

import type { ObjectivePeriod, TeamObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { PageTitle } from 'components';
import FetchContainer from 'components/FetchContainer';

import TeamListEmptyState from 'scenes/objectives/team/TeamList/TeamListEmptyState';

import TeamItem from './TeamItem';

type Props = {|
  isFetching: boolean,
  hasError: boolean,
  objectivePeriod: ObjectivePeriod,
  sortedTeamObjectivePeriods: Array<TeamObjectivePeriod>,
|};

export default function TeamsObjectives({
  isFetching,
  hasError,
  objectivePeriod,
  sortedTeamObjectivePeriods,
}: Props) {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        invariant(
          objectivePeriod && objectivePeriod.teamObjectivePeriods,
          'Organization objective period should come with teams here.'
        );

        const periodIsEmpty = sortedTeamObjectivePeriods.length === 0;

        return (
          <React.Fragment>
            <PageTitle title={__('%1 Objectives', objectivePeriod.name)} />
            {periodIsEmpty ? (
              <div additionalClassName="is-centered" style={{ paddingTop: 28 }}>
                <TeamListEmptyState objectivePeriod={objectivePeriod} />
              </div>
            ) : (
              <div
                style={{ marginTop: 20 }}
                className="organization-objective-period-page"
              >
                {sortedTeamObjectivePeriods.map(teamObjectivePeriod => (
                  <div
                    style={{ marginBottom: 40 }}
                    key={teamObjectivePeriod.id}
                  >
                    <TeamItem teamObjectivePeriod={teamObjectivePeriod} />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        );
      }}
    />
  );
}
