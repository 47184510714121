// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import type { ObjectivePeriod } from 'models';
import type { Match } from 'react-router-dom';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  Can,
  EmptyStateWithIcon,
  FetchContainer,
  Testable,
  Toggleable,
} from 'components';

import CreationModal from '../components/CreateOrganizationPeriodModal';

type Props = {|
  match: Match,
|};

type AfterConnectProps = {|
  ...Props,
  currentObjectivePeriod: ObjectivePeriod,
  createObjectivePeriod: string => Promise<*>,
  notifySuccess: string => void,
  notifyError: string => void,
  isFetching: boolean,
  hasError: boolean,
|};

function OrganizationPeriodCreator({
  currentObjectivePeriod,
  match,
  isFetching,
  hasError,
}: AfterConnectProps) {
  if (currentObjectivePeriod) {
    return (
      <Redirect
        to={{
          pathname: `${match.url}/${currentObjectivePeriod.slug}`,
          state: {
            objectivePeriod: currentObjectivePeriod,
          },
        }}
      />
    );
  }

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <Can
          perform="manage_team_objectives"
          on="organization"
          alternativeChildren={
            <EmptyStateWithIcon
              iconName="track_changes"
              description={__(
                "Team objectives haven't yet been activated by your organization."
              )}
            />
          }
        >
          <Toggleable
            render={(isToggled, toggle) => (
              <React.Fragment>
                <Testable name="test-emptystate-objective-period-creation">
                  <Button color="primary" onClick={toggle}>
                    {__('Create a period')}
                  </Button>
                </Testable>
                <EmptyStateWithIcon
                  iconName="track_changes"
                  additionalClassName="mt-4"
                  description={__(
                    'Create your first organization period to start using team objectives.'
                  )}
                />

                <CreationModal isActive={isToggled} onClose={toggle} />
              </React.Fragment>
            )}
          />
        </Can>
      )}
    />
  );
}

export default (newDataLoader({
  fetch: () => get('organization_objective_periods/current'),
  hydrate: {
    currentObjectivePeriod: {},
  },
})(OrganizationPeriodCreator): React.ComponentType<Props>);
