import React from 'react';

import {
  PeopleUserReviewPlannedAction,
  PlannedActionStatus,
  Tag,
} from 'models';

import {
  dateFromDefaultDateString,
  dateToDefaultDateTimeStringFormat,
} from 'helpers/date';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Control,
  DatePicker,
  Field,
  FieldError,
  Flex,
  Helper,
  Input,
  Label,
  Textarea,
} from 'components';

import TagPicker from 'scenes/components/TagPicker';
import StatusPicker from 'scenes/components/plannedActions/StatusPicker';

type Props = {
  plannedActionObject: Partial<PeopleUserReviewPlannedAction>;
  setPlannedActionObject: (
    plannedAction: Partial<PeopleUserReviewPlannedAction>
  ) => void;
  errors: Partial<PeopleUserReviewPlannedAction>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    tags: Array<Tag>;
    popperPlacement?: string;
  };

const PeopleReviewActionForm = ({
  plannedActionObject,
  setPlannedActionObject,
  errors,
  tags,
}: AfterDataLoaderProps) => {
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  const nonPersistedTags = plannedActionObject.tagLabels
    .filter(label => !tags?.find(tag => tag.label === label))
    .map(label => ({ label: label })) as Array<Tag>;
  const allTags = (tags || []).concat(nonPersistedTags);

  return (
    <React.Fragment>
      <Field>
        <Label>{__('Action title')}</Label>
        <Input
          value={plannedActionObject.title}
          placeholder={__(
            'Give a title to the action (e.g. Promotion following the people review...)'
          )}
          onChange={title =>
            setPlannedActionObject({
              ...plannedActionObject,
              title,
            })
          }
        />
        <FieldError>{errors.title}</FieldError>
      </Field>
      <Field>
        <Label>{__('Type')}</Label>
        <TagPicker
          tags={allTags}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          selectedTags={plannedActionObject.tagLabels.map(label =>
            allTags.find(tag => tag.label === label)
          )}
          onTagChange={tags => {
            const tagLabels = tags.map(tag => tag.label);
            setPlannedActionObject({
              ...plannedActionObject,
              tagLabels,
            });
          }}
        />
      </Field>

      <Field>
        <Label>{__('Description')}</Label>
        <Textarea
          value={plannedActionObject.description}
          placeholder={__(
            "What is the purpose of this action? Why is it important for the employee's development?"
          )}
          rows={5}
          maxRows={10}
          onChange={description =>
            setPlannedActionObject({
              ...plannedActionObject,
              description,
            })
          }
        />
        <FieldError>{errors.description}</FieldError>
      </Field>
      <Field additionalClassName="date-picker">
        <Label>{__('Due date')}</Label>
        <Flex>
          <Control
            isClearable={!!plannedActionObject.dueDate}
            onClear={() =>
              setPlannedActionObject({
                ...plannedActionObject,
                dueDate: null,
              })
            }
          >
            <DatePicker
              value={
                plannedActionObject.dueDate
                  ? dateFromDefaultDateString(plannedActionObject.dueDate)
                  : null
              }
              onChange={dueDate =>
                setPlannedActionObject({
                  ...plannedActionObject,
                  dueDate: dueDate
                    ? dateToDefaultDateTimeStringFormat(dueDate)
                    : null,
                })
              }
              placeholder={__('Select a date')}
              popperPlacement="right-end"
            />
          </Control>
        </Flex>
        <FieldError>{errors.dueDate}</FieldError>
      </Field>
      <Field>
        <Label>{__('Status')}</Label>
        <StatusPicker
          value={plannedActionObject.status as PlannedActionStatus}
          onChange={status =>
            setPlannedActionObject({
              ...plannedActionObject,
              status,
            })
          }
        />
        <FieldError>{errors.status}</FieldError>
      </Field>
      {!!plannedActionObject.dueDate && (
        <Helper>
          {__(
            'A reminder will be sent to you by email in the week preceding the due date.'
          )}
        </Helper>
      )}
    </React.Fragment>
  );
};

export default newDataLoader({
  fetch: () =>
    get('tags', {
      context: 'planned_action',
    }),
  hydrate: { tags: {} },
})(PeopleReviewActionForm);
