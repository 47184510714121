// @flow
import React, { type Node } from 'react';

type Props = {
  imgSrc: ?string,
  children: Node,
  defaultBackgroundColor: string,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
};

const Banner = ({ imgSrc, children, defaultBackgroundColor }: Props) => {
  return (
    <div
      className="banner h-32 sm:h-40 md:h-50"
      style={{ background: defaultBackgroundColor }}
    >
      {!!imgSrc && (
        <img
          className="h-32 sm:h-40 w-full object-cover md:h-50"
          alt="banner"
          src={imgSrc}
        />
      )}
      {!!children && children}
    </div>
  );
};

export default Banner;
