import { ActiveUser } from 'models/index';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getActiveUser } from 'redux/reducers';

const useActiveUser = (): ActiveUser => {
  return useAppSelector(state => {
    const activeUser = getActiveUser(state);

    invariant(activeUser, 'Active user should be defined.');

    return activeUser;
  });
};

export default useActiveUser;
