// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ReviewCycle, UserReview } from 'models';
import type { AppDispatch } from 'redux/actions';

import { history } from 'config/history';

import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToReviewCycleParticipants } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { removeParticipant } from 'redux/actions/resources/reviewCycle';
import { getRemoveParticipantSideEffects } from 'redux/actions/resources/sideEffects';

import { MenuItem } from 'components/navigation/Menus';

export function removeParticipantAction(
  reviewCycle: ReviewCycle,
  userReview: UserReview
) {
  return async (dispatch: AppDispatch) => {
    const { user } = userReview;

    await confirmAsync(
      __('Remove %1 from cycle', user.fullName),
      __(
        'Removing %1 from this review cycle will also delete the following items.',
        <b>{user.fullName}</b>
      ),
      {
        onConfirm: async () => {
          if (
            (await dispatch(removeParticipant(reviewCycle, userReview))) &&
            history.location.pathname !==
              pathToReviewCycleParticipants(reviewCycle.id)
          ) {
            navigate(pathToReviewCycleParticipants(reviewCycle.id));
          }
        },
        confirmLabel: __('Remove %1 from cycle', user.fullName),
        sideEffects: getRemoveParticipantSideEffects(userReview),
      }
    );
  };
}

type Props = {|
  reviewCycle: ReviewCycle,
  userReview: UserReview,
|};

type AfterConnectProps = {|
  removeParticipant: () => Promise<any>,
|};

const RemoveParticipantAction = ({ removeParticipant }: AfterConnectProps) => (
  <MenuItem isDanger onClick={removeParticipant}>
    {__('Remove from campaign')}
  </MenuItem>
);

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => ({
  removeParticipant: () =>
    dispatch(
      removeParticipantAction(ownProps.reviewCycle, ownProps.userReview)
    ),
});

export default (connect(
  null,
  mapDispatchToProps
)(RemoveParticipantAction): React.ComponentType<Props>);
