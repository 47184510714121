import React from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';

import {
  DraftSurveyCampaign,
  PaginatedCollection,
  SurveyCampaign,
} from 'models';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import {
  pathToSurveyCampaignEdit,
  pathToSurveyCampaignOverview,
} from 'helpers/paths';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withPagination,
} from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
  SimpleTable,
  Text,
  Tooltip,
} from 'components';

import CampaignStatusTag from '../components/CampaignStatusTag';
import pollingPeriodInfoByCampaignStatus from './helpers/pollingPeriodInfoByCampaignStatus';

type Props = WithPaginationProps;
type AfterPaginateProps = Props & PaginationProps;
type AfterDataLoaderProps = Props &
  PaginationProps &
  DataLoaderProvidedProps & {
    campaignCollection: PaginatedCollection<
      SurveyCampaign | DraftSurveyCampaign
    >;
  };

const CampaignList = ({
  search,
  page,
  countPerPage,
  campaignCollection,
  isFetching,
  hasError,
  onSearchChange,
  filter,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
  refetchData,
}: AfterDataLoaderProps) => {
  const campaigns = campaignCollection?.items || [];

  const organization = useOrganization();
  const dispatch = useAppDispatch();

  const duplicateCampaign = async (campaignId: string) => {
    await dispatch(post(`survey/campaigns/${campaignId}/duplicate`));
    refetchData();
  };

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={campaignCollection}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'not_archived', label: __('Not archived') },
          { param: 'archived', label: __('Archived') },
          { param: 'all', label: __('All') },
        ]}
        filter={filter}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <EmptyState
            title={__('No survey campaign matches your search')}
            inBoxList
          />
        )}
        totalCountRenderer={totalCount =>
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          n__('%1 survey campaign', '%1 survey campaigns', totalCount)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              columns={[
                {
                  header: __('Name'),
                  cell: campaign => (
                    <Text preset="16s5.5">
                      <Link
                        to={
                          campaign.status === 'draft'
                            ? pathToSurveyCampaignEdit(campaign.id)
                            : pathToSurveyCampaignOverview(campaign.id)
                        }
                      >
                        {campaign.name}
                      </Link>
                    </Text>
                  ),
                },
                {
                  header: __('Type'),
                  cell: campaign =>
                    campaign.recurrenceType === 'one_off'
                      ? __('One-off')
                      : __('Recurring'),
                },
                {
                  header: __('Status'),
                  cell: campaign => (
                    <CampaignStatusTag status={campaign.status} />
                  ),
                },
                {
                  header: __('Additional information'),
                  cell: campaign => pollingPeriodInfoByCampaignStatus(campaign),
                },
                {
                  header: '',
                  cell: campaign => {
                    const canDuplicate = can({
                      perform: 'duplicate',
                      on: campaign,
                    });
                    const DuplicateMenuItem = ({ disabled }) => (
                      <MenuItem
                        disabled={disabled}
                        key="duplicate"
                        onClick={() => duplicateCampaign(campaign.id)}
                      >
                        <Text>{__('Duplicate')}</Text>
                      </MenuItem>
                    );

                    if (!canDuplicate && !organization.plan.surveysEnabled)
                      return;

                    return (
                      <HamburgerMenu>
                        <MenuList>
                          <Tooltip
                            enabled={!canDuplicate}
                            content={__(
                              'As an entity admin, you can only duplicate campaigns created by you'
                            )}
                          >
                            <DuplicateMenuItem disabled={!canDuplicate} />
                          </Tooltip>
                        </MenuList>
                      </HamburgerMenu>
                    );
                  },
                },
              ]}
              rows={campaigns}
              keyFn={campaign => campaign.id}
              // TODO: Add an empty state
              emptyState={<div />}
              additionalClassName="mb-4"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, search, filter }: AfterPaginateProps) =>
      get('survey/campaigns', {
        page,
        countPerPage,
        search,
        filter,
      }),
    hydrate: {
      campaignCollection: {
        items: { abilities: {} },
      },
    },
    cacheKey: ({ page, countPerPage, search, filter }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter }),
  })
)(CampaignList);
