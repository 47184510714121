import React from 'react';

import { BoldHtmlText, Notification, Text } from 'components';

interface Props {
  text: string;
  additionalClassName?: string;
}

const BlueInfoBox = ({ text, additionalClassName }: Props) => (
  <Notification kind="primary" additionalClassName={additionalClassName}>
    <Text additionalClassName="mr-2">ℹ️</Text>
    <BoldHtmlText html={text} />
  </Notification>
);

export default BlueInfoBox;
